import { EmailInfo } from './../../Models/email-info';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { Urls } from 'src/app/Helpers/Urls';
import { Reminder } from 'src/app/Models/Reminder';
import { StringUpdater } from 'src/app/Models/Updater';
import { Variables } from 'src/app/Models/variables';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  url = new Urls();
  apiUrl: string;
  orgId: number;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = this.url.apiUrl;
      const v = new Variables();
      const val = localStorage.getItem('oi');
      const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);

      this.orgId = +oi;
      // this.orgId = +localStorage.getItem('organisation-id');
  }

  getReport(configid, startdate, enddate, key) {
    const v = new Variables();
    const val = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);

    this.orgId = +oi;
    const url = this.apiUrl + '/api/reports/' + this.orgId + '/' + configid + '/' + startdate + '/' + enddate + '/' + key;
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  getReportGeneric(configid, startdate, enddate, key, tids) {
    const v = new Variables();
    const val = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);

    this.orgId = +oi;
    const url = this.apiUrl + '/api/reports/' + this.orgId + '/' + configid + '/' + startdate + '/'
     + enddate + '/' + key + '/' + tids + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  generateProcessComplianceOverviewReport(configid, startdate, enddate) {
    const v = new Variables();
    const val = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);

    this.orgId = +oi;

    const url = this.apiUrl + '/api/reports/' + this.orgId + '/' + configid + '/' + startdate + '/' + enddate + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  postEmail(info: EmailInfo): Observable<any> {
    // const body = JSON.stringify(inst);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.post<any>(this.apiUrl + '/api/reports', info, options)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  private handleError(error: any) {
    // console.error('error', error);
    throw error;
    return error;
  }

}
