import { Configuration } from 'src/app/Models/Configuration';
import { Injectable } from '@angular/core';
import { Urls } from 'src/app/Helpers/Urls';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Updater } from 'src/app/Models/Updater';
import { Variables } from 'src/app/Models/variables';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  url = new Urls();
  apiUrl: string;
  orgId: number;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = this.url.apiUrl;
      const v = new Variables();
      const val = localStorage.getItem('oi');
      const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);

      this.orgId = +oi;
      // this.orgId = +localStorage.getItem('organisation-id');
  }


// tslint:disable-next-line: max-line-length
getConfigurations(type: string) {
  const v = new Variables();
  const val = localStorage.getItem('oi');
  const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);

  this.orgId = +oi;
  // tslint:disable-next-line: max-line-length
  const url = this.apiUrl + '/api/configurations/' + this.orgId + '/' + type + '/';
  return this.httpclient.get<any>(url)
  .pipe(
    tap( // Log the result or error
      error => this.handleError
    )
  );
}

getConfigurationsByType(type: string, page: number, size: number) {
  const v = new Variables();
  const val = localStorage.getItem('oi');
  const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);

  this.orgId = +oi;
  // tslint:disable-next-line: max-line-length
  const url = this.apiUrl + '/api/configurations/' + page + '/' + size + '/' + this.orgId + '/' + type;
  return this.httpclient.get<any>(url)
  .pipe(
    tap( // Log the result or error
      error => this.handleError
    )
  );
}

getConfigurationsByTypeWithCategory(type: string, page: number, size: number) {
  const v = new Variables();
  const val = localStorage.getItem('oi');
  const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);

  this.orgId = +oi;
  // tslint:disable-next-line: max-line-length
  const url = this.apiUrl + '/api/configurations/' + page + '/' + size + '/' + this.orgId + '/' + type + '/1';
  return this.httpclient.get<any>(url)
  .pipe(
    tap( // Log the result or error
      error => this.handleError
    )
  );
}

postConfiguration(config: Configuration): Observable<any> {
  // const body = JSON.stringify(inst);
  const options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  return this.httpclient.post(this.apiUrl + '/api/configurations', config, options)
                  .pipe(
                    catchError(this.handleError)
                  );
}

putConfiguration(id: number, upt: Updater): Observable<any> {
  // const body = JSON.stringify(inst);
  const options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  return this.httpclient.put(this.apiUrl + '/api/configurations/' + id, upt, options)
                  .pipe(
                    catchError(this.handleError)
                  );
}

putLoc(configid: number, loc: string): Observable<any> {
  // const body = JSON.stringify(inst);
  const options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  return this.httpclient.put(this.apiUrl + '/api/location/' + configid, loc, options)
                  .pipe(
                    catchError(this.handleError)
                  );
}

deleteConfiguration(id: number): Observable<any> {
  const options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  return this.httpclient.delete<any>(this.apiUrl + '/api/configurations/' + id, options)
                  .pipe(
                    catchError(this.handleError)
                  );
  }

  private handleError(error: any) {
    console.error('error', error);
    throw error;
    return error;
  }

}
