import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { FileService } from 'src/app/services/file/file.service';
import { ReportsService } from 'src/app/services/reports/reports.service';
import * as CryptoJS from 'crypto-js';

import * as FileSaver from 'file-saver';
import { Variables } from 'src/app/Models/variables';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-display-pdf',
  templateUrl: './display-pdf.component.html',
  styleUrls: ['./display-pdf.component.css']
})
export class DisplayPdfComponent implements OnInit {

  filename: string;
  pdfSource: any;

  file: any;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private messageService: MessageService,
    private reportService: ReportsService,
    private configService: ConfigurationService,
    private analyticsService: AnalyticsService,
    private fileService: FileService,
    private domSanitizer: DomSanitizer
  ) {
    this.translate.addLangs(['English', 'French']);
    this.translate.setDefaultLang('English');

    let browserLang = this.translate.getBrowserLang();

    const pass = 'ranitessarldev2019';
    const lang = cookieService.get('goepla-portal-language');
    if (lang.trim() !== '') {
      const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
      const lg = declang;
      browserLang = lg;
      localStorage.setItem('language', lg);
    }

    if (localStorage.getItem('language') !== null) {
      browserLang = localStorage.getItem('language');
    }
    this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    const v = new Variables();
    const val = localStorage.getItem('pdffile');
    const pdffile = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);
    this.file = JSON.parse(pdffile);
    this.filename = this.file.fileName;
    // this.pdfSource = this.domSanitizer.bypassSecurityTrustUrl(this.file.info.url).toString();
    this.pdfSource = this.file.info.url;
    console.log(this.pdfSource)
   }

  ngOnInit() {
  }

  downloadPdf() {
    this.fileService.downloadFile(this.file.info.url, this.file.info.metaData['Content-Type']).subscribe((res2) => {
      FileSaver.saveAs(res2, this.file.fileName);

      this.translate.get('GENERAL.SUCCESS').subscribe((title: string) => {
        this.translate.get('GENERAL.FILEDOWNLOADED').subscribe((text: string) => {
          this.showSuccess(title, text);
        });
      });
    }, err => this.handleError(err));
  }

  showSuccess(title, message) {
    this.messageService.add({ key: 'tc', severity: 'success', summary: title, detail: message });
  }

  showInfo(title, message) {
      this.messageService.add({ key: 'tc', severity: 'info', summary: title, detail: message });
  }

  showWarn(title, message) {
      this.messageService.add({ key: 'tc', severity: 'warn', summary: title, detail: message });
  }

  showError(title, message) {
      this.messageService.add({ key: 'tc', severity: 'error', summary: title, detail: message });
  }

  showSuccessDuration(title, message) {
    this.messageService.add({ key: 'tc', severity: 'success', summary: title, detail: message, life: 60000 });
  }

  showWarnDuration(title, message) {
      this.messageService.add({ key: 'tc', severity: 'warn', summary: title, detail: message, life: 60000 });
  }

  handleError(err) {
    console.log(err);
  }

}
