import { Updater, KeyValue, ConfigUpdater } from './../../Models/Updater';
import { Component, OnInit } from '@angular/core';
import { Configuration } from 'src/app/Models/Configuration';
import { UsrGrp } from 'src/app/Models/UsrGrp';
import { Group } from 'src/app/Models/Group';
import { User } from 'src/app/Models/User';
import { UsersgroupsService } from 'src/app/services/usersgroups/usersgroups.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { ConfirmationService } from 'primeng/api';
import { CookieService } from 'ngx-cookie-service';
import * as CryptoJS from 'crypto-js';
import { TypeRoleGroup, TypeRoleGroupData } from 'src/app/Models/role';

@Component({
  selector: 'app-edit-information-configuration',
  templateUrl: './edit-information-configuration.component.html',
  styleUrls: ['./edit-information-configuration.component.css']
})
export class EditInformationConfigurationComponent implements OnInit {

  organisations = [];
  organisation: any;

  configId: number;

  // users = new FormControl();
  userList = [];
  users = [];

  // groups = new FormControl();
  groupList = [];
  groups = [];

  us = [];
  gs = [];

  types = [];
  type = 'I';

  priorities = [];
  priority: any;

  vibrations = [];
  vibration: any;

  channels = [];
  channel: any;

  Organisation: number;
  MessageType: string;
  ConfigurationName: string;
  ConfigurationType: string;
  Priority: number;
  TimeUntilExpiry: number;
  Color: string;
  Vibration: number;
  Channel: string;
  TaskReactionTimeLimit = 0;
  TaskStartTimeLimit = 0;
  TaskClosureTimeLimit = 0;

  usrgrp: UsrGrp[] = [];
  usrgrp2 = [];

  isTask = false;

  isSave = false;
  loading = false;

  OrgName: string;
  QueueSize: any;

  inigroups1 = [];
  inigroups2 = [];

  eagroups1 = [];
  eagroups2 = [];

  angroups1 = [];
  angroups2 = [];

  adgroups1 = [];
  adgroups2 = [];

  cf: any;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private configService: ConfigurationService,
    private notificationSvc: NotificationService,
    private usersgroupsService: UsersgroupsService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService
  ) {
    // this.translate.addLangs(['English', 'French']);
    // this.translate.setDefaultLang('English');

    // let browserLang = this.translate.getBrowserLang();

    // const pass = 'ranitessarldev2019';
    // const lang = cookieService.get('goepla-portal-language');
    // if (lang.trim() !== '') {
    //   const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
    //   const lg = declang;
    //   browserLang = lg;
    // }

    // if (localStorage.getItem('language') !== null) {
    //   browserLang = localStorage.getItem('language');
    // }
    // this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    translate.use('English');

    // tslint:disable-next-line: deprecation
    translate.get('GENERAL.INFORMATION').subscribe((res1) => {
      this.types.push({label: res1, value: 'I'});
      // tslint:disable-next-line: deprecation
      translate.get('GENERAL.TASK').subscribe((res2) => {
        this.types.push({label: res2, value: 'T'});
      });
    });

      // tslint:disable-next-line: deprecation
    translate.get('GENERAL.CRITICAL').subscribe((res1) => {
      this.priorities.push({label: res1, value: 1});
      // tslint:disable-next-line: deprecation
      translate.get('GENERAL.HIGH').subscribe((res2) => {
        this.priorities.push({label: res2, value: 2});
        // tslint:disable-next-line: deprecation
        translate.get('GENERAL.NORMAL').subscribe((res3) => {
          this.priorities.push({label: res3, value: 3});
          // tslint:disable-next-line: deprecation
          translate.get('GENERAL.LOW').subscribe((res4) => {
            this.priorities.push({label: res4, value: 4});
            // tslint:disable-next-line: deprecation
            translate.get('GENERAL.VERYLOW').subscribe((res5) => {
              this.priorities.push({label: res5, value: 5});
            });
          });
        });
      });
    });

      // tslint:disable-next-line: deprecation
    translate.get('GENERAL.NONE').subscribe((res1) => {
      this.vibrations.push({label: res1, value: 0});
      // tslint:disable-next-line: deprecation
      translate.get('GENERAL.SHORT').subscribe((res2) => {
        this.vibrations.push({label: res2, value: 1});
        // tslint:disable-next-line: deprecation
        translate.get('GENERAL.LONG').subscribe((res3) => {
          this.vibrations.push({label: res3, value: 2});
          // tslint:disable-next-line: deprecation
          translate.get('GENERAL.VERYLONG').subscribe((res4) => {
            this.vibrations.push({label: res4, value: 3});
          });
        });
      });
    });

      // tslint:disable-next-line: deprecation
    translate.get('GENERAL.MQTT').subscribe((res1) => {
      this.channels.push({label: res1, value: 'M'});
      // tslint:disable-next-line: deprecation
      translate.get('GENERAL.SMS').subscribe((res2) => {
        this.channels.push({label: res2, value: 'S'});
        // tslint:disable-next-line: deprecation
        translate.get('GENERAL.EMAIL').subscribe((res3) => {
          this.channels.push({label: res3, value: 'E'});
        });
      });
    });

    const conf = JSON.parse(localStorage.getItem('config'));
    this.cf = conf;
    this.configId = conf.configurationId;
    this.Organisation = conf.organisationId;

    // this.getUsers();
    this.getGroups();
    this.getOrganisation();

    this.Organisation = conf.organisationId;
    this.type = conf.configurationType;
    this.ConfigurationName = conf.configurationName;
    this.priority = conf.priority;
    this.TimeUntilExpiry = conf.expiryTime;
    this.Color = conf.color;
    this.vibration = conf.vibrate;
    this.channel = conf.channel;
    this.TaskReactionTimeLimit = conf.reactionTimeLimit;
    this.TaskStartTimeLimit = conf.startTimeLimit;
    this.TaskClosureTimeLimit = conf.closeTimeLimit;
    if (conf.queueSize === null || conf.queueSize === 'null') {

    } else {
      this.QueueSize = conf.queueSize;
    }
    this.usrgrp = conf.usrGrp;
    this.usrgrp2 = conf.usrGrp;

    if (conf.configurationType === 'T') {
      this.isTask = true;
    } else {
      this.isTask = false;
    }
   }

  ngOnInit() {

  }

  setValues() {
    if (this.usrgrp2 != null) {
      const usrs = [];
      const grps = [];
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < this.usrgrp2.length; i++) {
        if (this.usrgrp2[i].idType === 'U') {
          const usr = new User();
          usr.UserId = this.usrgrp2[i].id;
          usr.UserName = this.usrgrp2[i].name;
          usrs.push(usr);
        } else if (this.usrgrp2[i].idType === 'G') {
          const grp = new Group();
          grp.GroupId = this.usrgrp2[i].id;
          grp.GroupName = this.usrgrp2[i].name;
          grps.push(grp);
        }
      }

      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < this.us.length; i++) {
        this.userList.push({label: this.us[i].UserName, value: this.us[i]});
        // tslint:disable-next-line: prefer-for-of
        for (let j = 0; j < usrs.length; j++) {
          if (this.us[i].userId === usrs[j].UserId) {
            this.users.push(this.us[i]);
          }
        }
      }

      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < this.gs.length; i++) {
        this.groupList.push({label: this.gs[i].GroupName, value: this.gs[i]});
        // tslint:disable-next-line: prefer-for-of
        for (let j = 0; j < grps.length; j++) {
          if (this.gs[i].groupId === grps[j].GroupId) {
            this.groups.push(this.gs[i]);
          }
        }
      }
    }
  }

  arrangeRoles() {
    if (this.gs !== null && this.gs.length > 0) {
      if (this.cf.typeRoleGroups !== null && this.cf.typeRoleGroups.length > 0) {
        for (const v of this.gs) {
          let ea = true;
          let an = true;
          let ad = true;
          let ini = true;
          for (const val of this.cf.typeRoleGroups) {
            if (val.typeRoleGroup.roleName === 'Expert-All') {
              if (v.groupId === val.typeRoleGroup.groupId) {
                this.eagroups2.push(v);
                ea = false;
              }
            }
            if (val.typeRoleGroup.roleName === 'Analytics') {
              if (v.groupId === val.typeRoleGroup.groupId) {
                this.angroups2.push(v);
                an = false;
              }
            }
            if (val.typeRoleGroup.roleName === 'Administrator') {
              if (v.groupId === val.typeRoleGroup.groupId) {
                this.adgroups2.push(v);
                ad = false;
              }
            }
            if (val.typeRoleGroup.roleName === 'Initiator') {
              if (v.groupId === val.typeRoleGroup.groupId) {
                this.inigroups2.push(v);
                ini = false;
              }
            }
          }

          if (ea === true) {
            this.eagroups1.push(v);
          }
          if (an === true) {
            this.angroups1.push(v);
          }
          if (ad === true) {
            this.adgroups1.push(v);
          }
          if (ini === true) {
            this.inigroups1.push(v);
          }
        }
      } else {
        for (const val of this.gs) {
          this.eagroups1.push(val);
          this.angroups1.push(val);
          this.adgroups1.push(val);
          this.inigroups1.push(val);
        }
      }
    }
  }

  getUsers() {
    // tslint:disable-next-line: deprecation
    this.usersgroupsService.getUsers().subscribe(async (res) => {
      if (res != null) {
        const userss = [];
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          res[i].UserName = res[i].firstName + ' ' + res[i].lastName;
          this.us.push(res[i]);
        }
        this.getGroups();
      }
    });
  }

  async getGroups() {
    this.loading = true;
    // tslint:disable-next-line: deprecation
    this.usersgroupsService.getGroups().subscribe((res) => {
      if (res != null) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          res[i].GroupName = res[i].groupName;
          this.gs.push(res[i]);
        }
        this.setValues();
        this.arrangeRoles();
      }
      this.loading = false;
    });
  }

  getOrganisation() {
    this.loading = true;
    // tslint:disable-next-line: deprecation
    this.usersgroupsService.getOrganisations().subscribe((res) => {
      if (res != null) {
        this.organisation = res;
        this.OrgName = res.organisationName;
        // tslint:disable-next-line: prefer-for-of
        /*for (let i = 0; i < res.length; i++) {
          // this.organisations.push(res[i]);
          this.organisations.push({label: res[i].organisationName + ' *', value: res[i]});
          if (res[i].organisationId === this.Organisation) {
            this.organisation = res[i];
          }
        }*/
      }
      this.loading = false;
    });
  }

  selectedMessageType(event) {
    if (event.value === 'I') {
      this.isTask = false;
    } else if (event.value === 'T') {
      this.isTask = true;
    }
  }

  selectedPriority(event) {

  }

  selectedColor(event) {

  }

  selectedVibration(event) {

  }

  selectedChannel(event) {

  }

  onSave() {
    if (this.organisation === undefined || this.organisation === null) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTORGANISATION').subscribe((res: string) => {
        this.confirm(res);
      });
      return;
    }
    if (this.type === undefined || this.type.toString().trim() === null || this.type.toString().trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTMESSAGETYPE').subscribe((res: string) => {
        this.confirm(res);
      });
      return;
    }
    if (this.ConfigurationName === undefined || this.ConfigurationName.toString().trim() === null
        || this.ConfigurationName.toString().trim() === ''
        || this.ConfigurationName.toString().trim() === ' ') {
          // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTERCONFIGURATIONNAME').subscribe((res: string) => {
        this.confirm(res);
      });
      return;
    }
    if (this.priority === undefined || this.priority.toString().trim() === null) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTPRIORITY').subscribe((res: string) => {
        this.confirm(res);
      });
      return;
    }
    if (this.TimeUntilExpiry === undefined || this.TimeUntilExpiry.toString().trim() === null
        || this.TimeUntilExpiry.toString().trim() === '') {
          // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTERTIMEUNTILEXPIRY').subscribe((res: string) => {
        this.confirm(res);
      });
      return;
    } else {
      if (isNaN(this.TimeUntilExpiry)) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.TIMEUNTILEXPIRYCHECK').subscribe((res: string) => {
          this.confirm(res);
        });
        return;
      }
    }
    if (this.Color === undefined || this.Color.toString().trim() === null) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTCOLOR').subscribe((res: string) => {
        this.confirm(res);
      });
      return;
    }
    if (this.vibration === undefined || this.vibration.toString().trim() === null) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTVIBRATEOPTION').subscribe((res: string) => {
        this.confirm(res);
      });
      return;
    }
    if (this.channel === undefined || this.channel.toString().trim() === null) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTCHANNEL').subscribe((res: string) => {
        this.confirm(res);
      });
      return;
    }
    if (this.QueueSize === undefined || this.QueueSize === null
        || this.QueueSize.toString().trim() === '') {
      this.QueueSize = 'null';
      } else {
      if (isNaN(this.QueueSize)) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.QUEUESIZENOTNUMBER').subscribe((res: string) => {
          this.confirm(res);
        });
        return;
      }
    }
    this.usrgrp = [];
    if (this.users != null) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < this.users.length; i++) {
        const usr = new UsrGrp();
        usr.Id = this.users[i].userId;
        usr.IdType = 'U';
        usr.Name = this.users[i].UserName;
        this.usrgrp.push(usr);
      }
    }

    if (this.groups != null) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < this.groups.length; i++) {
        const grp = new UsrGrp();
        grp.Id = this.groups[i].groupId;
        grp.IdType = 'G';
        grp.Name = this.groups[i].GroupName;
        this.usrgrp.push(grp);
      }
    }

    if (this.TimeUntilExpiry.toString().includes('.')) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.NOPOINTS').subscribe((res: string) => {
        this.confirm(res);
      });
      return;
    }

    this.isSave = true;
    this.loading = true;

    const values = new ConfigUpdater();
    values.Id = this.configId;
    values.Values = [];

    const name = new KeyValue();
    name.Key = 'name';
    name.Value = this.ConfigurationName;
    values.Values.push(name);

    const priority = new KeyValue();
    priority.Key = 'priority';
    priority.Value = this.priority;
    values.Values.push(priority);

    const explim = new KeyValue();
    explim.Key = 'exp_lim';
    explim.Value = this.TimeUntilExpiry.toString();
    values.Values.push(explim);

    const color = new KeyValue();
    color.Key = 'color';
    color.Value = this.Color;
    values.Values.push(color);

    const vibrate = new KeyValue();
    vibrate.Key = 'vibrate';
    vibrate.Value = this.vibration;
    values.Values.push(vibrate);

    const channel = new KeyValue();
    channel.Key = 'channel';
    channel.Value = this.channel;
    values.Values.push(channel);

    const queue = new KeyValue();
    queue.Key = 'queue';
    queue.Value = this.QueueSize;
    values.Values.push(queue);

    const usrgrp = new KeyValue();
    usrgrp.Key = 'usr_grp';
    usrgrp.Value = JSON.stringify(this.usrgrp);
    values.Values.push(usrgrp);

    const rgroups = [];
    if (this.eagroups2 !== null && this.eagroups2.length > 0) {
      for (const val of this.eagroups2) {
        const rgroup = new TypeRoleGroup();
        rgroup.GroupId = val.groupId;
        rgroup.ParentId = this.cf.configurationId;
        rgroup.RoleName = 'Expert-All';
        rgroup.RoleParentType = 'info_type';

        rgroups.push(rgroup);
      }
    }
    if (this.angroups2 !== null && this.angroups2.length > 0) {
      for (const val of this.angroups2) {
        const rgroup = new TypeRoleGroup();
        rgroup.GroupId = val.groupId;
        rgroup.ParentId = this.cf.configurationId;
        rgroup.RoleName = 'Analytics';
        rgroup.RoleParentType = 'info_type';

        rgroups.push(rgroup);
      }
    }
    if (this.adgroups2 !== null && this.adgroups2.length > 0) {
      for (const val of this.adgroups2) {
        const rgroup = new TypeRoleGroup();
        rgroup.GroupId = val.groupId;
        rgroup.ParentId = this.cf.configurationId;
        rgroup.RoleName = 'Administrator';
        rgroup.RoleParentType = 'info_type';

        rgroups.push(rgroup);
      }
    }
    if (this.inigroups2 !== null && this.inigroups2.length > 0) {
      for (const val of this.inigroups2) {
        const rgroup = new TypeRoleGroup();
        rgroup.GroupId = val.groupId;
        rgroup.ParentId = this.cf.configurationId;
        rgroup.RoleName = 'Initiator';
        rgroup.RoleParentType = 'info_type';

        rgroups.push(rgroup);
      }
    }

    const data = new TypeRoleGroupData();
    data.ParentId = this.cf.configurationId;
    data.RoleParentType = 'info_type';
    data.TypeRoleGroup = rgroups;

    const rg = new KeyValue();
    rg.Key = 'role_groups';
    rg.Value = JSON.stringify(data);
    values.Values.push(rg);

    // tslint:disable-next-line: deprecation
    this.configService.putConfiguration(this.configId, values).subscribe(() => {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.CONFIGURATIONUPDATED').subscribe((res: string) => {
        this.notificationSvc.info('', res, 2000);
      });
      this.router.navigateByUrl('/informationconfigurations');
      this.isSave = false;
      this.loading = false;
    }, err => this.handleError(err));
    this.isSave = false;
    this.loading = false;
  }

  onCancel() {
    this.router.navigateByUrl('/informationconfigurations');
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  handleError(err) {
    this.loading = false;
    if (err.error.Message === 'Input string was not in a correct format.') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.NOPOINTS').subscribe((res: string) => {
        this.confirm(res);
      });
    }

    if (this.QueueSize === null || this.QueueSize === 'null') {
      this.QueueSize = null;
    }
  }

}
