import { AppComponent } from './../../app.component';
import { Location } from '@angular/common';
import { PanelUpdater, KeyValue } from './../../Models/Updater';
import { Component, OnInit, ɵSWITCH_VIEW_CONTAINER_REF_FACTORY__POST_R3__ } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { ConfirmationService } from 'primeng/api';
import { PanelsService } from 'src/app/services/panels/panels.service';
import * as CryptoJS from 'crypto-js';
import { UsersgroupsService } from 'src/app/services/usersgroups/usersgroups.service';
import { TypeRoleGroup, TypeRoleGroupData } from 'src/app/Models/role';

@Component({
  selector: 'app-edit-panel-configuration',
  templateUrl: './edit-panel-configuration.component.html',
  styleUrls: ['./edit-panel-configuration.component.css']
})
export class EditPanelConfigurationComponent implements OnInit {

  loading: boolean;

  PanelName: any;
  PanelType: string;
  PanelDescription: string;
  pan: any;

  gs = [];

  ragroups1 = [];
  ragroups2 = [];

  adgroups1 = [];
  adgroups2 = [];

  constructor(
    private router: Router,
    private notificationSvc: NotificationService,
    public translate: TranslateService,
    private configService: ConfigurationService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private location: Location,
    private panelService: PanelsService,
    private appComp: AppComponent,
    private usersgroupsService: UsersgroupsService
  ) {
    // this.translate.addLangs(['English', 'French']);
    // this.translate.setDefaultLang('English');

    // let browserLang = this.translate.getBrowserLang();

    // const pass = 'ranitessarldev2019';
    // const lang = cookieService.get('goepla-portal-language');
    // if (lang.trim() !== '') {
    //   const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
    //   const lg = declang;
    //   browserLang = lg;
    // }

    // if (localStorage.getItem('language') !== null) {
    //   browserLang = localStorage.getItem('language');
    // }
    // this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    translate.use('English');

    /*this.translate.get('GENERAL.SELECTTASKCONFIGURATION').subscribe((res: string) => {
      this.configs1.push({ label: res + ' *', value: null });
      this.conf1 = this.configs1[0];
    });*/

    this.pan = JSON.parse(localStorage.getItem('panel'));
    this.PanelName = this.pan.panelName;
    this.PanelType = this.pan.panelType;
    this.PanelDescription = this.pan.panelDescription;
  }

  ngOnInit() {
    this.getGroups();
  }

  getGroups() {
    this.loading = true;
    // tslint:disable-next-line: deprecation
    this.usersgroupsService.getGroups().subscribe((res) => {
      if (res != null) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          res[i].GroupName = res[i].groupName;
          // this.groupList.push({label: res[i].GroupName, value: res[i]});
          this.gs.push(res[i]);
        }

        this.arrangeRoles();
      }
      this.loading = false;
    });
  }

  arrangeRoles() {
    if (this.gs !== null && this.gs.length > 0) {
      if (this.pan.typeRoleGroups !== null && this.pan.typeRoleGroups.length > 0) {
        for (const v of this.gs) {
          let ra = true;
          let ad = true;
          for (const val of this.pan.typeRoleGroups) {
            if (val.typeRoleGroup.roleName === 'Reader-All') {
              if (v.groupId === val.typeRoleGroup.groupId) {
                this.ragroups2.push(v);
                ra = false;
              }
            }
            if (val.typeRoleGroup.roleName === 'Administrator') {
              if (v.groupId === val.typeRoleGroup.groupId) {
                this.adgroups2.push(v);
                ad = false;
              }
            }
          }

          if (ra === true) {
            this.ragroups1.push(v);
          }
          if (ad === true) {
            this.adgroups1.push(v);
          }
        }
      } else {
        for (const val of this.gs) {
          this.ragroups1.push(val);
          this.adgroups1.push(val);
        }
      }
    }
  }

  onSave() {
    this.loading = true;

    if (this.PanelName === undefined || this.PanelName == null || this.PanelName.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.PROVIDEPANELNAME').subscribe((res: string) => {
        this.confirm(res);
      });

      this.loading = false;
    } else if (this.PanelDescription === undefined || this.PanelDescription == null || this.PanelDescription.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.PROVIDEPANELDESCRIPTION').subscribe((res: string) => {
        this.confirm(res);
      });

      this.loading = false;
    } else {
      const panel = new PanelUpdater();
      panel.Id = this.pan.panelId;
      panel.Values = [];

      const pname = new KeyValue();
      pname.Key = 'p_name';
      pname.Value = this.PanelName;
      panel.Values.push(pname);

      const pdesc = new KeyValue();
      pdesc.Key = 'p_desc';
      pdesc.Value = this.PanelDescription;
      panel.Values.push(pdesc);

      const rgroups = [];
      if (this.ragroups2 !== null && this.ragroups2.length > 0) {
        for (const val of this.ragroups2) {
          const rgroup = new TypeRoleGroup();
          rgroup.GroupId = val.groupId;
          rgroup.ParentId = this.pan.panelId;
          rgroup.RoleName = 'Reader-All';
          rgroup.RoleParentType = 'panel_type';

          rgroups.push(rgroup);
        }
      }
      if (this.adgroups2 !== null && this.adgroups2.length > 0) {
        for (const val of this.adgroups2) {
          const rgroup = new TypeRoleGroup();
          rgroup.GroupId = val.groupId;
          rgroup.ParentId = this.pan.panelId;
          rgroup.RoleName = 'Administrator';
          rgroup.RoleParentType = 'panel_type';

          rgroups.push(rgroup);
        }
      }

      const data = new TypeRoleGroupData();
      data.ParentId = this.pan.panelId;
      data.RoleParentType = 'panel_type';
      data.TypeRoleGroup = rgroups;

      const rg = new KeyValue();
      rg.Key = 'role_groups';
      rg.Value = JSON.stringify(data);
      panel.Values.push(rg);

      // tslint:disable-next-line: deprecation
      this.panelService.putPanel(panel.Id, panel).subscribe(async (res) => {
        await this.appComp.loadMenuAgain();
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.PANELCONFIGURATIONUPDATED').subscribe((resp: string) => {
          this.notificationSvc.info('', resp, 2000);
        });
        this.location.back();
        this.loading = false;
      }, err => this.handleError(err));
    }
  }

  onCancel() {
    this.loading = true;
    this.location.back();
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  handleError(err) {
    this.loading = false;
    if (err.error.MessageText === 'duplicate key value violates unique constraint "t_ntm_panel_panel_name_key"') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.PANELALREADYEXISTS').subscribe((res: string) => {
        this.confirm(res);
      });
    }
  }
}
