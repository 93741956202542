import { AppComponent } from './../../app.component';
import { PanelsService } from './../../services/panels/panels.service';
import { Panels } from './../../Models/Panels';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { ConfirmationService } from 'primeng/api';
import { CookieService } from 'ngx-cookie-service';
import { Location } from '@angular/common';
import * as CryptoJS from 'crypto-js';
import { Variables } from 'src/app/Models/variables';
import { UsersgroupsService } from 'src/app/services/usersgroups/usersgroups.service';
import { TypeRoleGroup } from 'src/app/Models/role';

@Component({
  selector: 'app-create-panel-configuration',
  templateUrl: './create-panel-configuration.component.html',
  styleUrls: ['./create-panel-configuration.component.css']
})
export class CreatePanelConfigurationComponent implements OnInit {

  types = [];
  type: any = 'Advanced';

  loading: boolean;

  PanelName: any;
  PanelDescription: string;

  ragroups1 = [];
  ragroups2 = [];

  adgroups1 = [];
  adgroups2 = [];

  constructor(
    private router: Router,
    private notificationSvc: NotificationService,
    public translate: TranslateService,
    private configService: ConfigurationService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private location: Location,
    private panelService: PanelsService,
    private appComp: AppComponent,
    private usersgroupsService: UsersgroupsService
  ) {
    // this.translate.addLangs(['English', 'French']);
    // this.translate.setDefaultLang('English');

    // let browserLang = this.translate.getBrowserLang();

    // const pass = 'ranitessarldev2019';
    // const lang = cookieService.get('goepla-portal-language');
    // if (lang.trim() !== '') {
    //   const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
    //   const lg = declang;
    //   browserLang = lg;
    // }

    // if (localStorage.getItem('language') !== null) {
    //   browserLang = localStorage.getItem('language');
    // }
    // this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    translate.use('English');

      // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.ADVANCED').subscribe((res1: string) => {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SIMPLIFIED').subscribe((res2: string) => {
        this.types.push({label: res1, value: 'Advanced'});
        this.types.push({label: res2, value: 'Simplified'});
      });
    });
  }

  ngOnInit() {
    this.getGroups();
  }

  getGroups() {
    this.loading = true;
    // tslint:disable-next-line: deprecation
    this.usersgroupsService.getGroups().subscribe((res) => {
      if (res != null) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          res[i].GroupName = res[i].groupName;
          // this.groupList.push({label: res[i].GroupName, value: res[i]});
          this.ragroups1.push(res[i]);
          this.adgroups1.push(res[i]);
        }
      }
      this.loading = false;
    });
  }

  onCreate() {
    this.loading = true;

    if (this.type === undefined || this.type.value === null) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTPANELTYPE').subscribe((res: string) => {
        this.confirm(res);
      });

      this.loading = false;
    } else if (this.PanelName === undefined || this.PanelName == null || this.PanelName.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.PROVIDEPANELNAME').subscribe((res: string) => {
        this.confirm(res);
      });

      this.loading = false;
    }  else if (this.PanelDescription === undefined || this.PanelDescription == null || this.PanelDescription.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.PROVIDEPANELDESCRIPTION').subscribe((res: string) => {
        this.confirm(res);
      });

      this.loading = false;
    } else {
      const panel = new Panels();

      const v = new Variables();
      const org = localStorage.getItem('oi');
      const oi = CryptoJS.AES.decrypt(org, v.pass).toString(CryptoJS.enc.Utf8);

      panel.OrganisationId = +oi;
      // panel.OrganisationId = +localStorage.getItem('organisation-id');
      panel.PanelName = this.PanelName;
      panel.PanelType = this.type;
      panel.PanelDescription = this.PanelDescription;

      const rgroups = [];
      if (this.ragroups2 !== null && this.ragroups2.length > 0) {
        for (const val of this.ragroups2) {
          const rgroup = new TypeRoleGroup();
          rgroup.GroupId = val.groupId;
          rgroup.RoleName = 'Reader-All';
          rgroup.RoleParentType = 'panel_type';

          rgroups.push(rgroup);
        }
      }
      if (this.adgroups2 !== null && this.adgroups2.length > 0) {
        for (const val of this.adgroups2) {
          const rgroup = new TypeRoleGroup();
          rgroup.GroupId = val.groupId;
          rgroup.RoleName = 'Administrator';
          rgroup.RoleParentType = 'panel_type';

          rgroups.push(rgroup);
        }
      }

      panel.RoleGroups = rgroups;

      // tslint:disable-next-line: deprecation
      this.panelService.postPanel(panel).subscribe(async (res) => {
        await this.appComp.loadMenuAgain();
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.PANELCONFIGURATIONCREATED').subscribe((resp: string) => {
          this.notificationSvc.info('', resp, 2000);
        });
        this.location.back();
        this.loading = false;
      }, err => this.handleError(err));
    }
  }

  onCancel() {
    this.location.back();
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  handleError(err) {
    this.loading = false;
    if (err.error.MessageText === 'duplicate key value violates unique constraint "t_ntm_panel_panel_name_key"') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.PANELALREADYEXISTS').subscribe((res: string) => {
        this.confirm(res);
      });
    }
  }
}
