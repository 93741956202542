import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { MonitorItemUpdater, KeyValue } from './../../Models/Updater';
import { MonitorItem } from './../../Models/monitor-item';
import { MonitorItemsService } from './../../services/monitor-items/monitor-items.service';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { CookieService } from 'ngx-cookie-service';
import { AppComponent } from 'src/app/app.component';
import { Monitor } from 'src/app/Models/Monitor';

@Component({
  selector: 'app-create-monitor-item',
  templateUrl: './create-monitor-item.component.html',
  styleUrls: ['./create-monitor-item.component.css']
})
export class CreateMonitorItemComponent implements OnInit {

  loading: boolean;

  MonitorName: string;
  MonitorItemName: string;

  configList = [];
  configs = [];

  page = 1;
  size = 100;
  configs1 = [];
  conf1: any;

  fmodes = [];
  fmode: any;

  constructor(
    private router: Router,
    private notificationSvc: NotificationService,
    public translate: TranslateService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private location: Location,
    private appComp: AppComponent,
    private monitorItemService: MonitorItemsService,
    private configurationService: ConfigurationService
  ) {
    // this.translate.addLangs(['English', 'French']);
    // this.translate.setDefaultLang('English');

    // let browserLang = this.translate.getBrowserLang();

    // const pass = 'ranitessarldev2019';
    // const lang = cookieService.get('goepla-portal-language');
    // if (lang.trim() !== '') {
    //   const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
    //   const lg = declang;
    //   browserLang = lg;
    // }

    // if (localStorage.getItem('language') !== null) {
    //   browserLang = localStorage.getItem('language');
    // }
    // this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    translate.use('English');

    const mon = JSON.parse(localStorage.getItem('monitor'));
    this.MonitorName = mon.monitorName;

    this.getConfigurations();
  }

  async ngOnInit() {
    const fmode1 = await this.translate.get('GENERAL.VISUALONLY').toPromise();
    const fmode2 = await this.translate.get('GENERAL.VISUALANDACOUSTIC').toPromise();
    this.fmodes.push({ label: fmode1, value: 1 });
    this.fmodes.push({ label: fmode2, value: 2 });

    this.fmode = 1;
  }

  getConfigs() {
    //
  }

  getConfigurations() {
    this.loading = true;
    // tslint:disable-next-line: deprecation
    this.configurationService.getConfigurations('T').subscribe((res) => {
      if (res != null) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          this.configs.push(res[i]);
          this.configs1.push({ label: res[i].configurationName, value: res[i] });
          /*if (res[i].configurationType === 'T') {
            this.configs.push(res[i]);
            this.configs1.push({ label: res[i].configurationName, value: res[i] });
          }*/
        }
      }
      this.loading = false;
    });
  }

  /*getConfigurations() {
    this.configurationService.getConfigurations('T').subscribe((res) => {
      if (res != null) {
        let count = 0;
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          this.configList.push({label: res[i].configurationName, value: res[i]});
          count += 1;
        }
      }
    });
  }*/

  onCreate() {
    this.loading = true;
    if (this.conf1 === undefined || this.conf1.value === null) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTCONFIGURATION').subscribe((res: string) => {
        this.confirm(res);
      });

      this.loading = false;
    } else if (this.MonitorItemName === undefined || this.MonitorItemName === null || this.MonitorItemName.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.PROVIDEMONITORITEMNAME').subscribe((res: string) => {
        this.confirm(res);
      });

      this.loading = false;
    } else {
      const monitor = new MonitorItem();
      monitor.MonitorId = +localStorage.getItem('monitor-id');
      monitor.MonitorItemName = this.MonitorItemName;
      monitor.ConfigurationId = this.conf1.configurationId;
      monitor.FocusMode = this.fmode;

      // tslint:disable-next-line: deprecation
      this.monitorItemService.postMonitorItem(monitor).subscribe((res) => {
        this.appComp.loadMenuAgain();
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.MONITORITEMCREATED').subscribe((resp: string) => {
          this.notificationSvc.info('', resp, 2000);
        });
        this.location.back();
        this.loading = false;
      }, err => this.handleError(err));
    }
  }

  onCancel() {
    this.location.back();
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  handleError(err) {
    this.loading = false;
    if (err.error.MessageText === 'duplicate key value violates unique constraint "t_ntm_monitor_item_monitor_item_name_key"') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.MONITORITEMALREADYEXISTS').subscribe((res: string) => {
        this.confirm(res);
      });
    }

  }

}
