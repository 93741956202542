export class Updater {
    public Id: number;
    public Values: KeyValue[];
}

export class KeyValue {
    public Key: string;
    public Value: string;
}

export class ConfigUpdater {
    public Id: number;
    public Values: KeyValue[];
}

export class TaskUpdater {
    public Id: string;
    public Values: KeyValue[];
    public AdditionalValue: string;
}

export class Int64Updater {
    public Id: number;
    public Values: KeyValue[];
}

export class PanelUpdater {
    public Id: number;
    public Values: KeyValue[];
}

export class PanelItemUpdater {
    public Id: number;
    public Values: KeyValue[];
}

export class DeviceUpdater {
    public Id: string;
    public Values: KeyValue[];
}

export class MonitorUpdater {
    public Id: number;
    public Values: KeyValue[];
}

export class MonitorItemUpdater {
    public Id: number;
    public Values: KeyValue[];
}

export class StringUpdater {
    public Id: string;
    public Values: KeyValue[];
}
