import { FileService } from './../../services/file/file.service';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { ReportsService } from './../../services/reports/reports.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ConfirmationService, MessageService } from 'primeng/api';
import * as CryptoJS from 'crypto-js';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';

import * as FileSaver from 'file-saver';
import { EmailInfo } from 'src/app/Models/email-info';
import { Variables } from 'src/app/Models/variables';

@Component({
  selector: 'app-dashboard-process-compliance-overview',
  templateUrl: './dashboard-process-compliance-overview.component.html',
  styleUrls: ['./dashboard-process-compliance-overview.component.css']
})
export class DashboardProcessComplianceOverviewComponent implements OnInit {

  fromrange: any;
  tillrange: any;

  start = ' ';
  end = ' ';

  tasktypes = [];
  tasktype: any;

  isapply = true;
  loading = false;
  allText: string;

  iskpi = false;
  kpi = 0;
  devtotal = 0;
  devavg = 0;
  closedwithoutdev = 0;
  percentclosedwithoutdev = 0;
  taskswithdev = 0;

  assignedontime = 0;
  startedontime = 0;
  closedontime = 0;

  assignedontimepercent = 0;
  startedontimepercent = 0;
  closedontimepercent = 0;

  taskscreated = 0;

  assigned = 0;
  started = 0;
  closed = 0;
  expired = 0;

  closedpercent = 0;
  expiredpercent = 0;

  time: string;

  maxDateFrom: any;
  maxDateTill: any;

  showemails = false;
  emails: any;
  comment: any;

  cancelText = 'Cancel';

  options: { title: { display: boolean; text: string; fontSize: number; }; legend: { position: string; }; };
  data: any;

  options2: { title: { display: boolean; text: string; fontSize: number; }; legend: { position: string; }; };
  data2: any;

  confid = 0;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private messageService: MessageService,
    private reportService: ReportsService,
    private configService: ConfigurationService,
    private analyticsService: AnalyticsService,
    private fileService: FileService
  ) {
      this.translate.addLangs(['English', 'French']);
      this.translate.setDefaultLang('English');

      let browserLang = this.translate.getBrowserLang();

      const pass = 'ranitessarldev2019';
      const lang = cookieService.get('goepla-portal-language');
      if (lang.trim() !== '') {
        const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
        const lg = declang;
        browserLang = lg;
        localStorage.setItem('language', lg);
      }

      if (localStorage.getItem('language') !== null) {
        browserLang = localStorage.getItem('language');
      }
      this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ALL').subscribe((resp: string) => {
        this.allText = resp;
      });

      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.CANCEL').subscribe((res) => {
        this.cancelText = res;
      });

      this.maxDateFrom = new Date();
      this.maxDateTill = new Date();
 }

  async ngOnInit() {
    let get = false;
    if (localStorage.getItem('startDate') !== undefined && localStorage.getItem('startDate') != null
    && localStorage.getItem('endDate') !== undefined && localStorage.getItem('endDate') != null
    && localStorage.getItem('startDate') !== 'null' && localStorage.getItem('endDate') !== 'null') {
      this.fromrange = new Date(localStorage.getItem('startDate'));
      this.tillrange = new Date(localStorage.getItem('endDate'));
      get = true;
    } else {
      const date = new Date();
      this.fromrange = new Date(date.setHours(date.getHours() - 1));
      this.tillrange = new Date();
      get = false;
    }

    if (localStorage.getItem('taskType') !== undefined && localStorage.getItem('taskType') != null
    && localStorage.getItem('taskType') !== 'null') {
      this.confid = +localStorage.getItem('taskType');
      get = true;
    } else {
      get = false;
    }

    localStorage.setItem('startDate', null);
    localStorage.setItem('endDate', null);
    localStorage.setItem('taskType', null);

    await this.getConfigurations();

    if (get === true) {
      this.apply();
    }
  }

  setMaxFrom() {
    this.maxDateFrom = new Date();
  }

  setMaxTill() {
    this.maxDateTill = new Date();
  }

  async getConfigurations() {
    this.loading = true;
    const res = await this.configService.getConfigurations('T').toPromise()
    .catch(err => this.handleError(err));

    this.tasktypes.push({ label: this.allText, value: 'All' });
    if (res != null) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < res.length; i++) {
        if (this.confid === 0) {
          this.tasktype = this.tasktypes[0].value;
        } else {
          if (res[i].configurationId === this.confid) {
            this.tasktype = res[i];
          }
        }
        this.tasktypes.push({ label: res[i].configurationName, value: res[i] });
      }
    }

    this.loading = false;
  }

  async getReport() {
    this.loading = true;

    let configid = 0;

    if (this.tasktype === 'All') {

    } else {
      configid = this.tasktype.configurationId;
    }

    let amount = '';
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.AMOUNT').subscribe((trans) => {
      amount = trans;
    });

    const report1 = await this.reportService.getReport(configid, this.start, this.end, 'PROCESSCOMPLIANCEKPI').toPromise()
    .catch(err => this.handleError(err));
    const report2 = await this.reportService.getReport(configid, this.start, this.end, 'CLOSEDWITHOUTDEVIATION').toPromise()
    .catch(err => this.handleError(err));
    const report3 = await this.reportService.getReport(configid, this.start, this.end, 'DEVIATIONDISTRIBUTIONBYTYPE').toPromise()
    .catch(err => this.handleError(err));

    if (report1 != null && report1.length > 0) {
      this.iskpi = true;
      for (const val of report1) {
        this.taskswithdev = val.not.length;
        this.kpi = Math.round((+val.value3 + Number.EPSILON) * 100) / 100;
        this.devtotal = val.value1;
        if (val.value4 !== '0') {
          this.devavg = Math.round((+val.value4 + Number.EPSILON) * 100) / 100;
        }
      }
    }

    if (report2 != null && report2.length > 0) {
      for (const val of report2) {
        if (val.not != null && val.not.length > 0) {
          this.closedwithoutdev = val.not.length;
          this.percentclosedwithoutdev = Math.round((+val.value1 + Number.EPSILON) * 100) / 100;
        }
      }
    }

    if (report3 != null && report3.length > 0) {
      const labs: string[] = [];
      const datasets: number[] = [];
      let count = 0;
      for (const val of report3) {
        labs.push(val.value1);
        datasets.push(Math.round((+val.value3 + Number.EPSILON) * 100) / 100);
        count += 1;
        if (count === report3.length) {
          this.data2 = {
            labels: labs.reverse(),
            datasets: [
                {
                    label: amount,
                    backgroundColor: '#42A5F5',
                    borderColor: '#1E88E5',
                    data: datasets.reverse()
                }
            ]
          };
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.DEVIATIONSDISTRIBUTIONBYTASKTYPE').subscribe((trans) => {
            this.options2 = {
              title: {
                  display: true,
                  text: '',
                  fontSize: 16
              },
              legend: {
                  position: 'top'
              }
            };
          });
        }
      }
    }

    this.loading = false;
  }

  async getAmount() {
    this.loading = true;

    let configid = 0;

    if (this.tasktype === 'All') {

    } else {
      configid = this.tasktype.configurationId;
    }

    this.time = ' ';
    const asg = await this.analyticsService.getDashboards2(configid, this.start, this.end, 0, 'assigned2', this.time).toPromise()
    .catch(err => this.handleError(err));
    const str = await this.analyticsService.getDashboards2(configid, this.start, this.end, 0, 'started2', this.time).toPromise()
    .catch(err => this.handleError(err));
    const cls = await this.analyticsService.getDashboards2(configid, this.start, this.end, 0, 'closed', this.time).toPromise()
    .catch(err => this.handleError(err));
    const exp = await this.analyticsService.getDashboards2(configid, this.start, this.end, 0, 'expired', this.time).toPromise()
    .catch(err => this.handleError(err));
    const assot = await this.analyticsService.getDashboards2(configid, this.start, this.end, 0, 'assignedontime', this.time).toPromise()
    .catch(err => this.handleError(err));
    const strot = await this.analyticsService.getDashboards2(configid, this.start, this.end, 0, 'startedontime', this.time).toPromise()
    .catch(err => this.handleError(err));
    const clsot = await this.analyticsService.getDashboards2(configid, this.start, this.end, 0, 'closedontime', this.time).toPromise()
    .catch(err => this.handleError(err));
    const crt = await this.analyticsService.getDashboards2(configid, this.start, this.end, 0, 'created', this.time).toPromise()
    .catch(err => this.handleError(err));
    const clsexp = await this.analyticsService.getDashboards2(configid, this.start, this.end, 0, 'closedexpired', this.time).toPromise()
    .catch(err => this.handleError(err));

    this.taskscreated = crt;

    this.assigned = asg;
    this.started = str;
    this.closed = cls;
    this.expired = exp;
    if (assot > 0) {
      this.assignedontime = assot;
      this.assignedontimepercent = Math.round((((assot / asg) * 100) + Number.EPSILON) * 100) / 100;
    }
    if (strot > 0) {
      this.startedontime = strot;
      this.startedontimepercent = Math.round((((strot / str) * 100) + Number.EPSILON) * 100) / 100;
    }
    if (clsot > 0) {
      this.closedontime = clsot;
      this.closedontimepercent = Math.round((((clsot / cls) * 100) + Number.EPSILON) * 100) / 100;
    }

    if (crt > 0 && cls > 0) {
      this.closedpercent = Math.round((((cls / crt) * 100) + Number.EPSILON) * 100) / 100;
    }

    if (crt > 0 && exp > 0) {
      this.expiredpercent = Math.round((((exp / crt) * 100) + Number.EPSILON) * 100) / 100;
    }

    this.loading = false;
  }

  async getAnalytics() {
    let configid = 0;

    if (this.tasktype === 'All') {

    } else {
      configid = this.tasktype.configurationId;
    }

    let open = '';
    let assigned = '';
    let started = '';
    let mins = '';
    let others = '';
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.OPEN').subscribe((trans) => {
      open = trans;
    });
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.ASSIGNED').subscribe((trans) => {
      assigned = trans;
    });
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.STARTED').subscribe((trans) => {
      started = trans;
    });
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.MINUTES').subscribe((trans) => {
      mins = trans;
    });
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.OTHERS').subscribe((trans) => {
      others = trans;
    });

    this.loading = true;

    const resp = await this.analyticsService.getAnalytics(configid, 'T', this.start, this.end,
    0, 'averagetimeonstatus', ' ').toPromise().catch(err => this.handleError(err));

    if (resp !== undefined && resp != null && (resp[0].value2 !== 0 || resp[1].value2 !== 0 || resp[2].value2 !== 0)) {
      let o = 0;
      let a = 0;
      let s = 0;
      let count = 0;
      for (const res of resp) {
        if (res.value1 === 'OPEN_TIME') {
          o = Math.round((res.value2 + Number.EPSILON) * 100) / 100;
        }
        if (res.value1 === 'ASSIGNED_TIME') {
          a = Math.round((res.value2 + Number.EPSILON) * 100) / 100;
        }
        if (res.value1 === 'STARTED_TIME') {
          s = Math.round((res.value2 + Number.EPSILON) * 100) / 100;
        }
        count += 1;
        if (resp.length === count) {
          this.data = {
            labels: [open, assigned, started, others],
            datasets: [
                {
                    label: mins,
                    backgroundColor: '#42A5F5',
                    borderColor: '#1E88E5',
                    data: [o, a, s, 0]
                }
            ]
          };
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.AVGTTCAVGTIMEONSTATUS').subscribe((trans) => {
            this.options = {
              title: {
                  display: true,
                  text: ' ',
                  fontSize: 16
              },
              legend: {
                  position: 'top'
              }
            };
          });
        }
      }
    }

    this.loading = false;
  }

  async apply() {
    if (this.fromrange === undefined || this.fromrange === null || this.tillrange === undefined || this.tillrange === null) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTTIMERANGE').subscribe((res) => {
        this.confirm(res);
      });
      return;
    } else if (this.tasktype === undefined || this.tasktype === null) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTTASKTYPE').subscribe((res) => {
        this.confirm(res);
      });
      return;
    } else {
      if (this.fromrange > this.tillrange) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.FROMRANGECANNOTBEGREATERTHANTORANGE').subscribe((res) => {
          this.confirm(res);
        });
        return;
      }

      this.start = this.fromrange.toJSON().toString();
      this.end = this.tillrange.toJSON().toString();

      this.loading = true;
      this.isapply = false;

      this.iskpi = false;
      this.kpi = 0;
      this.devtotal = 0;
      this.devavg = 0;
      this.closedwithoutdev = 0;
      this.percentclosedwithoutdev = 0;

      this.assignedontime = 0;
      this.startedontime = 0;
      this.closedontime = 0;

      this.assignedontimepercent = 0;
      this.startedontimepercent = 0;
      this.closedontimepercent = 0;

      this.taskscreated = 0;

      this.assigned = 0;
      this.started = 0;
      this.closed = 0;
      this.expired = 0;

      this.closedpercent = 0;
      this.expiredpercent = 0;

      this.data = null;
      this.data2 = null;

      // call function here
      await this.getReport();
      await this.getAmount();
      await this.getAnalytics();

      this.loading = false;
      this.isapply = true;
    }
  }

  async generatepdf() {
    this.loading = true;
    let configid = 0;

    if (this.fromrange === undefined || this.fromrange === null || this.tillrange === undefined || this.tillrange === null) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTTIMERANGE').subscribe((res) => {
        this.confirm(res);
      });
      return;
    }
    if (this.tasktype === undefined || this.tasktype === null) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTTASKTYPE').subscribe((res) => {
        this.confirm(res);
      });
      return;
    }
    if (this.fromrange > this.tillrange) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.FROMRANGECANNOTBEGREATERTHANTORANGE').subscribe((res) => {
        this.confirm(res);
      });
      return;
    }

    if (this.tasktype === 'All') {

    } else {
      configid = this.tasktype.configurationId;
    }

    this.start = this.fromrange.toJSON().toString();
    this.end = this.tillrange.toJSON().toString();

    const file = await this.reportService.generateProcessComplianceOverviewReport(configid, this.start, this.end)
    .toPromise().catch(err => this.handleError(err));

    if (file != null && file.fileId != null) {
      const v = new Variables();
      const pdffile = CryptoJS.AES.encrypt(JSON.stringify(file), v.pass).toString();
      /*localStorage.setItem('pdffile', pdffile);

      localStorage.setItem('startDate', this.start);
      localStorage.setItem('endDate', this.end);
      localStorage.setItem('taskType', configid.toString());

      this.router.navigateByUrl('display-pdf');*/

      // tslint:disable-next-line: deprecation
      this.fileService.downloadFile(file.info.url, file.info.metaData['Content-Type']).subscribe((res2) => {
        FileSaver.saveAs(res2, file.fileName);

      // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.SUCCESS').subscribe((title: string) => {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.FILEDOWNLOADED').subscribe((text: string) => {
            this.showSuccess(title, text);
          });
        });
      }, err => this.handleError(err));
    } else {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ERROR').subscribe((title: string) => {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.COULDNOTGENERATEPDF').subscribe((text: string) => {
          this.showError(title, text);
        });
      });
    }

    this.loading = false;
  }

  async generatepdf2() {
    this.loading = true;
    let configid = 0;

    if (this.fromrange === undefined || this.fromrange === null || this.tillrange === undefined || this.tillrange === null) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTTIMERANGE').subscribe((res) => {
        this.confirm(res);
      });
      return;
    }
    if (this.tasktype === undefined || this.tasktype === null) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTTASKTYPE').subscribe((res) => {
        this.confirm(res);
      });
      return;
    }
    if (this.fromrange > this.tillrange) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.FROMRANGECANNOTBEGREATERTHANTORANGE').subscribe((res) => {
        this.confirm(res);
      });
      return;
    }

    if (this.tasktype === 'All') {

    } else {
      configid = this.tasktype.configurationId;
    }

    this.start = this.fromrange.toJSON().toString();
    this.end = this.tillrange.toJSON().toString();

    const file = await this.reportService.generateProcessComplianceOverviewReport(configid, this.start, this.end)
    .toPromise().catch(err => this.handleError(err));

    if (file != null && file.fileId != null) {
      const v = new Variables();
      const pdffile = CryptoJS.AES.encrypt(JSON.stringify(file), v.pass).toString();
      localStorage.setItem('pdffile', pdffile);

      localStorage.setItem('startDate', this.start);
      localStorage.setItem('endDate', this.end);
      localStorage.setItem('taskType', configid.toString());

      this.router.navigateByUrl('display-pdf');
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SUCCESS').subscribe((title: string) => {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.REPORTGENERATED').subscribe((text: string) => {
          this.showSuccess(title, text);
        });
      });
    } else {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ERROR').subscribe((title: string) => {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.COULDNOTGENERATEPDF').subscribe((text: string) => {
          this.showError(title, text);
        });
      });
    }

    this.loading = false;
  }

  showAddEmails() {
    this.showemails = true;
  }

  async sendEmail() {
    if (this.emails === undefined || this.emails == null) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.PROVIDEEMAILS').subscribe((res) => {
        this.confirm(res);
      });
      return;
    } else {
      this.loading = true;
      let configid = 0;

      if (this.fromrange === undefined || this.fromrange === null || this.tillrange === undefined || this.tillrange === null) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.SELECTTIMERANGE').subscribe((res) => {
          this.confirm(res);
        });
        return;
      }
      if (this.tasktype === undefined || this.tasktype === null) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.SELECTTASKTYPE').subscribe((res) => {
          this.confirm(res);
        });
        return;
      }
      if (this.fromrange > this.tillrange) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.FROMRANGECANNOTBEGREATERTHANTORANGE').subscribe((res) => {
          this.confirm(res);
        });
        return;
      }

      if (this.tasktype === 'All') {

      } else {
        configid = this.tasktype.configurationId;
      }

      this.start = this.fromrange.toJSON().toString();
      this.end = this.tillrange.toJSON().toString();

      const file = await this.reportService.generateProcessComplianceOverviewReport(configid, this.start, this.end)
      .toPromise().catch(err => this.handleError(err));

      const info = new EmailInfo();
      info.Emails = this.emails;
      if (this.comment === undefined || this.comment == null) {
        info.Comment = '';
      } else {
        info.Comment = this.comment;
      }
      info.PdfUrl = file.info.url;
      info.Title = file.fileName;

      const resp = await this.reportService.postEmail(info).toPromise()
      .catch(err => this.handleError(err));

      if (resp != null && resp === true) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.SUCCESS').subscribe((title) => {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.EMAILSENT').subscribe((text) => {
            this.showSuccess(title, text);
          });
        });

        this.emails = null;
        this.comment = null;
        this.showemails = false;
      } else {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.ERROR').subscribe((title) => {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.EMAILNOTSENT').subscribe((text) => {
            this.showError(title, text);
          });
        });
      }
      this.loading = false;
      }
  }

  cancel() {
    this.emails = null;
    this.comment = null;
    this.showemails = false;
  }

  test() {
    /*const now = new Date();
    this.fromrange = new Date(now.setFullYear(new Date().getFullYear() - 1));
    this.tillrange = new Date(now.setFullYear(new Date().getFullYear() + 1));*/
    this.emails = [ 'ranitessarl@gmail.com' ];
  }

  confirm(text) {
    this.confirmationService.confirm({
        message: text,
        accept: async () => {
            // Accept logic
        },
        reject: () => {
          // Reject logic
        }
    });
  }

  showSuccess(title, message) {
    this.messageService.add({ key: 'tc', severity: 'success', summary: title, detail: message });
  }

  showInfo(title, message) {
      this.messageService.add({ key: 'tc', severity: 'info', summary: title, detail: message });
  }

  showWarn(title, message) {
      this.messageService.add({ key: 'tc', severity: 'warn', summary: title, detail: message });
  }

  showError(title, message) {
      this.messageService.add({ key: 'tc', severity: 'error', summary: title, detail: message });
  }

  showSuccessDuration(title, message) {
    this.messageService.add({ key: 'tc', severity: 'success', summary: title, detail: message, life: 60000 });
  }

  showWarnDuration(title, message) {
      this.messageService.add({ key: 'tc', severity: 'warn', summary: title, detail: message, life: 60000 });
  }

  handleError(err) {
    this.loading = false;
    this.isapply = true;
    console.log(err);
  }

}
