import { Router } from '@angular/router';
import { ChannelNotService } from './../../services/channel-not/channel-not.service';
import { UserChannelService } from './../../services/user-channel/user-channel.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { KeycloakService } from 'keycloak-angular';
import { MessageService } from 'primeng/api';
import { Variables } from 'src/app/Models/variables';
import * as CryptoJS from 'crypto-js';
import { CookieService } from 'ngx-cookie-service';
import { CatClassService } from 'src/app/services/cat-class/cat-class.service';
import { CategoryService } from 'src/app/services/category/category.service';
import { ClassItemService } from 'src/app/services/class-item/class-item.service';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { ContactService } from 'src/app/services/contact/contact.service';
import { DevicesService } from 'src/app/services/devices/devices.service';
import { GroupDefService } from 'src/app/services/group-def/group-def.service';
import { NotLogService } from 'src/app/services/not-log/not-log.service';
import { TaskClassificationService } from 'src/app/services/task-classification/task-classification.service';
import { UsersService } from 'src/app/services/users/users.service';
import { UsersgroupsService } from 'src/app/services/usersgroups/usersgroups.service';
import { KeyValue, TaskUpdater } from 'src/app/Models/Updater';
import { TasksService } from 'src/app/services/tasks/tasks.service';
import { ConfirmationService } from 'primeng/api';
import { TaskClassification } from 'src/app/Models/task-classification';
import { ChannelService } from 'src/app/services/channel/channel.service';

@Component({
  selector: 'app-channel',
  templateUrl: './channel.component.html',
  styleUrls: ['./channel.component.css']
})
export class ChannelComponent implements OnInit {

  colorpalette = ['#CC9999', '#B3B31A', '#00E680', '#FF99E6', '#CCFF1A',
                  '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
                  '#80B300', '#809900', '#E6B3B3', '#4D80CC', '#9900B3',
                  '#4D8066', '#809980', '#E6FF80', '#6680B3', '#66991A',
                  '#E6331A', '#33FFCC', '#66994D', '#B366CC', '#4D8000',
                  '#B33300', '#CC80CC', '#FF3380', '#CCCC00', '#66E64D',
                  '#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
                  '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
                  '#E666B3', '#33991A', '#1AFF33', '#999933', '#FF1A66'];

  // months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September',
  //          'October', 'November', 'December'];
  months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep',
          'Oct', 'Nov', 'Dec'];
  menuheight: any;

  divheight = '0px';
  divheight2 = '0px';
  contentdiv = '';
  innerdiv = '';
  innerdivmobile = '';
  paneldiv = '';
  paneldivmobile = '';
  dialogheight = '';
  panelwidth = '';
  panelmaxwidth = '';

  channels = [];
  channel: any;

  showfilter = true;

  opentasks = [];
  assignedtasks = [];
  startedtasks = [];
  closedtasks = [];

  ocount = 0;
  acount = 0;
  scount = 0;
  ccount = 0;

  doptions: any = { year: 'numeric', month: 'long', day: 'numeric',
  hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false };

  // doptions: any = { year: 'numeric', month: 'short', day: 'numeric',
  // hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false };

  tids = ' ';
  page = 1;
  opage = 1;
  apage = 1;
  spage = 1;
  cpage = 1;
  size = 100;

  isopenloadmore = false;
  isassignedloadmore = false;
  isstartedloadmore = false;
  isclosedloadmore = false;

  loading = false;

  isdetail = false;

  isopendrop = true;
  isassigneddrop = false;
  isstarteddrop = false;
  iscloseddrop = false;

  isopendrag = false;
  isassigneddrag = false;
  isstarteddrag = false;
  iscloseddrag = true;

  opendivname = '';
  assigneddivname = '';
  starteddivname = '';
  closeddivname = '';

  openbgcolor = 'transparent';
  assignedbgcolor = 'transparent';
  startedbgcolor = 'transparent';
  closedbgcolor = 'transparent';

  users = [];
  user: any;
  display = false;
  isuserapply = false;

  not: any;

  iscategorize = false;
  isclassdisabled = false;

  classes = [];
  class: any;

  citems = [];
  citem: any;

  notval: any;

  cname = '';

  issavedisabled = true;

  channelnames = [];

  padding = '0px';

  master = 'x';
  detail = 'y';

  isadm = false;

  constructor(
    private domSanitizer: DomSanitizer,
    private translate: TranslateService,
    private messageService: MessageService,
    private channelService: ChannelService,
    private keycloak: KeycloakService,
    private fb: FormBuilder,
    private userChanService: UserChannelService,
    private chanNotService: ChannelNotService,
    private usergroupService: UsersgroupsService,
    private userService: UsersService,
    private cookieService: CookieService,
    private deviceService: DevicesService,
    private configService: ConfigurationService,
    private contactService: ContactService,
    private cgroupService: GroupDefService,
    private notlogService: NotLogService,
    private catclassService: CatClassService,
    private itemService: ClassItemService,
    private catService: CategoryService,
    private tcService: TaskClassificationService,
    private tasksService: TasksService,
    private confirmationService: ConfirmationService,
    private router: Router
  ) {
    this.translate.addLangs(['English', 'French']);
    this.translate.setDefaultLang('English');

    const menuheight = document.getElementById('menu').offsetHeight;
    const contentheight = window.innerHeight;

    this.menuheight = menuheight;
    if (menuheight !== undefined && menuheight !== null
      && contentheight !== undefined && contentheight !== null) {
      this.contentdiv = (+contentheight - menuheight - 20) + 'px';
    } else {
      this.contentdiv = '85vh';
    }
    const v = new Variables();
    const p = localStorage.getItem('ups');
    if (p != null) {
      const val = CryptoJS.AES.decrypt(p, v.pass).toString(CryptoJS.enc.Utf8);
      const perm = JSON.parse(val);
      if (perm.includes('NTM.Administration.Full')) {
        this.isadm = true;
      }
    }
  }

  async ngOnInit() {
    this.onResize(null);
    await this.getUserChannels();
  }

  async getUserChannels() {
    this.showLoad();
    const v = new Variables();
    const va = localStorage.getItem('ugids');
    const mgs = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
    const gs = mgs.split(':').join(',');

    const u = localStorage.getItem('ui');
    const ui = CryptoJS.AES.decrypt(u, v.pass).toString(CryptoJS.enc.Utf8);

    const chans = await this.userChanService.getUserChannelsGeneric(0, 0, gs, +ui).toPromise()
    .catch(err => this.handleError(err));

    if (chans !== undefined && chans !== null && chans.length > 0) {
      let count = 0;
      for (const val of chans) {
        val.color = 'white';
        if (val.not.lastNotificationTime !== '0001-01-01T00:00:00') {
          const date = new Date(val.not.lastNotificationTime);
          val.timevalue = this.arrangeDate(date);
        }
        if (val.not.taskCount > 99) {
          val.tCount = '99+';
        } else {
          val.tCount = val.not.taskCount;
        }
        if (val.not.infoCount > 99) {
          val.iCount = '99+';
        } else {
          val.iCount = val.not.infoCount;
        }
        if (val.channel.channelIcon !== null) {
          val.chanImage = this.domSanitizer.bypassSecurityTrustUrl(val.channel.channelIcon);
          val.Image = '';
        } else {
          val.chanImage = '';
          val.Image = val.channel.channelName.charAt(0).toUpperCase();

          val.col = this.colorpalette[count];
          count += 1;
          if (count === this.colorpalette.length) {
            count = 0;
          }
        }
        if (!this.channelnames.includes(val.channel.channelName)) {
          this.channelnames.push(val.channel.channelName);
          this.channels.push(val);
        }
      }
    }
    this.hideLoad();
  }

  async getChannelNots(typeids) {
    this.showLoad();
    this.tids = typeids;
    this.opentasks = [];
    this.assignedtasks = [];
    this.startedtasks = [];
    this.closedtasks = [];
    this.ocount = 0;
    this.acount = 0;
    this.scount = 0;
    this.ccount = 0;

    const v = new Variables();
    const va = localStorage.getItem('ugids');
    const mgs = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
    const gs = mgs.split(':').join(',');

    const u = localStorage.getItem('ui');
    const ui = CryptoJS.AES.decrypt(u, v.pass).toString(CryptoJS.enc.Utf8);

    const nots = await this.chanNotService.getChannelNotifications(this.page, this.size, typeids, gs, +ui).toPromise()
    .catch(err => this.handleError(err));

    this.tids = typeids;
    this.opentasks = [];
    this.assignedtasks = [];
    this.startedtasks = [];
    this.closedtasks = [];
    this.ocount = 0;
    this.acount = 0;
    this.scount = 0;
    this.ccount = 0;

    if (nots !== undefined && nots !== null) {
      if (nots.open !== undefined && nots.open !== null && nots.open.length > 0) {
        // this.ocount = nots.open.length;
        this.ocount = nots.openCount;
        for (const val of nots.open) {
          if (new Date(val.locationConfirmation).getFullYear() === 1) {
            val.loc = false;
          } else {
            val.loc = true;
          }

          if (val.notOrders !== null && val.notOrders.length > 0) {
            const vorders = [];
            for (const ov of val.notOrders) {
              if (ov.order.orderStatus === 'open') {
                ov.color = 'yellow';
              } else {
                ov.color = 'green';
              }
              const date = new Date(ov.order.created);
              ov.orderName = date.getFullYear().toString() + date.getMonth().toString()
               + date.getDate().toString() + '-' + ov.order.orderId;

              vorders.push(ov);
            }

            val.notOrder = vorders;
          }

          if (val.location !== null) {
            val.opt = true;
            const options = [];
            this.translate.get('GENERAL.ORDERS').subscribe((resp1: string) => {
              options.push({label: resp1 + ' ', value: 'Orders',
               command: async (event) => { await this.manage(event, val); } });
            });
            val.options = options;
          } else {
            val.opt = false;
          }

          this.opentasks.push(val);
        }

        if (nots.open.length >= this.size) {
          this.isopenloadmore = true;
        } else {
          this.isopenloadmore = false;
        }
      }
      if (nots.assigned !== undefined && nots.assigned !== null && nots.assigned.length > 0) {
        // this.acount = nots.assigned.length;
        this.acount = nots.assignedCount;
        for (const val of nots.assigned) {
          if (new Date(val.locationConfirmation).getFullYear() === 1) {
            val.loc = false;
          } else {
            val.loc = true;
          }

          if (val.notOrders !== null && val.notOrders.length > 0) {
            const vorders = [];
            for (const ov of val.notOrders) {
              if (ov.order.orderStatus === 'open') {
                ov.color = 'yellow';
              } else {
                ov.color = 'green';
              }
              const date = new Date(ov.order.created);
              ov.orderName = date.getFullYear().toString() + date.getMonth().toString()
               + date.getDate().toString() + '-' + ov.order.orderId;

              vorders.push(ov);
            }

            val.notOrder = vorders;
          }

          if (val.location !== null) {
            val.opt = true;
            const options = [];
            this.translate.get('GENERAL.ORDERS').subscribe((resp1: string) => {
              options.push({label: resp1 + ' ', value: 'Orders',
               command: async (event) => { await this.manage(event, val); } });
            });
            val.options = options;
          } else {
            val.opt = false;
          }

          this.assignedtasks.push(val);
        }

        if (nots.assigned.length >= this.size) {
          this.isassignedloadmore = true;
        } else {
          this.isassignedloadmore = false;
        }
      }
      if (nots.started !== undefined && nots.started !== null && nots.started.length > 0) {
        // this.scount = nots.started.length;
        this.scount = nots.startedCount;
        for (const val of nots.started) {
          if (new Date(val.locationConfirmation).getFullYear() === 1) {
            val.loc = false;
          } else {
            val.loc = true;
          }

          if (val.notOrders !== null && val.notOrders.length > 0) {
            const vorders = [];
            for (const ov of val.notOrders) {
              if (ov.order.orderStatus === 'open') {
                ov.color = 'yellow';
              } else {
                ov.color = 'green';
              }
              const date = new Date(ov.order.created);
              ov.orderName = date.getFullYear().toString() + date.getMonth().toString()
               + date.getDate().toString() + '-' + ov.order.orderId;

              vorders.push(ov);
            }

            val.notOrder = vorders;
          }

          if (val.location !== null) {
            val.opt = true;
            const options = [];
            this.translate.get('GENERAL.ORDERS').subscribe((resp1: string) => {
              options.push({label: resp1 + ' ', value: 'Orders',
               command: async (event) => { await this.manage(event, val); } });
            });
            val.options = options;
          } else {
            val.opt = false;
          }

          this.startedtasks.push(val);
        }

        if (nots.started.length >= this.size) {
          this.isstartedloadmore = true;
        } else {
          this.isstartedloadmore = false;
        }
      }
      if (nots.closed !== undefined && nots.closed !== null && nots.closed.length > 0) {
        // this.ccount = nots.closed.length;
        this.ccount = nots.closedCount;
        for (const val of nots.closed) {
          if (new Date(val.locationConfirmation).getFullYear() === 1) {
            val.loc = false;
          } else {
            val.loc = true;
          }

          if (val.notOrders !== null && val.notOrders.length > 0) {
            const vorders = [];
            for (const ov of val.notOrders) {
              if (ov.order.orderStatus === 'open') {
                ov.color = 'yellow';
              } else {
                ov.color = 'green';
              }
              const date = new Date(ov.order.created);
              ov.orderName = date.getFullYear().toString() + date.getMonth().toString()
               + date.getDate().toString() + '-' + ov.order.orderId;

              vorders.push(ov);
            }

            val.notOrder = vorders;
          }

          if (val.location !== null) {
            val.opt = true;
            const options = [];
            this.translate.get('GENERAL.ORDERS').subscribe((resp1: string) => {
              options.push({label: resp1 + ' ', value: 'Orders',
               command: async (event) => { await this.manage(event, val); } });
            });
            val.options = options;
          } else {
            val.opt = false;
          }

          this.closedtasks.push(val);
        }

        if (nots.closed.length >= this.size) {
          this.isclosedloadmore = true;
        } else {
          this.isclosedloadmore = false;
        }
      }
    }

    this.hideLoad();
  }

  async getOpenNots() {
    this.showLoad();
    this.opage += 1;

    const v = new Variables();
    const va = localStorage.getItem('ugids');
    const mgs = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
    const gs = mgs.split(':').join(',');

    const u = localStorage.getItem('ui');
    const ui = CryptoJS.AES.decrypt(u, v.pass).toString(CryptoJS.enc.Utf8);

    const nots = await this.chanNotService.getNotifications(this.opage, this.size, ' ', 0, 'T', ' ', ' ', 0, 0, ' ',
    0, ' ', 'open', ' ', ' ', this.tids, ' ', 'xyz', gs, +ui).toPromise().catch(err => this.handleError(err));

    if (nots !== undefined && nots !== null && nots.length > 0) {
      // this.ocount += nots.length;
      for (const val of nots) {
        if (new Date(val.locationConfirmation).getFullYear() === 1) {
          val.loc = false;
        } else {
          val.loc = true;
        }

        if (val.notOrders !== null && val.notOrders.length > 0) {
          const vorders = [];
          for (const ov of val.notOrders) {
            if (ov.order.orderStatus === 'open') {
              ov.color = 'yellow';
            } else {
              ov.color = 'green';
            }
            const date = new Date(ov.order.created);
            ov.orderName = date.getFullYear().toString() + date.getMonth().toString()
             + date.getDate().toString() + '-' + ov.order.orderId;

            vorders.push(ov);
          }

          val.notOrder = vorders;
        }

        if (val.location !== null) {
          val.opt = true;
          const options = [];
          this.translate.get('GENERAL.ORDERS').subscribe((resp1: string) => {
            options.push({label: resp1 + ' ', value: 'Orders',
             command: async (event) => { await this.manage(event, val); } });
          });
          val.options = options;
        } else {
          val.opt = false;
        }

        this.opentasks.push(val);
      }

      if (nots.length >= this.size) {
        this.isopenloadmore = true;
      } else {
        this.isopenloadmore = false;
      }
    } else {
      this.isopenloadmore = false;
    }

    this.hideLoad();
  }

  async getAssignedNots() {
    this.showLoad();
    this.apage += 1;

    const v = new Variables();
    const va = localStorage.getItem('ugids');
    const mgs = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
    const gs = mgs.split(':').join(',');

    const u = localStorage.getItem('ui');
    const ui = CryptoJS.AES.decrypt(u, v.pass).toString(CryptoJS.enc.Utf8);

    const nots = await this.chanNotService.getNotifications(this.apage, this.size, ' ', 0, 'T', ' ', ' ', 0, 0, ' ',
    0, ' ', 'assigned', ' ', ' ', this.tids, ' ', 'xyz', gs, +ui).toPromise().catch(err => this.handleError(err));

    if (this.apage === 1) {
      this.assignedtasks = [];
    }

    if (nots !== undefined && nots !== null && nots.length > 0) {
      // this.acount += nots.length;
      for (const val of nots) {
        if (new Date(val.locationConfirmation).getFullYear() === 1) {
          val.loc = false;
        } else {
          val.loc = true;
        }

        if (val.notOrders !== null && val.notOrders.length > 0) {
          const vorders = [];
          for (const ov of val.notOrders) {
            if (ov.order.orderStatus === 'open') {
              ov.color = 'yellow';
            } else {
              ov.color = 'green';
            }
            const date = new Date(ov.order.created);
            ov.orderName = date.getFullYear().toString() + date.getMonth().toString()
             + date.getDate().toString() + '-' + ov.order.orderId;

            vorders.push(ov);
          }

          val.notOrder = vorders;
        }

        if (val.location !== null) {
          val.opt = true;
          const options = [];
          this.translate.get('GENERAL.ORDERS').subscribe((resp1: string) => {
            options.push({label: resp1 + ' ', value: 'Orders',
             command: async (event) => { await this.manage(event, val); } });
          });
          val.options = options;
        } else {
          val.opt = false;
        }

        this.assignedtasks.push(val);
      }

      if (nots.length >= this.size) {
        this.isassignedloadmore = true;
      } else {
        this.isassignedloadmore = false;
      }
    } else {
      this.isassignedloadmore = false;
    }

    this.hideLoad();
  }

  async getStartedNots() {
    this.showLoad();
    this.spage += 1;

    const v = new Variables();
    const va = localStorage.getItem('ugids');
    const mgs = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
    const gs = mgs.split(':').join(',');

    const u = localStorage.getItem('ui');
    const ui = CryptoJS.AES.decrypt(u, v.pass).toString(CryptoJS.enc.Utf8);

    const nots = await this.chanNotService.getNotifications(this.spage, this.size, ' ', 0, 'T', ' ', ' ', 0, 0, ' ',
    0, ' ', 'started', ' ', ' ', this.tids, ' ', 'xyz', gs, +ui).toPromise().catch(err => this.handleError(err));

    if (this.spage === 1) {
      this.startedtasks = [];
    }

    if (nots !== undefined && nots !== null && nots.length > 0) {
      // this.scount += nots.length;
      for (const val of nots) {
        if (new Date(val.locationConfirmation).getFullYear() === 1) {
          val.loc = false;
        } else {
          val.loc = true;
        }

        if (val.notOrders !== null && val.notOrders.length > 0) {
          const vorders = [];
          for (const ov of val.notOrders) {
            if (ov.order.orderStatus === 'open') {
              ov.color = 'yellow';
            } else {
              ov.color = 'green';
            }
            const date = new Date(ov.order.created);
            ov.orderName = date.getFullYear().toString() + date.getMonth().toString()
             + date.getDate().toString() + '-' + ov.order.orderId;

            vorders.push(ov);
          }

          val.notOrder = vorders;
        }

        if (val.location !== null) {
          val.opt = true;
          const options = [];
          this.translate.get('GENERAL.ORDERS').subscribe((resp1: string) => {
            options.push({label: resp1 + ' ', value: 'Orders',
             command: async (event) => { await this.manage(event, val); } });
          });
          val.options = options;
        } else {
          val.opt = false;
        }

        this.startedtasks.push(val);
      }

      if (nots.length >= this.size) {
        this.isstartedloadmore = true;
      } else {
        this.isstartedloadmore = false;
      }
    } else {
      this.isstartedloadmore = false;
    }

    this.hideLoad();
  }

  async getClosedNots() {
    this.showLoad();
    this.cpage += 1;

    const v = new Variables();
    const va = localStorage.getItem('ugids');
    const mgs = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
    const gs = mgs.split(':').join(',');

    const u = localStorage.getItem('ui');
    const ui = CryptoJS.AES.decrypt(u, v.pass).toString(CryptoJS.enc.Utf8);

    const nots = await this.chanNotService.getNotifications(this.cpage, this.size, ' ', 0, 'T', ' ', ' ', 0, 0, ' ',
    0, ' ', 'closed', ' ', ' ', this.tids, ' ', 'xyz', gs, +ui).toPromise().catch(err => this.handleError(err));

    if (this.cpage === 1) {
      this.closedtasks = [];
    }

    if (nots !== undefined && nots !== null && nots.length > 0) {
      // this.ccount = nots.length;
      for (const val of nots) {
        if (new Date(val.locationConfirmation).getFullYear() === 1) {
          val.loc = false;
        } else {
          val.loc = true;
        }

        if (val.notOrders !== null && val.notOrders.length > 0) {
          const vorders = [];
          for (const ov of val.notOrders) {
            if (ov.order.orderStatus === 'open') {
              ov.color = 'yellow';
            } else {
              ov.color = 'green';
            }
            const date = new Date(ov.order.created);
            ov.orderName = date.getFullYear().toString() + date.getMonth().toString()
             + date.getDate().toString() + '-' + ov.order.orderId;

            vorders.push(ov);
          }

          val.notOrder = vorders;
        }

        if (val.location !== null) {
          val.opt = true;
          const options = [];
          this.translate.get('GENERAL.ORDERS').subscribe((resp1: string) => {
            options.push({label: resp1 + ' ', value: 'Orders',
             command: async (event) => { await this.manage(event, val); } });
          });
          val.options = options;
        } else {
          val.opt = false;
        }

        this.closedtasks.push(val);
      }

      if (nots.length >= this.size) {
        this.isclosedloadmore = true;
      } else {
        this.isclosedloadmore = false;
      }
    } else {
      this.isclosedloadmore = false;
    }

    this.hideLoad();
  }

  async channelClicked(chan) {
    const newchannels = [];
    for (const val of this.channels) {
      const newchan = val;
      if (val.channel.channelId === chan.channel.channelId) {
        newchan.color = '#c9e2ee';
      } else {
        newchan.color = 'white';
      }

      newchannels.push(newchan);
    }

    this.channels = newchannels;
    this.channel = chan;

    this.page = 1;
    this.opage = 1;
    this.apage = 1;
    this.spage = 1;
    this.cpage = 1;

    this.isopenloadmore = false;
    this.isassignedloadmore = false;
    this.isstartedloadmore = false;
    this.isclosedloadmore = false;

    if (chan.channelTaskTypes !== undefined && chan.channelTaskTypes !== null && chan.channelTaskTypes.length > 0) {
      let ids = '';
      for (const val of chan.channelTaskTypes) {
        ids += val.taskTypeId + ',';
      }

      await this.getChannelNots(ids);
    } else {
      this.ocount = 0;
      this.acount = 0;
      this.scount = 0;
      this.ccount = 0;
      this.opentasks = [];
      this.assignedtasks = [];
      this.startedtasks = [];
      this.closedtasks = [];
    }

    this.onResize(null);
    this.isdetail = true;

    this.master = 'w';
    this.detail = 'z';
  }

  async manage(event, notif) {
    event.value = event.item.value;
    // localStorage.setItem('notification', JSON.stringify(notif));
    if (event.value === 'Orders') {
      // this.router.navigateByUrl('/orders');
      this.router.navigate(['/orders'],
       { state: { notId: notif.notificationId, loc: notif.location } });
    }
  }

  backClicked() {
    this.detail = 'y';
    this.master = 'x';
  }

  drop(event) {
    this.opendivname = 'normaldiv';
    this.assigneddivname = 'normaldiv';
    this.starteddivname = 'normaldiv';
    this.closeddivname = 'normaldiv';

    this.openbgcolor = 'transparent';
    this.assignedbgcolor = 'transparent';
    this.startedbgcolor = 'transparent';
    this.closedbgcolor = 'transparent';
  }

  dragStart(event, op) {
    if (op.assigned === '0001-01-01T00:00:00') {
      this.opendivname = 'disableddiv';
      this.assigneddivname = 'possiblediv';
      this.starteddivname = 'disableddiv';
      this.closeddivname = 'disableddiv';

      this.openbgcolor = 'transparent';
      this.assignedbgcolor = '#d6ebd6';
      this.startedbgcolor = 'transparent';
      this.closedbgcolor = 'transparent';
    }
  }

  dropOpen(event) {
  }

  dropAssigned(event) {
    const v = new Variables();
    let rown = false;
    let rall = false;
    let init = false;
    let eall = false;
    let eas = false;
    // check roles
    if (this.not.configuration.typeRoleGroups !== null && this.not.configuration.typeRoleGroups.length > 0) {
      const gis = localStorage.getItem('gi');
      const gs = CryptoJS.AES.decrypt(gis, v.pass).toString(CryptoJS.enc.Utf8);
      const gids = JSON.parse(gs);

      for (const ro of this.not.configuration.typeRoleGroups) {
        if (ro.typeRoleGroup.roleName === 'Reader-Own' && gids.includes(ro.typeRoleGroup.groupId)) {
          rown = true;
        }
        if (ro.typeRoleGroup.roleName === 'Reader-All' && gids.includes(ro.typeRoleGroup.groupId)) {
          rown = true;
          rall = true;
        }
        if (ro.typeRoleGroup.roleName === 'Initiator' && gids.includes(ro.typeRoleGroup.groupId)) {
          init = true;
        }
        if (ro.typeRoleGroup.roleName === 'Expert-All' && gids.includes(ro.typeRoleGroup.groupId)) {
          eas = true;
          eall = true;
        }
        if (ro.typeRoleGroup.roleName === 'Expert-Assigned' && gids.includes(ro.typeRoleGroup.groupId)) {
          eas = true;
        }
      }
    }
    if (this.isadm === true) {
      rown = true;
      rall = true;
      init = true;
      eall = true;
      eas = true;
    }

    const va = localStorage.getItem('ui');
    const ui = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
    if ((this.not.assigned !== '0001-01-01T00:00:00' && this.not.started === '0001-01-01T00:00:00') ||
    this.not.configuration.workflow === 'OC' || this.not.configuration.workflow === 'OSC' ||
    (this.not.configuration.workflow === 'OASC' && this.not.assigned !== '0001-01-01T00:00:00'
     && this.not.taskUserId === +ui && eas === false) ||
     (this.not.configuration.workflow === 'OASC' && this.not.assigned !== '0001-01-01T00:00:00'
      && this.not.taskUserId === +ui && eall === false && eas === false) ||
      (this.not.configuration.workflow === 'OASC' && this.not.assigned !== '0001-01-01T00:00:00'
       && this.not.taskUserId !== +ui) ||
       (this.not.assigned === '0001-01-01T00:00:00' && eall === false)) {
      return;
    }
    // if ((this.not.assigned !== '0001-01-01T00:00:00' && this.not.started === '0001-01-01T00:00:00') ||
    // this.not.configuration.workflow === 'OC' || this.not.configuration.workflow === 'OSC' ||
    // (this.not.configuration.workflow === 'OASC' && this.not.assigned !== '0001-01-01T00:00:00' && this.not.taskUserId !== +ui)) {
    //   return;
    // }
    this.getUsers(this.not);
  }

  dropStarted(event) {
    const v = new Variables();
    let rown = false;
    let rall = false;
    let init = false;
    let eall = false;
    let eas = false;
    // check roles
    if (this.not.configuration.typeRoleGroups !== null && this.not.configuration.typeRoleGroups.length > 0) {
      const gis = localStorage.getItem('gi');
      const gs = CryptoJS.AES.decrypt(gis, v.pass).toString(CryptoJS.enc.Utf8);
      const gids = JSON.parse(gs);

      for (const ro of this.not.configuration.typeRoleGroups) {
        if (ro.typeRoleGroup.roleName === 'Reader-Own' && gids.includes(ro.typeRoleGroup.groupId)) {
          rown = true;
        }
        if (ro.typeRoleGroup.roleName === 'Reader-All' && gids.includes(ro.typeRoleGroup.groupId)) {
          rown = true;
          rall = true;
        }
        if (ro.typeRoleGroup.roleName === 'Initiator' && gids.includes(ro.typeRoleGroup.groupId)) {
          init = true;
        }
        if (ro.typeRoleGroup.roleName === 'Expert-All' && gids.includes(ro.typeRoleGroup.groupId)) {
          eas = true;
          eall = true;
        }
        if (ro.typeRoleGroup.roleName === 'Expert-Assigned' && gids.includes(ro.typeRoleGroup.groupId)) {
          eas = true;
        }
      }
    }
    if (this.isadm === true) {
      rown = true;
      rall = true;
      init = true;
      eall = true;
      eas = true;
    }
    const va = localStorage.getItem('ui');
    const ui = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
    if (this.not.started !== '0001-01-01T00:00:00' ||
     (this.not.assigned === '0001-01-01T00:00:00' && this.not.configuration.workflow === 'OASC') ||
     this.not.configuration.workflow === 'OC' ||
     (this.not.configuration.workflow === 'OSC' && eall === false) ||
     (this.not.configuration.workflow !== 'OSC' && this.not.taskUserId === +ui && eas === false) ||
     (this.not.configuration.workflow !== 'OSC' && this.not.taskUserId !== +ui && eall === false)) {
      return;
    }
    // if (this.not.started !== '0001-01-01T00:00:00' ||
    //  (this.not.assigned === '0001-01-01T00:00:00' && this.not.configuration.workflow === 'OASC') ||
    //  this.not.configuration.workflow === 'OC' ||
    //  (this.not.configuration.workflow !== 'OSC' && this.not.taskUserId !== +ui)) {
    //   return;
    // }
    this.startTask();
  }

  dropClosed(event) {
    const v = new Variables();
    let rown = false;
    let rall = false;
    let init = false;
    let eall = false;
    let eas = false;
    // check roles
    if (this.not.configuration.typeRoleGroups !== null && this.not.configuration.typeRoleGroups.length > 0) {
      const gis = localStorage.getItem('gi');
      const gs = CryptoJS.AES.decrypt(gis, v.pass).toString(CryptoJS.enc.Utf8);
      const gids = JSON.parse(gs);

      for (const ro of this.not.configuration.typeRoleGroups) {
        if (ro.typeRoleGroup.roleName === 'Reader-Own' && gids.includes(ro.typeRoleGroup.groupId)) {
          rown = true;
        }
        if (ro.typeRoleGroup.roleName === 'Reader-All' && gids.includes(ro.typeRoleGroup.groupId)) {
          rown = true;
          rall = true;
        }
        if (ro.typeRoleGroup.roleName === 'Initiator' && gids.includes(ro.typeRoleGroup.groupId)) {
          init = true;
        }
        if (ro.typeRoleGroup.roleName === 'Expert-All' && gids.includes(ro.typeRoleGroup.groupId)) {
          eas = true;
          eall = true;
        }
        if (ro.typeRoleGroup.roleName === 'Expert-Assigned' && gids.includes(ro.typeRoleGroup.groupId)) {
          eas = true;
        }
      }
    }
    if (this.isadm === true) {
      rown = true;
      rall = true;
      init = true;
      eall = true;
      eas = true;
    }
    const va = localStorage.getItem('ui');
    const ui = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
    if ((this.not.configuration.workflow !== 'OC' && this.not.started === '0001-01-01T00:00:00') ||
    (this.not.configuration.workflow !== 'OC' && this.not.taskUserId === +ui && eas === false) ||
    (this.not.configuration.workflow !== 'OC' && this.not.taskUserId !== +ui && eall === false)) {
      return;
    }
    // if ((this.not.configuration.workflow !== 'OC' && this.not.started === '0001-01-01T00:00:00') ||
    // (this.not.configuration.workflow !== 'OC' && this.not.taskUserId !== +ui)) {
    //   return;
    // }
    this.closeTask();
  }

  dragStartOpen(event, mynot) {
    let rown = false;
    let rall = false;
    let init = false;
    let eall = false;
    let eas = false;
    const v = new Variables();
    // check roles
    if (mynot.configuration.typeRoleGroups !== null && mynot.configuration.typeRoleGroups.length > 0) {
      const gis = localStorage.getItem('gi');
      const gs = CryptoJS.AES.decrypt(gis, v.pass).toString(CryptoJS.enc.Utf8);
      const gids = JSON.parse(gs);

      for (const ro of mynot.configuration.typeRoleGroups) {
        if (ro.typeRoleGroup.roleName === 'Reader-Own' && gids.includes(ro.typeRoleGroup.groupId)) {
          rown = true;
        }
        if (ro.typeRoleGroup.roleName === 'Reader-All' && gids.includes(ro.typeRoleGroup.groupId)) {
          rown = true;
          rall = true;
        }
        if (ro.typeRoleGroup.roleName === 'Initiator' && gids.includes(ro.typeRoleGroup.groupId)) {
          init = true;
        }
        if (ro.typeRoleGroup.roleName === 'Expert-All' && gids.includes(ro.typeRoleGroup.groupId)) {
          eas = true;
          eall = true;
        }
        if (ro.typeRoleGroup.roleName === 'Expert-Assigned' && gids.includes(ro.typeRoleGroup.groupId)) {
          eas = true;
        }
      }
    }
    if (this.isadm === true) {
      rown = true;
      rall = true;
      init = true;
      eall = true;
      eas = true;
    }
    this.not = mynot;
    if (this.not.configuration.workflow === 'OASC') {
      this.opendivname = 'disableddiv';
      this.assigneddivname = 'disableddiv';
      this.starteddivname = 'disableddiv';
      this.closeddivname = 'disableddiv';
      if (eall === true) {
        this.assigneddivname = 'possiblediv';
      }
    } else if (this.not.configuration.workflow === 'OSC') {
      this.opendivname = 'disableddiv';
      this.assigneddivname = 'disableddiv';
      this.starteddivname = 'disableddiv';
      this.closeddivname = 'disableddiv';
      if (eall === true) {
        this.starteddivname = 'possiblediv';
      }
    } else if (this.not.configuration.workflow === 'OC') {
      this.opendivname = 'disableddiv';
      this.assigneddivname = 'disableddiv';
      this.starteddivname = 'disableddiv';
      this.closeddivname = 'disableddiv';
      if (eall === true) {
        this.closeddivname = 'possiblediv';
      }
    }

    // if (this.not.configuration.workflow === 'OASC') {
    //   this.opendivname = 'disableddiv';
    //   this.assigneddivname = 'possiblediv';
    //   this.starteddivname = 'disableddiv';
    //   this.closeddivname = 'disableddiv';
    // } else if (this.not.configuration.workflow === 'OSC') {
    //   this.opendivname = 'disableddiv';
    //   this.assigneddivname = 'disableddiv';
    //   this.starteddivname = 'possiblediv';
    //   this.closeddivname = 'disableddiv';
    // } else if (this.not.configuration.workflow === 'OC') {
    //   this.opendivname = 'disableddiv';
    //   this.assigneddivname = 'disableddiv';
    //   this.starteddivname = 'disableddiv';
    //   this.closeddivname = 'possiblediv';
    // }
  }

  dragStartAssigned(event, mynot) {
    let rown = false;
    let rall = false;
    let init = false;
    let eall = false;
    let eas = false;
    const v = new Variables();
    // check roles
    if (mynot.configuration.typeRoleGroups !== null && mynot.configuration.typeRoleGroups.length > 0) {
      const gis = localStorage.getItem('gi');
      const gs = CryptoJS.AES.decrypt(gis, v.pass).toString(CryptoJS.enc.Utf8);
      const gids = JSON.parse(gs);

      for (const ro of mynot.configuration.typeRoleGroups) {
        if (ro.typeRoleGroup.roleName === 'Reader-Own' && gids.includes(ro.typeRoleGroup.groupId)) {
          rown = true;
        }
        if (ro.typeRoleGroup.roleName === 'Reader-All' && gids.includes(ro.typeRoleGroup.groupId)) {
          rown = true;
          rall = true;
        }
        if (ro.typeRoleGroup.roleName === 'Initiator' && gids.includes(ro.typeRoleGroup.groupId)) {
          init = true;
        }
        if (ro.typeRoleGroup.roleName === 'Expert-All' && gids.includes(ro.typeRoleGroup.groupId)) {
          eas = true;
          eall = true;
        }
        if (ro.typeRoleGroup.roleName === 'Expert-Assigned' && gids.includes(ro.typeRoleGroup.groupId)) {
          eas = true;
        }
      }
    }
    if (this.isadm === true) {
      rown = true;
      rall = true;
      init = true;
      eall = true;
      eas = true;
    }

    this.not = mynot;
    this.opendivname = 'disableddiv';
    this.assigneddivname = 'disableddiv';
    // this.starteddivname = 'possiblediv';
    this.starteddivname = 'disableddiv';
    this.closeddivname = 'disableddiv';

    const va = localStorage.getItem('ui');
    const ui = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
    // if (mynot.taskUserId !== +ui) {
    //   this.starteddivname = 'disableddiv';
    // }
    if ((mynot.taskUserId === +ui && eas === true) || (mynot.taskUserId === +ui && eall === true)) {
      this.starteddivname = 'possiblediv';
    }
  }

  dragStartStarted(event, mynot) {
    let rown = false;
    let rall = false;
    let init = false;
    let eall = false;
    let eas = false;
    const v = new Variables();
    // check roles
    if (mynot.configuration.typeRoleGroups !== null && mynot.configuration.typeRoleGroups.length > 0) {
      const gis = localStorage.getItem('gi');
      const gs = CryptoJS.AES.decrypt(gis, v.pass).toString(CryptoJS.enc.Utf8);
      const gids = JSON.parse(gs);

      for (const ro of mynot.configuration.typeRoleGroups) {
        if (ro.typeRoleGroup.roleName === 'Reader-Own' && gids.includes(ro.typeRoleGroup.groupId)) {
          rown = true;
        }
        if (ro.typeRoleGroup.roleName === 'Reader-All' && gids.includes(ro.typeRoleGroup.groupId)) {
          rown = true;
          rall = true;
        }
        if (ro.typeRoleGroup.roleName === 'Initiator' && gids.includes(ro.typeRoleGroup.groupId)) {
          init = true;
        }
        if (ro.typeRoleGroup.roleName === 'Expert-All' && gids.includes(ro.typeRoleGroup.groupId)) {
          eas = true;
          eall = true;
        }
        if (ro.typeRoleGroup.roleName === 'Expert-Assigned' && gids.includes(ro.typeRoleGroup.groupId)) {
          eas = true;
        }
      }
    }
    if (this.isadm === true) {
      rown = true;
      rall = true;
      init = true;
      eall = true;
      eas = true;
    }
    this.not = mynot;
    this.opendivname = 'disableddiv';

    const va = localStorage.getItem('ui');
    const ui = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
    if (mynot.reassignSize > 0 && mynot.configuration.workflow === 'OASC') {
      if ((mynot.taskUserId === +ui && eas === true) || (mynot.taskUserId === +ui && eall === true)) {
        this.assigneddivname = 'possiblediv';
      } else {
        this.assigneddivname = 'disableddiv';
      }
    } else {
      this.assigneddivname = 'disableddiv';
    }
    this.starteddivname = 'disableddiv';
    this.closeddivname = 'disableddiv';

    if ((mynot.taskUserId === +ui && eas === true) || (mynot.taskUserId === +ui && eall === true)) {
      this.closeddivname = 'possiblediv';
    }

    // this.opendivname = 'disableddiv';
    // if (mynot.reassignSize > 0 && mynot.configuration.workflow === 'OASC') {
    //   this.assigneddivname = 'possiblediv';
    // } else {
    //   this.assigneddivname = 'disableddiv';
    // }
    // this.starteddivname = 'disableddiv';
    // this.closeddivname = 'possiblediv';

    // const va = localStorage.getItem('ui');
    // const ui = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
    // if (mynot.taskUserId !== +ui) {
    //   this.assigneddivname = 'disableddiv';
    //   this.closeddivname = 'disableddiv';
    // }
  }

  dragStartClosed(event, mynot) {
  }

  dragEndOpen(event) {
    this.opendivname = 'normaldiv';
    this.assigneddivname = 'normaldiv';
    this.starteddivname = 'normaldiv';
    this.closeddivname = 'normaldiv';
  }

  dragEndAssigned(event) {
    this.opendivname = 'normaldiv';
    this.assigneddivname = 'normaldiv';
    this.starteddivname = 'normaldiv';
    this.closeddivname = 'normaldiv';
  }

  dragEndStarted(event) {
    this.opendivname = 'normaldiv';
    this.assigneddivname = 'normaldiv';
    this.starteddivname = 'normaldiv';
    this.closeddivname = 'normaldiv';
  }

  dragEndClosed(event) {
    this.opendivname = 'normaldiv';
    this.assigneddivname = 'normaldiv';
    this.starteddivname = 'normaldiv';
    this.closeddivname = 'normaldiv';
  }

  arrangeDate(date: Date) {
    const newdate = new Date(date.toLocaleDateString());
    const yest = new Date(new Date().setDate(new Date().getDate() - 1));  // Yesterday
    const today = new Date();                 // today

    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0, 0);

    yest.setHours(0);
    yest.setMinutes(0);
    yest.setSeconds(0, 0);

    newdate.setHours(0);
    newdate.setMinutes(0);
    newdate.setSeconds(0, 0);

    if (newdate.getTime() === today.getTime()) {
      const d = date.toLocaleString('en-US', this.doptions);
      const dt = date.getHours() + ':' + date.getMinutes();
      return dt;
    } else if (newdate.getTime() === yest.getTime()) {
      return 'Yesterday';
    } else if (newdate.getTime() < yest.getTime()) {
      const d = date.toLocaleString('en-US', this.doptions);
      const dt =  date.getDate() + ' ' + this.months[date.getMonth()];
      return dt;
    }
  }

  showLoad(): any {
    this.loading = true;
    this.showConfirm('', '', 'loader', '');
  }

  hideLoad(): any {
    this.loading = false;
    this.messageService.clear('loader');
  }

  async getUsers(notif) {
    this.showLoad();
    // this.not = notif;
    this.users = [];
    const us = [];
    let count = 0;
    // this.username = '';
    if (notif.configuration.typeRoleGroups != null && notif.configuration.typeRoleGroups.length > 0) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < notif.configuration.typeRoleGroups.length; i++) {
        let res = null;
        if (notif.configuration.typeRoleGroups[i].typeRoleGroup.roleName === 'Expert-All' ||
        notif.configuration.typeRoleGroups[i].typeRoleGroup.roleName === 'Expert-Assigned' ||
        notif.configuration.typeRoleGroups[i].typeRoleGroup.roleName === 'Reader-All' ||
        notif.configuration.typeRoleGroups[i].typeRoleGroup.roleName === 'Reader-Own') {
          res = await this.usergroupService.getUserGroups(notif.configuration.typeRoleGroups[i].typeRoleGroup.groupId)
          .toPromise();
        }
        // const res = await this.usergroupService.getUserGroups(notif.configuration.typeRoleGroups[i].typeRoleGroup.groupId)
        // .toPromise();
        let counter = 0;
        if (res != null) {
          // tslint:disable-next-line: prefer-for-of
          for (let j = 0; j < res.length; j++) {
            // tslint:disable-next-line: prefer-const
            let usr: any;
            res[j].id = res[j].userId;
            res[j].name = res[j].firstName + ' ' + res[j].lastName;
            res[j].idType = 'U';
            if (!us.includes(res[j].name)) {
              this.users.push({ label: res[j].name, value: res[j] });
              us.push(res[j].name);
            }

            counter += 1;
            if (counter === res.length) {
              count += 1;
              if (count === notif.configuration.typeRoleGroups.length) {
                if (this.users.length > 0) {
                  this.showDialog();
                } else {
                  // tslint:disable-next-line: deprecation
                  this.translate.get('GENERAL.NOUSERSFOUND').subscribe((resp) => {
                    // no users
                  });
                }
              }
            }
          }
        } else {
          count += 1;
          if (count === notif.configuration.typeRoleGroups.length) {
            this.showDialog();
            if (this.users.length > 0) {
              this.showDialog();
            } else {
              // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.NOUSERSFOUND').subscribe((resp) => {
              // no users
              this.hideLoad();
            });
          }
          }
        }
      }
    } else {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.NOUSERSFOUND').subscribe((res) => {
        // no users
        this.showDialog();
        this.hideLoad();
      });
    }
  }

  showDialog() {
    this.isuserapply = false;
    let count = 0;
    const us = [];
    if (this.users.length === 0) {
      this.display = true;
      // tslint:disable-next-line: deprecation
      this.translate.get('NOUSERS').subscribe((res) => {
        // no users
      });
    } else {
      for (const usr of this.users) {
        usr.value.col = 'white';
        // tslint:disable-next-line: deprecation
        this.deviceService.getDevices(1, 1, ' ', ' ', usr.value.id, usr.value.name).subscribe((res) => {
          if (res != null) {
            const dt = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(),
                                  new Date().getHours(), new Date().getMinutes() - 3, new Date().getSeconds());
            const lb = new Date(res[0].lastHeartBeat);
            // const lhb = new Date(lb.getUTCFullYear(), lb.getUTCMonth(), lb.getUTCDate(),
            //                    lb.getUTCHours(), lb.getUTCMinutes(), lb.getUTCSeconds());
            const lhb = new Date(new Date(res[0].lastHeartBeat).toLocaleString() + ' UTC');
            if (lhb < dt) {
              usr.value.color = 'Red';
            } else {
              usr.value.color = 'Green';
            }
          } else {
            usr.value.color = 'Red';
          }
          us.push(usr);
          count += 1;

          if (this.users.length === count) {
            us.sort((a, b) => a.value.name.localeCompare(b.value.name));
            this.users = us;
            this.display = true;
            this.hideLoad();
          }
        });
      }
    }
  }

  selectUser(value) {
    this.user = value;
    this.isuserapply = true;
    const usrs = [];
    let usr = value;
    for (const val of this.users) {
      usr = val;
      if (val.value.id === value.id) {
        usr.value.col = '#c9e2ee';
      } else {
        usr.value.col = 'white';
      }

      usrs.push(usr);
    }

    this.users = usrs;
  }

  applyAssignUser() {
    if (this.not.configuration.workflow === 'OSC') {
      this.startTask();
    } else if (this.not.configuration.workflow === 'OC') {
      this.closeTask();
    } else {
      this.assignUser(this.user);
    }
  }

  async assignUser(user) {
    const values = new TaskUpdater();
    values.Id = this.not.notificationId;
    values.Values = [];

    const id = new KeyValue();
    id.Key = 'id';
    id.Value = user.id.toString();
    values.Values.push(id);

    const name = new KeyValue();
    name.Key = 'name';
    name.Value = user.name;
    values.Values.push(name);

    const assigned = new KeyValue();
    assigned.Key = 'assigned';
    assigned.Value = '';
    values.Values.push(assigned);

    this.showLoad();
    // tslint:disable-next-line: deprecation
    this.tasksService.putTask(values.Id, values).subscribe(async (res) => {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.USERASSIGNED').subscribe((resp) => {
        this.showSuccess(resp);
      });
      this.apage = 0;
      await this.getAssignedNots();

      const index = this.opentasks.indexOf(this.not, 0);
      if (index > -1) {
        this.opentasks.splice(index, 1);
      }

      const sindex = this.startedtasks.indexOf(this.not, 0);
      if (sindex > -1) {
        this.startedtasks.splice(sindex, 1);
      }

      this.updateCount();
      this.display = false;
      // this.loading = false;
    }, err => {
      this.handleError(err);
    });
  }

  async startTask() {
    const values = new TaskUpdater();
    values.Id = this.not.notificationId;
    values.Values = [];

    if (this.not.configuration.workflow === 'OSC') {
      const v = new Variables();
      const val = localStorage.getItem('ui');
      const ui = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);
      const uid = +ui;
      const res = await this.userService.getUser(0, uid, ' ', ' ').toPromise().catch(err => this.handleError(err));
      const id = new KeyValue();
      id.Key = 'id';
      id.Value = uid.toString();
      values.Values.push(id);

      const name = new KeyValue();
      name.Key = 'name';
      name.Value = res.firstName + ' ' + res.lastName;
      values.Values.push(name);

      const assigned = new KeyValue();
      assigned.Key = 'assigned';
      assigned.Value = '';
      values.Values.push(assigned);
    }

    const started = new KeyValue();
    started.Key = 'started';
    started.Value = '';
    values.Values.push(started);

    this.showLoad();
    // tslint:disable-next-line: deprecation
    this.tasksService.putTask(values.Id, values).subscribe(async (res) => {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.TASKSTARTED').subscribe((resp) => {
        this.showSuccess(resp);
      });
      this.spage = 0;
      await this.getStartedNots();

      const index = this.assignedtasks.indexOf(this.not, 0);
      if (index > -1) {
        this.assignedtasks.splice(index, 1);
      }

      const oindex = this.opentasks.indexOf(this.not, 0);
      if (oindex > -1) {
        this.opentasks.splice(oindex, 1);
      }

      this.updateCount();
      this.display = false;
      // this.loading = false;
    }, err => {
      this.handleError(err);
    });
  }

  async closeTask() {
    const values = new TaskUpdater();
    values.Id = this.not.notificationId;
    values.Values = [];

    if (this.not.configuration.workflow === 'OC') {
      const v = new Variables();
      const val = localStorage.getItem('ui');
      const ui = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);
      const uid = +ui;
      const res = await this.userService.getUser(0, uid, ' ', ' ').toPromise().catch(err => this.handleError(err));

      const id = new KeyValue();
      id.Key = 'id';
      id.Value = uid.toString();
      values.Values.push(id);

      const name = new KeyValue();
      name.Key = 'name';
      name.Value = res.firstName + ' ' + res.lastName;
      values.Values.push(name);

      const assigned = new KeyValue();
      assigned.Key = 'assigned';
      assigned.Value = '';
      values.Values.push(assigned);

      const started = new KeyValue();
      started.Key = 'started';
      started.Value = '';
      values.Values.push(started);
    }

    const closed = new KeyValue();
    closed.Key = 'closed';
    closed.Value = '';
    values.Values.push(closed);

    this.showLoad();
    // tslint:disable-next-line: deprecation
    this.tasksService.putTask(values.Id, values).subscribe(async (res) => {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.TASKCLOSED').subscribe((resp) => {
        this.showSuccess(resp);
      });
      this.cpage = 0;
      await this.getClosedNots();

      const index = this.startedtasks.indexOf(this.not, 0);
      if (index > -1) {
        this.startedtasks.splice(index, 1);
      }

      const oindex = this.opentasks.indexOf(this.not, 0);
      if (oindex > -1) {
        this.opentasks.splice(oindex, 1);
      }

      this.updateCount();
      this.display = false;
      // this.loading = false;
    }, err => {
      this.handleError(err);
    });
  }

  async getCatClass(catid: any) {
    this.showLoad();

    const res = await this.catclassService.getCategoryClasses(0, 0, 0, ' ', catid, ' ').toPromise()
    .catch(err => console.log(err));

    if (res !== undefined && res !== null && res.length > 0) {
      for (const val of res) {
        this.classes.push({label: val.categoryClassList.className, value: val});
      }
      if (res.length === 1) {
        this.isclassdisabled = true;
        this.class = this.classes[0].value;
        await this.getClassListItems(this.classes[0].value.categoryClassList.classId);
      } else {
        this.isclassdisabled = false;
      }
      this.cname = this.classes[0].value.category.categoryName;
    } else {
      const c = await this.catService.getCategories(0, 0, catid, ' ').toPromise()
      .catch(err => console.log(err));
      if (c !== undefined && c !== null && c.length > 0) {
        this.cname = c[0].categoryName;
      }
    }

    this.iscategorize = true;
    this.hideLoad();
  }

  async getClassListItems(classid: any) {
    this.showLoad();

    const res = await this.itemService.getClassItems(0, 0, classid, ' ', 0, ' ').toPromise()
    .catch(err => console.log(err));
    this.citems = [];
    if (res !== undefined && res !== null && res.length > 0) {
      for (const val of res) {
        this.citems.push({label: val.classListItem.itemName, value: val});
      }
    }

    this.hideLoad();
  }

  async classSelected() {
    this.citem = null;
    this.issavedisabled = true;
    await this.getClassListItems(this.class.categoryClassList.classId);
  }

  async classify() {
    if (this.class === undefined || this.class === null) {
      this.translate.get('GENERAL.SELECTCLASS').subscribe((res: string) => {
        this.confirm(res);
      });
    } else if (this.citem === undefined || this.citem === null) {
      this.translate.get('GENERAL.SELECTCLASSITEM').subscribe((res: string) => {
        this.confirm(res);
      });
    } else {
      const tc = new TaskClassification();
      tc.ItemId = this.citem.classListItem.itemId;
      tc.NotificationId = this.notval.notificationId;

      this.showLoad();

      const res = await this.tcService.postTaskClassification(tc).toPromise()
      .catch(err => console.log(err));

      if (res !== undefined && res !== null && res === true) {
        this.translate.get('GENERAL.TASKCLASSIFIED').subscribe((resp: string) => {
          // this.showSuccess(resp);
        });
        this.classifyCancel();
        this.closeTask();
      } else {
        this.translate.get('GENERAL.TASKCLASSIFICATIONFAILED').subscribe((resp: string) => {
          this.showError(resp);
        });
      }

      // this.loading = false;
    }
  }

  async classifyTask(event: any, val: any) {
    this.notval = val;
    this.classes = [];
    this.citems = [];
    this.class = null;
    this.citem = null;
    this.issavedisabled = true;
    await this.getCatClass(val.configuration.categoryId);
  }

  enableSave() {
    this.issavedisabled = false;
  }

  classifyCancel() {
    this.iscategorize = false;
  }

  showSuccess(msg) {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.SUCCESS').subscribe((trans) => {
      this.messageService.add({key: 'tc', severity: 'success', summary: trans, detail: msg});
    });
  }

  showInfo(msg) {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.INFO').subscribe((trans) => {
      this.messageService.add({key: 'tc', severity: 'info', summary: trans, detail: msg});
    });
  }

  showWarn(msg) {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.WARNING').subscribe((trans) => {
      this.messageService.add({key: 'tc', severity: 'warn', summary: trans, detail: msg});
    });
  }

  showError(msg) {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.ERROR').subscribe((trans) => {
      this.messageService.add({key: 'tc', severity: 'error', summary: trans, detail: msg});
    });
  }

  showConfirm(title: string, details: string, keyvalue: string, severe: string = 'warn'): any {
    // this.messageService.clear();
    this.messageService.add({key: keyvalue, sticky: true, severity: severe, summary: title, detail: details});
  }

  toggleFilter() {

  }

  apply() {

  }

  filterSelection() {

  }

  onResize(event) {
    const menuheight = document.getElementById('menu').offsetHeight;
    let contentheight = window.innerHeight;

    const cwidth = document.getElementById('mycontentdiv').offsetWidth;

    this.menuheight = menuheight;
    if (menuheight !== undefined && menuheight !== null
      && contentheight !== undefined && contentheight !== null) {
      this.contentdiv = (+contentheight - menuheight - 20) + 'px';
    } else {
      this.contentdiv = '85vh';
    }

    if (cwidth < 600) {
      this.padding = '20px';
      contentheight = contentheight - 20;
      // contentheight = contentheight - 15;
    } else {
      this.padding = '0px';
    }
    this.innerdiv = (contentheight - menuheight - 107) + 'px';
    this.paneldiv = (contentheight - menuheight - 125) + 'px';

    this.dialogheight = (contentheight - 175) + 'px';
    this.innerdivmobile = (contentheight - 225) + 'px';
    this.paneldivmobile = (contentheight - 175 - 70) + 'px';
  }

  onResize2(event: any): any {
    // const val = event.target.innerWidth;
    let height = 0;
    if (event === null) {
      height = window.innerHeight;
    } else {
      height = event.target.innerHeight;
    }
    this.contentdiv = (height - this.menuheight - 35) + 'px';
    const headerheight = document.getElementById('myheader').offsetHeight;
    const mainheight = 0;

    if (headerheight !== undefined && headerheight !== null
      && mainheight !== undefined && mainheight !== null) {
      this.divheight = (((height - this.menuheight - 25) - headerheight - mainheight) - 90) + 'px';
      this.divheight2 = this.divheight;
    }
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        },
        reject: () => {
          //
        }
    });
  }

  updateCount() {
    if (this.opentasks !== undefined && this.opentasks !== null) {
      this.ocount = this.opentasks.length;
    } else {
      this.ocount = 0;
    }

    if (this.assignedtasks !== undefined && this.assignedtasks !== null) {
      this.acount = this.assignedtasks.length;
    } else {
      this.acount = 0;
    }

    if (this.startedtasks !== undefined && this.startedtasks !== null) {
      this.scount = this.startedtasks.length;
    } else {
      this.scount = 0;
    }

    for (const val of this.channels) {
      if (val.channel.channelId === this.channel.channel.channelId) {
        val.tCount = this.ocount + this.acount + this.scount;
      }
    }
  }

  async handleError(err) {
    this.hideLoad();
    if (err.error.Message === 'Reassign limit reached') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.REASSIGNLIMITREACHED').subscribe((res) => {
        this.confirm(res);
      });
    }

    if (err.error.Message === 'Location has to be confirmed before assigning task') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.LOCATIONHASTOBECONFIRMEDBEFOREASSIGNINGTASK').subscribe((res) => {
        this.confirm(res);
      });
    }

    if (err.error.Message === 'Location has to be confirmed before starting task') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.LOCATIONHASTOBECONFIRMEDBEFORESTARTINGTASK').subscribe((res) => {
        this.confirm(res);
      });
    }

    if (err.error.Message === 'Location has to be confirmed before closing task') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.LOCATIONHASTOBECONFIRMEDBEFORECLOSINGTASK').subscribe((res) => {
        this.confirm(res);
      });
    }

    if (err.error.Message === 'Task has to be classified before closing task') {
      await this.classifyTask(null, this.not);
    }
  }

}
