import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ChannelTaskType } from 'src/app/Models/channel-task-type';
import { NotificationFeedback } from 'src/app/Models/notification-feedback';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  apiUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = environment.apiUrl;
  }

  getFeedbacks(notid: string, feedback: number): Observable<any> {
    return this.httpclient.get(this.apiUrl + '/api/notificationfeedback/' + notid + '/' + feedback)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  postFeedback(feedback: NotificationFeedback): Observable<any> {
    return this.httpclient.post(this.apiUrl + '/api/notificationfeedback', feedback)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  deleteFeedback(feedback: NotificationFeedback): Observable<any> {
    return this.httpclient.put(this.apiUrl + '/api/notificationfeedback/delete/id', feedback)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  private handleError(error: any): any {
    throw error;
    return error;
  }
}
