export class Devices {
    public IMEI: string;
    public DeviceName: string;
    public BatteryStatus: number;
    public Status: string;
    public LastHeartBeat: Date;
    public OrganisationId: number;
    public UserId: number;
    public UserName: string;
    public ShowOthers: string;
    public SyncTime: number;
    public Language: string;
}
