import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from './../../services/configuration/configuration.service';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from './../../services/analytics/analytics.service';
import * as CryptoJS from 'crypto-js';
import { ConfirmationService, MessageService } from 'primeng/api';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { UsersService } from 'src/app/services/users/users.service';
import { Variables } from 'src/app/Models/variables';

@Component({
  selector: 'app-closed-tasks-by-user',
  templateUrl: './closed-tasks-by-user.component.html',
  styleUrls: ['./closed-tasks-by-user.component.css']
})
export class ClosedTasksByUserComponent implements OnInit {

  data: any;
  range: any;
  start: any;
  end: any;
  show = false;
  load = false;

  configs1 = [];
  conf1: any;

  users = [];
  user: any;
  options: { title: { display: boolean; text: any; fontSize: number; }; legend: { position: string; }; };
  confid: number;
  def: Date;
  uid: number;

  allcount = 0;
  isadm = false;

  constructor(
    private analytics: AnalyticsService,
    private translate: TranslateService,
    private cookieService: CookieService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private notificationSvc: NotificationService,
    private configService: ConfigurationService,
    private userService: UsersService
  ) {
      this.translate.addLangs(['English', 'French']);
      this.translate.setDefaultLang('English');

      let browserLang = this.translate.getBrowserLang();

      const pass = 'ranitessarldev2019';
      const lang = cookieService.get('goepla-portal-language');
      if (lang.trim() !== '') {
        const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
        const lg = declang;
        browserLang = lg;
        localStorage.setItem('language', lg);
      }

      if (localStorage.getItem('language') !== null) {
        browserLang = localStorage.getItem('language');
      }
      this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

      if (localStorage.getItem('filter-start') !== null && localStorage.getItem('filter-end') !== null) {
        const st = localStorage.getItem('filter-start');
        const ed = localStorage.getItem('filter-end');
        this.range = [new Date(st), new Date(ed)];
      }

      if (localStorage.getItem('filter-config') !== null) {
        this.confid = +localStorage.getItem('filter-config');
      }

      if (localStorage.getItem('filter-user') !== null) {
        this.uid = +localStorage.getItem('filter-user');
      }

      // tslint:disable-next-line: deprecation
      translate.get('GENERAL.ALL').subscribe((trans) => {
        const conf: any = [];
        conf.configurationId = 0;
        conf.configurationName = trans;
        this.configs1.push({ label: trans, value: conf });
        if (this.confid === 0) {
          this.conf1 = conf;
        }
      });

      const v = new Variables();
      const p = localStorage.getItem('ups');
      if (p == null) {
        this.isadm = false;
      }

      const pr = CryptoJS.AES.decrypt(p, v.pass).toString(CryptoJS.enc.Utf8);
      const perm = JSON.parse(pr);
      if (perm.includes('NTM.Administration.Full')) {
            this.isadm = true;
      }

      this.getConfigurations();
      this.getUsers();
   }

   getConfigurations() {
    this.load = true;
    // tslint:disable-next-line: deprecation
    this.configService.getConfigurations('T').subscribe((res) => {
      if (res != null) {
        const v = new Variables();
        const gis = localStorage.getItem('gi');
        const gs = CryptoJS.AES.decrypt(gis, v.pass).toString(CryptoJS.enc.Utf8);
        const gids = JSON.parse(gs);

        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          if (res[i].typeRoleGroups !== null && res[i].typeRoleGroups.length > 0) {
            let show = false;
            for (const val of res[i].typeRoleGroups) {
              if (val.typeRoleGroup.roleName === 'Analytics' && gids.includes(val.typeRoleGroup.groupId)) {
                show = true;
              }
            }

            if (this.isadm === true) {
              show = true;
            }

            if (show === false) {
              continue;
            }
          } else {
            if (this.isadm === true) {
            } else {
              continue;
            }
          }
          this.configs1.push({ label: res[i].configurationName, value: res[i] });
          if (this.confid >= 0) {
            if (res[i].configurationId === this.confid) {
              this.conf1 = res[i];
            }
          }
        }
      }
      this.load = false;
    });
  }

  getUsers() {
    this.load = true;
    // tslint:disable-next-line: deprecation
    this.userService.getUsers().subscribe((res) => {
      if (res != null) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          res[i].UserName = res[i].firstName + ' ' + res[i].lastName;
          this.users.push({label: res[i].UserName, value: res[i]});
          if (res[i].userId === this.uid) {
            this.user = res[i];
          }
        }
      }
      this.load = false;
    });
  }

   getAnalytics() {
    if (this.configs1 === undefined || this.configs1 === null || this.configs1.length === 1) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.NODATAAVAILABLE').subscribe((resp: string) => {
        this.notificationSvc.info('', resp, 2000);
        });
      return;
    }
    let tids = '';
    if (this.conf1.configurationId === 0) {
      if (this.allcount + 1 !== this.configs1.length) {
        for (const val of this.configs1) {
          if (val.value.configurationId !== 0) {
            tids += val.value.configurationId + ',';
          }
        }
      }
    }
    if (tids === '') {
      tids = ' ';
    }
    let amttsks = '';
    let day = '';
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.AMOUNTOFTASKS').subscribe((trans) => {
      amttsks = trans;
    });
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.DAY').subscribe((trans) => {
      day = trans;
    });

    this.show = false;
    this.load = true;
    this.analytics.getAnalyticsGeneric(this.conf1.configurationId, 'T', this.start, this.end,
                               this.user.userId, 'closedbyday', ' ', tids)
                               // tslint:disable-next-line: deprecation
                  .subscribe((res) => {
      const labs: string[] = [];
      const datasets: number[] = [];
      let count = 0;
      if (res != null && res.length > 0) {
        for (const v of res) {
          const date = new Date(v.value2);
          labs.push(date.toDateString());
          datasets.push(v.value1);
          count += 1;

          if (count === res.length) {
            // labs.push(this.def.toDateString());
            // datasets.push(0);
            this.data = {
              labels: labs,
              datasets: [
                  {
                      label: amttsks,
                      backgroundColor: '#42A5F5',
                      borderColor: '#1E88E5',
                      data: datasets
                  }
              ]
            };
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.CLOSEDTASKSBYUSER').subscribe((trans) => {
              this.options = {
                title: {
                    display: true,
                    text: trans,
                    fontSize: 16
                },
                legend: {
                    position: 'top'
                }
              };
            });
            this.show = true;
          }
        }
      } else {
        this.show = false;
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.NODATAAVAILABLE').subscribe((resp: string) => {
          this.notificationSvc.info('', resp, 2000);
          });
      }
      this.load = false;
    }, err => this.handleError(err));
   }

   ok() {
    this.data = [];
    if (this.range === undefined || this.range === null) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTRANGE').subscribe((res) => {
        this.confirm(res);
      });
      return;
    } else {
      if (this.range[0] !== undefined && this.range[0] !== null) {
        this.start = this.range[0].toDateString();
        const a = this.range[0].toDateString();
        const b = new Date(a);
        const c = new Date(b.getFullYear(), b.getMonth(), b.getDate());
        this.start = c.toDateString();

        b.setDate(b.getDate() - 1);
        this.def = b;
      } else {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.SELECTRANGE').subscribe((res) => {
          this.confirm(res);
        });
        return;
      }
      if (this.range[1] !== undefined && this.range[1] !== null) {
        this.end = this.range[1].toDateString();
        const a = this.range[1].toDateString();
        const b = new Date(a);
        const c = new Date(b.getFullYear(), b.getMonth(), b.getDate());
        this.end = c.toDateString();
      } else {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.SELECTRANGE').subscribe((res) => {
          this.confirm(res);
        });
        return;
      }

      if (this.conf1 === undefined || this.conf1 === null) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.SELECTCONFIGURATION').subscribe((res) => {
          this.confirm(res);
        });
        return;
      }

      if (this.user === undefined || this.user === null) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.SELECTUSER').subscribe((res) => {
          this.confirm(res);
        });
        return;
      }
      localStorage.setItem('filter-start', this.start);
      localStorage.setItem('filter-end', this.end);
      localStorage.setItem('filter-config', this.conf1.configurationId);
      localStorage.setItem('filter-user', this.user.userId);
      this.getAnalytics();
    }
   }

  ngOnInit() {
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  showInfo() {
    this.messageService.add({severity: 'info', summary: 'Info Message', detail: 'PrimeNG rocks'});
  }

  handleError(err) {
    this.load = false;
    console.log(err);
  }

  setDate() {
    const d = new Date();
    const d1 = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 1);
    const d2 = new Date(d.getFullYear(), d.getMonth(), d.getDate() + 1);

    this.range = [d1, d2];
  }

}
