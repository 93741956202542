import { TypeRoleGroupData } from './../../Models/role';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Configuration } from 'src/app/Models/Configuration';
import { UsrGrp } from 'src/app/Models/UsrGrp';
import { Group } from 'src/app/Models/Group';
import { User } from 'src/app/Models/User';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { UsersgroupsService } from 'src/app/services/usersgroups/usersgroups.service';
import { ConfirmationService } from 'primeng/api';
import { CookieService } from 'ngx-cookie-service';
import { KeyValue, Updater, ConfigUpdater } from 'src/app/Models/Updater';
import * as CryptoJS from 'crypto-js';
import { CategoryService } from 'src/app/services/category/category.service';
import { TypeRoleGroup } from 'src/app/Models/role';

@Component({
  selector: 'app-edit-task-configuration',
  templateUrl: './edit-task-configuration.component.html',
  styleUrls: ['./edit-task-configuration.component.css']
})
export class EditTaskConfigurationComponent implements OnInit {

  organisations = [];
  organisation: any;

  configId: number;

  // users = new FormControl();
  userList = [];
  users = [];

  // groups = new FormControl();
  groupList = [];
  groups = [];

  us = [];
  gs = [];

  types = [];
  type = 'T';

  priorities = [];
  priority: any;

  vibrations = [];
  vibration: any;

  channels = [];
  channel: any;

  Organisation: number;
  MessageType: string;
  ConfigurationName: string;
  ConfigurationType: string;
  Priority: number;
  TimeUntilExpiry: number;
  Color: string;
  Vibration: number;
  Channel: string;
  TaskReactionTimeLimit: number;
  TaskStartTimeLimit: number;
  TaskClosureTimeLimit: number;
  QueueSize: any;
  Workflow: string;

  usrgrp: UsrGrp[] = [];
  usrgrp2 = [];

  isTask = true;

  isSave = false;
  loading = false;

  OrgName: string;

  sensitivity = [];
  Sensitive: any;

  MaxSize: number;

  policyloc = [];
  PolicyLocation: any;

  StatusUpdateList = [];
  StatusUpdate: any;

  issense: boolean;

  loc: string;

  assigneeoptions = [];
  assigneeoption: any;
  config: any;

  recoptions = [];
  recoption: any;

  cats = [];
  cat: any;
  catid = 0;

  rogroups1 = [];
  rogroups2 = [];

  ragroups1 = [];
  ragroups2 = [];

  ingroups1 = [];
  ingroups2 = [];

  ealgroups1 = [];
  ealgroups2 = [];

  easgroups1 = [];
  easgroups2 = [];

  angroups1 = [];
  angroups2 = [];

  adgroups1 = [];
  adgroups2 = [];

  cf: any;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private configService: ConfigurationService,
    private notificationSvc: NotificationService,
    private usersgroupsService: UsersgroupsService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private location: Location,
    private catService: CategoryService
  ) {
    // this.translate.addLangs(['English', 'French']);
    // this.translate.setDefaultLang('English');

    // let browserLang = this.translate.getBrowserLang();

    // const pass = 'ranitessarldev2019';
    // const lang = cookieService.get('goepla-portal-language');
    // if (lang.trim() !== '') {
    //   const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
    //   const lg = declang;
    //   browserLang = lg;
    // }

    // if (localStorage.getItem('language') !== null) {
    //   browserLang = localStorage.getItem('language');
    // }
    // this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    translate.use('English');

    // tslint:disable-next-line: deprecation
    translate.get('GENERAL.YES').subscribe((res1) => {
      this.sensitivity.push({label: res1, value: 'YES'});
      // tslint:disable-next-line: deprecation
      translate.get('GENERAL.NO').subscribe((res2) => {
        this.sensitivity.push({label: res2, value: 'NO'});
      });
    });

    // tslint:disable-next-line: deprecation
    translate.get('GENERAL.ALLTASKSTHATHAVETHATLOCATIONSET').subscribe((res1) => {
      this.policyloc.push({label: res1, value: 1});
      // tslint:disable-next-line: deprecation
      translate.get('GENERAL.ONLYFORTHELATESTTASK').subscribe((res2) => {
        this.policyloc.push({label: res2, value: 2});
        // tslint:disable-next-line: deprecation
        translate.get('GENERAL.ONLYFORTHEOLDESTTASK').subscribe((res3) => {
          this.policyloc.push({label: res3, value: 3});
        });
      });
    });

    // tslint:disable-next-line: deprecation
    translate.get('GENERAL.ASSIGNONLYIFLOCATIONISSET').subscribe((res1) => {
      this.StatusUpdateList.push({label: res1, value: 1});
      // tslint:disable-next-line: deprecation
      translate.get('GENERAL.STARTONLYIFLOCATIONISSET').subscribe((res2) => {
        this.StatusUpdateList.push({label: res2, value: 2});
        // tslint:disable-next-line: deprecation
        translate.get('GENERAL.CLOSEONLYIFLOCATIONISSET').subscribe((res3) => {
          this.StatusUpdateList.push({label: res3, value: 3});
        });
      });
    });

    // tslint:disable-next-line: deprecation
    translate.get('GENERAL.INFORMATION').subscribe((res1) => {
      this.types.push({label: res1, value: 'I'});
      // tslint:disable-next-line: deprecation
      translate.get('GENERAL.TASK').subscribe((res2) => {
        this.types.push({label: res2, value: 'T'});
      });
    });

    // tslint:disable-next-line: deprecation
    translate.get('GENERAL.CRITICAL').subscribe((res1) => {
      this.priorities.push({label: res1, value: 1});
      // tslint:disable-next-line: deprecation
      translate.get('GENERAL.HIGH').subscribe((res2) => {
        this.priorities.push({label: res2, value: 2});
        // tslint:disable-next-line: deprecation
        translate.get('GENERAL.NORMAL').subscribe((res3) => {
          this.priorities.push({label: res3, value: 3});
          // tslint:disable-next-line: deprecation
          translate.get('GENERAL.LOW').subscribe((res4) => {
            this.priorities.push({label: res4, value: 4});
            // tslint:disable-next-line: deprecation
            translate.get('GENERAL.VERYLOW').subscribe((res5) => {
              this.priorities.push({label: res5, value: 5});
            });
          });
        });
      });
    });

    // tslint:disable-next-line: deprecation
    translate.get('GENERAL.NONE').subscribe((res1) => {
      this.vibrations.push({label: res1, value: 0});
      // tslint:disable-next-line: deprecation
      translate.get('GENERAL.SHORT').subscribe((res2) => {
        this.vibrations.push({label: res2, value: 1});
        // tslint:disable-next-line: deprecation
        translate.get('GENERAL.LONG').subscribe((res3) => {
          this.vibrations.push({label: res3, value: 2});
          // tslint:disable-next-line: deprecation
          translate.get('GENERAL.VERYLONG').subscribe((res4) => {
            this.vibrations.push({label: res4, value: 3});
          });
        });
      });
    });

    // tslint:disable-next-line: deprecation
    translate.get('GENERAL.MQTT').subscribe((res1) => {
      this.channels.push({label: res1, value: 'M'});
      // tslint:disable-next-line: deprecation
      translate.get('GENERAL.SMS').subscribe((res2) => {
        this.channels.push({label: res2, value: 'S'});
        // tslint:disable-next-line: deprecation
        translate.get('GENERAL.EMAIL').subscribe((res3) => {
          this.channels.push({label: res3, value: 'E'});
        });
      });
    });

    // tslint:disable-next-line: deprecation
    translate.get('GENERAL.MANDATORY').subscribe((mand) => {
      // tslint:disable-next-line: deprecation
      translate.get('GENERAL.OPTIONAL').subscribe((opt) => {
        // tslint:disable-next-line: deprecation
        translate.get('GENERAL.NOTALLOWED').subscribe((notallw) => {
          this.assigneeoptions.push({label: mand, value: 'MANDATORY' });
          this.assigneeoptions.push({label: opt, value: 'OPTIONAL' });
          this.assigneeoptions.push({label: notallw, value: 'NOTALLOWED' });
        });
      });
    });

    // tslint:disable-next-line: deprecation
    translate.get('GENERAL.MANDATORY').subscribe((mand) => {
      // tslint:disable-next-line: deprecation
      translate.get('GENERAL.OPTIONAL').subscribe((opt) => {
        this.recoptions.push({label: mand, value: 'M' });
        this.recoptions.push({label: opt, value: 'O' });
      });
    });

    const conf = JSON.parse(localStorage.getItem('config'));
    this.cf = conf;
    this.configId = conf.configurationId;
    this.Organisation = conf.organisationId;
    this.Workflow = conf.work;

    this.config = conf;

    // this.getUsers();
    this.getGroups();
    this.getOrganisation();

    this.Organisation = conf.organisationId;
    this.type = conf.configurationType;
    this.ConfigurationName = conf.configurationName;
    this.priority = conf.priority;
    this.TimeUntilExpiry = conf.expiryTime;
    this.Color = conf.color;
    this.vibration = conf.vibrate;
    this.channel = conf.channel;
    this.TaskReactionTimeLimit = conf.reactionTimeLimit;
    this.TaskStartTimeLimit = conf.startTimeLimit;
    this.TaskClosureTimeLimit = conf.closeTimeLimit;
    this.MaxSize = conf.sameLocationMax;
    this.PolicyLocation = conf.policyLocationConfirmation;
    this.Sensitive = conf.locationSensitive;
    this.assigneeoption = conf.assigneeAtCreation;
    this.recoption = conf.taskRecOption;

    if (conf.categoryId > 0) {
      this.catid = conf.categoryId;
    }
    this.getCategories();

    const upt = [];
    if (conf.locationSensitive === 'NO') {
      this.StatusUpdate = null;
      this.PolicyLocation = null;
      this.MaxSize = null;
      this.Sensitive = 'NO';
    } else {
      const spl = conf.policyStatusUpdate.split(';');
      for (const val of spl) {
        upt.push(+val);
      }
      this.StatusUpdate = upt;
    }

    if (conf.queueSize === null || conf.queueSize === 'null') {

    } else {
      this.QueueSize = conf.queueSize;
    }
    this.usrgrp = conf.usrGrp;
    this.usrgrp2 = conf.usrGrp;

    if (conf.configurationType === 'T') {
      this.isTask = true;
    } else {
      this.isTask = false;
    }

    this.show();
   }

  ngOnInit() {

  }

  show() {
    if (this.Sensitive === 'YES') {
      this.issense = true;
    } else {
      this.issense = false;
    }
  }

  setValues() {
    if (this.usrgrp2 != null) {
      const usrs = [];
      const grps = [];
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < this.usrgrp2.length; i++) {
        if (this.usrgrp2[i].idType === 'U') {
          const usr = new User();
          usr.UserId = this.usrgrp2[i].id;
          usr.UserName = this.usrgrp2[i].name;
          usrs.push(usr);
        } else if (this.usrgrp2[i].idType === 'G') {
          const grp = new Group();
          grp.GroupId = this.usrgrp2[i].id;
          grp.GroupName = this.usrgrp2[i].name;
          grps.push(grp);
        }
      }

      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < this.us.length; i++) {
        this.userList.push({label: this.us[i].UserName, value: this.us[i]});
        // tslint:disable-next-line: prefer-for-of
        for (let j = 0; j < usrs.length; j++) {
          if (this.us[i].userId === usrs[j].UserId) {
            this.users.push(this.us[i]);
          }
        }
      }

      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < this.gs.length; i++) {
        this.groupList.push({label: this.gs[i].GroupName, value: this.gs[i]});
        // tslint:disable-next-line: prefer-for-of
        for (let j = 0; j < grps.length; j++) {
          if (this.gs[i].groupId === grps[j].GroupId) {
            this.groups.push(this.gs[i]);
          }
        }
      }
    }
  }

  arrangeRoles() {
    if (this.gs !== null && this.gs.length > 0) {
      if (this.cf.typeRoleGroups !== null && this.cf.typeRoleGroups.length > 0) {
        for (const v of this.gs) {
          let ra = true;
          let ro = true;
          let ini = true;
          let eal = true;
          let eas = true;
          let an = true;
          let ad = true;
          for (const val of this.cf.typeRoleGroups) {
            if (val.typeRoleGroup.roleName === 'Reader-All') {
              if (v.groupId === val.typeRoleGroup.groupId) {
                this.ragroups2.push(v);
                ra = false;
              }
            }
            if (val.typeRoleGroup.roleName === 'Reader-Own') {
              if (v.groupId === val.typeRoleGroup.groupId) {
                this.rogroups2.push(v);
                ro = false;
              }
            }
            if (val.typeRoleGroup.roleName === 'Initiator') {
              if (v.groupId === val.typeRoleGroup.groupId) {
                this.ingroups2.push(v);
                ini = false;
              }
            }
            if (val.typeRoleGroup.roleName === 'Expert-All') {
              if (v.groupId === val.typeRoleGroup.groupId) {
                this.ealgroups2.push(v);
                eal = false;
              }
            }
            if (val.typeRoleGroup.roleName === 'Expert-Assigned') {
              if (v.groupId === val.typeRoleGroup.groupId) {
                this.easgroups2.push(v);
                eas = false;
              }
            }
            if (val.typeRoleGroup.roleName === 'Analytics') {
              if (v.groupId === val.typeRoleGroup.groupId) {
                this.angroups2.push(v);
                an = false;
              }
            }
            if (val.typeRoleGroup.roleName === 'Administrator') {
              if (v.groupId === val.typeRoleGroup.groupId) {
                this.adgroups2.push(v);
                ad = false;
              }
            }
          }

          if (ra === true) {
            this.ragroups1.push(v);
          }
          if (ro === true) {
            this.rogroups1.push(v);
          }
          if (ini === true) {
            this.ingroups1.push(v);
          }
          if (eal === true) {
            this.ealgroups1.push(v);
          }
          if (eas === true) {
            this.easgroups1.push(v);
          }
          if (an === true) {
            this.angroups1.push(v);
          }
          if (ad === true) {
            this.adgroups1.push(v);
          }
        }
      } else {
        for (const val of this.gs) {
          this.rogroups1.push(val);
          this.ragroups1.push(val);
          this.ingroups1.push(val);
          this.ealgroups1.push(val);
          this.easgroups1.push(val);
          this.angroups1.push(val);
          this.adgroups1.push(val);
        }
      }
    }
  }

  getUsers() {
    // tslint:disable-next-line: deprecation
    this.usersgroupsService.getUsers().subscribe(async (res) => {
      if (res != null) {
        const userss = [];
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          res[i].UserName = res[i].firstName + ' ' + res[i].lastName;
          this.us.push(res[i]);
        }
        this.getGroups();
      }
    });
  }

  async getGroups() {
    this.loading = true;
    // tslint:disable-next-line: deprecation
    this.usersgroupsService.getGroups().subscribe((res) => {
      if (res != null) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          res[i].GroupName = res[i].groupName;
          this.gs.push(res[i]);
        }
        this.setValues();
        this.arrangeRoles();

      }
      this.loading = false;
    });
  }

  async getCategories() {
    this.loading = true;
    const none = await this.translate.get('GENERAL.NONE').toPromise();
    this.cats.push({label: none, value: null});
    // tslint:disable-next-line: deprecation
    const res = await this.catService.getCategories(0, 0, 0, ' ').toPromise()
    .catch(err => console.log(err));
    if (res != null) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < res.length; i++) {
        this.cats.push({label: res[i].categoryName, value: res[i]});
        if (this.catid > 0 && res[i].categoryId === this.catid) {
          this.cat = res[i];
        }
      }
    }
    if (this.catid === 0) {
      this.cat = null;
    }
    this.loading = false;
  }

  getOrganisation() {
    this.loading = true;
    // tslint:disable-next-line: deprecation
    this.usersgroupsService.getOrganisations().subscribe((res) => {
      if (res != null) {
        this.organisation = res;
        this.OrgName = res.organisationName;
        // tslint:disable-next-line: prefer-for-of
        /*for (let i = 0; i < res.length; i++) {
          // this.organisations.push(res[i]);
          this.organisations.push({label: res[i].organisationName + ' *', value: res[i]});
          if (res[i].organisationId === this.Organisation) {
            this.organisation = res[i];
          }
        }*/
      }
      this.loading = false;
    });
  }

  selectedMessageType(event) {
    if (event.value === 'I') {
      this.isTask = false;
    } else if (event.value === 'T') {
      this.isTask = true;
    }
  }

  selectedPriority(event) {

  }

  selectedColor(event) {

  }

  selectedVibration(event) {

  }

  selectedChannel(event) {

  }

  testloc() {
    // tslint:disable-next-line: deprecation
    this.configService.putLoc(this.configId, JSON.stringify(this.loc)).subscribe((res) => {

    }, err => { console.log(err); });
  }

  onSave() {
    let su = '';
    if (this.organisation === undefined || this.organisation === null) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTORGANISATION').subscribe((res: string) => {
        this.confirm(res);
      });
      return;
    }
    if (this.type === undefined || this.type.toString().trim() === null || this.type.toString().trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTMESSAGETYPE').subscribe((res: string) => {
        this.confirm(res);
      });
      return;
    }
    if (this.ConfigurationName === undefined || this.ConfigurationName.toString().trim() === null
        || this.ConfigurationName.toString().trim() === ''
        || this.ConfigurationName.toString().trim() === ' ') {
          // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTERCONFIGURATIONNAME').subscribe((res: string) => {
        this.confirm(res);
      });
      return;
    }
    if (this.priority === undefined || this.priority.toString().trim() === null) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTPRIORITY').subscribe((res: string) => {
        this.confirm(res);
      });
      return;
    }
    if (this.TimeUntilExpiry === undefined || this.TimeUntilExpiry.toString().trim() === null
        || this.TimeUntilExpiry.toString().trim() === '') {
          // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTERTIMEUNTILEXPIRY').subscribe((res: string) => {
        this.confirm(res);
      });
      return;
    } else {
      if (isNaN(this.TimeUntilExpiry)) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.TIMEUNTILEXPIRYCHECK').subscribe((res: string) => {
          this.confirm(res);
        });
        return;
      }
    }
    if (this.Color === undefined || this.Color.toString().trim() === null) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTCOLOR').subscribe((res: string) => {
        this.confirm(res);
      });
      return;
    }
    if (this.vibration === undefined || this.vibration.toString().trim() === null) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTVIBRATEOPTION').subscribe((res: string) => {
        this.confirm(res);
      });
      return;
    }
    if (this.channel === undefined || this.channel.toString().trim() === null) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTCHANNEL').subscribe((res: string) => {
        this.confirm(res);
      });
      return;
    }
    if (this.isTask === true) {
      if (this.TaskReactionTimeLimit === undefined || this.TaskReactionTimeLimit.toString().trim() === null
          || this.TaskReactionTimeLimit.toString().trim() === '') {
            // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.ENTERTASKREACTIONTIMELIMIT').subscribe((res: string) => {
          this.confirm(res);
        });
        return;
      } else {
        if (isNaN(this.TaskReactionTimeLimit)) {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.TASKREACTIONTIMELIMITCHECK').subscribe((res: string) => {
            this.confirm(res);
          });
          return;
        }
      }
      if (this.TaskStartTimeLimit === undefined || this.TaskStartTimeLimit.toString().trim() === null
          || this.TaskStartTimeLimit.toString().trim() === '') {
            // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.ENTERTASKSTARTTIMELIMIT').subscribe((res: string) => {
          this.confirm(res);
        });
        return;
      } else {
        if (isNaN(this.TaskStartTimeLimit)) {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.TASKSTARTTIMELIMITCHECK').subscribe((res: string) => {
            this.confirm(res);
          });
          return;
        }
      }
      if (this.TaskClosureTimeLimit === undefined || this.TaskClosureTimeLimit.toString().trim() === null
          || this.TaskClosureTimeLimit.toString().trim() === '') {
            // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.ENTERTASKCLOSURETIMELIMIT').subscribe((res: string) => {
          this.confirm(res);
        });
        return;
      } else {
        if (isNaN(this.TaskClosureTimeLimit)) {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.TASKCLOSURELIMITCHECK').subscribe((res: string) => {
            this.confirm(res);
          });
          return;
        }
      }
    } else {
      this.TaskReactionTimeLimit = 0;
      this.TaskStartTimeLimit = 0;
      this.TaskClosureTimeLimit = 0;
    }
    if (this.QueueSize === undefined || this.QueueSize === null
        || this.QueueSize.toString().trim() === '') {
      this.QueueSize = 'null';
    } else {
      if (isNaN(this.QueueSize)) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.QUEUESIZENOTNUMBER').subscribe((res: string) => {
          this.confirm(res);
        });
        return;
      }
    }

    if (this.Sensitive === 'YES') {
      if (this.MaxSize === undefined || this.MaxSize === null || this.MaxSize.toString().trim() === '') {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.ENTERMAXSIZE').subscribe((res: string) => {
          this.confirm(res);
        });
        if (this.QueueSize === 'null') {
          this.QueueSize = null;
        }
        return;
      } else {
        if (isNaN(this.MaxSize)) {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.MAXSIZENOTNUMBER').subscribe((res: string) => {
            this.confirm(res);
          });
          if (this.QueueSize === 'null') {
            this.QueueSize = null;
          }
          return;
        }
      }

      if (this.PolicyLocation === undefined || this.PolicyLocation === null) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.SELECTPOLICYLOCATION').subscribe((res: string) => {
          this.confirm(res);
        });
        if (this.QueueSize === 'null') {
          this.QueueSize = null;
        }
        return;
      }

      if (this.StatusUpdate === undefined || this.StatusUpdate === null || this.StatusUpdate.length === 0) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.SELECTSTATUSUPDATE').subscribe((res: string) => {
          this.confirm(res);
        });
        if (this.QueueSize === 'null') {
          this.QueueSize = null;
        }
        return;
      } else {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < this.StatusUpdate.length; i++) {
          if (this.StatusUpdate.length === 1) {
            su = this.StatusUpdate[i].toString();
          } else if (this.StatusUpdate.length === 2) {
            su = this.StatusUpdate[0].toString() + ';' + this.StatusUpdate[1].toString();
          }  else if (this.StatusUpdate.length === 3) {
            su = this.StatusUpdate[0].toString() + ';' + this.StatusUpdate[1].toString() + ';' + this.StatusUpdate[2].toString();
          }
        }

        if (su.includes('1')) {
          if (this.assigneeoption === 'MANDATORY' || this.assigneeoption === 'OPTIONAL') {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.ASSIGNEEOPTIONNOTCOMPATIBLE').subscribe((res: string) => {
              this.confirm(res);
            });
            if (this.QueueSize === 'null') {
              this.QueueSize = null;
            }
            return;
          }
        }
      }
    } else {
      this.Sensitive = 'NO';
    }

    if (this.config.workflow !== 'OASC' && (this.assigneeoption === 'MANDATORY' || this.assigneeoption === 'OPTIONAL')) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.WORKFLOWNOTCOMPATIBLEWITHASSIGNEEOPTION').subscribe((res: string) => {
        this.confirm(res);
      });
      if (this.QueueSize === 'null') {
        this.QueueSize = null;
      }
      return;
    }

    this.usrgrp = [];
    if (this.users != null) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < this.users.length; i++) {
        const usr = new UsrGrp();
        usr.Id = this.users[i].userId;
        usr.IdType = 'U';
        usr.Name = this.users[i].UserName;
        this.usrgrp.push(usr);
      }
    }

    if (this.groups != null) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < this.groups.length; i++) {
        const grp = new UsrGrp();
        grp.Id = this.groups[i].groupId;
        grp.IdType = 'G';
        grp.Name = this.groups[i].GroupName;
        this.usrgrp.push(grp);
      }
    }

    if (this.TimeUntilExpiry.toString().includes('.') || this.TaskReactionTimeLimit.toString().includes('.') ||
        this.TaskStartTimeLimit.toString().includes('.') || this.TaskClosureTimeLimit.toString().includes('.')) {
          // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.NOPOINTS').subscribe((res: string) => {
        this.confirm(res);
      });
      return;
    }

    this.isSave = true;
    this.loading = true;

    const values = new ConfigUpdater();
    values.Id = this.configId;
    values.Values = [];

    const name = new KeyValue();
    name.Key = 'name';
    name.Value = this.ConfigurationName;
    values.Values.push(name);

    const priority = new KeyValue();
    priority.Key = 'priority';
    priority.Value = this.priority;
    values.Values.push(priority);

    const explim = new KeyValue();
    explim.Key = 'exp_lim';
    explim.Value = this.TimeUntilExpiry.toString();
    values.Values.push(explim);

    const color = new KeyValue();
    color.Key = 'color';
    color.Value = this.Color;
    values.Values.push(color);

    const vibrate = new KeyValue();
    vibrate.Key = 'vibrate';
    vibrate.Value = this.vibration;
    values.Values.push(vibrate);

    const channel = new KeyValue();
    channel.Key = 'channel';
    channel.Value = this.channel;
    values.Values.push(channel);

    const rctlim = new KeyValue();
    rctlim.Key = 'tsk_rct_lim';
    rctlim.Value = this.TaskReactionTimeLimit.toString();
    values.Values.push(rctlim);

    const stlim = new KeyValue();
    stlim.Key = 'tsk_st_lim';
    stlim.Value = this.TaskStartTimeLimit.toString();
    values.Values.push(stlim);

    const clslim = new KeyValue();
    clslim.Key = 'tsk_cls_lim';
    clslim.Value = this.TaskClosureTimeLimit.toString();
    values.Values.push(clslim);

    const queue = new KeyValue();
    queue.Key = 'queue';
    queue.Value = this.QueueSize.toString();
    values.Values.push(queue);

    const locsst = new KeyValue();
    locsst.Key = 'loc_sst';
    locsst.Value = this.Sensitive.toString();
    values.Values.push(locsst);

    if (this.Sensitive === 'YES') {
      const locmax = new KeyValue();
      locmax.Key = 'loc_max';
      locmax.Value = this.MaxSize.toString();
      values.Values.push(locmax);

      const locconf = new KeyValue();
      locconf.Key = 'loc_conf';
      locconf.Value = this.PolicyLocation.toString();
      values.Values.push(locconf);

      const locstatupdate = new KeyValue();
      locstatupdate.Key = 'loc_stat_update';
      locstatupdate.Value = su;
      values.Values.push(locstatupdate);
    }

    const asg = new KeyValue();
    asg.Key = 'asg_at_crt';
    asg.Value = this.assigneeoption.toString();
    values.Values.push(asg);

    const rec = new KeyValue();
    rec.Key = 'tsk_rec_opt';
    rec.Value = this.recoption.toString();
    values.Values.push(rec);

    const usrgrp = new KeyValue();
    usrgrp.Key = 'usr_grp';
    usrgrp.Value = JSON.stringify(this.usrgrp);
    values.Values.push(usrgrp);

    const category = new KeyValue();
    category.Key = 'category';
    if (this.cat !== undefined && this.cat !== null) {
      category.Value = this.cat.categoryId;
    } else {
      category.Value = '0';
    }
    values.Values.push(category);

    const rgroups = [];
    if (this.ragroups2 !== null && this.ragroups2.length > 0) {
      for (const val of this.ragroups2) {
        const rgroup = new TypeRoleGroup();
        rgroup.GroupId = val.groupId;
        rgroup.RoleName = 'Reader-All';
        rgroup.RoleParentType = 'task_type';
        rgroup.ParentId = this.cf.configurationId;

        rgroups.push(rgroup);
      }
    }
    if (this.rogroups2 !== null && this.rogroups2.length > 0) {
      for (const val of this.rogroups2) {
        const rgroup = new TypeRoleGroup();
        rgroup.GroupId = val.groupId;
        rgroup.RoleName = 'Reader-Own';
        rgroup.RoleParentType = 'task_type';
        rgroup.ParentId = this.cf.configurationId;

        rgroups.push(rgroup);
      }
    }
    if (this.ingroups2 !== null && this.ingroups2.length > 0) {
      for (const val of this.ingroups2) {
        const rgroup = new TypeRoleGroup();
        rgroup.GroupId = val.groupId;
        rgroup.RoleName = 'Initiator';
        rgroup.RoleParentType = 'task_type';
        rgroup.ParentId = this.cf.configurationId;

        rgroups.push(rgroup);
      }
    }
    if (this.ealgroups2 !== null && this.ealgroups2.length > 0) {
      for (const val of this.ealgroups2) {
        const rgroup = new TypeRoleGroup();
        rgroup.GroupId = val.groupId;
        rgroup.RoleName = 'Expert-All';
        rgroup.RoleParentType = 'task_type';
        rgroup.ParentId = this.cf.configurationId;

        rgroups.push(rgroup);
      }
    }
    if (this.easgroups2 !== null && this.easgroups2.length > 0) {
      for (const val of this.easgroups2) {
        const rgroup = new TypeRoleGroup();
        rgroup.GroupId = val.groupId;
        rgroup.RoleName = 'Expert-Assigned';
        rgroup.RoleParentType = 'task_type';
        rgroup.ParentId = this.cf.configurationId;

        rgroups.push(rgroup);
      }
    }
    if (this.angroups2 !== null && this.angroups2.length > 0) {
      for (const val of this.angroups2) {
        const rgroup = new TypeRoleGroup();
        rgroup.GroupId = val.groupId;
        rgroup.RoleName = 'Analytics';
        rgroup.RoleParentType = 'task_type';
        rgroup.ParentId = this.cf.configurationId;

        rgroups.push(rgroup);
      }
    }
    if (this.adgroups2 !== null && this.adgroups2.length > 0) {
      for (const val of this.adgroups2) {
        const rgroup = new TypeRoleGroup();
        rgroup.GroupId = val.groupId;
        rgroup.RoleName = 'Administrator';
        rgroup.RoleParentType = 'task_type';
        rgroup.ParentId = this.cf.configurationId;

        rgroups.push(rgroup);
      }
    }

    const data = new TypeRoleGroupData();
    data.ParentId = this.cf.configurationId;
    data.RoleParentType = 'task_type';
    data.TypeRoleGroup = rgroups;

    const rg = new KeyValue();
    rg.Key = 'role_groups';
    rg.Value = JSON.stringify(data);
    values.Values.push(rg);

    // tslint:disable-next-line: deprecation
    this.configService.putConfiguration(this.configId, values).subscribe(() => {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.CONFIGURATIONUPDATED').subscribe((res: string) => {
        this.notificationSvc.info('', res, 2000);
      });
      // this.router.navigateByUrl('/taskconfigurations');
      this.location.back();
      this.isSave = false;
      this.loading = false;
    }, err => this.handleError(err));
  }

  onCancel() {
    // this.router.navigateByUrl('/taskconfigurations');
    this.location.back();
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  handleError(err) {
    this.isSave = false;
    this.loading = false;
    if (err.error.Message === 'Input string was not in a correct format.') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.NOPOINTS').subscribe((res: string) => {
        this.confirm(res);
      });
    }

    if (this.QueueSize === null || this.QueueSize === 'null') {
      this.QueueSize = null;
    }
  }

}
