import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from './../../services/configuration/configuration.service';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from './../../services/analytics/analytics.service';
import * as CryptoJS from 'crypto-js';
import { ConfirmationService, MessageService } from 'primeng/api';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { Variables } from 'src/app/Models/variables';

@Component({
  selector: 'app-average-time-on-status',
  templateUrl: './average-time-on-status.component.html',
  styleUrls: ['./average-time-on-status.component.css']
})
export class AverageTimeOnStatusComponent implements OnInit {

  data: any;
  range: any;
  start: any;
  end: any;
  show = false;

  configs1 = [];
  conf1: any;
  options: { title: { display: boolean; text: string; fontSize: number; }; legend: { position: string; }; };
  confid: number;

  loading = false;

  allcount = 0;
  isadm = false;

  constructor(
    private analytics: AnalyticsService,
    private translate: TranslateService,
    private cookieService: CookieService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private notificationSvc: NotificationService,
    private configService: ConfigurationService
  ) {
      this.translate.addLangs(['English', 'French']);
      this.translate.setDefaultLang('English');

      let browserLang = this.translate.getBrowserLang();

      const pass = 'ranitessarldev2019';
      const lang = cookieService.get('goepla-portal-language');
      if (lang.trim() !== '') {
        const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
        const lg = declang;
        browserLang = lg;
        localStorage.setItem('language', lg);
      }

      if (localStorage.getItem('language') !== null) {
        browserLang = localStorage.getItem('language');
      }
      this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

      if (localStorage.getItem('filter-start') !== null && localStorage.getItem('filter-end') !== null) {
        const st = localStorage.getItem('filter-start');
        const ed = localStorage.getItem('filter-end');
        this.range = [new Date(st), new Date(ed)];
      }

      if (localStorage.getItem('filter-config') !== null) {
        this.confid = +localStorage.getItem('filter-config');
      }

      // tslint:disable-next-line: deprecation
      translate.get('GENERAL.ALL').subscribe((trans) => {
        const conf: any = [];
        conf.configurationId = 0;
        conf.configurationName = trans;
        this.configs1.push({ label: trans, value: conf });
        if (this.confid === 0) {
          this.conf1 = conf;
        }
      });

      const v = new Variables();
      const p = localStorage.getItem('ups');
      if (p == null) {
        this.isadm = false;
      }

      const pr = CryptoJS.AES.decrypt(p, v.pass).toString(CryptoJS.enc.Utf8);
      const perm = JSON.parse(pr);
      if (perm.includes('NTM.Administration.Full')) {
            this.isadm = true;
      }

      this.getConfigurations();
   }

   getConfigurations() {
    this.loading = true;
    // tslint:disable-next-line: deprecation
    this.configService.getConfigurations('T').subscribe((res) => {
      if (res != null) {
        const v = new Variables();
        const gis = localStorage.getItem('gi');
        const gs = CryptoJS.AES.decrypt(gis, v.pass).toString(CryptoJS.enc.Utf8);
        const gids = JSON.parse(gs);

        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          if (res[i].typeRoleGroups !== null && res[i].typeRoleGroups.length > 0) {
            let show = false;
            for (const val of res[i].typeRoleGroups) {
              if (val.typeRoleGroup.roleName === 'Analytics' && gids.includes(val.typeRoleGroup.groupId)) {
                show = true;
              }
            }

            if (this.isadm === true) {
              show = true;
            }

            if (show === false) {
              continue;
            }
          } else {
            if (this.isadm === true) {
            } else {
              continue;
            }
          }
          this.configs1.push({ label: res[i].configurationName, value: res[i] });
          if (this.confid >= 0) {
            if (res[i].configurationId === this.confid) {
              this.conf1 = res[i];
            }
          }
        }
      }
      this.loading = false;
    });
  }

   getAnalytics() {
    let open = '';
    let assigned = '';
    let started = '';
    let mins = '';
    let others = '';
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.OPEN').subscribe((trans) => {
      open = trans;
    });
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.ASSIGNED').subscribe((trans) => {
      assigned = trans;
    });
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.STARTED').subscribe((trans) => {
      started = trans;
    });
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.MINUTES').subscribe((trans) => {
      mins = trans;
    });
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.OTHERS').subscribe((trans) => {
      others = trans;
    });

    if (this.configs1 === undefined || this.configs1 === null || this.configs1.length === 1) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.NODATAAVAILABLE').subscribe((resp: string) => {
        this.notificationSvc.info('', resp, 2000);
        });
      return;
    }
    let tids = '';
    if (this.conf1.configurationId === 0) {
      if (this.allcount + 1 !== this.configs1.length) {
        for (const val of this.configs1) {
          if (val.value.configurationId !== 0) {
            tids += val.value.configurationId + ',';
          }
        }
      }
    }
    if (tids === '') {
      tids = ' ';
    }
    this.loading = true;
    this.analytics.getAnalyticsGeneric(this.conf1.configurationId, 'T', this.start, this.end,
                               0, 'averagetimeonstatus', ' ', tids)
                               // tslint:disable-next-line: deprecation
                  .subscribe((resp) => {
      if (resp === null || (resp[0].value2 === 0 && resp[1].value2 === 0 && resp[2].value2 === 0)) {
        this.show = false;
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.NODATAAVAILABLE').subscribe((ress: string) => {
          this.notificationSvc.info('', ress, 2000);
          });
      } else {
        let o = 0;
        let a = 0;
        let s = 0;
        let count = 0;
        for (const res of resp) {
          if (res.value1 === 'OPEN_TIME') {
            o = Math.round((res.value2 + Number.EPSILON) * 100) / 100;
          }
          if (res.value1 === 'ASSIGNED_TIME') {
            a = Math.round((res.value2 + Number.EPSILON) * 100) / 100;
          }
          if (res.value1 === 'STARTED_TIME') {
            s = Math.round((res.value2 + Number.EPSILON) * 100) / 100;
          }
          count += 1;
          if (resp.length === count) {
            this.data = {
              labels: [open, assigned, started, others],
              datasets: [
                  {
                      label: mins,
                      backgroundColor: '#42A5F5',
                      borderColor: '#1E88E5',
                      data: [o, a, s, 0]
                  }
              ]
            };
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.AVERAGETIMEONSTATUS').subscribe((trans) => {
              this.options = {
                title: {
                    display: true,
                    text: trans,
                    fontSize: 16
                },
                legend: {
                    position: 'top'
                }
              };
            });

            this.show = true;
          }
        }
      }

      this.loading = false;
    }, err => this.handleError(err));
   }

   ok() {
    this.data = [];
    if (this.range === undefined || this.range === null) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTRANGE').subscribe((res) => {
        this.confirm(res);
      });
      return;
    } else {
      if (this.range[0] !== undefined && this.range[0] !== null) {
        this.start = this.range[0].toDateString();
        const a = this.range[0].toDateString();
        const b = new Date(a);
        const c = new Date(b.getFullYear(), b.getMonth(), b.getDate());
        this.start = c.toDateString();
      } else {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.SELECTRANGE').subscribe((res) => {
          this.confirm(res);
        });
        return;
      }
      if (this.range[1] !== undefined && this.range[1] !== null) {
        this.end = this.range[1].toDateString();
        const a = this.range[1].toDateString();
        const b = new Date(a);
        const c = new Date(b.getFullYear(), b.getMonth(), b.getDate());
        this.end = c.toDateString();
      } else {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.SELECTRANGE').subscribe((res) => {
          this.confirm(res);
        });
        return;
      }

      if (this.conf1 === undefined || this.conf1 === null) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.SELECTCONFIGURATION').subscribe((res) => {
          this.confirm(res);
        });
        return;
      }
      localStorage.setItem('filter-start', this.start);
      localStorage.setItem('filter-end', this.end);
      localStorage.setItem('filter-config', this.conf1.configurationId);
      this.getAnalytics();
    }
   }

  ngOnInit() {
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  showInfo() {
    this.messageService.add({severity: 'info', summary: 'Info Message', detail: 'PrimeNG rocks'});
  }

  handleError(err) {
    this.loading = false;
    console.log(err);
  }

  setDate() {
    const d = new Date();
    const d1 = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 1);
    const d2 = new Date(d.getFullYear(), d.getMonth(), d.getDate() + 1);

    this.range = [d1, d2];
  }

}
