import { UsersService } from 'src/app/services/users/users.service';
import { Location } from '@angular/common';
import { Reminder } from './../../Models/Reminder';
import { RemindersService } from 'src/app/services/reminders/reminders.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CookieService } from 'ngx-cookie-service';
import { ReminderTypesService } from 'src/app/services/reminder-types/reminder-types.service';
import * as CryptoJS from 'crypto-js';
import { Variables } from 'src/app/Models/variables';

@Component({
  selector: 'app-create-reminder',
  templateUrl: './create-reminder.component.html',
  styleUrls: ['./create-reminder.component.css']
})
export class CreateReminderComponent implements OnInit {

  types = [];
  type: any;

  Details = [];
  detail: any;

  ReminderName: string;

  disableInterval = false;
  disableDurations = true;
  loading = false;
  name: string;

  user: string;
  count = 0;

  constructor(
    private router: Router,
    private notificationSvc: NotificationService,
    public translate: TranslateService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private remindertypeService: ReminderTypesService,
    private messageService: MessageService,
    private location: Location,
    private reminderService: RemindersService,
    private userService: UsersService
    ) {
    this.translate.addLangs(['English', 'French']);
    this.translate.setDefaultLang('English');

    let browserLang = this.translate.getBrowserLang();

    const pass = 'ranitessarldev2019';
    const lang = cookieService.get('goepla-portal-language');
    if (lang.trim() !== '') {
      const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
      const lg = declang;
      browserLang = lg;
      localStorage.setItem('language', lg);
    }

    if (localStorage.getItem('language') !== null) {
      browserLang = localStorage.getItem('language');
    }
    this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');
   }

  ngOnInit() {
    this.getUser();
    this.getReminderTypes();
  }

  getReminderTypes() {
    this.loading = true;
    // tslint:disable-next-line: deprecation
    this.remindertypeService.getReminderTypes(0, 0).subscribe((res) => {
      if (res != null) {
        for (const val of res) {
          this.types.push({label: val.reminderTypeName, value: val});
        }
      }
      this.loading = false;
    }, err => this.handleError(err));
  }

  getUser() {
    const v = new Variables();
    const val = localStorage.getItem('ui');
    const ui = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);
    const usrid = +ui;
    // tslint:disable-next-line: deprecation
    this.userService.getUser(0, usrid, ' ', ' ').subscribe((res) => {
      this.name = res.firstName + ' ' + res.lastName;
    }, err => this.handleError2(err));
  }

  getDetail() {
    this.Details = [];
    const det = this.type.reminderTypeDetail.toString().split(',');
    for (const val of det) {
      this.Details.push({label: val.trim(), value: val.trim()});
    }
  }

  onCreate() {
    if (this.type === undefined || this.type === null) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTREMINDERTYPE').subscribe((trans) => {
        this.confirm(trans);
      });
      return;
    }

    if (this.detail === undefined || this.detail === null) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTOPTION').subscribe((trans) => {
        this.confirm(trans);
      });
      return;
    }

    let rname = '';
    if (this.ReminderName === undefined || this.ReminderName === null || this.ReminderName.trim() === '') {
      /*this.translate.get('GENERAL.ENTERREMINDERNAME').subscribe((trans) => {
        this.confirm(trans);
      });
      return;*/
    } else {
      rname = ': ' + this.ReminderName;
    }

    const rem = new Reminder();
    // rem.CreatedBy = this.type.createdBy;
    rem.CreatedBy = this.name;
    rem.Expiry = this.type.reminderExpiry;
    rem.ReminderName = this.detail + rname;
    rem.ReminderTypeName = this.type.reminderTypeName;
    rem.Status = 'ENABLED';

    this.loading = true;
    // tslint:disable-next-line: deprecation
    this.reminderService.postReminder(rem).subscribe((res) => {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.REMINDERCREATED').subscribe((trans) => {
        this.showSuccess(trans);
        this.location.back();
      });
    }, err => this.handleError(err));
  }

  onCancel() {
    this.location.back();
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  showSuccess(msg) {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.SUCCESS').subscribe((trans) => {
      this.messageService.add({key: 'tc', severity: 'success', summary: trans, detail: msg});
    });
  }

  showInfo(msg) {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.INFO').subscribe((trans) => {
      this.messageService.add({key: 'tc', severity: 'info', summary: trans, detail: msg});
    });
  }

  showWarn(msg) {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.WARNING').subscribe((trans) => {
      this.messageService.add({key: 'tc', severity: 'warn', summary: trans, detail: msg});
    });
  }

  showError(msg) {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.ERROR').subscribe((trans) => {
      this.messageService.add({key: 'tc', severity: 'error', summary: trans, detail: msg});
    });
  }

  handleError(err) {
    this.loading = false;
    if (err.error.MessageText === 'duplicate key value violates unique constraint "t_ntm_reminder_reminder_name_reminder_type_name_key"') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.REMINDERALREADYEXISTS').subscribe((trans) => {
        this.confirm(trans);
      });
    }
  }

  handleError2(err) {
    this.count += 1;
    if (this.count < 10) {
      this.getUser();
    }
  }

}
