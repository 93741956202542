import { TypeRoleGroup, TypeRoleGroupInfo } from './role';
import { UsrGrp } from './UsrGrp';

export class Configuration {
    public OrganisationId: number;
    public ConfigurationId: number;
    public ConfigurationType: string;
    public ConfigurationName: string;
    public Priority: number;
    public ExpiryTime: number;
    public Color: string;
    public Vibrate: number;
    public Channel: string;
    public ReactionTimeLimit: number;
    public StartTimeLimit: number;
    public CloseTimeLimit: number;
    public QueueSize: string;
    public LocationSensitive: string;
    public SameLocationMax: number;
    public PolicyLocationConfirmation: number;
    public PolicyStatusUpdate: string;
    public Workflow: string;
    public AssigneeAtCreation: string;
    public TaskRecOption: string;
    public UsrGrp: UsrGrp[];
    public CategoryId: number;
    public RoleGroups: TypeRoleGroup[];
    public TypeRoleGroups: TypeRoleGroupInfo[];
}
