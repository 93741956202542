import { DataCache } from 'src/app/Helpers/data-cache';
import { Location } from './../../Models/location';
import { LocationService } from './../../services/location/location.service';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { Variables } from 'src/app/Models/variables';
import * as CryptoJS from 'crypto-js';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.css']
})
export class LocationsComponent implements OnInit {

  locs = [];
  loc: any;

  locname: any;
  locdesc: any;

  locnameerror = false;
  locdescerror = false;

  page = 1;
  size = 100;

  loading = false;
  loadmore = false;

  isaddloc = false;

  ismanage = false;

  constructor(
    private locService: LocationService,
    private messageService: MessageService,
    public translate: TranslateService,
    private http: HttpClient
    ) {
      translate.use('English');

      const v = new Variables();
      const p = localStorage.getItem('ups');
      if (p == null) {
        this.ismanage = false;
      }

      const pr = CryptoJS.AES.decrypt(p, v.pass).toString(CryptoJS.enc.Utf8);
      const perm = JSON.parse(pr);
      if (perm.includes('NTM.Administration.Locations')
          || perm.includes('NTM.Administration.Full')) {
            this.ismanage = true;
      } else {
        this.ismanage = false;
      }
   }

  ngOnInit() {
    this.getLocations();
  }

  async getLocations() {
    this.loading = true;
    this.showLoad();
    const lcs = await this.locService.getLocations(this.page, this.size, ' ').toPromise()
    .catch(err => this.handleError(err));
    const ls = [];
    if (lcs !== undefined && lcs !== null && lcs.length > 0) {
      if (lcs.length >= this.size) {
        this.loadmore = true;
      } else {
        this.loadmore = false;
      }

      for (const val of lcs) {
        val.bcolor = 'white';
        if (this.page > 1) {
          this.locs.push(val);
        } else {
          ls.push(val);
        }
      }
    } else {
      this.loadmore = false;
    }

    if (this.page === 1) {
      this.locs = ls;
    }

    this.hideLoad();
    this.loading = false;
  }

  async create() {
    let iscreate = true;
    this.locdescerror = false;
    this.locnameerror = false;
    if (this.locname === undefined || this.locname === null || this.locname.trim() === '') {
      iscreate = false;
      this.locnameerror = true;
    }
    if (this.locdesc === undefined || this.locdesc === null || this.locdesc.trim() === '') {
      iscreate = false;
      this.locdescerror = true;
    }

    if (iscreate === true) {
      const v = new Variables();
      const val = localStorage.getItem('oi');
      const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);

      const loc = new Location();
      loc.LocationName = this.locname;
      loc.LocationDescription = this.locdesc;
      loc.OrganisationId = +oi;

      this.loading = true;
      this.showLoad();
      const res = await this.locService.postLocation(loc).toPromise()
      .catch(err => this.handleError(err));

      if (res !== undefined && res !== null && res === true) {
        // success
        const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch();
        const msg = await this.translate.get('GENERAL.LOCATIONCREATED').toPromise().catch();
        this.showSuccess(title, msg);

        this.size = this.size * this.page;
        this.page = 1;

        this.isaddloc = false;
        await this.getLocations();

        DataCache.loadData(this.http);
      } else {
        // failure
        // const title = await this.translate.get('GENERAL.ERROR').toPromise().catch();
        // const msg = await this.translate.get('GENERAL.LOCATIONNOTCREATED').toPromise().catch();
        // this.showError(title, msg, 10000);
      }

      this.hideLoad();
      this.loading = false;
    }

  }

  locClicked(loc) {
    const vs = [];
    for (const val of this.locs) {
      const v = val;
      if (val.locationName === loc.locationName) {
        v.bcolor = 'lightblue';
      } else {
        v.bcolor = 'white';
      }

      vs.push(v);
    }

    this.locs = vs;
  }

  loadMore() {
    this.page += 1;
    this.getLocations();
  }

  showAddLocation() {
    this.locname = null;
    this.locdesc = null;
    this.locnameerror = false;
    this.locdescerror = false;

    this.isaddloc = true;
  }

  cancel() {
    this.locname = null;
    this.locdesc = null;
    this.locnameerror = false;
    this.locdescerror = false;

    this.isaddloc = false;
  }

  async showConfirmDeleteLoc(value) {
    this.loc = value;
    const title = await this.translate.get('GENERAL.DELETE').toPromise().catch(err => console.log(err));
    this.showConfirm(title + ' ' + value.locationName + '?',
    '', 'deleteloc');
  }

  async deleteLocation() {
    const loc = new Location();
    loc.LocationName = this.loc.locationName;
    loc.LocationDescription = this.loc.locationDescription;
    loc.OrganisationId = this.loc.organisationId;

    this.loading = true;
    this.showLoad();
    const res = await this.locService.deleteLocation(loc).toPromise()
    .catch(err => this.handleError(err));

    if (res !== undefined && res !== null && res === true) {
      // success
      const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch();
      const msg = await this.translate.get('GENERAL.LOCATIONDELETED').toPromise().catch();
      this.showSuccess(title, msg);

      this.onRejectDeleteLoc();

      this.size = this.size * this.page;
      this.page = 1;

      await this.getLocations();

      DataCache.loadData(this.http);
    } else {
      // failure
      const title = await this.translate.get('GENERAL.ERROR').toPromise().catch();
      const msg = await this.translate.get('GENERAL.LOCATIONNOTDELETED').toPromise().catch();
      this.showError(title, msg, 10000);
    }

    this.hideLoad();
    this.loading = false;
  }

  onRejectDeleteLoc() {
    this.loc = null;
    this.messageService.clear('deleteloc');
  }

  showLoad(): any {
    this.loading = true;
    this.showConfirm('', '', 'loader', '');
  }

  hideLoad(): any {
    this.loading = false;
    this.messageService.clear('loader');
  }

  showSuccess(title: string, details: string, duration: number = 3000): any {
    this.messageService.add({severity: 'success', summary: title, detail: details, key: 'tc', life: duration});
  }

  showInfo(title: string, details: string, duration: number = 3000): any {
      this.messageService.add({severity: 'info', summary: title, detail: details, key: 'tc', life: duration});
  }

  showWarn(title: string, details: string, duration: number = 3000): any {
      this.messageService.add({severity: 'warn', summary: title, detail: details, key: 'tc', life: duration});
  }

  showError(title: string, details: string, duration: number = 3000): any {
      this.messageService.add({severity: 'error', summary: title, detail: details, key: 'tc', life: duration});
  }

  showConfirm(title: string, details: string, keyvalue: string, severe: string = 'warn'): any {
    // this.messageService.clear();
    this.messageService.add({key: keyvalue, sticky: true, severity: severe, summary: title, detail: details});
  }

  async handleError(err) {
    this.loading = false;
    if (err !== undefined && err !== null) {
      if (err.error !== undefined && err.error !== null) {
        if (err.error.MessageText === 'duplicate key value violates unique constraint "t_location_pkey"') {
          const title = await this.translate.get('GENERAL.INFO').toPromise().catch();
          const msg = await this.translate.get('GENERAL.LOCATIONEXISTS').toPromise().catch();
          this.showWarn(title, msg, 10000);
        } else {
          console.log(err);
        }
      }
    }
  }

}
