import { Observable } from 'rxjs';
import { tap, shareReplay } from 'rxjs/operators';
import { Variables } from '../Models/variables';
import { Urls } from './Urls';
import * as CryptoJS from 'crypto-js';
import { HttpClient } from '@angular/common/http';

export class DataCache  {
    static locdata$: Observable<any>;
    static catdata$: Observable<any>;
    static catitemdata$: Observable<any>;
    static itemdefdata$: Observable<any>;
    static groupdata$: Observable<any>;

    static locData: any;
    static catData: any;
    static catitemData: any;
    static itemdefData: any;
    static groupData: any;

    static url = new Urls();
    static http: any;

    constructor(private ht: HttpClient) {
        DataCache.http = ht;
    }

    static loadData(ht: HttpClient) {
        DataCache.http = ht;
        const apiUrl = this.url.apiUrl;
        const usmApiUrl = this.url.usmApiUrl;

        const v = new Variables();
        const val = localStorage.getItem('oi');
        if (val === undefined || val === null) {
          return;
        }
        const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);

        const orgId = +oi;

        const page = 0;
        const size = 0;
        const catid = 0;
        const itemid = 0;
        const catname = ' ';
        const loc = ' ';

        // this.locdata$ = this.http.get(apiUrl + '/api/locations/' + page + '/' + size + '/' + orgId
        //  + '/' + loc + '/').pipe(
        //   tap(console.log),
        //   shareReplay(1),
        //   tap(() => console.log('after sharing'))
        // );

        // get locations
        this.locdata$ = this.http.get(apiUrl + '/api/locations/' + page + '/' + size + '/' + orgId
         + '/' + loc + '/').pipe(
          tap(),
          shareReplay(1),
          tap()
        );

        // get order categories
        this.catdata$ = this.http.get(apiUrl + '/api/ordercategory/' + page + '/' + size + '/'
         + orgId + '/' + catid + '/' + catname + '/').pipe(
          tap(),
          shareReplay(1),
          tap()
        );

        // get order category items
        this.catitemdata$ = this.http.get(apiUrl + '/api/ordercategoryitem/' + page + '/' + size + '/'
         + catid + '/').pipe(
          tap(),
          shareReplay(1),
          tap()
        );

        // get order category items
        this.itemdefdata$ = this.http.get(apiUrl + '/api/itemdefinition/' + page + '/' + size + '/'
         + itemid + '/').pipe(
          tap(),
          shareReplay(1),
          tap()
        );

        // get groups
        this.groupdata$ = this.http.get(usmApiUrl + '/api/groups/' + orgId).pipe(
          tap(),
          shareReplay(1),
          tap()
        );
    }

    getLocData() {
      DataCache.locdata$.subscribe(data => DataCache.locData = data);
    }

    getCatData() {
        DataCache.catdata$.subscribe(data => DataCache.catData = data);
    }

    getCatItemData() {
        DataCache.catitemdata$.subscribe(data => DataCache.catitemData = data);
    }

    getItemDefData() {
      DataCache.itemdefdata$.subscribe(data => DataCache.itemdefData = data);
    }

    getGroupData() {
      DataCache.groupdata$.subscribe(data => DataCache.groupData = data);
    }

    clearData() {
        DataCache.locData = null;
        DataCache.catData = null;
        DataCache.catitemData = null;
        DataCache.groupData = null;
    }
  }
