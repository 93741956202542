import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Urls } from 'src/app/Helpers/Urls';
import { Variables } from 'src/app/Models/variables';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class UsersgroupsService {
  url = new Urls();
  usmApiUrl: string;
  orgId: number;

  constructor(
    private httpclient: HttpClient
  ) {
      this.usmApiUrl = this.url.usmApiUrl;
      const v = new Variables();
      const val = localStorage.getItem('oi');
      const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);

      this.orgId = +oi;
      // this.orgId = +localStorage.getItem('organisation-id');
  }

  getUsers() {
    const v = new Variables();
    const val = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);

    this.orgId = +oi;
    // this.orgId = +localStorage.getItem('organisation-id');
    // tslint:disable-next-line: max-line-length
    const url = this.usmApiUrl + '/api/users/' + this.orgId;
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  getGroups() {
    const v = new Variables();
    const val = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);

    this.orgId = +oi;
    // this.orgId = +localStorage.getItem('organisation-id');
    // tslint:disable-next-line: max-line-length
    const url = this.usmApiUrl + '/api/groups/' + this.orgId;
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  getUserGroups(id: number) {
    const v = new Variables();
    const val = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);

    this.orgId = +oi;
    // this.orgId = +localStorage.getItem('organisation-id');
    // tslint:disable-next-line: max-line-length
    const url = this.usmApiUrl + '/api/usergroups/' + this.orgId + '/' + id;
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  getUserGroupsByGroupIdUserId(gid: number, uid: number) {
    const v = new Variables();
    const val = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);

    this.orgId = +oi;
    // this.orgId = +localStorage.getItem('organisation-id');
    // tslint:disable-next-line: max-line-length
    const url = this.usmApiUrl + '/api/usergroups/Get_ByUserId/' + this.orgId + '/' + gid + '/' + uid;
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  getOrganisations() {
    const v = new Variables();
    const val = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);

    this.orgId = +oi;
    // this.orgId = +localStorage.getItem('organisation-id');
    // tslint:disable-next-line: max-line-length
    const url = this.usmApiUrl + '/api/organisations/' + this.orgId;
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  private handleError(error: any) {
    // console.error('error', error);
    throw error;
    return error;
  }
}
