import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Urls } from 'src/app/Helpers/Urls';
import { Variables } from 'src/app/Models/variables';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class ChannelNotService {

  url = new Urls();
  apiUrl: string;
  orgId: number;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = this.url.apiUrl;
      const v = new Variables();
      const val = localStorage.getItem('oi');
      const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);

      this.orgId = +oi;
      // this.orgId = +localStorage.getItem('organisation-id');
  }

  getChannelNotifications(page: number, size: number, typeids: string, gids: string, uid: number) {
    const url = this.apiUrl + '/api/channelnotifications/' + page + '/' + size + '/' + typeids + '/'
    + gids + '/' + uid + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  getChannelNotifications2(page: number, size: number, typeids: string) {
    const url = this.apiUrl + '/api/channelnotifications/' + page + '/' + size + '/' + typeids + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  getNotifications(page: number, size: number, notId: string, confId: number, type: string, start: string,
                   end: string, read: number, closed: number, loc: string, uid: number, name: string,
                   status: string, title: string, content: string, tids: string,
                   stat: string, phone: string, gids: string, userid: number) {
    const v = new Variables();
    const val = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);

    this.orgId = +oi;
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/notifications/getnotification/' + page + '/' + size + '/' + 0 + '/' + notId + '/'
    + confId + '/' + type + '/' + start + '/' + end + '/' + read + '/' + closed + '/' + loc + '/' + uid + '/'
    + name + '/' + status + '/' + title + '/' + content + '/' + tids + '/'
    + stat + '/' + phone + '/' + gids + '/' + userid + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  private handleError(error: any) {
    // console.error('error', error);
    throw error;
    return error;
  }
}
