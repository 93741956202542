import { ConfirmationService } from 'primeng/api';
import { ConfigurationService } from './../../services/configuration/configuration.service';
import { NotificationService } from './../../services/notification/notification.service';
import { Notification } from './../../Models/Notification';
import { NotificationsServiceService } from './../../services/notifications-service/notifications-service.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { Location } from '@angular/common';
import * as CryptoJS from 'crypto-js';
import { Variables } from 'src/app/Models/variables';

@Component({
  selector: 'app-create-notification',
  templateUrl: './create-notification.component.html',
  styleUrls: ['./create-notification.component.css']
})
export class CreateNotificationComponent implements OnInit {

  isSelectUser = false;
  isSelectGroup = false;

  users = ['test_user_one', 'test_user_two'];
  groups = ['test_group_one', 'test_group_two'];

  configs = [];
  conf: any;

  configs1 = [];
  conf1: any;

  alertMessage: string;
  isCancel: boolean;
  isSend: boolean;
  isAlert: boolean;
  loading: boolean;

  MessageTitle: string;
  MessageBody: string;

  isadm = false;

  constructor(
    private router: Router,
    private notification: NotificationsServiceService,
    private notificationSvc: NotificationService,
    public translate: TranslateService,
    private configService: ConfigurationService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private location: Location
  ) {
    this.translate.addLangs(['English', 'French']);
    this.translate.setDefaultLang('English');

    let browserLang = this.translate.getBrowserLang();

    const pass = 'ranitessarldev2019';
    const lang = cookieService.get('goepla-portal-language');
    if (lang.trim() !== '') {
      const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
      const lg = declang;
      browserLang = lg;
      localStorage.setItem('language', lg);
    }

    if (localStorage.getItem('language') !== null) {
      browserLang = localStorage.getItem('language');
    }
    this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    const v = new Variables();
    const p = localStorage.getItem('ups');
    if (p != null) {
      const val = CryptoJS.AES.decrypt(p, v.pass).toString(CryptoJS.enc.Utf8);
      const perm = JSON.parse(val);
      if (perm.includes('NTM.Administration.Full')) {
        this.isadm = true;
      }
    }

    this.getConfigurations();
  }

  ngOnInit() {
  }

  getConfigurations() {
    this.loading = true;
    // tslint:disable-next-line: deprecation
    this.configService.getConfigurations('I').subscribe((res) => {
      if (res != null) {
        const v = new Variables();
        const gis = localStorage.getItem('gi');
        const gs = CryptoJS.AES.decrypt(gis, v.pass).toString(CryptoJS.enc.Utf8);
        const gids = JSON.parse(gs);
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          if (res[i].typeRoleGroups !== null && res[i].typeRoleGroups.length > 0) {
            let show = false;
            for (const val of res[i].typeRoleGroups) {
              if (val.typeRoleGroup.roleName === 'Initiator' && gids.includes(val.typeRoleGroup.groupId)) {
                show = true;
              }
            }

            if (this.isadm === true) {
              show = true;
            }

            if (show === false) {
              continue;
            }
          } else {
            if (this.isadm === true) {
            } else {
              continue;
            }
          }
          this.configs.push(res[i]);
          this.configs1.push({label: res[i].configurationName, value: res[i]});
          /*if (res[i].configurationType === 'I') {
            this.configs.push(res[i]);
            this.configs1.push({label: res[i].configurationName, value: res[i]});
          }*/
        }
      }
      this.loading = false;
    });
  }

  onSend() {
    this.loading = true;
    this.isSend = true;

    if (this.conf1 === undefined || this.conf1 === null) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTINFORMATIONCONFIGURATION').subscribe((res: string) => {
        this.confirm(res);
      });

      this.loading = false;
      this.isSend = false;
    } else if (this.MessageTitle == null || this.MessageTitle === '' || this.MessageTitle === ' ') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.PROVIDEMESSAGETITLE').subscribe((res: string) => {
        this.confirm(res);
      });

      this.loading = false;
      this.isSend = false;
    } else if (this.MessageBody == null || this.MessageBody === '' || this.MessageBody === ' ') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.PROVIDEMESSAGEBODY').subscribe((res: string) => {
        this.confirm(res);
      });

      this.loading = false;
      this.isSend = false;
    } else {
      const notif = new Notification();
      notif.NotificationId = '';
      notif.ConfigurationId = this.conf1.configurationId;
      notif.MessageTitle = this.MessageTitle;
      notif.MessageBody = this.MessageBody;

      const v = new Variables();
      const val = localStorage.getItem('ui');
      const ui = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);
      notif.CreatedBy = +ui;

      const now = new Date();
      notif.Created = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours() + 1,
                                + now.getMinutes(), now.getSeconds());
      notif.Expires = new Date(notif.Created.getFullYear(), notif.Created.getMonth(), notif.Created.getDate(),
                            + notif.Created.getHours() + 5, notif.Created.getMinutes(), notif.Created.getSeconds());

      if (this.conf1.queueSize === null || this.conf1.queueSize === 'null') {
        this.create(notif);
      } else {
        this.checkQueueSize(notif);
      }
    }
  }

  create(notif) {
    // tslint:disable-next-line: deprecation
    this.notification.postNotification(notif).subscribe(() => {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.NOTIFICATIONCREATED').subscribe((res: string) => {
        this.notificationSvc.info('', res, 2000);
      });
      // this.router.navigateByUrl('/notifications');
      this.location.back();
      this.isSend = false;
      this.loading = false;
    });
  }

  checkQueueSize(notif) {
    let all = 0;
    let read = 0;
    let unread = 0;
    const qs = +this.conf1.queueSize;
    this.notification.getNotifications(0, 0, ' ', this.conf1.configurationId, 'I', ' ', ' ', 0, 0, ' ',
    // tslint:disable-next-line: deprecation
    0, ' ', ' ', ' ', ' ').subscribe((res) => {
      if (res != null) {
        all = res.length;
        this.notification.getNotifications(0, 0, ' ', this.conf1.configurationId, 'I', ' ', ' ', 1, 0, ' ',
        // tslint:disable-next-line: deprecation
        0, ' ', ' ', ' ', ' ').subscribe((resp) => {
          if (resp != null) {
            read = resp.length;
            unread = all - read;
            if (unread >= qs) {
              // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.QUEUELIMIT1').subscribe((res1: string) => {
                // tslint:disable-next-line: deprecation
                this.translate.get('GENERAL.IQUEUELIMIT2').subscribe((res2: string) => {
                  this.confirm(res1 + ' ' + unread + ' ' + res2);
                });
              });
              this.isSend = false;
              this.loading = false;
            } else {
              this.create(notif);
            }
          } else {
            if (all >= qs) {
              // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.QUEUELIMIT1').subscribe((res1: string) => {
                // tslint:disable-next-line: deprecation
                this.translate.get('GENERAL.IQUEUELIMIT2').subscribe((res2: string) => {
                  this.confirm(res1 + ' ' + all + ' ' + res2);
                });
              });
              this.isSend = false;
              this.loading = false;
            } else {
              this.create(notif);
            }
          }
        });
      } else {
        if (qs === 0) {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.QUEUELIMIT1').subscribe((res1: string) => {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.IQUEUELIMIT2').subscribe((res2: string) => {
              this.confirm(res1 + ' ' + 0 + ' ' + res2);
            });
          });
          this.isSend = false;
          this.loading = false;
        } else {
          this.create(notif);
        }
      }
    });
  }

  onCancel() {
    this.loading = true;
    this.isCancel = true;
    // this.router.navigateByUrl('/notifications');
    this.location.back();
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

}
