import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { CookieService } from 'ngx-cookie-service';
import { Urls } from 'src/app/Helpers/Urls';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { Variables } from 'src/app/Models/variables';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})
export class OverviewComponent implements OnInit {

  urls = new Urls();

  myaveragettc = 0;
  myclosedtasks = 0;
  myactivities = 0;
  myqueue = 0;

  inqueue = 0;
  open = 0;
  assigned = 0;
  started = 0;

  options: { title: { display: boolean; text: string; fontSize: number; }; legend: { position: string; }; };
  avgData: any;

  uid: number;
  now: string;
  past: string;
  distData: { labels: string[]; datasets: { data: number[]; backgroundColor: any[]; hoverBackgroundColor: any[]; }[]; };
  usrClosedData: { labels: string[]; datasets: { data: number[]; backgroundColor: any[]; hoverBackgroundColor: any[]; }[]; };
  closedUserData: { labels: string[]; datasets: { label: string; backgroundColor: string; borderColor: string; data: number[]; }[]; };
  usrActData: { labels: string[]; datasets: { label: string; backgroundColor: string; borderColor: string; data: number[]; }[]; };
  myttcData: { labels: string[]; datasets: { label: string; backgroundColor: string; borderColor: string; data: number[]; }[]; };
  distData2: { labels: string[]; datasets: { data: number[]; backgroundColor: string[]; hoverBackgroundColor: string[]; }[]; };

  closed = 0;
  expired = 0;

  assignedontime = 0;
  startedontime = 0;
  closedontime = 0;
  closedlate = 0;

  createdbydayData: any;
  closedbydayData: any;
  ttcbydayData: any;

  assignedontimeData: any;
  startedontimeData: any;
  closedontimeData: any;

  show = true;

  constructor(
    private router: Router,
    private notificationSvc: NotificationService,
    public translate: TranslateService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private analyticsService: AnalyticsService
    ) {
      this.translate.addLangs(['English', 'French']);
      this.translate.setDefaultLang('English');

      let browserLang = this.translate.getBrowserLang();

      const pass = 'ranitessarldev2019';
      const lang = cookieService.get('goepla-portal-language');
      if (lang.trim() !== '') {
        const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
        const lg = declang;
        browserLang = lg;
        localStorage.setItem('language', lg);
      }

      if (localStorage.getItem('language') !== null) {
        browserLang = localStorage.getItem('language');
      }
      this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

      const v = new Variables();
      const val = localStorage.getItem('ui');
      const ui = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);
      this.uid = +ui;
      const d = new Date();
      this.now = new Date().toDateString();
      this.past = new Date(d.setDate(d.getDate() - 10)).toDateString();
    }

  ngOnInit() {
    this.getAmount();
    this.getAmount2();
    this.getAnalyticsClosedTaskByUser();
    this.getAnalyticsUserActivitiesByDay();
    this.getAnalyticsMyTTC();
    this.getAnalyticsAssignedOnTimeByDay();
    this.getAnalyticsStartedOnTimeByDay();
    this.getAnalyticsClosedOnTimeByDay();
  }

  getAmount() {
    this.analyticsService.getDashboards(this.past, this.now, this.uid, 'inqueue', ' ').subscribe((mq) => {
      this.analyticsService.getDashboards(this.past, this.now, this.uid, 'closed', ' ').subscribe((mcls) => {
        this.myqueue = mq;
        this.myclosedtasks = mcls;
      }, err => this.handleError(err));
    }, err => this.handleError(err));
  }

  getAmount2() {
    this.analyticsService.getDashboards(this.past, this.now, this.uid, 'assigned2', ' ').subscribe((asg) => {
      this.analyticsService.getDashboards(this.past, this.now, this.uid, 'started2', ' ').subscribe((str) => {
        this.analyticsService.getDashboards(this.past, this.now, this.uid, 'closed', ' ').subscribe((cls) => {
          this.analyticsService.getDashboards(this.past, this.now, this.uid, 'expired', ' ').subscribe((exp) => {
            this.analyticsService.getDashboards(this.past, this.now, this.uid, 'assignedontime', ' ').subscribe((assot) => {
              this.analyticsService.getDashboards(this.past, this.now, this.uid, 'startedontime', ' ').subscribe((strot) => {
                this.analyticsService.getDashboards(this.past, this.now, this.uid, 'closedontime', ' ').subscribe((clsot) => {
                  this.analyticsService.getDashboards(this.past, this.now, this.uid, 'closedlate', ' ').subscribe((clslate) => {
                    this.analyticsService.getDashboards(this.past, this.now, this.uid, 'created', ' ').subscribe((crt) => {
                      /*this.assigned = asg;
                      this.started = str;
                      this.closed = cls;
                      this.expired = exp;*/
                      if (assot > 0) {
                        this.assignedontime = Math.round((((assot / asg) * 100) + Number.EPSILON) * 100) / 100;
                      }
                      if (strot > 0) {
                        this.startedontime = Math.round((((strot / str) * 100) + Number.EPSILON) * 100) / 100;
                      }
                      if (clsot > 0) {
                        this.closedontime = Math.round((((clsot / cls) * 100) + Number.EPSILON) * 100) / 100;
                      }
                      if (clslate > 0) {
                        this.closedlate = Math.round((((clslate / cls) * 100) + Number.EPSILON) * 100) / 100;
                      }
                    }, err => this.handleError(err));
                  }, err => this.handleError(err));
                }, err => this.handleError(err));
              }, err => this.handleError(err));
            }, err => this.handleError(err));
          }, err => this.handleError(err));
        }, err => this.handleError(err));
      }, err => this.handleError(err));
    }, err => this.handleError(err));
  }

   getAnalyticsClosedTaskByUser() {
    let amttsks = '';
    let day = '';
    this.translate.get('GENERAL.AMOUNTOFTASKS').subscribe((trans) => {
      amttsks = trans;
    });
    this.translate.get('GENERAL.DAY').subscribe((trans) => {
      day = trans;
    });

    this.analyticsService.getAnalytics(0, 'T', this.past, this.now,
                               this.uid, 'closedbyday', ' ')
                  .subscribe((res) => {
      const labs: string[] = [];
      const datasets: number[] = [];
      let count = 0;
      if (res != null && res.length > 0) {
        for (const v of res) {
          const date = new Date(v.value2);
          labs.push(date.toDateString());
          datasets.push(v.value1);
          count += 1;

          if (count === res.length) {
            // labs.push(this.def.toDateString());
            // datasets.push(0);
            this.closedUserData = {
              labels: labs,
              datasets: [
                  {
                      label: amttsks,
                      backgroundColor: '#42A5F5',
                      borderColor: '#1E88E5',
                      data: datasets
                  }
              ]
            };
          }
        }
      } else {
      }
    }, err => this.handleError(err));
   }

   getAnalyticsUserActivitiesByDay() {
    let mins = '';
    let week = '';
    let tot = 0;
    this.translate.get('GENERAL.MINUTES').subscribe((trans) => {
      mins = trans;
    });

    this.translate.get('GENERAL.WEEK').subscribe((trans) => {
      week = trans;
    });

    this.analyticsService.getAnalytics(0, 'T', this.past,
                                this.now, this.uid, 'useractivitybyday', ' ').subscribe((res) => {
      const labs: string[] = [];
      const datasets: number[] = [];
      let count = 0;
      if (res != null && res.length > 0) {
        for (const v of res) {
          labs.push(new Date(v.value3).toDateString());
          datasets.push(v.value1);
          tot += +v.value1;
          count += 1;

          if (count === res.length) {
            this.myactivities = Math.round(((tot / 60) + Number.EPSILON) * 100) / 100;
            this.usrActData = {
              labels: labs,
              datasets: [
                  {
                      label: mins,
                      backgroundColor: '#42A5F5',
                      borderColor: '#1E88E5',
                      data: datasets
                  }
              ]
            };
          }
        }
      } else {
      }
    }, err => this.handleError(err));
   }

   getAnalyticsMyTTC() {
    let mins = '';
    let week = '';
    let tot = 0;
    this.translate.get('GENERAL.MINUTES').subscribe((trans) => {
      mins = trans;
    });

    this.translate.get('GENERAL.WEEK').subscribe((trans) => {
      week = trans;
    });

    this.analyticsService.getAnalytics(0, 'T', this.past,
                                this.now, this.uid, 'averagettctrend', ' ').subscribe((res) => {
      const labs: string[] = [];
      const datasets: number[] = [];

      let count = 0;
      if (res != null && res.length > 0) {
        for (const v of res) {
          labs.push(new Date(v.value3).toDateString());
          datasets.push(Math.round(((v.value1) + Number.EPSILON) * 100) / 100);
          tot += v.value1;
          count += 1;

          if (count === res.length) {
            this.myaveragettc = Math.round(((tot / count) + Number.EPSILON) * 100) / 100;
            this.myttcData = {
              labels: labs,
              datasets: [
                  {
                      label: mins,
                      backgroundColor: '#42A5F5',
                      borderColor: '#1E88E5',
                      data: datasets
                  }
              ]
            };
          }
        }
      } else {
      }
    }, err => this.handleError(err));
   }

   getAnalyticsAssignedOnTimeByDay() {
    let perc = '';
    this.translate.get('GENERAL.PERCENTAGE').subscribe((trans) => {
      perc = trans;
    });

    this.analyticsService.getAnalytics(0, 'T', this.past, this.now,
                                this.uid, 'assignedontime', ' ')
                  .subscribe((resp) => {
      const labs: string[] = [];
      const datasets: number[] = [];
      let count = 0;
      let total = 0;
      if (resp != null && resp.length > 0) {
        for (const res of resp) {
          labs.push(new Date(res.value2).toDateString());
          total += res.value1;
          count += 1;
          if (count === resp.length) {
            count = 0;
            for (const val of resp) {
              const p = Math.round((((val.value1 / total) * 100) + Number.EPSILON) * 100) / 100;
              datasets.push(p);
              count += 1;
            }

            if (count === resp.length) {
              this.assignedontimeData = {
                labels: labs,
                datasets: [
                    {
                        label: perc,
                        data: datasets,
                        backgroundColor: '#42A5F5',
                        borderColor: '#1E88E5',
                    }]
                };
            }
          }
        }
      } else {
        //
      }
    }, err => this.handleError(err));
   }

   getAnalyticsStartedOnTimeByDay() {
    let perc = '';
    this.translate.get('GENERAL.PERCENTAGE').subscribe((trans) => {
      perc = trans;
    });

    this.analyticsService.getAnalytics(0, 'T', this.past, this.now,
                                this.uid, 'startedontime', ' ')
                  .subscribe((resp) => {
      const labs: string[] = [];
      const datasets: number[] = [];
      let count = 0;
      let total = 0;
      if (resp != null && resp.length > 0) {
        for (const res of resp) {
          labs.push(new Date(res.value2).toDateString());
          total += res.value1;
          count += 1;
          if (count === resp.length) {
            count = 0;
            for (const val of resp) {
              const p = Math.round((((val.value1 / total) * 100) + Number.EPSILON) * 100) / 100;
              datasets.push(p);
              count += 1;
            }

            if (count === resp.length) {
              this.startedontimeData = {
                labels: labs,
                datasets: [
                    {
                        label: perc,
                        data: datasets,
                        backgroundColor: '#42A5F5',
                        borderColor: '#1E88E5',
                    }]
                };
            }
          }
        }
      } else {
        //
      }
    }, err => this.handleError(err));
   }

   getAnalyticsClosedOnTimeByDay() {
    let perc = '';
    this.translate.get('GENERAL.PERCENTAGE').subscribe((trans) => {
      perc = trans;
    });

    this.analyticsService.getAnalytics(0, 'T', this.past, this.now,
                                this.uid, 'closedontime', ' ')
                  .subscribe((resp) => {
      const labs: string[] = [];
      const datasets: number[] = [];
      let count = 0;
      let total = 0;
      if (resp != null && resp.length > 0) {
        for (const res of resp) {
          labs.push(new Date(res.value2).toDateString());
          total += res.value1;
          count += 1;
          if (count === resp.length) {
            count = 0;
            for (const val of resp) {
              const p = Math.round((((val.value1 / total) * 100) + Number.EPSILON) * 100) / 100;
              datasets.push(p);
              count += 1;
            }

            if (count === resp.length) {
              this.closedontimeData = {
                labels: labs,
                datasets: [
                    {
                        label: perc,
                        data: datasets,
                        backgroundColor: '#42A5F5',
                        borderColor: '#1E88E5',
                    }]
                };
            }
          }
        }
      } else {
        //
      }
    }, err => this.handleError(err));
   }

  handleError(err) {
    console.log(err);
  }

}
