import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from './../../services/analytics/analytics.service';
import { Component, OnInit } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { ConfirmationService, MessageService } from 'primeng/api';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { Variables } from 'src/app/Models/variables';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';

@Component({
  selector: 'app-overview-in-queue',
  templateUrl: './overview-in-queue.component.html',
  styleUrls: ['./overview-in-queue.component.css']
})
export class OverviewInQueueComponent implements OnInit {

  data: any;
  range: any;
  start: any;
  end: any;
  show = false;
  load = false;
  options: { title: { display: boolean; text: any; fontSize: number; }; legend: { position: string; }; };

  allcount = 0;
  isadm = false;
  configs1 = [];

  constructor(
    private analytics: AnalyticsService,
    private translate: TranslateService,
    private cookieService: CookieService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private notificationSvc: NotificationService,
    private configService: ConfigurationService
  ) {
      this.translate.addLangs(['English', 'French']);
      this.translate.setDefaultLang('English');

      let browserLang = this.translate.getBrowserLang();

      const pass = 'ranitessarldev2019';
      const lang = cookieService.get('goepla-portal-language');
      if (lang.trim() !== '') {
        const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
        const lg = declang;
        browserLang = lg;
        localStorage.setItem('language', lg);
      }

      if (localStorage.getItem('language') !== null) {
        browserLang = localStorage.getItem('language');
      }
      this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

      if (localStorage.getItem('filter-start') !== null && localStorage.getItem('filter-end') !== null) {
        const st = localStorage.getItem('filter-start');
        const ed = localStorage.getItem('filter-end');
        this.range = [new Date(st), new Date(ed)];
      }

      const v = new Variables();
      const p = localStorage.getItem('ups');
      if (p == null) {
        this.isadm = false;
      }

      const pr = CryptoJS.AES.decrypt(p, v.pass).toString(CryptoJS.enc.Utf8);
      const perm = JSON.parse(pr);
      if (perm.includes('NTM.Administration.Full')) {
            this.isadm = true;
      }
   }

   async getConfigurations() {
    this.load = true;
    // tslint:disable-next-line: deprecation
    const res = await this.configService.getConfigurations('T').toPromise();
    if (res != null) {
      const v = new Variables();
      const gis = localStorage.getItem('gi');
      const gs = CryptoJS.AES.decrypt(gis, v.pass).toString(CryptoJS.enc.Utf8);
      const gids = JSON.parse(gs);

      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < res.length; i++) {
        if (res[i].typeRoleGroups !== null && res[i].typeRoleGroups.length > 0) {
          let show = false;
          for (const val of res[i].typeRoleGroups) {
            if (val.typeRoleGroup.roleName === 'Analytics' && gids.includes(val.typeRoleGroup.groupId)) {
              show = true;
            }
          }

          if (this.isadm === true) {
            show = true;
          }

          if (show === false) {
            continue;
          }
        } else {
          if (this.isadm === true) {
          } else {
            continue;
          }
        }
        this.configs1.push({ label: res[i].configurationName, value: res[i] });
      }
    }
  }

   async getAnalytics() {
    await this.getConfigurations();
    if (this.configs1 === undefined || this.configs1 === null || this.configs1.length === 0) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.NODATAAVAILABLE').subscribe((resp: string) => {
        this.notificationSvc.info('', resp, 2000);
        });
      this.load = false;
      return;
    }
    let tids = '';
    if (this.allcount + 1 !== this.configs1.length) {
      for (const val of this.configs1) {
        if (val.value.configurationId !== 0) {
          tids += val.value.configurationId + ',';
        }
      }
    }
    if (tids === '') {
      tids = ' ';
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.NODATAAVAILABLE').subscribe((resp: string) => {
        this.notificationSvc.info('', resp, 2000);
        });
      this.load = false;
      return;
    }
    let tsk = '';
    let others = '';
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.TASKS').subscribe((trans) => {
      tsk = trans;
    });
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.OTHERS').subscribe((trans) => {
      others = trans;
    });

    this.show = false;
    this.load = true;
    this.analytics.getAnalyticsGeneric(0, 'T', this.start, this.end, 0, 'taskoverviewinqueue', ' ', tids)
    // tslint:disable-next-line: deprecation
                  .subscribe((res) => {
      const labs: string[] = [];
      const datasets: number[] = [];
      let count = 0;
      if (res != null && res.length > 0) {
        for (const v of res) {
          labs.push(v.value1);
          datasets.push(v.value2);
          count += 1;

          if (count === res.length) {
            datasets.push(0);
            labs.push(others);
            this.data = {
              labels: labs,
              datasets: [
                  {
                      label: tsk,
                      backgroundColor: '#42A5F5',
                      borderColor: '#1E88E5',
                      data: datasets
                  }
              ]
            };
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.OVERVIEWINQUEUE').subscribe((trans) => {
              this.options = {
                title: {
                    display: true,
                    text: trans,
                    fontSize: 16
                },
                legend: {
                    position: 'top'
                }
              };
            });
            this.show = true;
          }
        }
      } else {
        this.show = false;
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.NODATAAVAILABLE').subscribe((resp: string) => {
          this.notificationSvc.info('', resp, 2000);
          });
      }
      this.load = false;
    }, err => this.handleError(err));
   }

   ok() {
    this.data = [];
    if (this.range === undefined || this.range === null) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTRANGE').subscribe((res) => {
        this.confirm(res);
      });
      return;
    } else {
      if (this.range[0] !== undefined && this.range[0] !== null) {
        this.start = this.range[0].toDateString();
        const a = this.range[0].toDateString();
        const b = new Date(a);
        const c = new Date(b.getFullYear(), b.getMonth(), b.getDate());
        this.start = c.toDateString();
      } else {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.SELECTRANGE').subscribe((res) => {
          this.confirm(res);
        });
        return;
      }
      if (this.range[1] !== undefined && this.range[1] !== null) {
        this.end = this.range[1].toDateString();
        const a = this.range[1].toDateString();
        const b = new Date(a);
        const c = new Date(b.getFullYear(), b.getMonth(), b.getDate());
        this.end = c.toDateString();
      } else {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.SELECTRANGE').subscribe((res) => {
          this.confirm(res);
        });
        return;
      }
      localStorage.setItem('filter-start', this.start);
      localStorage.setItem('filter-end', this.end);
      this.getAnalytics();
    }
   }

  ngOnInit() {
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  showInfo() {
    this.messageService.add({severity: 'info', summary: 'Info Message', detail: 'PrimeNG rocks'});
  }

  handleError(err) {
    this.load = false;
    console.log(err);
  }

  setDate() {
    const d = new Date();
    const d1 = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 1);
    const d2 = new Date(d.getFullYear(), d.getMonth(), d.getDate() + 1);

    this.range = [d1, d2];
  }

}
