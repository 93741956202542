import { AppComponent } from './../../app.component';
import { PanelsService } from './../../services/panels/panels.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { ConfirmationService } from 'primeng/api';
import { CookieService } from 'ngx-cookie-service';
import * as CryptoJS from 'crypto-js';
import { Variables } from 'src/app/Models/variables';
import { DataCache } from 'src/app/Helpers/data-cache';

@Component({
  selector: 'app-panels-configurations',
  templateUrl: './panels-configurations.component.html',
  styleUrls: ['./panels-configurations.component.css']
})
export class PanelsConfigurationsComponent implements OnInit {

  panels = [];
  panel: any;

  loading = false;
  isVis: boolean;

  page = 1;
  size = 100;

  isLoadMore = true;
  isTask: boolean;
  load = false;
  ismanage = false;

  showdetail = false;

  isadm = false;

  gps = [];

  constructor(
    private router: Router,
    public translate: TranslateService,
    private configService: ConfigurationService,
    private notificationSvc: NotificationService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private panelService: PanelsService,
    private appComp: AppComponent
  ) {
    // this.translate.addLangs(['English', 'French']);
    // this.translate.setDefaultLang('English');

    // let browserLang = this.translate.getBrowserLang();

    // const pass = 'ranitessarldev2019';
    // const lang = cookieService.get('goepla-portal-language');
    // if (lang.trim() !== '') {
    //   const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
    //   const lg = declang;
    //   browserLang = lg;
    // }

    // if (localStorage.getItem('language') !== null) {
    //   browserLang = localStorage.getItem('language');
    // }
    // this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    translate.use('English');

    const v = new Variables();
    const p = localStorage.getItem('ups');
    if (p != null) {
      const val = CryptoJS.AES.decrypt(p, v.pass).toString(CryptoJS.enc.Utf8);
      const perm = JSON.parse(val);
      if (perm.includes('NTM.Administration.PanelTypes') || perm.includes('NTM.Administration.Full')) {
        this.ismanage = true;
      }
      if (perm.includes('NTM.Administration.Full')) {
        this.isadm = true;
      }
    }
    this.getPanels();
    this.getGroups();
   }

  ngOnInit() {
    localStorage.removeItem('panel');
    localStorage.removeItem('panel-id');
  }

  gotoCreatePanel() {
    this.router.navigateByUrl('/createpanel');
  }

  getPanels() {
    this.loading = true;
    this.load = true;
    // tslint:disable-next-line: deprecation
    this.panelService.getPanels(this.page, this.size).subscribe((res) => {
      if (res != null) {
        const v = new Variables();
        const gis = localStorage.getItem('gi');
        const gs = CryptoJS.AES.decrypt(gis, v.pass).toString(CryptoJS.enc.Utf8);
        const gids = JSON.parse(gs);

        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          let isedit = false;
          if (res[i].typeRoleGroups !== null && res[i].typeRoleGroups.length > 0) {
            for (const val of res[i].typeRoleGroups) {
              if (val.typeRoleGroup.roleName === 'Administrator' && gids.includes(val.typeRoleGroup.groupId)) {
                isedit = true;
              }
            }
          }

          const options = [];
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.PANELITEMS').subscribe((res1) => {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.DELETE').subscribe((res2) => {
              // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.EDIT').subscribe((res3) => {
                if (isedit === true || this.isadm === true) {
                  options.push({label: res3, value: 'Edit'});
                }
                options.push({label: res1, value: 'PanelItems'});
                options.push({label: 'Detail', value: 'Detail'});
                if (this.isadm === true) {
                  options.push({label: res2, value: 'Delete'});
                }

                if (this.ismanage === true) {
                  res[i].options = options;
                }
                this.panels.push(res[i]);
              });
            });
          });
        }
        if (res.length < this.size) {
          this.isLoadMore = true;
        } else {
          this.isLoadMore = false;
        }
      } else {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.NOPANELCONFIGURATIONS').subscribe((resp: string) => {
          this.notificationSvc.info('', resp, 3000);
          this.isLoadMore = true;
        });
      }
      this.loading = false;
      this.load = false;
    }, err => {
      this.handleError(err);
    });
  }

  loadMore() {
    this.page += 1;
    this.getPanels();
  }

  // tslint:disable-next-line: no-shadowed-variable
  savePanel(event, panel) {
    localStorage.setItem('panel', JSON.stringify(panel));

    if (event.value === 'Edit') {
      this.router.navigateByUrl('editpanel');
    } else if (event.value === 'PanelItems') {
      localStorage.setItem('panel-id', panel.panelId);
      this.router.navigateByUrl('panelitems');
    } else if (event.value === 'Delete') {
      this.deletePanel(panel);
    } else if (event.value === 'Detail') {
      panel.g = null;
      if (panel.typeRoleGroups !== null && panel.typeRoleGroups.length > 0) {
        for (const val of panel.typeRoleGroups) {
          val.typeRoleGroup.g = null;
          if (this.gps !== undefined && this.gps !== null && this.gps.length > 0) {
            for (const g of this.gps) {
              if (val.typeRoleGroup.groupId === g.groupId) {
                val.typeRoleGroup.g = g.groupName;
              }
            }
          }
        }
      }
      this.panel = panel;
      this.showdetail = true;
    }
  }

  // tslint:disable-next-line: no-shadowed-variable
  deletePanel(panel) {
    this.load = true;
    // tslint:disable-next-line: deprecation
    this.panelService.deletePanel(panel.panelId).subscribe(async (res) => {
      if (res === true) {
        await this.appComp.loadMenuAgain();
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.PANELDELETED').subscribe((resp: string) => {
          this.notificationSvc.info('', resp, 3000);
        });
        this.panels = [];
        this.getPanels();
      } else {
        this.loading = false;
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.COULDNOTDELETEPANEL').subscribe((resp: string) => {
          this.notificationSvc.info('', resp, 3000);
        });
      }
    }, err => {
      this.handleError(err);
    });
  }

  getGroups() {
    DataCache.groupdata$.subscribe(data => {
      if (data !== undefined && data !== null && data.length > 0) {
        for (const val of data) {
          this.gps.push(val);
        }
      }
    });
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  handleError(err) {
    this.load = false;
    // tslint:disable-next-line: max-line-length
    if (err.error.Message === '23503: update or delete on table "t_ntm_panel" violates foreign key constraint "t_ntm_panel_item_panel_id_fkey" on table "t_ntm_panel_item"') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.PANELSDEPENDS').subscribe((res) => {
        this.confirm(res);
      });
    }
    this.loading = false;
  }

}
