import { PanelItemsService } from './../../services/panel-items/panel-items.service';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { ConfirmationService } from 'primeng/api';
import { CookieService } from 'ngx-cookie-service';
import { PanelsService } from 'src/app/services/panels/panels.service';
import { Panels } from 'src/app/Models/Panels';
import { PanelItems } from 'src/app/Models/panel-items';
import * as CryptoJS from 'crypto-js';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-create-panel-item',
  templateUrl: './create-panel-item.component.html',
  styleUrls: ['./create-panel-item.component.css']
})
export class CreatePanelItemComponent implements OnInit {

  configs1 = [];
  conf1: any;

  configs = [];
  conf: any;

  pans = [];
  pan: any;

  loading: boolean;

  PanelItemName: any;
  PanelName: string;
  PanelId = 0;
  IconName: any;
  Icon: any;
  icon: any;
  toption = 'NO';

  constructor(
    private router: Router,
    private notificationSvc: NotificationService,
    public translate: TranslateService,
    private configService: ConfigurationService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private location: Location,
    private panelService: PanelsService,
    private panelItemService: PanelItemsService,
    private domSanitizer: DomSanitizer
  ) {
    // this.translate.addLangs(['English', 'French']);
    // this.translate.setDefaultLang('English');

    // let browserLang = this.translate.getBrowserLang();

    // const pass = 'ranitessarldev2019';
    // const lang = cookieService.get('goepla-portal-language');
    // if (lang.trim() !== '') {
    //   const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
    //   const lg = declang;
    //   browserLang = lg;
    // }

    // if (localStorage.getItem('language') !== null) {
    //   browserLang = localStorage.getItem('language');
    // }
    // this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    translate.use('English');

    /*this.translate.get('GENERAL.SELECTTASKCONFIGURATION').subscribe((res: string) => {
      this.configs1.push({ label: res + ' *', value: null });
      this.conf1 = this.configs1[0];
    });*/

    const pid = +localStorage.getItem('panel-id');
    this.getConfigurations();
    this.getPanel(pid);
  }

  ngOnInit() {
  }

  getConfigurations() {
    this.loading = true;
    // tslint:disable-next-line: deprecation
    this.configService.getConfigurations(' ').subscribe((res) => {
      if (res != null) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          this.configs.push(res[i]);
          this.configs1.push({ label: res[i].configurationName, value: res[i] });
          /*if (res[i].configurationType === 'T') {
            this.configs.push(res[i]);
            this.configs1.push({ label: res[i].configurationName, value: res[i] });
          }*/
        }
      }
      this.loading = false;
    });
  }

  getPanels() {
    // tslint:disable-next-line: deprecation
    this.panelService.getPanels(0, 0).subscribe((res) => {
      if (res != null) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          this.pans.push({label: res[i].panelName, value: res[i]});
        }
      }
    });
  }

  getPanel(pid) {
    this.loading = true;
    // tslint:disable-next-line: deprecation
    this.panelService.getPanelId(pid).subscribe((res) => {
      if (res.panelId > 0) {
        this.PanelId = res.panelId;
        this.PanelName = res.panelName;
      }
      this.loading = false;
    });
  }

  onCreate() {
    this.loading = true;

    if (this.conf1 === undefined || this.conf1.value === null) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTCONFIGURATION').subscribe((res: string) => {
        this.confirm(res);
      });

      this.loading = false;
    } else if (this.PanelId <= 0) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTPANEL').subscribe((res: string) => {
        this.confirm(res);
      });

      this.loading = false;
    } else if (this.PanelItemName === undefined || this.PanelItemName == null || this.PanelItemName.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.PROVIDEPANELITEMNAME').subscribe((res: string) => {
        this.confirm(res);
      });

      this.loading = false;
    } else {
      const panel = new PanelItems();
      panel.PanelId = this.PanelId;
      panel.ConfigurationId = this.conf1.configurationId;
      panel.PanelItemName = this.PanelItemName;
      panel.PanelItemTskDesc = this.toption;
      if (this.Icon !== undefined && this.Icon !== null && this.Icon.trim() !== '') {
        panel.PanelItemIcon = this.Icon;
      } else {
        panel.PanelItemIcon = null;
      }

    // tslint:disable-next-line: deprecation
      this.panelItemService.postPanel(panel).subscribe((res) => {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.PANELITEMCREATED').subscribe((resp: string) => {
          this.notificationSvc.info('', resp, 2000);
        });
        this.location.back();
        this.loading = false;
      }, err => this.handleError(err));
    }
  }

  onCancel() {
    this.loading = true;
    this.location.back();
  }

  onFileSelected() {
    const inputNode: any = document.querySelector('#file');

    if (typeof (FileReader) !== 'undefined') {
      const reader = new FileReader();
      if (inputNode.files[0]) {
        reader.readAsDataURL(inputNode.files[0]);
      }

      reader.onloadend = (e: any) => {
        this.IconName = inputNode.files[0].name;
        const base64 = e.target.result;
        this.Icon = base64;
        this.icon = this.domSanitizer.bypassSecurityTrustUrl(base64);
      };
      reader.onerror = (() => {
        this.IconName = '';
        this.Icon = '';
        this.icon = null;
      });
    }
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  handleError(err) {
    this.loading = false;
    if (err.error.MessageText === 'duplicate key value violates unique constraint "t_ntm_panel_item_panel_item_name_key"') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.PANELALITEMREADYEXISTS').subscribe((res: string) => {
        this.confirm(res);
      });
    }
  }
}
