import { NotificationReads } from './../../Models/NotificationReads';
import { NotReadService } from './../../services/not-read/not-read.service';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-notification-detail',
  templateUrl: './notification-detail.component.html',
  styleUrls: ['./notification-detail.component.css']
})
export class NotificationDetailComponent implements OnInit {

  MessageTitle: string;
  MessageBody: string;
  TargetType: string;
  Created: string;
  Expires: string;
  Read: string;

  notif: any;

  constructor(
    public translate: TranslateService,
    private cookieService: CookieService,
    private notreadService: NotReadService
    ) {
    this.translate.addLangs(['English', 'French']);
    this.translate.setDefaultLang('English');

    let browserLang = this.translate.getBrowserLang();

    const pass = 'ranitessarldev2019';
    const lang = cookieService.get('goepla-portal-language');
    if (lang.trim() !== '') {
      const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
      const lg = declang;
      browserLang = lg;
      localStorage.setItem('language', lg);
    }

    if (localStorage.getItem('language') !== null) {
      browserLang = localStorage.getItem('language');
    }
    this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    const doptions: any = { year: 'numeric', month: 'short', day: 'numeric',
    hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false };
    const not = JSON.parse(localStorage.getItem('notif'));
    not.created = new Date(not.created).toLocaleString('en-US', doptions);
    not.expires = new Date(not.expires).toLocaleString('en-US', doptions);

    if (not.read === '0001-01-01T00:00:00') {
      this.translate.get('GENERAL.NOTREAD').subscribe((resp: string) => {
        not.reads = resp;
      });
    } else {
      not.reads = new Date(not.read.toString().replace('T', ' ') + ' UTC').toLocaleString('en-US', doptions);
      // not.reads = new Date(not.read).toLocaleString();
    }

    this.notif = not;

    this.MessageTitle = not.messageTitle;
    this.MessageBody = not.messageBody;
    this.TargetType = not.targetType;
    this.Created = not.created;
    this.Expires = not.expires;

    if (not.user === 0) {
      this.Read = 'False';
    } else {
      this.Read = 'True';
    }
   }

  ngOnInit() {
  }

  async setConf() {
    const conf = new NotificationReads();
    conf.NotificationId = this.notif.notificationId;
    conf.ReadUser = 'Admin User';
    conf.UserId = 1;
    conf.UserName = 'Admin User';
    await this.notreadService.PostReadConfirmation(conf).toPromise()
    .catch(err => this.handleError(err));
  }

  handleError(err) {
    console.log(err)
  }

}
