import { RemindersService } from './../../services/reminders/reminders.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CookieService } from 'ngx-cookie-service';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-reminder-detail',
  templateUrl: './reminder-detail.component.html',
  styleUrls: ['./reminder-detail.component.css']
})
export class ReminderDetailComponent implements OnInit {

  type: any = [];
  page = 1;
  size = 100;

  tasks = [];

  disableLoadMore = false;
  load = false;

  doptions: any = { year: 'numeric', month: 'short', day: 'numeric',
  hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false };

  constructor(
    private router: Router,
    private notificationSvc: NotificationService,
    public translate: TranslateService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private messageService: MessageService,
    private reminderService: RemindersService
    ) {
    this.translate.addLangs(['English', 'French']);
    this.translate.setDefaultLang('English');

    let browserLang = this.translate.getBrowserLang();

    const pass = 'ranitessarldev2019';
    const lang = cookieService.get('goepla-portal-language');
    if (lang.trim() !== '') {
      const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
      const lg = declang;
      browserLang = lg;
      localStorage.setItem('language', lg);
    }

    if (localStorage.getItem('language') !== null) {
      browserLang = localStorage.getItem('language');
    }
    this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');
   }

  ngOnInit() {
    this.type = JSON.parse(localStorage.getItem('reminder'));
    this.getReminderTasks();
  }

  getReminderTasks() {
    this.load = true;
    this.reminderService.getReminderTasks(this.page, this.size, this.type.reminderNameId, 0).subscribe((res) => {
      if (res != null) {
        if (res.length < this.size) {
          this.disableLoadMore = true;
        }
        for (const val of res) {
          val.created = new Date(val.created.toString().replace('T', ' ') + ' UTC').toLocaleString('en-US', this.doptions);
          this.tasks.push(val);
        }
      } else {
        this.disableLoadMore = true;
        if (this.page > 1) {
          this.translate.get('GENERAL.NOMORETASKS').subscribe((trans) => {
            this.showInfo(trans);
          });
        }
      }
      this.load = false;
    }, err => this.handleError(err));
  }

  loadMore() {
    this.page += 1;
    this.getReminderTasks();
  }

  showSuccess(msg) {
    this.translate.get('GENERAL.SUCCESS').subscribe((trans) => {
      this.messageService.add({key: 'tc', severity: 'success', summary: trans, detail: msg});
    });
  }

  showInfo(msg) {
    this.translate.get('GENERAL.INFO').subscribe((trans) => {
      this.messageService.add({key: 'tc', severity: 'info', summary: trans, detail: msg});
    });
  }

  showWarn(msg) {
    this.translate.get('GENERAL.WARNING').subscribe((trans) => {
      this.messageService.add({key: 'tc', severity: 'warn', summary: trans, detail: msg});
    });
  }

  showError(msg) {
    this.translate.get('GENERAL.ERROR').subscribe((trans) => {
      this.messageService.add({key: 'tc', severity: 'error', summary: trans, detail: msg});
    });
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  handleError(err) {
    this.load = false;
    console.log(err);
  }

}
