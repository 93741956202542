import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-configuration-detail',
  templateUrl: './configuration-detail.component.html',
  styleUrls: ['./configuration-detail.component.css']
})
export class ConfigurationDetailComponent implements OnInit {

  config: any;

  constructor(
    public translate: TranslateService,
    private cookieService: CookieService
    ) {
    // this.translate.addLangs(['English', 'French']);
    // this.translate.setDefaultLang('English');

    // let browserLang = this.translate.getBrowserLang();

    // const pass = 'ranitessarldev2019';
    // const lang = cookieService.get('goepla-portal-language');
    // if (lang.trim() !== '') {
    //   const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
    //   const lg = declang;
    //   browserLang = lg;
    // }

    // if (localStorage.getItem('language') !== null) {
    //   browserLang = localStorage.getItem('language');
    // }
    // this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    translate.use('English');

    const not = JSON.parse(localStorage.getItem('notif'));
    this.showConfig(not.configurationId, not.configuration);
  }

  ngOnInit() {
  }

  showConfig(id, configuration) {
    configuration.configurationId = id;
    if (configuration.priority === 1) {
      this.translate.get('GENERAL.CRITICAL').subscribe((res) => {
        configuration.prior = res;
      });
    } else if (configuration.priority === 2) {
      this.translate.get('GENERAL.HIGH').subscribe((res) => {
        configuration.prior = res;
      });
    } else if (configuration.priority === 3) {
      this.translate.get('GENERAL.NORMAL').subscribe((res) => {
        configuration.prior = res;
      });
    } else if (configuration.priority === 4) {
      this.translate.get('GENERAL.LOW').subscribe((res) => {
        configuration.prior = res;
      });
    } else if (configuration.priority === 4) {
      this.translate.get('GENERAL.VERYLOW').subscribe((res) => {
        configuration.prior = res;
      });
    }

    if (configuration.channel === 'M') {
      this.translate.get('GENERAL.MQTT').subscribe((res) => {
        configuration.chan = res;
      });
    } else if (configuration.channel === 'S') {
      this.translate.get('GENERAL.SMS').subscribe((res) => {
        configuration.chan = res;
      });
    } else if (configuration.channel === 'E') {
      this.translate.get('GENERAL.EMAIL').subscribe((res) => {
        configuration.chan = res;
      });
    }

    if (configuration.vibrate === 0) {
      this.translate.get('GENERAL.NONE').subscribe((res) => {
        configuration.vib = res;
      });
    } else if (configuration.vibrate === 1) {
      this.translate.get('GENERAL.SHORT').subscribe((res) => {
        configuration.vib = res;
      });
    } else if (configuration.vibrate === 2) {
      this.translate.get('GENERAL.LONG').subscribe((res) => {
        configuration.vib = res;
      });
    } else if (configuration.vibrate === 3) {
      this.translate.get('GENERAL.VERYLONG').subscribe((res) => {
        configuration.vib = res;
      });
    }

    if (configuration.configurationType === 'I') {
      this.translate.get('GENERAL.INFORMATION').subscribe((res) => {
        configuration.configType = res;
      });
    } else if (configuration.configurationType === 'T') {
      this.translate.get('GENERAL.TASK').subscribe((res) => {
        configuration.configType = res;
      });
    }
    this.config = configuration;
  }

}
