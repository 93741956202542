import { TypeRoleGroup, TypeRoleGroupInfo } from './role';

export class Monitor {
    public OrganisationId: number;
    public MonitorId: number;
    public MonitorName: string;
    public MonitorDescription: string;
    public MaxSize: number;
    public MonitorItems: number;
    public Display: string;
    public RoleGroups: TypeRoleGroup[];
    public TypeRoleGroups: TypeRoleGroupInfo[];
}
