import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import * as CryptoJS from 'crypto-js';
import { ConfirmationService, MessageService } from 'primeng/api';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { CatClassService } from 'src/app/services/cat-class/cat-class.service';
import { CategoryService } from 'src/app/services/category/category.service';
import { ClassItemService } from 'src/app/services/class-item/class-item.service';
import { TaskClassificationService } from 'src/app/services/task-classification/task-classification.service';
import { Variables } from 'src/app/Models/variables';

@Component({
  selector: 'app-analytics-task-classification',
  templateUrl: './analytics-task-classification.component.html',
  styleUrls: ['./analytics-task-classification.component.css']
})
export class AnalyticsTaskClassificationComponent implements OnInit {

  data: any;
  data2: any;
  range: any;
  start: any;
  end: any;
  show = false;
  load = false;

  configs1 = [];
  conf1: any;
  options: { title: { display: boolean; text: string; fontSize: number; }; legend: { position: string; }; };
  confid: number;
  options2: { title: { display: boolean; text: string; fontSize: number; }; legend: { position: string; }; };

  istasktype = false;
  iscat = false;
  isclass = false;
  isitem = false;

  TaskTypeOpt = false;
  CategoryOpt = false;
  ClassOpt = false;
  ItemOpt = false;

  cats = [];
  cat: any;

  classes = [];
  class: any;

  items = [];
  item: any;

  tasktypeid = 0;
  catid = 0;
  classid = 0;
  itemid = 0;

  allcount = 0;
  isadm = false;

  constructor(
    private analytics: AnalyticsService,
    private translate: TranslateService,
    private cookieService: CookieService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private notificationSvc: NotificationService,
    private configService: ConfigurationService,
    private catclassService: CatClassService,
    private itemService: ClassItemService,
    private catService: CategoryService,
    private tcService: TaskClassificationService
  ) {
      this.translate.addLangs(['English', 'French']);
      this.translate.setDefaultLang('English');

      let browserLang = this.translate.getBrowserLang();

      const pass = 'ranitessarldev2019';
      const lang = cookieService.get('goepla-portal-language');
      if (lang.trim() !== '') {
        const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
        const lg = declang;
        browserLang = lg;
        localStorage.setItem('language', lg);
      }

      if (localStorage.getItem('language') !== null) {
        browserLang = localStorage.getItem('language');
      }
      this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

      if (localStorage.getItem('filter-start') !== null && localStorage.getItem('filter-end') !== null) {
        const st = localStorage.getItem('filter-start');
        const ed = localStorage.getItem('filter-end');
        this.range = [new Date(st), new Date(ed)];
      }

      if (localStorage.getItem('filter-config') !== null) {
        this.confid = +localStorage.getItem('filter-config');
      }

      // tslint:disable-next-line: deprecation
      // translate.get('GENERAL.ALL').subscribe((trans) => {
      //   const conf: any = [];
      //   conf.configurationId = 0;
      //   conf.configurationName = trans;
      //   this.configs1.push({ label: trans, value: conf });
      //   if (this.confid === 0) {
      //     this.conf1 = conf;
      //   }
      // });

      const v = new Variables();
      const p = localStorage.getItem('ups');
      if (p == null) {
        this.isadm = false;
      }

      const pr = CryptoJS.AES.decrypt(p, v.pass).toString(CryptoJS.enc.Utf8);
      const perm = JSON.parse(pr);
      if (perm.includes('NTM.Administration.Full')) {
            this.isadm = true;
      }

      this.getConfigurations();
      this.getCategories();
   }

   getConfigurations() {
    this.load = true;
    // tslint:disable-next-line: deprecation
    this.configService.getConfigurationsByTypeWithCategory('T', 0, 0).subscribe((res) => {
      if (res != null) {
        const v = new Variables();
        const gis = localStorage.getItem('gi');
        const gs = CryptoJS.AES.decrypt(gis, v.pass).toString(CryptoJS.enc.Utf8);
        const gids = JSON.parse(gs);

        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          if (res[i].typeRoleGroups !== null && res[i].typeRoleGroups.length > 0) {
            let show = false;
            for (const val of res[i].typeRoleGroups) {
              if (val.typeRoleGroup.roleName === 'Analytics' && gids.includes(val.typeRoleGroup.groupId)) {
                show = true;
              }
            }

            if (this.isadm === true) {
              show = true;
            }

            if (show === false) {
              continue;
            }
          } else {
            if (this.isadm === true) {
            } else {
              continue;
            }
          }
          this.configs1.push({ label: res[i].configurationName, value: res[i] });
          if (this.confid >= 0) {
            if (res[i].configurationId === this.confid) {
              this.conf1 = res[i];
            }
          }
        }
      }
      this.load = false;
    });
  }

  async getCategories() {
    this.load = true;
    this.cats = [];
    const c = await this.catService.getCategories(0, 0, 0, ' ').toPromise()
    .catch(err => console.log(err));
    if (c !== undefined && c !== null && c.length > 0) {
      for (const val of c) {
        this.cats.push({label: val.categoryName, value: val});
      }
    }

    this.load = false;
  }

  async getCatClass(catid: any) {
    this.load = true;
    this.classes = [];
    const res = await this.catclassService.getCategoryClasses(0, 0, 0, ' ', catid, ' ').toPromise()
    .catch(err => console.log(err));

    if (res !== undefined && res !== null && res.length > 0) {
      for (const val of res) {
        this.classes.push({label: val.categoryClassList.className, value: val});
      }
    }

    this.load = false;
  }

  async getClassListItems(classid: any) {
    this.load = true;
    this.items = [];
    const res = await this.itemService.getClassItems(0, 0, classid, ' ', 0, ' ').toPromise()
    .catch(err => console.log(err));
    this.items = [];
    if (res !== undefined && res !== null && res.length > 0) {
      for (const val of res) {
        this.items.push({label: val.classListItem.itemName, value: val});
      }
    }

    this.load = false;
  }

  tasktypeCheckChanged() {
    this.istasktype = this.TaskTypeOpt;
  }

  categoryCheckChanged() {
    this.iscat = this.CategoryOpt;
  }

  classCheckChanged() {
    this.isclass = this.ClassOpt;
  }

  itemCheckChanged() {
    this.isitem = this.ItemOpt;
  }

  async catSelected() {
    this.items = [];
    await this.getCatClass(this.cat.categoryId);
  }

  async classSelected() {
    await this.getClassListItems(this.class.categoryClassList.classId);
  }

  itemSelected() {
  }

  async getAnalytics() {
    if (this.configs1 === undefined || this.configs1 === null || this.configs1.length === 0) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.NODATAAVAILABLE').subscribe((resp22: string) => {
        this.notificationSvc.info('', resp22, 2000);
        });
      return;
    }
    let tids = '';
    if (this.conf1.configurationId === 0) {
      if (this.allcount + 1 !== this.configs1.length) {
        for (const val of this.configs1) {
          if (val.value.configurationId !== 0) {
            tids += val.value.configurationId + ',';
          }
        }
      }
    }
    if (tids === '') {
      tids = ' ';
    }
    let amttsks = '';
    let others = '';
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.AMOUNTOFTASKS').subscribe((trans) => {
      amttsks = trans;
    });
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.OTHERS').subscribe((trans) => {
      others = trans;
    });

    const colorpalette = ['#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
                                '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
                                '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
                                '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
                                '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
                                '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
                                '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
                                '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
                                '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
                                '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'];
    const colors = [];
    this.show = false;
    this.load = true;

    const resp = await this.tcService.getTaskClassificationsGeneric(0, 0, this.tasktypeid, this.catid,
    this.classid, this.itemid, this.start, this.end, tids).toPromise()
    .catch(err => this.handleError(err));

    const labs: string[] = [];
    const datasets: number[] = [];
    const datasets2: number[] = [];
    let count = 0;
    let ccount = 0;
    let total = 0;
    if (resp != null && resp.length > 0) {
      for (const res of resp) {
        if (ccount >= 49) {
          ccount = 1;
        }
        colors.push(colorpalette[ccount]);
        ccount += 1;
        labs.push(res.itemName);
        total += res.amount;
        datasets.push(res.amount);
        count += 1;
        if (count === resp.length) {
          count = 0;
          for (const val of resp) {
            const p = Math.round((((val.amount / total) * 100) + Number.EPSILON) * 100) / 100;
            datasets2.push(p);
            count += 1;
          }

          if (count === resp.length) {
            datasets.push(0);
            datasets2.push(0);
            labs.push(others);
            this.data = {
              labels: labs,
              datasets: [
                  {
                      label: amttsks,
                      backgroundColor: '#42A5F5',
                      borderColor: '#1E88E5',
                      data: datasets
                  }
              ]
            };
            this.data2 = {
              labels: labs,
              datasets: [
                  {
                      data: datasets2,
                      backgroundColor: colors,
                      hoverBackgroundColor: colors
                  }]
              };

    // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.TASKCLASSIFICATION').subscribe((trans) => {
              this.options = {
                title: {
                    display: true,
                    text: trans,
                    fontSize: 16
                },
                legend: {
                    position: 'top'
                }
              };
              this.options2 = {
                title: {
                    display: true,
                    text: trans + '(%)',
                    fontSize: 16
                },
                legend: {
                    position: 'top'
                }
              };
            });

            this.show = true;
          }
        }
      }
    } else {
      this.show = false;
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.NODATAAVAILABLE').subscribe((res: string) => {
        this.notificationSvc.info('', res, 2000);
        });
    }
    this.load = false;
   }

   ok() {
    this.tasktypeid = 0;
    this.catid = 0;
    this.classid = 0;
    this.itemid = 0;

    this.data = [];
    this.data2 = [];
    if (this.range === undefined || this.range === null) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTRANGE').subscribe((res) => {
        this.confirm(res);
      });
      return;
    } else {
      if (this.range[0] !== undefined && this.range[0] !== null) {
        this.start = this.range[0].toJSON().toString();
        // this.start = this.range[0].toDateString();
        // const a = this.range[0].toDateString();
        // const b = new Date(a);
        // const c = new Date(b.getFullYear(), b.getMonth(), b.getDate());
        // this.start = c.toDateString();
      } else {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.SELECTRANGE').subscribe((res) => {
          this.confirm(res);
        });
        return;
      }
      if (this.range[1] !== undefined && this.range[1] !== null) {
        this.end = this.range[1].toJSON().toString();
        // this.end = this.range[1].toDateString();
        // const a = this.range[1].toDateString();
        // const b = new Date(a);
        // const c = new Date(b.getFullYear(), b.getMonth(), b.getDate());
        // this.end = c.toDateString();
      } else {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.SELECTRANGE').subscribe((res) => {
          this.confirm(res);
        });
        return;
      }

      if (this.istasktype && (this.conf1 === undefined || this.conf1 === null)) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.SELECTCONFIGURATION').subscribe((res) => {
          this.confirm(res);
        });
        return;
      } else if (this.istasktype) {
        this.tasktypeid = this.conf1.configurationId;
      }

      if (this.iscat && (this.cat === undefined || this.cat === null)) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.SELECTCATEGORY').subscribe((res) => {
          this.confirm(res);
        });
        return;
      } else if (this.iscat) {
        this.catid = this.cat.categoryId;
      }

      if (this.isclass && (this.class === undefined || this.class === null)) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.SELECTCLASS').subscribe((res) => {
          this.confirm(res);
        });
        return;
      } else if (this.isclass) {
        this.classid = this.class.categoryClassList.classId;
      }

      if (this.isitem && (this.item === undefined || this.item === null)) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.SELECTITEM').subscribe((res) => {
          this.confirm(res);
        });
        return;
      } else if (this.isitem) {
        this.itemid = this.item.classListItem.itemId;
      }

      localStorage.setItem('filter-start', this.start);
      localStorage.setItem('filter-end', this.end);
      localStorage.setItem('filter-config', this.tasktypeid.toString());
      // localStorage.setItem('filter-config', this.conf1.configurationId);
      this.getAnalytics();
    }
   }

  ngOnInit() {
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  showInfo() {
    this.messageService.add({severity: 'info', summary: 'Info Message', detail: 'PrimeNG rocks'});
  }

  handleError(err) {
    this.load = false;
    console.log(err);
  }

  setDate() {
    const d = new Date();
    const d1 = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 1);
    const d2 = new Date(d.getFullYear(), d.getMonth(), d.getDate() + 1);

    this.range = [d1, d2];
  }

}
