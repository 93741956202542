import { OrderData } from './../../Models/order-data';
import { OrderItemService } from './../../services/order-item/order-item.service';
import { OrderItem } from './../../Models/order-item';
import { Int64Updater, KeyValue } from './../../Models/Updater';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { DataCache } from 'src/app/Helpers/data-cache';
import { OrderService } from 'src/app/services/order/order.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Variables } from 'src/app/Models/variables';
import * as CryptoJS from 'crypto-js';
import { Order } from 'src/app/Models/order';
import { OrderNotification } from 'src/app/Models/order-notification';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit {

  constructor(
    private orderService: OrderService,
    private messageService: MessageService,
    public translate: TranslateService,
    private router: Router,
    private domSanitizer: DomSanitizer,
    private oitemService: OrderItemService
  ) {
    // DataCache.locdata$.subscribe(data => console.log('locs orders', data));
    // grouping
    this.translate.get('GENERAL.NONE').subscribe((resp: string) => {
      this.grouping.push({ label: resp, value: 'none'});
    });
    this.translate.get('GENERAL.YES').subscribe((resp: string) => {
      this.grouping.push({ label: resp, value: 'yes'});
    });

    this.group = this.grouping[0];

    // statuses
    this.translate.get('GENERAL.ALL').subscribe((resp: string) => {
      this.statuses.push({ label: resp, value: ' '});
    });
    this.translate.get('GENERAL.OPEN').subscribe((resp: string) => {
      this.statuses.push({ label: resp, value: 'open'});
    });
    this.translate.get('GENERAL.CLOSED').subscribe((resp: string) => {
      this.statuses.push({ label: resp, value: 'closed'});
    });

    this.status = this.statuses[0];

    // locations
    this.translate.get('GENERAL.ALL').subscribe((resp: string) => {
      this.locations.push({ label: resp, value: ' '});
    });

    this.loc = this.locations[0];

    DataCache.locdata$.subscribe(data => {
      if (data !== undefined && data !== null && data.length > 0) {
        for (const val of data) {
          this.locations.push({ label: val.locationName, value: val.locationName});
        }
      }
    });

    if (this.router.getCurrentNavigation().extras !== undefined
    && this.router.getCurrentNavigation().extras !== null
    && this.router.getCurrentNavigation().extras.state !== undefined
    && this.router.getCurrentNavigation().extras.state !== null
    && this.router.getCurrentNavigation().extras.state.notId !== undefined
    && this.router.getCurrentNavigation().extras.state.notId !== null
    && this.router.getCurrentNavigation().extras.state.loc !== undefined
    && this.router.getCurrentNavigation().extras.state.loc !== null) {
      this.isfromtask = true;
      this.taskloc = this.router.getCurrentNavigation().extras.state.loc;
      this.notid = this.router.getCurrentNavigation().extras.state.notId;
      this.loc = { label: this.router.getCurrentNavigation().extras.state.loc,
        value: this.router.getCurrentNavigation().extras.state.loc };
    }

    const v = new Variables();
    const p = localStorage.getItem('ups');
    if (p == null) {
      this.ismanage = false;
    }

    const pr = CryptoJS.AES.decrypt(p, v.pass).toString(CryptoJS.enc.Utf8);
    const perm = JSON.parse(pr);
    if (perm.includes('NTM.Orders.Manage')
        || perm.includes('NTM.Administration.Full')) {
          this.ismanage = true;
    } else {
      this.ismanage = false;
    }

    this.getOrders();
   }

  page = 1;
  size = 100;

  orders = [];
  ordersgrouped = [];

  order: any;
  ordergrouped: any;

  notid = ' ';
  loc: any;
  status: any;
  group: any;

  loading = false;
  isLoadMore = false;

  locations = [];
  statuses = [];
  grouping = [];

  isgrouped = false;

  isfromtask = false;
  taskloc = '';

  ismanageorders = false;
  showcategories = false;
  showitems = false;
  isoverview = false;
  orderoverview = false;

  title = '';

  cats = [];
  items = [];
  oitems = [];

  ord: any;

  ismanage = false;

  locs = [];
  oloc: any;
  orderloc: any;
  isloc = false;

  iscreate = false;

  ngOnInit() {
  }

  groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}

  async getOrders() {
    this.loading = true;
    this.showLoad();

    const ords = await this.orderService.getOrders(this.page, this.size, this.notid, this.loc.value, this.status.value)
    .toPromise().catch(err => this.handleError(err));
    if (this.page === 1) {
      this.orders = [];
    }

    if (ords !== undefined && ords !== null && ords.length > 0) {
      if (ords.length >= this.size) {
        this.isLoadMore = true;
      } else {
        this.isLoadMore = false;
      }

      let count = 0;
      for (const val of ords) {
        count += 1;
        const options = [];

        if (val.order.orderStatus === 'open') {
          val.color = 'yellow';

          this.translate.get('GENERAL.OPEN').subscribe((resp: string) => {
            val.stat = resp;
          });
          this.translate.get('GENERAL.EDIT').subscribe((resp: string) => {
            options.push({label: resp, value: 'Edit',
            command: async (event) => { await this.manageOrder(event, val); }});
          });
          this.translate.get('GENERAL.CLOSE').subscribe((resp: string) => {
            options.push({label: resp + '   ', value: 'Close',
             command: async (event) => { await this.manageOrder(event, val); }});
          });
        } else {
          val.color = 'green';

          this.translate.get('GENERAL.CLOSED').subscribe((resp: string) => {
            val.stat = resp;
          });
        }

        let price = 0;
        if (val.orderItems !== null && val.orderItems.length > 0) {
          for (const item of val.orderItems) {
            price += (item.orderItem.quantity * item.orderItem.price);
          }
        }

        val.totalPrice = price;
        const date = new Date(val.order.created);
        val.orderName = date.getFullYear().toString() + date.getMonth().toString() + date.getDate().toString() + '-' + val.order.orderId;
        val.orderNameId = 'Order ' + count;

        val.options = options;
        if (options.length === 0) {
          val.isopts = false;
        } else {
          val.isopts = true;
        }

        this.orders.push(val);
      }
    } else {
      this.isLoadMore = false;
      this.orders = [];
      this.ordersgrouped = [];
    }

    if (this.isgrouped) {
      this.groupOrders();
    }

    this.hideLoad();
    this.loading = false;
  }

  loadMore() {
    this.page += 1;
    this.getOrders();
  }

  apply() {
    this.page = 1;
    this.getOrders();
  }

  filterLoc(loca) {
    this.loc = loca;
  }

  filterStatus(stt) {
    this.status = stt;
  }

  filterGroup(gp) {
    this.group = gp;
    if (gp.value === 'yes') {
      this.groupOrders();
      this.isgrouped = true;
    } else {
      this.isgrouped = false;
    }
  }

  groupOrders() {
    if (this.orders.length > 0) {
      const grouped = this.groupBy(this.orders, ord => ord.order.orderLocation);

      const grps = [];
      for (const val of grouped) {
        const grp: any = [];
        grp.location = val[0];

        grp.orders = [];
        grp.orderItems = [];
        grp.totalPrice = 0;

        const options = [];
        let isopt = true;
        for (const gp of val[1]) {
          if (gp.order.orderStatus === 'closed') {
            isopt = false;
            gp.order.ocolor = 'green';
          } else {
            gp.order.ocolor = 'yellow';
          }

          grp.orders.push(gp);

          for (const i of gp.orderItems) {
            grp.orderItems.push(i);
            grp.totalPrice += (i.orderItem.quantity * i.orderItem.price);
          }
        }

        if (isopt === true) {
          this.translate.get('GENERAL.CLOSE').subscribe((resp: string) => {
            options.push({label: resp + '   ', value: 'Close',
             command: async (event) => { await this.manageOrder(event, val); }});
          });
        }

        grp.isopt = isopt;
        grp.options = options;

        grps.push(grp);
      }

      this.ordersgrouped = grps;
    }
  }

  gOrderClicked(o) {
    const vals = [];
    for (const val of this.ordersgrouped) {
      const v = val;
      if (val.location === o.location) {
        v.bcolor = 'lightblue';
      } else {
        v.bcolor = 'white';
      }

      vals.push(v);
    }

    this.ordersgrouped = vals;
  }

  orderClicked(o) {
    const vals = [];
    for (const val of this.orders) {
      const v = val;
      if (val.order.orderId === o.order.orderId) {
        v.order.bcolor = 'lightblue';
      } else {
        v.order.bcolor = 'white';
      }

      vals.push(v);
    }

    this.orders = vals;
  }

  manageOrder(event, value) {
    this.ord = value;
    if (event.item.value === 'Edit') {
      this.iscreate = false;
      this.showcategories = false;
      this.isoverview = false;
      this.showitems = false;
      this.orderoverview = false;
      this.isloc = false;

      this.orderloc = value.order.orderLocation;

      this.showOldItems();
      // this.getCategories();
    } else {
      this.showConfirmCloseOrder(value);
      // this.closeOrder(value);
    }
  }

  async onConfirmCloseOrder() {
    this.messageService.clear('ccloseorder');
    await this.closeOrder(this.ord);
    this.ord = null;
  }

  onRejectCloseOrder(): any {
    this.ord = null;
    this.messageService.clear('ccloseorder');
  }

  async closeOrder(order) {
    this.loading = true;
    this.showLoad();
    if (this.isgrouped === false) {
      const update = new Int64Updater();
      update.Id = order.order.orderId;
      update.Values = [];

      const status = new KeyValue();
      status.Key = 'status';
      status.Value = 'closed';
      update.Values.push(status);

      const res = await this.orderService.putOrder(update).toPromise().catch(err => this.handleError(err));
      if (res !== undefined && res !== null && res === true) {
        // order closed
        this.translate.get('GENERAL.SUCCESS').subscribe((resp: string) => {
          this.translate.get('GENERAL.ORDERCLOSED').subscribe((resp2: string) => {
            this.showSuccess(resp, resp2);
          });
        });
        this.size = this.page * this.size;
        this.page = 1;
        await this.getOrders();
      } else {
        // order not closed
        this.translate.get('GENERAL.ERROR').subscribe((resp: string) => {
          this.translate.get('GENERAL.ORDERNOTCLOSED').subscribe((resp2: string) => {
            this.showSuccess(resp, resp2, 10000);
          });
        });
      }
    } else {
      let saved = true;
      for (const i of order[1]) {
        const update = new Int64Updater();
        update.Id = i.order.orderId;
        update.Values = [];

        const status = new KeyValue();
        status.Key = 'status';
        status.Value = 'closed';
        update.Values.push(status);

        const res = await this.orderService.putOrder(update).toPromise().catch(err => this.handleError(err));
        if (res !== undefined && res !== null && res === true) {
          // order closed
        } else {
          // order not closed
          saved = false;
        }
      }

      if (saved === true) {
        // order closed
        this.translate.get('GENERAL.SUCCESS').subscribe((resp: string) => {
          this.translate.get('GENERAL.ORDERSCLOSED').subscribe((resp2: string) => {
            this.showSuccess(resp, resp2);
          });
        });
      } else {
        // order not closed
        this.translate.get('GENERAL.ERROR').subscribe((resp: string) => {
          this.translate.get('GENERAL.ORDERSNOTCLOSED').subscribe((resp2: string) => {
            this.showSuccess(resp, resp2, 10000);
          });
        });
      }

      this.size = this.page * this.size;
      this.page = 1;
      await this.getOrders();
    }
    this.loading = false;
    this.hideLoad();
  }

  async showConfirmCloseOrder(value) {
    const title = await this.translate.get('GENERAL.CLOSE').toPromise().catch(err => console.log(err));
    const at = await this.translate.get('GENERAL.AT').toPromise().catch(err => console.log(err));
    const closeall = await this.translate.get('GENERAL.CLOSEALLORDERSAT').toPromise().catch(err => console.log(err));
    if (this.isgrouped === false) {
      this.showConfirm(title + ' ' + value.orderName + ' ' + at + ' ' + value.order.orderLocation + '?',
       '', 'ccloseorder');
    } else {
      this.showConfirm(closeall + ' ' + value[0] + '?',
       '', 'ccloseorder');
    }
  }

  async getCategories() {
    this.showLoad();
    this.cats = [];
    const data = await DataCache.catdata$.toPromise().catch();
    if (data !== undefined && data !== null && data.length > 0) {
      for (const val of data) {
        if (val.categoryImage !== null) {
          val.image = this.domSanitizer.bypassSecurityTrustUrl(val.categoryImage);
        } else {
          val.image = '';
        }
        this.cats.push(val);
      }
    }

    const select = await this.translate.get('GENERAL.SELECTCATEGORY').toPromise().catch(err => console.log(err));
    this.title = select;
    this.ismanageorders = true;
    this.showcategories = true;
    this.orderoverview = false;

    if (this.oitems.length > 0) {
      this.isoverview = true;
    } else {
      this.isoverview = false;
    }

    this.hideLoad();
  }

  async showOldItems() {
    this.showLoad();
    this.oitems = [];
    const data = await DataCache.catitemdata$.toPromise().catch();
    if (data !== undefined && data !== null && data.length > 0) {
      const iis = [];
      for (const val of data) {
        val.count = 0;
        if (this.ord !== null) {
          for (const i of this.ord.orderItems) {
            if (i.orderItem.itemId === val.categoryItem.itemId && !iis.includes(i.orderItem.itemId)) {
              iis.push(i.orderItem.itemId);
              val.count = i.orderItem.quantity;
              this.oitems.push(val);
            }
          }
        }
      }
    }

    const select = await this.translate.get('GENERAL.ORDEROVERVIEW').toPromise().catch(err => console.log(err));
    this.title = select;
    this.ismanageorders = true;
    this.orderoverview = true;
    this.hideLoad();
  }

  async categorySelected(cat) {
    this.showLoad();
    this.items = [];
    this.title = cat.categoryName;
    const data = await DataCache.catitemdata$.toPromise().catch();
    if (data !== undefined && data !== null && data.length > 0) {
      for (const val of data) {
        let cnt = false;
        if (cat.categoryId === val.orderCategory.categoryId) {
          // val.count = 0;
          if (this.oitems !== null && this.oitems.length > 0) {
            for (const i of this.oitems) {
              if (i.categoryItem.itemId === val.categoryItem.itemId) {
                val.count = i.count;
                cnt = true;
              }
            }
          } else {
            val.count = 0;
          }

          if (cnt === false) {
            val.count = 0;
          }
          this.items.push(val);
        }
      }
    }

    this.showitems = true;
    this.showcategories = false;

    this.hideLoad();
  }

  async categoriesBack() {
    const select = await this.translate.get('GENERAL.ORDEROVERVIEW').toPromise().catch(err => console.log(err));
    this.title = select;
    this.orderoverview = true;
    this.showcategories = false;
  }

  async itemBack() {
    let showoverview = false;
    const its = [];
    for (const i of this.items) {
      for (const val of this.oitems) {
        if (val.categoryItem.itemId === i.categoryItem.itemId) {
          const index = this.oitems.indexOf(val, 0);
          if (index > -1) {
            this.oitems.splice(index, 1);
          }
        }
      }
      if (i.count > 0) {
        its.push(i);
        this.oitems.push(i);
        showoverview = true;
      } else {
      }
    }

    // this.oitems = its;
    this.isoverview = showoverview;
    // this.items = its;

    const select = await this.translate.get('GENERAL.SELECTCATEGORY').toPromise().catch(err => console.log(err));
    this.title = select;
    this.showitems = false;
    this.showcategories = true;

    if (this.oitems.length > 0) {
      this.isoverview = true;
    } else {
      this.isoverview = false;
    }
  }

  orderOverviewBack() {
    this.items = [];
  }

  increase(item) {
    for (const val of this.items) {
      if (val.categoryItem.itemId === item.categoryItem.itemId) {
        val.count += 1;
      }
    }
  }

  decrease(item) {
    for (const val of this.items) {
      if (val.categoryItem.itemId === item.categoryItem.itemId && val.count > 0) {
        val.count -= 1;
      }
    }
  }

  oincrease(item) {
    for (const val of this.oitems) {
      if (val.categoryItem.itemId === item.categoryItem.itemId) {
        val.count += 1;
      }
    }
  }

  odecrease(item) {
    for (const val of this.oitems) {
      if (val.categoryItem.itemId === item.categoryItem.itemId && val.count > 0) {
        val.count -= 1;
      }
    }
  }

  async saveOrders() {
    this.loading = true;
    const its = [];
    if (this.oitems !== null && this.oitems.length > 0) {
      let count = 0;
      for (const val of this.oitems) {
        if (val.count > 0) {
          count += 1;
          const i = new OrderItem();
          i.ItemId = val.categoryItem.itemId;
          i.OrderId = this.ord.order.orderId;
          i.Position = count;
          i.Price = val.categoryItem.itemPrice;
          i.Quantity = val.count;

          its.push(i);
        }
      }

      this.showLoad();
      if (its.length > 0) {
        // save items
        const res = await this.oitemService.putOrderItem(this.ord.order.orderId, its).toPromise()
        .catch(err => this.handleError(err));
        if (res !== undefined && res !== null && res === true) {
          // items saved
          const ttl = await this.translate.get('GENERAL.SUCCESS').toPromise().catch(err => console.log(err));
          const msg = await this.translate.get('GENERAL.ITEMSSAVED').toPromise().catch(err => console.log(err));
          this.showSuccess(ttl, msg);

          this.ord = null;

          this.showcategories = false;
          this.isoverview = false;
          this.showitems = false;
          this.orderoverview = false;
          this.ismanageorders = false;

          this.size = this.page * this.size;
          this.page = 1;
          await this.getOrders();
        } else {
          // items not saved
          const ttl = await this.translate.get('GENERAL.ERROR').toPromise().catch(err => console.log(err));
          const msg = await this.translate.get('GENERAL.ITEMSNOTSAVED').toPromise().catch(err => console.log(err));
          this.showError(ttl, msg, 10000);
        }
      }

      this.hideLoad();
    }

    this.loading = false;
  }

  async createOrder() {
    const order = new OrderData();

    order.Order = new Order();
    order.Order.OrderLocation = this.orderloc;
    order.Order.OrderStatus = 'open';

    order.OrderItems = [];
    let count = 0;
    for (const val of this.oitems) {
      if (val.count > 0) {
        count += 1;
        const i = new OrderItem();
        i.ItemId = val.categoryItem.itemId;
        i.Position = count;
        i.Price = val.categoryItem.itemPrice;
        i.Quantity = val.count;

        order.OrderItems.push(i);
      }
    }

    if (this.notid !== undefined && this.notid !== null && this.notid.trim() !== '') {
      order.OrderNotification = new OrderNotification();
      order.OrderNotification.NotificationId = this.notid;
    }

    this.loading = true;
    this.showLoad();
    if (order.OrderItems.length > 0) {
      // save items
      const res = await this.orderService.postOrder(order).toPromise()
      .catch(err => this.handleError(err));
      if (res !== undefined && res !== null && res > 0) {
        // items saved
        const ttl = await this.translate.get('GENERAL.SUCCESS').toPromise().catch(err => console.log(err));
        const msg = await this.translate.get('GENERAL.ORDERCREATED').toPromise().catch(err => console.log(err));
        this.showSuccess(ttl, msg);

        this.ord = null;

        this.showcategories = false;
        this.isoverview = false;
        this.showitems = false;
        this.orderoverview = false;
        this.ismanageorders = false;

        this.size = this.page * this.size;
        this.page = 1;
        await this.getOrders();
      } else {
        // items not saved
        const ttl = await this.translate.get('GENERAL.ERROR').toPromise().catch(err => console.log(err));
        const msg = await this.translate.get('GENERAL.ORDERNOTCREATED').toPromise().catch(err => console.log(err));
        this.showError(ttl, msg, 10000);
      }
    }

    this.loading = false;
    this.hideLoad();
  }

  async showAddOrder() {
    const select = await this.translate.get('GENERAL.ORDERLOCATION').toPromise().catch(err => console.log(err));
    this.title = select;

    this.iscreate = true;
    this.showcategories = false;
    this.isoverview = false;
    this.showitems = false;
    this.orderoverview = false;
    this.isloc = false;
    this.oitems = [];
    if (this.loc === undefined || this.loc === null || this.loc.value.trim() === '') {
      // show select location
      this.locs = [];
      const data = await DataCache.locdata$.toPromise().catch();
      if (data !== undefined && data !== null && data.length > 0) {
        for (const val of data) {
          val.color = 'white';
          this.locs.push(val);
        }
      }

      this.ismanageorders = true;
      this.isloc = true;
    } else {
      this.orderloc = this.loc.value;
      await this.getCategories();
    }
  }

  async addOrder() {
    await this.getCategories();
  }

  async select() {
    if (this.oloc !== undefined && this.oloc !== null) {
      this.orderloc = this.oloc.locationName;
      await this.getCategories();
      this.isloc = false;
    }
  }

  locClicked(loc) {
    this.oloc = loc;
    const vals = [];
    for (const val of this.locs) {
      const v = val;
      if (loc.locationName === val.locationName) {
        v.color = 'lightblue';
      } else {
        v.color = 'white';
      }
      vals.push(v);
    }

    this.locs = vals;
  }

  showLoad(): any {
    this.loading = true;
    this.showConfirm('', '', 'loader', '');
  }

  hideLoad(): any {
    this.loading = false;
    this.messageService.clear('loader');
  }

  showSuccess(title: string, details: string, duration: number = 3000): any {
    this.messageService.add({severity: 'success', summary: title, detail: details, key: 'tc', life: duration});
  }

  showInfo(title: string, details: string, duration: number = 3000): any {
      this.messageService.add({severity: 'info', summary: title, detail: details, key: 'tc', life: duration});
  }

  showWarn(title: string, details: string, duration: number = 3000): any {
      this.messageService.add({severity: 'warn', summary: title, detail: details, key: 'tc', life: duration});
  }

  showError(title: string, details: string, duration: number = 3000): any {
      this.messageService.add({severity: 'error', summary: title, detail: details, key: 'tc', life: duration});
  }

  showConfirm(title: string, details: string, keyvalue: string, severe: string = 'warn'): any {
    // this.messageService.clear();
    this.messageService.add({key: keyvalue, sticky: true, severity: severe, summary: title, detail: details});
  }

  handleError(err) {
    this.loading = false;
    this.hideLoad();
    console.log(err);
  }

}
