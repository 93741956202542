import { NotificationReads } from './../../Models/NotificationReads';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Urls } from 'src/app/Helpers/Urls';

@Injectable({
  providedIn: 'root'
})
export class NotReadService {

  url = new Urls();
  apiUrl: string;
  orgId: number;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = this.url.apiUrl;
  }

  getReadNotifications(page, size, notid) {
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/notificationsread/' + page + '/' + size + '/' + notid + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  PostReadConfirmation(conf: NotificationReads) {
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/notificationsread';
    return this.httpclient.post<any>(url, conf)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  private handleError(error: any) {
    // console.error('error', error);
    throw error;
    return error;
  }
}
