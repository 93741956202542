import { MonitorItemsService } from './../../services/monitor-items/monitor-items.service';
import { Location } from '@angular/common';
import { MonitorItemUpdater } from './../../Models/Updater';
import { Component, OnInit } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { ConfirmationService } from 'primeng/api';
import { CookieService } from 'ngx-cookie-service';
import { PanelItemUpdater, KeyValue } from 'src/app/Models/Updater';

@Component({
  selector: 'app-edit-monitor-item',
  templateUrl: './edit-monitor-item.component.html',
  styleUrls: ['./edit-monitor-item.component.css']
})
export class EditMonitorItemComponent implements OnInit {

  ConfigName: any;
  MonitorName: any;
  MonitorItemName: any;

  ConfigId = 0;
  MonitorId = 0;

  MonitorItemId = 0;
  loading: boolean;

  fmodes = [];
  fmode: any;

  constructor(
    private router: Router,
    private notificationSvc: NotificationService,
    public translate: TranslateService,
    private configService: ConfigurationService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private location: Location,
    private monitoritemService: MonitorItemsService
  ) {
    // this.translate.addLangs(['English', 'French']);
    // this.translate.setDefaultLang('English');

    // let browserLang = this.translate.getBrowserLang();

    // const pass = 'ranitessarldev2019';
    // const lang = cookieService.get('goepla-portal-language');
    // if (lang.trim() !== '') {
    //   const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
    //   const lg = declang;
    //   browserLang = lg;
    // }

    // if (localStorage.getItem('language') !== null) {
    //   browserLang = localStorage.getItem('language');
    // }
    // this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    translate.use('English');
   }

  async ngOnInit() {
    const fmode1 = await this.translate.get('GENERAL.VISUALONLY').toPromise();
    const fmode2 = await this.translate.get('GENERAL.VISUALANDACOUSTIC').toPromise();
    this.fmodes.push({ label: fmode1, value: 1 });
    this.fmodes.push({ label: fmode2, value: 2 });

    const mon = JSON.parse(localStorage.getItem('monitor-item'));
    this.ConfigName = mon.configuration.configurationName;
    this.MonitorName = mon.monitorName;
    this.ConfigId = mon.configuration.configurationId;
    this.MonitorId = mon.monitorId;
    this.MonitorItemName = mon.monitorItemName;
    this.MonitorItemId = mon.id;

    this.fmode = mon.focusMode;
  }

  onSave() {
    if (this.MonitorItemName === undefined || this.MonitorItemName === null || this.MonitorItemName.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.PROVIDEMONITORITEMNAME').subscribe((res: string) => {
        this.confirm(res);
      });

      this.loading = false;
    } else {
      const monitor = new MonitorItemUpdater();
      monitor.Id = this.MonitorItemId;
      monitor.Values = [];

      const mitemname = new KeyValue();
      mitemname.Key = 'm_item_name';
      mitemname.Value = this.MonitorItemName;
      monitor.Values.push(mitemname);

      const focusmode = new KeyValue();
      focusmode.Key = 'focus_mode';
      focusmode.Value = this.fmode;
      monitor.Values.push(focusmode);

      this.loading = true;
      // tslint:disable-next-line: deprecation
      this.monitoritemService.putMonitorItem(this.MonitorItemId, monitor).subscribe((res) => {
        if (res  === true) {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.MONITORITEMUPDATED').subscribe((resp: string) => {
            this.notificationSvc.info('', resp, 2000);
          });
          this.location.back();
          this.loading = false;
        } else {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.COULDNOTUPDATEMONITORITEM').subscribe((resp: string) => {
            this.confirm(resp);
          });
          this.loading = false;
        }
      }, err => this.handleError(err));
    }
  }

  onCancel() {
    this.loading = false;
    this.location.back();
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  handleError(err) {
    this.loading = false;
    if (err.error.MessageText === 'duplicate key value violates unique constraint "t_ntm_monitor_item_monitor_item_name_key"') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.MONITORITEMALREADYEXISTS').subscribe((res: string) => {
        this.confirm(res);
      });
    }
  }

}
