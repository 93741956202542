import { MonitorItemsService } from './../../services/monitor-items/monitor-items.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { CookieService } from 'ngx-cookie-service';
import { NotificationsServiceService } from 'src/app/services/notifications-service/notifications-service.service';
import { TasksService } from 'src/app/services/tasks/tasks.service';
import * as CryptoJS from 'crypto-js';
import { UpdateService } from 'src/app/services/update/update.service';

@Component({
  selector: 'app-monitors',
  templateUrl: './monitors.component.html',
  styleUrls: ['./monitors.component.css']
})
export class MonitorsComponent implements OnInit {
  monitors = [];
  miname: string;
  mid: number;
  oldid: any;

  ismonitors: boolean;
  mtsks: any;
  showmessage: boolean;

  up = false;
  down = true;

  doptions: any = { year: 'numeric', month: 'short', day: 'numeric',
  hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false };

  sint: any;
  isplaying = false;

  audio: HTMLAudioElement;

  constructor(
    private router: Router,
    private notificationSvc: NotificationService,
    public translate: TranslateService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private notification: NotificationsServiceService,
    private tasksService: TasksService,
    private monitoritemService: MonitorItemsService,
    private myService: UpdateService
    ) {
      this.translate.addLangs(['English', 'French']);
      this.translate.setDefaultLang('English');

      let browserLang = this.translate.getBrowserLang();

      const pass = 'ranitessarldev2019';
      const lang = cookieService.get('goepla-portal-language');
      if (lang.trim() !== '') {
        const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
        const lg = declang;
        browserLang = lg;
        localStorage.setItem('language', lg);
      }

      if (localStorage.getItem('language') !== null) {
        browserLang = localStorage.getItem('language');
      }
      this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

      try {
        const route = router.url;
        if (route !== undefined && route !== null) {
          const split = route.split('/');
          // this.miname = split[2].toUpperCase().replace('%20', ' ');
          // this.miname = split[2].replace('%20', ' ');
          this.miname = split[2].split('%20').join(' ');
          this.mid = +split[3];
          this.oldid = this.mid;
          localStorage.setItem('mi', this.mid.toString());
          this.ismonitors = true;
          this.getMonitors();
        }
      } catch (error) {
      }
     }

  ngOnInit() {
    // tslint:disable-next-line: deprecation
    this.myService.getRefresh().subscribe((value: boolean) => {
      if (value) {
        this.getMonitors();
      }
    });

    // refresh every 60s
    let int60: any;
    // tslint:disable-next-line: only-arrow-functions
    int60 = setInterval(() => {
      this.showmessage = false;
      this.getMonitors();
    }, 60000);

    let run = true;
    // tslint:disable-next-line: only-arrow-functions
    const to = setInterval(() => {
      const route = this.router.url;
      const split = route.split('/');
      const n = split[2];
      const id = +split[3];
      if (route.includes('monitors') && run === true) {
        if (id === this.oldid) {
          //
        } else {
          this.oldid = id;
          this.mid = id;
          localStorage.setItem('mi', this.mid.toString());
          // this.miname = n.replace('%20', ' ');
          this.miname = n.split('%20').join(' ');
          this.ismonitors = true;
          this.showmessage = true;
          this.monitors = [];
          this.stopSound();
          this.getMonitors();
        }
      } else {
        run = false;
        clearInterval(to);
        clearInterval(int60);
      }
    }, 1);
  }

  public getMonitors() {
    const id = localStorage.getItem('mi');
    if (id === undefined || id === null) {
      return;
    } else {
      this.mid = +id;
    }

    let show = true;
    const pans = [];
    let counting = 0;
    let c = 0;
    // tslint:disable-next-line: deprecation
    this.monitoritemService.getMonitorItems2(0, 0, this.mid, 1).subscribe((res) => {
      if (res != null) {
        let isoverdue = false;
        let isplaysound = false;
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          // counting += 1;
          if (res[i].configuration.configurationType === 'T') {
            let count = 0;
            const ress = res[i].notifications;
            c += 1;
            if (ress != null) {
              if (res[i].focusMode === 2) {
                isplaysound = true;
              }
              // tslint:disable-next-line: prefer-for-of
              for (let j = 0; j < ress.length; j++) {
                if (new Date(ress[j].locationConfirmation).getFullYear() === 1) {
                  ress[j].loc = false;
                } else {
                  ress[j].loc = true;
                }
                ress[j].up = false;
                ress[j].down = true;

                ress[j].a = 'green';
                ress[j].s = 'green';
                ress[j].c = 'green';
                ress[j].e = 'green';

                ress[j].border = 'transparent';
                ress[j].shadow = 'none';
                const ocolor = 'red';
                const oshadow = '0px 0px 26px 1px red';
                // const oshadow = '0px 20px 15px -15px red';
                // oshadow = '0 14px 28px red, 0 10px 10px red';
                // oshadow = '0 10px 20px red, 0 6px 6px red';

                ress[j].expstatus = '';
                ress[j].expcolor = 'green';
                ress[j].usr = '';
                ress[j].exp = new Date(ress[j].expires.toString().replace('T', ' ') + ' UTC').toLocaleString('en-US', this.doptions);
                ress[j].crt = new Date(ress[j].created.toString().replace('T', ' ') + ' UTC').toLocaleString('en-US', this.doptions);
                ress[j].expiress = new Date(ress[j].expires.toString().replace('T', ' ') + ' UTC');

                // check if expired
                if (ress[j].expiress < new Date()) {
                  // tslint:disable-next-line: deprecation
                  this.translate.get('GENERAL.EXPIRED').subscribe((resp1: string) => {
                    ress[j].expstatus = resp1;
                    ress[j].expcolor = 'Red';
                  });

                  // check if not closed and signal overdue
                  if (new Date(ress[j].closed).getFullYear() === 1) {
                    isoverdue = true;
                  }
                } // else {
                if (ress[j].taskUserId > 0) {
                  // tslint:disable-next-line: deprecation
                  this.translate.get('GENERAL.ASSIGNED').subscribe((resp1: string) => {
                    // ress[j].status = resp1 + ' - ' + ress[j].taskUserName;
                    ress[j].status = resp1;
                    ress[j].usr = ress[j].taskUserName;
                    ress[j].color = 'Yellow';
                  });
                  if (new Date(ress[j].started).getFullYear() !== 1) {
                    // tslint:disable-next-line: deprecation
                    this.translate.get('GENERAL.STARTED').subscribe((resp1: string) => {
                      // ress[j].status = resp1 + ' - ' + ress[j].taskUserName;
                      ress[j].status = resp1;
                      ress[j].usr = ress[j].taskUserName;
                      ress[j].color = 'LightBlue';
                    });
                  }

                  // check overdues
                  // assigned tasks
                  if (new Date(ress[j].assigned).getFullYear() !== 1) {
                    const dt = new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(),
                                      new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds());
                    if (new Date(ress[j].closed).getFullYear() === 1 && new Date(ress[j].expires) < dt) {
                      ress[j].e = 'Red';
                      // ress[j].border = ocolor;
                      // ress[j].shadow = oshadow;
                      // isoverdue = true;
                    }

                    const crt = new Date(ress[j].created);
                    const ovra = new Date(crt.getFullYear(), crt.getMonth(), crt.getDate(), crt.getHours(),
                                            crt.getMinutes() + ress[j].configuration.reactionTimeLimit, crt.getSeconds());
                    if (new Date(ress[j].assigned) > ovra) {
                      ress[j].a = 'Red';
                      // ress[j].border = ocolor;
                      // ress[j].shadow = oshadow;
                      // isoverdue = true;
                    }

                    if (new Date(ress[j].started).getFullYear() === 1) {
                      const ovrs = new Date(crt.getFullYear(), crt.getMonth(), crt.getDate(), crt.getHours(),
                                            crt.getMinutes() + ress[j].configuration.startTimeLimit, crt.getSeconds());
                      if (dt > ovrs) {
                        ress[j].s = 'Red';
                        // ress[j].border = ocolor;
                        // ress[j].shadow = oshadow;
                        // isoverdue = true;
                      }
                    }

                    if (new Date(ress[j].closed).getFullYear() === 1) {
                      const ovrc = new Date(crt.getFullYear(), crt.getMonth(), crt.getDate(), crt.getHours(),
                                            crt.getMinutes() + ress[j].configuration.closeTimeLimit, crt.getSeconds());
                      if (dt > ovrc) {
                        ress[j].c = 'Red';
                        // ress[j].border = ocolor;
                        // ress[j].shadow = oshadow;
                        // isoverdue = true;
                      }
                    }
                  } else {
                    // will never execute since the task is open
                    // task not yet assigned
                    // check if overdue assigned and signal overdue
                    const dt = new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(),
                              new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds());

                    const crt = new Date(ress[j].created);
                    const ovra = new Date(crt.getFullYear(), crt.getMonth(), crt.getDate(), crt.getHours(),
                                            crt.getMinutes() + ress[j].configuration.reactionTimeLimit, crt.getSeconds());
                    if (dt > new Date(ovra)) {
                      ress[j].border = ocolor;
                      ress[j].shadow = oshadow;
                      isoverdue = true;
                    }
                  }

                  // started tasks
                  if (new Date(ress[j].started).getFullYear() !== 1) {
                    const dt = new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(),
                                      new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds());
                    if (new Date(ress[j].closed).getFullYear() === 1 && new Date(ress[j].expires) < dt) {
                      ress[j].e = 'Red';
                      // ress[j].border = ocolor;
                      // ress[j].shadow = oshadow;
                      // isoverdue = true;
                    }

                    const crt = new Date(ress[j].created);
                    const ovra = new Date(crt.getFullYear(), crt.getMonth(), crt.getDate(), crt.getHours(),
                                            crt.getMinutes() + ress[j].configuration.reactionTimeLimit, crt.getSeconds());
                    if (new Date(ress[j].assigned) > ovra) {
                      ress[j].a = 'Red';
                      // ress[j].border = ocolor;
                      // ress[j].shadow = oshadow;
                      // isoverdue = true;
                    }

                    const ovrs = new Date(crt.getFullYear(), crt.getMonth(), crt.getDate(), crt.getHours(),
                                            crt.getMinutes() + ress[j].configuration.startTimeLimit, crt.getSeconds());
                    if (new Date(ress[j].started) > ovrs) {
                      ress[j].s = 'Red';
                      // ress[j].border = ocolor;
                      // ress[j].shadow = oshadow;
                      // isoverdue = true;
                    }

                    if (new Date(ress[j].closed).getFullYear() === 1) {
                      const ovrc = new Date(crt.getFullYear(), crt.getMonth(), crt.getDate(), crt.getHours(),
                                            crt.getMinutes() + ress[j].configuration.closeTimeLimit, crt.getSeconds());
                      if (dt > ovrc) {
                        ress[j].c = 'Red';
                        ress[j].border = ocolor;
                        ress[j].shadow = oshadow;
                        isoverdue = true;
                      }
                    }
                  } else {
                    // task not yet started
                    // check if overdue started and signal overdue
                    const dt = new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(),
                              new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds());

                    const crt = new Date(ress[j].created);
                    const ovrs = new Date(crt.getFullYear(), crt.getMonth(), crt.getDate(), crt.getHours(),
                                            crt.getMinutes() + ress[j].configuration.startTimeLimit, crt.getSeconds());
                    if (dt > ovrs) {
                      ress[j].border = ocolor;
                      ress[j].shadow = oshadow;
                      isoverdue = true;
                    }
                  }
                } else {
                  // tslint:disable-next-line: deprecation
                  this.translate.get('GENERAL.OPEN').subscribe((resp1: string) => {
                    ress[j].status = resp1;
                    ress[j].color = 'lightgray';
                  });

                  // open tasks
                  // check for due times
                  const dt = new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(),
                              new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds());
                              // console.log(ress[j], dt, new Date(ress[j].closed), new Date(ress[j].expires))
                  if (new Date(ress[j].closed).getFullYear() === 1 && new Date(ress[j].expires) < dt) {
                    ress[j].e = 'Red';
                    ress[j].border = ocolor;
                    ress[j].shadow = oshadow;
                    isoverdue = true;
                  }
                  const crt = new Date(ress[j].created);
                  const ovra = new Date(crt.getFullYear(), crt.getMonth(), crt.getDate(), crt.getHours(),
                                          crt.getMinutes() + ress[j].configuration.reactionTimeLimit, crt.getSeconds());
                  if (dt > new Date(ovra)) {
                    ress[j].a = 'Red';
                    ress[j].border = ocolor;
                    ress[j].shadow = oshadow;
                    isoverdue = true;
                  }

                  const ovrs = new Date(crt.getFullYear(), crt.getMonth(), crt.getDate(), crt.getHours(),
                                            crt.getMinutes() + ress[j].configuration.startTimeLimit, crt.getSeconds());
                  if (dt > ovrs) {
                    ress[j].s = 'Red';
                    ress[j].border = ocolor;
                    ress[j].shadow = oshadow;
                    isoverdue = true;
                  }

                  const ovrc = new Date(crt.getFullYear(), crt.getMonth(), crt.getDate(), crt.getHours(),
                                            crt.getMinutes() + ress[j].configuration.closeTimeLimit, crt.getSeconds());
                  if (dt > ovrc) {
                    ress[j].c = 'Red';
                    ress[j].border = ocolor;
                    ress[j].shadow = oshadow;
                    isoverdue = true;
                  }
                }
                // }

                if (pans.length < res[i].maxSize) {
                  pans.push(ress[j]);
                }
                count += 1;
                if (count === ress.length) {
                  counting += 1;
                }
                if (res.length === counting && count === ress.length) {
                  if (pans === null || pans.length <= 0) {
                    if (this.showmessage === true) {
                      if (show === true) {
                        this.monitors = [];
                        // tslint:disable-next-line: deprecation
                        this.translate.get('GENERAL.NOMONITORITEMS').subscribe((res1: string) => {
                          this.notificationSvc.info('', res1, 2000);
                        });
                        this.ismonitors = false;
                        show = false;
                      }
                    }

                    this.stopSound();
                  } else {
                    this.monitors = [];
                    if (res[i].display === 'FIFO') {
                      pans.sort((a, b) => a.created.localeCompare(b.created));
                    } else {
                      pans.sort((a, b) => a.created.localeCompare(b.created)).reverse();
                    }

                    // check and play sound
                    if (isoverdue === true && isplaysound === true) {
                      this.playSound();
                    } else {
                      this.stopSound();
                    }

                    const ids = [];
                    let numb = 0;
                    // tslint:disable-next-line: prefer-for-of
                    for (let k = 0; k < pans.length; k++) {
                      if (k < res[0].maxSize) {
                        if (!ids.includes(pans[k].notificationId)) {
                          numb += 1;
                          ids.push(pans[k].notificationId);
                          pans[k].pos = numb;
                          this.monitors.push(pans[k]);
                          this.ismonitors = false;
                        }
                      }
                    }
                  }
                }
              }
            } else {
              counting += 1;
              if (c === res.length && (pans === null || pans.length <= 0)) {
                this.monitors = [];
                this.ismonitors = false;
                if (this.showmessage === true) {
                  if (show === true) {
                    // tslint:disable-next-line: deprecation
                    this.translate.get('GENERAL.NOMONITORITEMS').subscribe((res1: string) => {
                      this.notificationSvc.info('', res1, 2000);
                    });
                    this.ismonitors = false;
                    show = false;
                  }
                }

                this.stopSound();
              } else if (res.length === c) {
                if (pans === null || pans.length <= 0) {
                  this.monitors = [];
                  this.ismonitors = false;
                  if (this.showmessage === true) {
                    if (show === true) {
                      // tslint:disable-next-line: deprecation
                      this.translate.get('GENERAL.NOMONITORITEMS').subscribe((res1: string) => {
                        this.notificationSvc.info('', res1, 2000);
                      });
                      this.ismonitors = false;
                      show = false;
                    }
                  }
                  this.stopSound();
                } else {
                  this.monitors = [];
                  // pans.sort((a, b) => a.created.localeCompare(b.created)).reverse();
                  // pans.sort((a, b) => a.created.localeCompare(b.created));
                  if (res[i].display === 'FIFO') {
                    pans.sort((a, b) => a.created.localeCompare(b.created));
                  } else {
                    pans.sort((a, b) => a.created.localeCompare(b.created)).reverse();
                  }

                  // check and play sound
                  if (isoverdue === true && isplaysound === true) {
                    this.playSound();
                  } else {
                    this.stopSound();
                  }

                  const ids = [];
                  let numb = 0;
                  // tslint:disable-next-line: prefer-for-of
                  for (let k = 0; k < pans.length; k++) {
                    if (k < res[0].maxSize) {
                      if (!ids.includes(pans[k].notificationId)) {
                        numb += 1;
                        ids.push(pans[k].notificationId);
                        pans[k].pos = numb;
                        this.monitors.push(pans[k]);
                        this.ismonitors = false;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      } else {
        this.monitors = [];
        this.ismonitors = false;
        if (this.showmessage === true) {
          if (show === true) {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.NOMONITORITEMS').subscribe((res1: string) => {
              this.notificationSvc.info('', res1, 2000);
            });
            this.ismonitors = false;
            show = false;
          }
        }

        this.stopSound();
      }
    }, err => this.handleError(err));
  }

  toggleUp(monitor) {
    this.up = false;
    this.down = true;
    for (const val of this.monitors) {
      if (val.notificationId === monitor.notificationId) {
        val.down = true;
        val.up = false;
      }
    }
  }

  toggleDown(monitor) {
    this.up = true;
    this.down = false;
    for (const val of this.monitors) {
      if (val.notificationId === monitor.notificationId) {
        val.down = false;
        val.up = true;
      }
    }
  }

  refresh() {
    this.ismonitors = true;
    this.showmessage = true;
    this.getMonitors();
  }

  playSound() {
    if (this.isplaying === false) {
      this.isplaying = true;
      // tslint:disable-next-line: only-arrow-functions
      this.sint = setInterval(() => {
        // play sound
        this.audio = new Audio('assets/sounds/Notification4.mp3');
        this.audio.play();
      }, 3000);

      const to = setInterval(() => {
        const route = this.router.url;
        if (route.includes('monitors')) {
        } else {
          this.isplaying = false;
          clearInterval(to);
          clearInterval(this.sint);
        }
      }, 1);
    }
  }

  stopSound() {
    // if (this.audio !== undefined && this.audio !== null) {
    //   this.audio.pause();
    // }
    this.isplaying = false;
    clearInterval(this.sint);
  }

  handleError(err) {
    console.log(err);
  }

}
