import { MonitorItemsService } from './../../services/monitor-items/monitor-items.service';
import { Component, OnInit } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { ConfirmationService } from 'primeng/api';
import { CookieService } from 'ngx-cookie-service';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-monitor-items',
  templateUrl: './monitor-items.component.html',
  styleUrls: ['./monitor-items.component.css']
})
export class MonitorItemsComponent implements OnInit {

  monitors = [];
  monitor: any;

  loading = false;
  isVis: boolean;

  page = 1;
  size = 100;

  isLoadMore = true;
  isTask: boolean;

  id: number;
  load = false;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private notificationSvc: NotificationService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private appComp: AppComponent,
    private monitorItemService: MonitorItemsService
  ) {
    // this.translate.addLangs(['English', 'French']);
    // this.translate.setDefaultLang('English');

    // let browserLang = this.translate.getBrowserLang();

    // const pass = 'ranitessarldev2019';
    // const lang = cookieService.get('goepla-portal-language');
    // if (lang.trim() !== '') {
    //   const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
    //   const lg = declang;
    //   browserLang = lg;
    // }

    // if (localStorage.getItem('language') !== null) {
    //   browserLang = localStorage.getItem('language');
    // }
    // this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    translate.use('English');

    this.id = +localStorage.getItem('monitor-id');

    this.getMonitorItems();
   }

  ngOnInit() {
    localStorage.removeItem('monitor-item');
  }

  gotoCreateMonitorItem() {
    this.router.navigateByUrl('/createmonitoritem');
  }

  getMonitorItems() {
    this.loading = true;
    this.load = true;
    // tslint:disable-next-line: deprecation
    this.monitorItemService.getMonitorItems(this.page, this.size, this.id).subscribe((res) => {
      if (res != null) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          const options = [];
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.DELETE').subscribe((res2) => {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.EDIT').subscribe((res3) => {
              options.push({label: res3, value: 'Edit'});
              options.push({label: res2, value: 'Delete'});

              res[i].options = options;
              this.monitors.push(res[i]);
            });
          });
        }
        if (res.length < this.size) {
          this.isLoadMore = true;
        } else {
          this.isLoadMore = false;
        }
      } else {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.NOMONITORITEMS').subscribe((resp: string) => {
          this.notificationSvc.info('', resp, 3000);
          this.isLoadMore = true;
        });
      }
      this.loading = false;
      this.load = false;
    }, err => {
      this.handleError(err);
    });
  }

  loadMore() {
    this.page += 1;
    this.getMonitorItems();
  }

  // tslint:disable-next-line: no-shadowed-variable
  saveMonitorItem(event, monitorItem) {
    localStorage.setItem('monitor-item', JSON.stringify(monitorItem));

    if (event.value === 'Edit') {
      this.router.navigateByUrl('editmonitoritem');
    } else if (event.value === 'Delete') {
      this.deleteMonitorItem(monitorItem);
    }
  }

  // tslint:disable-next-line: no-shadowed-variable
  deleteMonitorItem(monitorItem) {
    this.load = true;
    // tslint:disable-next-line: deprecation
    this.monitorItemService.deleteMonitorItem(monitorItem.id).subscribe((res) => {
      if (res === true) {
        this.appComp.loadMenuAgain();
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.MONITORITEMDELETED').subscribe((resp: string) => {
          this.notificationSvc.info('', resp, 3000);
        });
        this.monitors = [];
        this.getMonitorItems();
      } else {
        this.load = false;
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.COULDNOTDELETEMONITORITEM').subscribe((resp: string) => {
          this.notificationSvc.info('', resp, 3000);
        });
      }
    }, err => {
      this.handleError(err);
    });
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  handleError(err) {
    this.load = false;
    this.loading = false;
    // tslint:disable-next-line: max-line-length
    if (err.error.Message === '23503: update or delete on table "t_ntm_panel" violates foreign key constraint "t_ntm_panel_item_panel_id_fkey" on table "t_ntm_panel_item"') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.MONITORITEMHASDEPENDENTS').subscribe((res) => {
        this.confirm(res);
      });
    }
  }

}
