import { environment } from './../../environments/environment';

export class Urls {
    apiUrl = environment.apiUrl;
    appUrl = environment.appUrl;
    usmApiUrl = environment.usmApiUrl;
    flmApiUrl = environment.flmApiUrl;
    colorpalette = ['#FF6633', '#FF33FF', '#FFFF99', '#FFB399', '#00B3E6',
                    '#E6B333', '#999966', '#99FF99', '#3366E6', '#B34D4D',
                    '#80B300', '#E6B3B3', '#6680B3', '#809900', '#66991A',
                    '#FF99E6', '#FF1A66', '#E6331A', '#CCFF1A', '#33FFCC',
                    '#66994D', '#4D8000', '#B33300', '#4DB380', '#CC80CC',
                    '#66664D', '#E666FF', '#4DB3FF', '#991AFF', '#1AB399',
                    '#E666B3', '#CC9999', '#B3B31A', '#33991A', '#00E680',
                    '#4D8066', '#E6FF80', '#1AFF33', '#809980', '#999933',
                    '#FF3380', '#66E64D', '#4D80CC', '#CCCC00', '#9900B3',
                    '#E64D66', '#FF4D4D', '#99E6E6', '#B366CC', '#6666FF'];
}
