import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ConfirmationService } from 'primeng/api';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import * as CryptoJS from 'crypto-js';
import { Variables } from 'src/app/Models/variables';

@Component({
  selector: 'app-dashboard-performance-ten-hours',
  templateUrl: './dashboard-performance-ten-hours.component.html',
  styleUrls: ['./dashboard-performance-ten-hours.component.css']
})
export class DashboardPerformanceTenHoursComponent implements OnInit {

  assigned = 0;
  started = 0;
  closed = 0;
  expired = 0;

  assignedontime = 0;
  startedontime = 0;
  closedontime = 0;
  closedlate = 0;

  createdbydayData: any;
  closedbydayData: any;
  ttcbydayData: any;

  assignedontimeData: any;
  startedontimeData: any;
  closedontimeData: any;

  uid: number;
  now: string;
  past: string;
  time: string;

  constructor(
    private router: Router,
    private notificationSvc: NotificationService,
    public translate: TranslateService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private analyticsService: AnalyticsService
    ) {
      this.translate.addLangs(['English', 'French']);
      this.translate.setDefaultLang('English');

      let browserLang = this.translate.getBrowserLang();

      const pass = 'ranitessarldev2019';
      const lang = cookieService.get('goepla-portal-language');
      if (lang.trim() !== '') {
        const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
        const lg = declang;
        browserLang = lg;
        localStorage.setItem('language', lg);
      }

      if (localStorage.getItem('language') !== null) {
        browserLang = localStorage.getItem('language');
      }
      this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

      const v = new Variables();
      const val = localStorage.getItem('ui');
      const ui = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);
      this.uid = +ui;
      const d = new Date();
      const dt = new Date();
      this.now = new Date().toDateString();
      this.past = new Date(d.setHours(d.getHours() - 10)).toDateString();
      this.time = new Date(dt.setHours(dt.getHours() - 10)).toJSON().toString();
    }

  ngOnInit() {
    this.getAmount();
    this.getAnalyticsCreatedTasksByDay();
    this.getAnalyticsClosedTasksByDay();
    this.getAnalyticsTTCByDay();
    this.getAnalyticsAssignedOnTimeByDay();
    this.getAnalyticsStartedOnTimeByDay();
    this.getAnalyticsClosedOnTimeByDay();
  }

  getAmount() {
    this.analyticsService.getDashboards(this.past, this.now, 0, 'assigned2', this.time).subscribe((asg) => {
      this.analyticsService.getDashboards(this.past, this.now, 0, 'started2', this.time).subscribe((str) => {
        this.analyticsService.getDashboards(this.past, this.now, 0, 'closed', this.time).subscribe((cls) => {
          this.analyticsService.getDashboards(this.past, this.now, 0, 'expired', this.time).subscribe((exp) => {
            this.analyticsService.getDashboards(this.past, this.now, 0, 'assignedontime', this.time).subscribe((assot) => {
              this.analyticsService.getDashboards(this.past, this.now, 0, 'startedontime', this.time).subscribe((strot) => {
                this.analyticsService.getDashboards(this.past, this.now, 0, 'closedontime', this.time).subscribe((clsot) => {
                  this.analyticsService.getDashboards(this.past, this.now, 0, 'closedlate', this.time).subscribe((clslate) => {
                    this.analyticsService.getDashboards(this.past, this.now, 0, 'created', this.time).subscribe((crt) => {
                      this.assigned = asg;
                      this.started = str;
                      this.closed = cls;
                      this.expired = exp;
                      if (assot > 0) {
                        this.assignedontime = Math.round((((assot / asg) * 100) + Number.EPSILON) * 100) / 100;
                      }
                      if (strot > 0) {
                        this.startedontime = Math.round((((strot / str) * 100) + Number.EPSILON) * 100) / 100;
                      }
                      if (clsot > 0) {
                        this.closedontime = Math.round((((clsot / cls) * 100) + Number.EPSILON) * 100) / 100;
                      }
                      if (clslate > 0) {
                        this.closedlate = Math.round((((clslate / cls) * 100) + Number.EPSILON) * 100) / 100;
                      }
                    }, err => this.handleError(err));
                  }, err => this.handleError(err));
                }, err => this.handleError(err));
              }, err => this.handleError(err));
            }, err => this.handleError(err));
          }, err => this.handleError(err));
        }, err => this.handleError(err));
      }, err => this.handleError(err));
    }, err => this.handleError(err));
  }

  getAnalyticsCreatedTasksByDay() {
    let amttsks = '';
    this.translate.get('GENERAL.AMOUNTOFTASKS').subscribe((trans) => {
      amttsks = trans;
    });
    this.analyticsService.getAnalytics(0, 'T', this.past, this.now,
                               0, 'createdbyhour', this.time)
                  .subscribe((res) => {
      const labs: string[] = [];
      const datasets: number[] = [];
      let count = 0;
      if (res != null && res.length > 0) {
        for (const v of res) {
          // const date = new Date(v.value2);
          // labs.push(date.toDateString());
          labs.push(new Date(new Date(v.value2.toString().replace('T', ' ') + ' UTC')).getHours().toString());
          datasets.push(v.value1);
          count += 1;

          if (count === res.length) {
            // labs.push(this.def.toDateString());
            // datasets.push(0);
            this.createdbydayData = {
              labels: labs,
              datasets: [
                  {
                      label: amttsks,
                      backgroundColor: '#42A5F5',
                      borderColor: '#1E88E5',
                      data: datasets
                  }
              ]
            };
          }
        }
      } else {
      }
    }, err => this.handleError(err));
   }

   getAnalyticsClosedTasksByDay() {
    let amttsks = '';
    this.translate.get('GENERAL.AMOUNTOFTASKS').subscribe((trans) => {
      amttsks = trans;
    });
    this.analyticsService.getAnalytics(0, 'T', this.past, this.now,
                               0, 'closedbyhour', this.time)
                  .subscribe((res) => {
      const labs: string[] = [];
      const datasets: number[] = [];
      let count = 0;
      if (res != null && res.length > 0) {
        for (const v of res) {
          // const date = new Date(v.value2);
          // labs.push(date.toDateString());
          labs.push(new Date(new Date(v.value2.toString().replace('T', ' ') + ' UTC')).getHours().toString());
          datasets.push(v.value1);
          count += 1;

          if (count === res.length) {
            // labs.push(this.def.toDateString());
            // datasets.push(0);
            this.closedbydayData = {
              labels: labs,
              datasets: [
                  {
                      label: amttsks,
                      backgroundColor: '#42A5F5',
                      borderColor: '#1E88E5',
                      data: datasets
                  }
              ]
            };
          }
        }
      } else {
      }
    }, err => this.handleError(err));
   }

   getAnalyticsTTCByDay() {
    let mins = '';
    let tot = 0;
    this.translate.get('GENERAL.MINUTES').subscribe((trans) => {
      mins = trans;
    });

    this.analyticsService.getAnalytics(0, 'T', this.past,
                                this.now, 0, 'useractivitybyhour', this.time).subscribe((res) => {
      const labs: string[] = [];
      const datasets: number[] = [];
      let count = 0;
      if (res != null && res.length > 0) {
        for (const v of res) {
          // labs.push(new Date(v.value3).toDateString());
          labs.push(new Date(new Date(v.value3.toString().replace('T', ' ') + ' UTC')).getHours().toString());
          datasets.push(v.value1);
          tot += +v.value1;
          count += 1;

          if (count === res.length) {
            // this.myactivities = Math.round(((tot / 60) + Number.EPSILON) * 100) / 100;
            this.ttcbydayData = {
              labels: labs,
              datasets: [
                  {
                      label: mins,
                      backgroundColor: '#42A5F5',
                      borderColor: '#1E88E5',
                      data: datasets
                  }
              ]
            };
          }
        }
      } else {
      }
    }, err => this.handleError(err));
   }

   getAnalyticsAssignedOnTimeByDay() {
    let perc = '';
    this.translate.get('GENERAL.PERCENTAGE').subscribe((trans) => {
      perc = trans;
    });

    this.analyticsService.getAnalytics(0, 'T', this.past, this.now,
                                0, 'assignedontimebyhour', this.time)
                  .subscribe((resp) => {
      const labs: string[] = [];
      const datasets: number[] = [];
      let count = 0;
      let total = 0;
      if (resp != null && resp.length > 0) {
        for (const res of resp) {
          // labs.push(new Date(res.value2).toDateString());
          labs.push(new Date(new Date(res.value2.toString().replace('T', ' ') + ' UTC')).getHours().toString());
          total += res.value1;
          count += 1;
          if (count === resp.length) {
            count = 0;
            for (const val of resp) {
              const p = Math.round((((val.value1 / total) * 100) + Number.EPSILON) * 100) / 100;
              datasets.push(p);
              count += 1;
            }

            if (count === resp.length) {
              this.assignedontimeData = {
                labels: labs,
                datasets: [
                    {
                        label: perc,
                        data: datasets,
                        backgroundColor: '#42A5F5',
                        borderColor: '#1E88E5',
                    }]
                };
            }
          }
        }
      } else {
        //
      }
    }, err => this.handleError(err));
   }

   getAnalyticsStartedOnTimeByDay() {
    let perc = '';
    this.translate.get('GENERAL.PERCENTAGE').subscribe((trans) => {
      perc = trans;
    });

    this.analyticsService.getAnalytics(0, 'T', this.past, this.now,
                                0, 'startedontimebyhour', this.time)
                  .subscribe((resp) => {
      const labs: string[] = [];
      const datasets: number[] = [];
      let count = 0;
      let total = 0;
      if (resp != null && resp.length > 0) {
        for (const res of resp) {
          // labs.push(new Date(res.value2).toDateString());
          labs.push(new Date(new Date(res.value2.toString().replace('T', ' ') + ' UTC')).getHours().toString());
          total += res.value1;
          count += 1;
          if (count === resp.length) {
            count = 0;
            for (const val of resp) {
              const p = Math.round((((val.value1 / total) * 100) + Number.EPSILON) * 100) / 100;
              datasets.push(p);
              count += 1;
            }

            if (count === resp.length) {
              this.startedontimeData = {
                labels: labs,
                datasets: [
                    {
                        label: perc,
                        data: datasets,
                        backgroundColor: '#42A5F5',
                        borderColor: '#1E88E5',
                    }]
                };
            }
          }
        }
      } else {
        //
      }
    }, err => this.handleError(err));
   }

   getAnalyticsClosedOnTimeByDay() {
    let perc = '';
    this.translate.get('GENERAL.PERCENTAGE').subscribe((trans) => {
      perc = trans;
    });

    this.analyticsService.getAnalytics(0, 'T', this.past, this.now,
                                0, 'closedontimebyhour', this.time)
                  .subscribe((resp) => {
      const labs: string[] = [];
      const datasets: number[] = [];
      let count = 0;
      let total = 0;
      if (resp != null && resp.length > 0) {
        for (const res of resp) {
          // labs.push(new Date(res.value2).toDateString());
          labs.push(new Date(new Date(res.value2.toString().replace('T', ' ') + ' UTC')).getHours().toString());
          total += res.value1;
          count += 1;
          if (count === resp.length) {
            count = 0;
            for (const val of resp) {
              const p = Math.round((((val.value1 / total) * 100) + Number.EPSILON) * 100) / 100;
              datasets.push(p);
              count += 1;
            }

            if (count === resp.length) {
              this.closedontimeData = {
                labels: labs,
                datasets: [
                    {
                        label: perc,
                        data: datasets,
                        backgroundColor: '#42A5F5',
                        borderColor: '#1E88E5',
                    }]
                };
            }
          }
        }
      } else {
        //
      }
    }, err => this.handleError(err));
   }

  handleError(err: any): void {
    console.log(err);
  }

}
