import { Updater, TaskUpdater } from 'src/app/Models/Updater';
import { Injectable } from '@angular/core';
import { Urls } from 'src/app/Helpers/Urls';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { Tasks } from 'src/app/Models/Tasks';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TasksService {
  url = new Urls();
  apiUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = this.url.apiUrl;
  }


getTasks() {
  // tslint:disable-next-line: max-line-length
  const url = this.apiUrl + '/api/tasks';
  return this.httpclient.get<any>(url)
  .pipe(
    tap( // Log the result or error
      error => this.handleError
    )
  );
}

getTaskById(id: string) {
  const url = this.apiUrl + '/api/tasks/' + id;
  return this.httpclient.get<any>(url)
  .pipe(
    tap( // Log the result or error
      error => this.handleError
    )
  );
}

postTask(task: Tasks): Observable<any> {
  // const body = JSON.stringify(inst);
  const options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      responseType: 'text'
    })
  };
  return this.httpclient.post<any>(this.apiUrl + '/api/tasks', task, options)
                  .pipe(
                    catchError(this.handleError)
                  );
}

putTask(id: string, upt: TaskUpdater): Observable<any> {
  // const body = JSON.stringify(inst);
  const options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      responseType: 'text'
    })
  };
  const val = localStorage.getItem('uname');
  upt.AdditionalValue = val;
  return this.httpclient.put<any>(this.apiUrl + '/api/tasks/' + id, upt, options)
                  .pipe(
                    catchError(this.handleError)
                  );
}

deleteTask(id: string): Observable<any> {
  const options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  return this.httpclient.delete<any>(this.apiUrl + '/api/task/' + id, options)
                  .pipe(
                    catchError(this.handleError)
                  );
}

private handleError(error: any) {
  throw error;
  return error;
}
}
