import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CookieService } from 'ngx-cookie-service';
import { ReminderTypesService } from 'src/app/services/reminder-types/reminder-types.service';
import { StringUpdater, KeyValue } from 'src/app/Models/Updater';
import * as CryptoJS from 'crypto-js';
import { RemindersService } from 'src/app/services/reminders/reminders.service';
import { Variables } from 'src/app/Models/variables';

@Component({
  selector: 'app-reminders',
  templateUrl: './reminders.component.html',
  styleUrls: ['./reminders.component.css']
})
export class RemindersComponent implements OnInit {

  types = [];

  page = 1;
  size = 100;

  disableLoadMore = false;
  showDetail = false;
  type: any = [];

  item = [];
  load = false;

  ismanage = false;

  doptions: any = { year: 'numeric', month: 'short', day: 'numeric',
  hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false };

  constructor(
    private router: Router,
    private notificationSvc: NotificationService,
    public translate: TranslateService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private reminderService: RemindersService,
    private messageService: MessageService
    ) {
    this.translate.addLangs(['English', 'French']);
    this.translate.setDefaultLang('English');

    let browserLang = this.translate.getBrowserLang();

    const pass = 'ranitessarldev2019';
    const lang = cookieService.get('goepla-portal-language');
    if (lang.trim() !== '') {
      const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
      const lg = declang;
      browserLang = lg;
      localStorage.setItem('language', lg);
    }

    if (localStorage.getItem('language') !== null) {
      browserLang = localStorage.getItem('language');
    }
    this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');
   }

  ngOnInit() {
    localStorage.removeItem('reminder');
    const v = new Variables();
    const p = localStorage.getItem('ups');
    if (p != null) {
      const val = CryptoJS.AES.decrypt(p, v.pass).toString(CryptoJS.enc.Utf8);
      const perm = JSON.parse(val);
      if (perm.includes('NTM.Reminders.Manage') || perm.includes('NTM.Administration.Full')) {
        this.ismanage = true;
      }
    }
    this.getReminders();
  }

  getReminders() {
    this.load = true;
    // tslint:disable-next-line: deprecation
    this.reminderService.getReminders(this.page, this.size, 'no', 'no').subscribe((res) => {
      if (res != null) {
        if (res.length < this.size) {
          this.disableLoadMore = true;
        } else {
          this.disableLoadMore = false;
        }
        for (const val of res) {
          val.int = +val.intervalCycle;
          const options = [];
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.DETAILS').subscribe((resp1: string) => {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.ENABLE').subscribe((resp2: string) => {
              // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.DISABLE').subscribe((resp3: string) => {
                // tslint:disable-next-line: deprecation
                this.translate.get('GENERAL.DELETE').subscribe((resp4: string) => {
                  if (this.ismanage === true) {
                    options.push({label: resp2, value: 'Enable'});
                    options.push({label: resp3, value: 'Disable'});
                  }
                  options.push({label: resp1, value: 'Details'});
                  // options.push({label: resp4, value: 'Delete'});

                  val.options = options;
                });
              });
            });
          });

          if (+val.intervalCycle > 0) {
            val.int = '';
            if (+val.runReason > 0) {
              if (+val.intervalCycle === 1) {
                // tslint:disable-next-line: deprecation
                this.translate.get('GENERAL.MIN2').subscribe((resp: string) => {
                  val.int = '(' + val.intervalCycle + ' ' + resp + ')';
                });
              } else {
                // tslint:disable-next-line: deprecation
                this.translate.get('GENERAL.MINS').subscribe((resp: string) => {
                  val.int = '(' + val.intervalCycle + ' ' + resp + ')';
                });
              }
            }
            val.intervalDurations = '-';
          } else {
            val.int = '';
            val.intervalCycle = '-';

            if (+val.runReason === 0) {
              //
            } else if (+val.runReason === 1) {
              // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.MIN2').subscribe((resp: string) => {
                val.int = '(' + val.runReason + ' ' + resp + ')';
              });
            } else {
              // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.MINS').subscribe((resp: string) => {
                val.int = '(' + val.runReason + ' ' + resp + ')';
              });
            }
          }

          val.expiryTarget = new Date(val.expiryTarget.toString().replace('T', ' ') + ' UTC').toLocaleString('en-US', this.doptions);
          val.reminderCreated = new Date(val.reminderCreated.toString().replace('T', ' ') + ' UTC').toLocaleString('en-US', this.doptions);
          if (new Date(val.lastReminderGenerated).getFullYear() === 1) {
            val.lastReminderGenerated = '-';
          } else {
            val.lastReminderGenerated = new Date(val.lastReminderGenerated.toString().replace('T', ' ') + ' UTC')
            .toLocaleString('en-US', this.doptions);
          }
          val.expiration = new Date(new Date(val.reminderCreated).getTime() + (+val.reminderExpiry * 60000))
          .toLocaleString('en-US', this.doptions);
          this.types.push(val);
        }
      } else {
        this.disableLoadMore = true;
        if (this.page === 1) {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.NOREMINDERS').subscribe((trans) => {
            this.showInfo(trans);
          });
        } else {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.NOMOREREMINDERS').subscribe((trans) => {
            this.showInfo(trans);
          });
        }
      }
      this.load = false;
    }, err => this.handleError(err));
  }

  loadMore() {
    this.page += 1;
    this.getReminders();
  }

  gotoCreateReminder() {
    this.router.navigateByUrl('/create-reminder');
  }

  saveType(event, type) {
    if (event.value === 'Enable') {
      this.update('ENABLED', type);
    } else if (event.value === 'Disable') {
      this.update('DISABLED', type);
    } else if (event.value === 'Details') {
      // this.type = type;
      // this.showDetail = true;
      localStorage.setItem('reminder', JSON.stringify(type));
      this.router.navigateByUrl('/reminders/' + type.reminderNameId);
    }
    this.item = [];
  }

  update(value, type) {
    const update = new StringUpdater();
    update.Id = type.reminderNameId;
    update.Values = [];

    const status = new KeyValue();
    status.Key = 'status';
    status.Value = value;
    update.Values.push(status);

    this.load = true;
    // tslint:disable-next-line: deprecation
    this.reminderService.putReminder(update.Id, update).subscribe((res) => {
      if (res === true) {
        if (value === 'ENABLED') {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.ENABLED').subscribe((trans) => {
            this.showInfo(trans);
          });
        } else {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.DISABLED').subscribe((trans) => {
            this.showInfo(trans);
          });
        }
        this.types = [];
        this.page = 1;
        this.getReminders();
      } else {
        this.load = false;
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.COULDNOTUPDATE').subscribe((trans) => {
          this.showError(trans);
        });
      }
    }, err => this.handleError(err));
  }

  showSuccess(msg) {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.SUCCESS').subscribe((trans) => {
      this.messageService.add({key: 'tc', severity: 'success', summary: trans, detail: msg});
    });
  }

  showInfo(msg) {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.INFO').subscribe((trans) => {
      this.messageService.add({key: 'tc', severity: 'info', summary: trans, detail: msg});
    });
  }

  showWarn(msg) {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.WARNING').subscribe((trans) => {
      this.messageService.add({key: 'tc', severity: 'warn', summary: trans, detail: msg});
    });
  }

  showError(msg) {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.ERROR').subscribe((trans) => {
      this.messageService.add({key: 'tc', severity: 'error', summary: trans, detail: msg});
    });
  }

  handleError(err) {
    this.load = false;
    console.log(err);
    this.item = [];
  }

}
