import { ConfirmationService } from 'primeng/api';
import { Notification } from './../../Models/Notification';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsServiceService } from 'src/app/services/notifications-service/notifications-service.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { CookieService } from 'ngx-cookie-service';
import { Location } from '@angular/common';
import { TasksService } from 'src/app/services/tasks/tasks.service';
import * as CryptoJS from 'crypto-js';
import { DevicesService } from 'src/app/services/devices/devices.service';
import { UsersgroupsService } from 'src/app/services/usersgroups/usersgroups.service';
import { KeyValue, TaskUpdater } from 'src/app/Models/Updater';

@Component({
  selector: 'app-create-task',
  templateUrl: './create-task.component.html',
  styleUrls: ['./create-task.component.css']
})
export class CreateTaskComponent implements OnInit {

  configs1 = [];
  conf1: any;

  isSelectUser = false;
  isSelectGroup = false;

  users = [];

  configs = [];
  conf: any;

  alertMessage: string;
  isCancel: boolean;
  isSend: boolean;
  isAlert: boolean;
  loading: boolean;

  MessageTitle: string;
  MessageBody: string;
  Location: string;

  islocation = false;
  not: Notification;

  display = false;
  assignee: string;
  user: any;

  isassignee = false;
  assign = false;

  isoptional = false;

  constructor(
    private router: Router,
    private notification: NotificationsServiceService,
    private notificationSvc: NotificationService,
    public translate: TranslateService,
    private configService: ConfigurationService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private location: Location,
    private tasksService: TasksService,
    private usergroupService: UsersgroupsService,
    private deviceService: DevicesService
  ) {
    this.translate.addLangs(['English', 'French']);
    this.translate.setDefaultLang('English');

    let browserLang = this.translate.getBrowserLang();

    const pass = 'ranitessarldev2019';
    const lang = cookieService.get('goepla-portal-language');
    if (lang.trim() !== '') {
      const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
      const lg = declang;
      browserLang = lg;
    }

    if (localStorage.getItem('language') !== null) {
      browserLang = localStorage.getItem('language');
    }
    this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    /*this.translate.get('GENERAL.SELECTTASKCONFIGURATION').subscribe((res: string) => {
      this.configs1.push({ label: res + ' *', value: null });
      this.conf1 = this.configs1[0];
    });*/

    this.getConfigurations();
  }

  ngOnInit() {
  }

  getConfigurations() {
    // tslint:disable-next-line: deprecation
    this.configService.getConfigurations('T').subscribe((res) => {
      if (res != null) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          this.configs.push(res[i]);
          this.configs1.push({ label: res[i].configurationName, value: res[i] });
          /*if (res[i].configurationType === 'T') {
            this.configs.push(res[i]);
            this.configs1.push({ label: res[i].configurationName, value: res[i] });
          }*/
        }
      }
    });
  }

  change() {
    if (this.conf1.locationSensitive === 'YES') {
      this.islocation = true;
    } else {
      this.islocation = false;
    }

    if (this.conf1.assigneeAtCreation === 'NOTALLOWED') {
      this.isassignee = false;
    } else {
      if (this.conf1.assigneeAtCreation === 'MANDATORY') {
        this.isoptional = false;
      } else if (this.conf1.assigneeAtCreation === 'OPTIONAL') {
        this.isoptional = true;
      }
      this.isassignee = true;
    }
  }

  onSend() {
    this.loading = true;
    this.isSend = true;

    if (this.conf1 === undefined || this.conf1.value === null) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTTASKCONFIGURATION').subscribe((res: string) => {
        this.confirm(res);
      });

      this.loading = false;
      this.isSend = false;
    } else if (this.MessageTitle == null || this.MessageTitle === '' || this.MessageTitle === ' ') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.PROVIDEMESSAGETITLE').subscribe((res: string) => {
        this.confirm(res);
      });

      this.loading = false;
      this.isSend = false;
    } else if (this.MessageBody == null || this.MessageBody === '' || this.MessageBody === ' ') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.PROVIDEMESSAGEBODY').subscribe((res: string) => {
        this.confirm(res);
      });

      this.loading = false;
      this.isSend = false;
    } else if (this.Location != null && this.Location !== undefined && this.Location.trim() === ' ') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.LOCATIONHASONLYEMPTYSPACES').subscribe((res: string) => {
        this.confirm(res);
      });
      this.loading = false;
      this.isSend = false;
    } else if ((this.user == null || this.user === undefined) && this.conf1.assigneeAtCreation === 'MANDATORY') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTASSIGNEE').subscribe((res: string) => {
        this.confirm(res);
      });
      this.loading = false;
      this.isSend = false;
    } else {
      this.assign = false;
      if (this.conf1.assigneeAtCreation === 'MANDATORY') {
        this.assign = true;
      } else if (this.conf1.assigneeAtCreation === 'OPTIONAL' && this.user != null && this.user !== undefined) {
        this.assign = true;
      }

      const notif = new Notification();
      notif.NotificationId = '';
      notif.ConfigurationId = this.conf1.configurationId;
      notif.MessageTitle = this.MessageTitle;
      notif.MessageBody = this.MessageBody;
      notif.Location = this.Location;

      const now = new Date();
      notif.Created = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours() + 1,
                                + now.getMinutes(), now.getSeconds());
      notif.Expires = new Date(notif.Created.getFullYear(), notif.Created.getMonth(), notif.Created.getDate(),
                            + notif.Created.getHours() + 5, notif.Created.getMinutes(), notif.Created.getSeconds());

      this.not = notif;
      if (this.conf1.queueSize === null || this.conf1.queueSize === 'null') {
        this.create(notif);
      } else {
        this.checkQueueSize(notif);
      }
    }
  }

  create(notif) {
    // tslint:disable-next-line: deprecation
    this.notification.postNotification(notif).subscribe((res) => {
      if (this.assign === true) {
        const values = new TaskUpdater();
        values.Id = res;
        values.Values = [];

        const id = new KeyValue();
        id.Key = 'id';
        id.Value = this.user.userId.toString();
        values.Values.push(id);

        const name = new KeyValue();
        name.Key = 'name';
        name.Value = this.user.firstName + ' ' + this.user.lastName;
        values.Values.push(name);

        const assigned = new KeyValue();
        assigned.Key = 'assigned';
        assigned.Value = '';
        values.Values.push(assigned);

    // tslint:disable-next-line: deprecation
        this.tasksService.putTask(values.Id, values).subscribe(() => {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.NOTIFICATIONCREATED').subscribe((resp: string) => {
            this.notificationSvc.info('', resp, 2000);
          });
          // this.router.navigateByUrl('/tasks');
          this.location.back();
          this.isSend = false;
          this.loading = false;
        }, err => {
          this.handleError(err);
        });
      } else {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.NOTIFICATIONCREATED').subscribe((resp: string) => {
          this.notificationSvc.info('', resp, 2000);
        });
        // this.router.navigateByUrl('/tasks');
        this.location.back();
        this.isSend = false;
        this.loading = false;
      }
    }, err => {
      this.handleError(err);
    });
  }

  checkQueueSize(notif) {
    let count = 0;
    let counter = 1;
    const qs = +this.conf1.queueSize;
    this.notification.getNotifications(0, 0, ' ', this.conf1.configurationId, 'T', ' ', ' ', 0, 0, ' ',
    // tslint:disable-next-line: deprecation
                                        0, ' ', ' ', ' ', ' ').subscribe((res) => {
      if (res != null) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          // tslint:disable-next-line: deprecation
          this.tasksService.getTaskById(res[i].notificationId).subscribe((resp) => {
            if (resp.notificationId !== null) {
              if (new Date(resp.closed).getFullYear() === 1) {
                count += 1;
              }

              if (res.length === counter) {
                this.create2(count, qs, notif);
              }
              counter += 1;
            }
          });
        }
      } else {
        if (qs === 0) {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.QUEUELIMIT1').subscribe((res1: string) => {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.TQUEUELIMIT2').subscribe((res2: string) => {
              this.confirm(res1 + ' ' + 0 + ' ' + res2);
            });
          });
          this.isSend = false;
          this.loading = false;
        } else {
          this.create(notif);
        }
      }
    });
  }

  create2(count, qs, notif) {
    if (count >= qs) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.QUEUELIMIT1').subscribe((res1: string) => {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.TQUEUELIMIT2').subscribe((res2: string) => {
          this.confirm(res1 + ' ' + count + ' ' + res2);
        });
      });
      this.isSend = false;
      this.loading = false;
    } else {
      this.create(notif);
    }
  }

  onCancel() {
    this.loading = true;
    this.isCancel = true;
    // this.router.navigateByUrl('/tasks');
    this.location.back();
  }

  async showUsers() {
    if (this.conf1 === undefined || this.conf1 === null) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTTASKCONFIGURATION').subscribe((res: string) => {
        this.confirm(res);
      });
    } else {
      this.loading = true;
      await this.getUsers();
      this.loading = false;
    }
  }

  async getUsers() {
    this.users = [];
    const us = [];
    let count = 0;
    // this.username = '';
    if (this.conf1.usrGrp != null && this.conf1.usrGrp.length > 0) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < this.conf1.usrGrp.length; i++) {
        if (this.conf1.usrGrp[i].idType === 'U') {
          if (!us.includes(this.conf1.usrGrp[i].name)) {
            this.users.push({ label: this.conf1.usrGrp[i].name, value: this.conf1.usrGrp[i] });
            us.push(this.conf1.usrGrp[i].name);
          }

          count += 1;
          if (count === this.conf1.usrGrp.length) {
            if (this.users.length > 0) {
              await this.showDialog();
            } else {
              // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.NOUSERSFOUND').subscribe((resp) => {
                this.confirm(resp);
              });
            }
          }
        }
        if (this.conf1.usrGrp[i].idType === 'G') {
          const res = await this.usergroupService.getUserGroups(this.conf1.usrGrp[i].id).toPromise()
          .catch(err => this.handleError(err));

          let counter = 0;
          if (res != null) {
            // tslint:disable-next-line: prefer-for-of
            for (let j = 0; j < res.length; j++) {
              // tslint:disable-next-line: prefer-const
              let usr: any;
              res[j].id = res[j].userId;
              res[j].name = res[j].firstName + ' ' + res[j].lastName;
              res[j].idType = 'U';
              if (!us.includes(res[j].name)) {
                this.users.push({ label: res[j].name, value: res[j] });
                us.push(res[j].name);
              }

              counter += 1;
              if (counter === res.length) {
                count += 1;
                if (count === this.conf1.usrGrp.length) {
                  if (this.users.length > 0) {
                    await this.showDialog();
                  } else {
                    // tslint:disable-next-line: deprecation
                    this.translate.get('GENERAL.NOUSERSFOUND').subscribe((resp) => {
                      this.confirm(resp);
                    });
                  }
                }
              }
            }
          } else {
            count += 1;
            if (count === this.conf1.usrGrp.length) {
              await this.showDialog();
              if (this.users.length > 0) {
                await this.showDialog();
              } else {
                // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.NOUSERSFOUND').subscribe((resp) => {
                this.confirm(resp);
              });
            }
            }
          }
        }
      }
    } else {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.NOUSERSFOUND').subscribe((res) => {
        this.confirm(res);
      });
    }
  }

  async showDialog() {
    let count = 0;
    const us = [];
    if (this.users.length === 0) {
      this.display = true;
      // tslint:disable-next-line: deprecation
      this.translate.get('NOUSERS').subscribe((res) => {
        this.confirm(res);
      });
    } else {
      for (const usr of this.users) {
        const res = await this.deviceService.getDevices(1, 1, ' ', ' ', usr.value.id, usr.value.name).toPromise()
        .catch(err => this.handleError(err));
        if (res != null) {
          const dt = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(),
                                new Date().getHours(), new Date().getMinutes() - 3, new Date().getSeconds());
          const lb = new Date(res[0].lastHeartBeat);
          // const lhb = new Date(lb.getUTCFullYear(), lb.getUTCMonth(), lb.getUTCDate(),
          //                    lb.getUTCHours(), lb.getUTCMinutes(), lb.getUTCSeconds());
          const lhb = new Date(new Date(res[0].lastHeartBeat).toLocaleString() + ' UTC');
          if (lhb < dt) {
            usr.value.color = 'Red';
          } else {
            usr.value.color = 'Green';
          }
        } else {
          usr.value.color = 'Red';
        }
        us.push(usr);
        count += 1;

        if (this.users.length === count) {
          us.sort((a, b) => a.value.name.localeCompare(b.value.name));
          this.users = us;
          this.display = true;
        }
      }
    }
  }

  setUser(user) {
    this.assignee = user.firstName + ' ' + user.lastName;
    this.user = user;
    this.display = false;
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  handleError(err) {
    if (err.error.Message === 'Location not set') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.PROVIDELOCATION').subscribe((res: string) => {
        this.confirm(res);
      });
    }

    if (err.error.Message === 'Same location limit reached') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SAMELOCATIONLIMITREACHED').subscribe((res: string) => {
        this.confirm(res);
      });
    }

    if (err.error.Message === 'Queue size limit reached') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.QUEUELIMITREACHED').subscribe((res: string) => {
        this.confirm(res);
      });
    }

    this.isSend = false;
    this.loading = false;
  }
}
