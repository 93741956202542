import { ChannelTaskType } from './channel-task-type';
import { TypeRole } from './role';

export class Channel {
    public ChannelId!: string;
    public ChannelName!: string;
    public ChannelIcon!: string;
    public OrganisationName!: string;
    public RoleGroups: TypeRoleGroupGuid[];
    public TypeRoleGroups: TypeRoleGroupInfoGuid[];
    public NotificationTypes: ChannelTaskType[];
}

export class TypeRoleGroupGuid {
    public RoleParentType: string;
    public RoleName: string;
    public ParentId: string;
    public GroupId: string;
}

export class TypeRoleGroupInfoGuid {
    public TypeRoleGroup: TypeRoleGroupGuid;
    public TypeRole: TypeRole;
}

export class TypeRoleGroupDataGuid {
    public RoleParentType: string;
    public ParentId: string;
    public TypeRoleGroup: TypeRoleGroupGuid[];
}
