import { ConfirmationService } from 'primeng/api';
import { UsersgroupsService } from './../../services/usersgroups/usersgroups.service';
import { ConfigurationService } from './../../services/configuration/configuration.service';
import { Organisation } from './../../Models/Organisation';
import { Group } from './../../Models/Group';
import { User } from './../../Models/User';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormControl } from '@angular/forms';
import { UsrGrp } from 'src/app/Models/UsrGrp';
import { Configuration } from 'src/app/Models/Configuration';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { CookieService } from 'ngx-cookie-service';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-create-configuration',
  templateUrl: './create-configuration.component.html',
  styleUrls: ['./create-configuration.component.css']
})
export class CreateConfigurationComponent implements OnInit {
  // users = new FormControl();
  userList = [];
  users: any;

  // groups = new FormControl();
  groupList = [];
  groups: any;

  // organisations: Organisation[] = [];

  organisations = [];
  organisation: any;

  types = [];
  type: any;

  priorities = [];
  priority: any;

  vibrations = [];
  vibration: any;

  channels = [];
  channel: any;

  Organisation = 0;
  MessageType: string;
  ConfigurationName: string;
  ConfigurationType: string;
  Priority: number;
  TimeUntilExpiry: number;
  Color: string;
  Vibration: number;
  Channel: string;
  TaskReactionTimeLimit: number;
  TaskStartTimeLimit: number;
  TaskClosureTimeLimit: number;

  usrgrp: UsrGrp[] = [];

  isTask = false;

  isCreate = false;
  loading = false;

  OrgName: string;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private configService: ConfigurationService,
    private notificationSvc: NotificationService,
    private usersgroupsService: UsersgroupsService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService
  ) {
    // this.translate.addLangs(['English', 'French']);
    // this.translate.setDefaultLang('English');

    // let browserLang = this.translate.getBrowserLang();

    // const pass = 'ranitessarldev2019';
    // const lang = cookieService.get('goepla-portal-language');
    // if (lang.trim() !== '') {
    //   const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
    //   const lg = declang;
    //   browserLang = lg;
    // }

    // if (localStorage.getItem('language') !== null) {
    //   browserLang = localStorage.getItem('language');
    // }
    // this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    translate.use('English');

    translate.get('GENERAL.INFORMATION').subscribe((res1) => {
      this.types.push({label: res1, value: 'I'});
      translate.get('GENERAL.TASK').subscribe((res2) => {
        this.types.push({label: res2, value: 'T'});
      });
    });

    translate.get('GENERAL.CRITICAL').subscribe((res1) => {
      this.priorities.push({label: res1, value: 1});
      translate.get('GENERAL.HIGH').subscribe((res2) => {
        this.priorities.push({label: res2, value: 2});
        translate.get('GENERAL.NORMAL').subscribe((res3) => {
          this.priorities.push({label: res3, value: 3});
          translate.get('GENERAL.LOW').subscribe((res4) => {
            this.priorities.push({label: res4, value: 4});
            translate.get('GENERAL.VERYLOW').subscribe((res5) => {
              this.priorities.push({label: res5, value: 5});
            });
          });
        });
      });
    });

    translate.get('GENERAL.NONE').subscribe((res1) => {
      this.vibrations.push({label: res1, value: 0});
      translate.get('GENERAL.SHORT').subscribe((res2) => {
        this.vibrations.push({label: res2, value: 1});
        translate.get('GENERAL.LONG').subscribe((res3) => {
          this.vibrations.push({label: res3, value: 2});
          translate.get('GENERAL.VERYLONG').subscribe((res4) => {
            this.vibrations.push({label: res4, value: 3});
          });
        });
      });
    });

    translate.get('GENERAL.MQTT').subscribe((res1) => {
      this.channels.push({label: res1, value: 'M'});
      translate.get('GENERAL.SMS').subscribe((res2) => {
        this.channels.push({label: res2, value: 'S'});
        translate.get('GENERAL.EMAIL').subscribe((res3) => {
          this.channels.push({label: res3, value: 'E'});
        });
      });
    });

    this.getUsers();
    this.getGroups();
    this.getOrganisation();
   }

  ngOnInit() {
  }

  getUsers() {
    this.usersgroupsService.getUsers().subscribe((res) => {
      if (res != null) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          res[i].UserName = res[i].firstName + ' ' + res[i].lastName;
          this.userList.push({label: res[i].UserName, value: res[i]});
        }
      }
    });
  }

  getGroups() {
    this.usersgroupsService.getGroups().subscribe((res) => {
      if (res != null) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          res[i].GroupName = res[i].groupName;
          this.groupList.push({label: res[i].GroupName, value: res[i]});
        }
      }
    });
  }

  getOrganisation() {
    this.usersgroupsService.getOrganisations().subscribe((res) => {
      if (res != null) {
        this.organisation = res;
        this.OrgName = res.organisationName;
        // tslint:disable-next-line: prefer-for-of
        /*for (let i = 0; i < res.length; i++) {
          this.organisations.push({label: res[i].organisationName, value: res[i]});
        }*/
      }
    });
  }

  selectedMessageType() {
    if (this.type === 'I') {
      this.isTask = false;
    } else if (this.type === 'T') {
      this.isTask = true;
    }
  }

  selectedPriority(event) {

  }

  selectedColor(event) {

  }

  selectedVibration(event) {

  }

  selectedChannel(event) {

  }

  onCreate() {
    if (this.organisation === undefined || this.organisation === null) {
      this.translate.get('GENERAL.SELECTORGANISATION').subscribe((res: string) => {
        this.confirm(res);
      });
      return;
    }
    if (this.type === undefined || this.type === null || this.type.toString().trim() === null || this.type.toString().trim() === '') {
      this.translate.get('GENERAL.SELECTMESSAGETYPE').subscribe((res: string) => {
        this.confirm(res);
      });
      return;
    }
    if (this.ConfigurationName === undefined || this.ConfigurationName.toString().trim() === null
        || this.ConfigurationName.toString().trim() === ''
        || this.ConfigurationName.toString().trim() === ' ') {
      this.translate.get('GENERAL.ENTERCONFIGURATIONNAME').subscribe((res: string) => {
        this.confirm(res);
      });
      return;
    }
    if (this.priority === undefined || this.priority.toString().trim() === null) {
      this.translate.get('GENERAL.SELECTPRIORITY').subscribe((res: string) => {
        this.confirm(res);
      });
      return;
    }
    if (this.TimeUntilExpiry === undefined || this.TimeUntilExpiry.toString().trim() === null
        || this.TimeUntilExpiry.toString().trim() === '') {
      this.translate.get('GENERAL.ENTERTIMEUNTILEXPIRY').subscribe((res: string) => {
        this.confirm(res);
      });
      return;
    } else {
      if (isNaN(this.TimeUntilExpiry)) {
        this.translate.get('GENERAL.TIMEUNTILEXPIRYCHECK').subscribe((res: string) => {
          this.confirm(res);
        });
        return;
      }
    }
    if (this.Color === undefined || this.Color.toString().trim() === null) {
      this.translate.get('GENERAL.SELECTCOLOR').subscribe((res: string) => {
        this.confirm(res);
      });
      return;
    }
    if (this.vibration === undefined || this.vibration.toString().trim() === null) {
      this.translate.get('GENERAL.SELECTVIBRATEOPTION').subscribe((res: string) => {
        this.confirm(res);
      });
      return;
    }
    if (this.channel === undefined || this.channel.toString().trim() === null) {
      this.translate.get('GENERAL.SELECTCHANNEL').subscribe((res: string) => {
        this.confirm(res);
      });
      return;
    }
    if (this.isTask === true) {
      if (this.TaskReactionTimeLimit === undefined || this.TaskReactionTimeLimit.toString().trim() === null
          || this.TaskReactionTimeLimit.toString().trim() === '') {
        this.translate.get('GENERAL.ENTERTASKREACTIONTIMELIMIT').subscribe((res: string) => {
          this.confirm(res);
        });
        return;
      } else {
        if (isNaN(this.TaskReactionTimeLimit)) {
          this.translate.get('GENERAL.TASKREACTIONTIMELIMITCHECK').subscribe((res: string) => {
            this.confirm(res);
          });
          return;
        }
      }
      if (this.TaskStartTimeLimit === undefined || this.TaskStartTimeLimit.toString().trim() === null
          || this.TaskStartTimeLimit.toString().trim() === '') {
        this.translate.get('GENERAL.ENTERTASKSTARTTIMELIMIT').subscribe((res: string) => {
          this.confirm(res);
        });
        return;
      } else {
        if (isNaN(this.TaskStartTimeLimit)) {
          this.translate.get('GENERAL.TASKSTARTTIMELIMITCHECK').subscribe((res: string) => {
            this.confirm(res);
          });
          return;
        }
      }
      if (this.TaskClosureTimeLimit === undefined || this.TaskClosureTimeLimit.toString().trim() === null
          || this.TaskClosureTimeLimit.toString().trim() === '') {
        this.translate.get('GENERAL.ENTERTASKCLOSURETIMELIMIT').subscribe((res: string) => {
          this.confirm(res);
        });
        return;
      } else {
        if (isNaN(this.TaskClosureTimeLimit)) {
          this.translate.get('GENERAL.TASKCLOSURELIMITCHECK').subscribe((res: string) => {
            this.confirm(res);
          });
          return;
        }
      }
    } else {
      this.TaskReactionTimeLimit = 0;
      this.TaskStartTimeLimit = 0;
      this.TaskClosureTimeLimit = 0;
    }

    this.usrgrp = [];
    if (this.users !== undefined || this.users != null) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < this.users.length; i++) {
        const usr = new UsrGrp();
        usr.Id = this.users[i].userId;
        usr.IdType = 'U';
        usr.Name = this.users[i].UserName;
        this.usrgrp.push(usr);
      }
    }

    if (this.groups !== undefined || this.groups != null) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < this.groups.length; i++) {
        const grp = new UsrGrp();
        grp.Id = this.groups[i].groupId;
        grp.IdType = 'G';
        grp.Name = this.groups[i].GroupName;
        this.usrgrp.push(grp);
      }
    }

    this.isCreate = true;
    this.loading = true;

    const config = new Configuration();
    config.OrganisationId = this.organisation.organisationId;
    config.ConfigurationId = 0;
    config.ConfigurationName = this.ConfigurationName;
    config.ConfigurationType = this.type;
    config.Priority = this.priority;
    config.ExpiryTime = this.TimeUntilExpiry;
    config.Color = this.Color;
    config.Vibrate = this.vibration;
    config.Channel = this.channel;
    config.ReactionTimeLimit = this.TaskReactionTimeLimit;
    config.StartTimeLimit = this.TaskStartTimeLimit;
    config.CloseTimeLimit = this.TaskClosureTimeLimit;
    config.UsrGrp = this.usrgrp;

    this.configService.postConfiguration(config).subscribe(() => {
      this.translate.get('GENERAL.CONFIGURATIONCREATED').subscribe((res: string) => {
        this.notificationSvc.info('', res, 2000);
      });
      this.router.navigateByUrl('/configurations');
      this.isCreate = false;
      this.loading = false;
    }, err => {
      this.handleError(err);
    });
  }

  onCancel() {
    this.router.navigateByUrl('/configurations');
  }

  selectedOrganisation(event) {
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  handleError(err) {
    this.isCreate = false;
    this.loading = false;
    if (err.error.MessageText === 'duplicate key value violates unique constraint "t_ntm_ncfg_org_id_ncfg_name_key"') {
      this.translate.get('GENERAL.CONFIGURATIONALREADYEXISTS').subscribe((res: string) => {
        alert(res);
      });
    }
  }

}
