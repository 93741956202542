import { InfoUserService } from './../../services/info-user/info-user.service';
import { ConfirmationService } from 'primeng/api';
import { Router } from '@angular/router';
import { NotificationsServiceService } from './../../services/notifications-service/notifications-service.service';
import { Component, OnInit, HostListener } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import * as CryptoJS from 'crypto-js';
import { Variables } from 'src/app/Models/variables';
import { NotLogService } from 'src/app/services/not-log/not-log.service';
import { Dropdown } from 'primeng/dropdown';
import { NotificationReads } from 'src/app/Models/NotificationReads';
import { NotReadService } from 'src/app/services/not-read/not-read.service';
import { NotificationFeedback } from 'src/app/Models/notification-feedback';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { CommentService } from 'src/app/services/comment/comment.service';
import { NotificationComment } from 'src/app/Models/notification-comment';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {

  config: any;
  isConfigVis = false;

  isDate = false;
  isNotDate = false;

  isVis = false;
  notif: any;

  invalidDate = false;

  date1 = new FormControl(new Date());
  date2 = new FormControl(new Date());
  serializedDate = new FormControl((new Date()).toISOString());

  notifications = [];

  isLoad: boolean;
  isLoadMore: boolean;

  page = 1;
  pager = 0;
  size = 100;

  selected: string;
  Filter: string;

  isNone: boolean;
  isNotId: boolean;
  isPostMsg: boolean;
  isTarget: boolean;
  isRange: boolean;

  first = true;

  notId: string;
  confId: number;
  type: string;
  start: string;
  end: string;
  read: number;

  options = [];

  filters = [];
  filter: any;

  range: any;

  ismanage = false;

  notlogs = [];
  ishistory = false;

  loading = false;

  infousers = [];
  isinfousers = false;

  MessageTitle: string;
  MessageBody: string;
  TargetType: string;
  Created: string;
  Expires: string;
  Read: string;

  notif2: any;

  createdby = 0;
  toread = 0;
  alreadyread = 0;
  readuser = 0;

  iscomments = false;
  mynot: any;

  comments = [];
  comment: any;

  likes = [];
  islikes = false;

  isadm = false;

  constructor(
    private notification: NotificationsServiceService,
    private router: Router,
    private notificationSvc: NotificationService,
    public translate: TranslateService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private notlogService: NotLogService,
    private infouserService: InfoUserService,
    private notreadService: NotReadService,
    private notFeedbackService: FeedbackService,
    private commentService: CommentService
  ) {
    const v = new Variables();
    this.translate.addLangs(['English', 'French']);
    this.translate.setDefaultLang('English');

    let browserLang = this.translate.getBrowserLang();

    const pass = 'ranitessarldev2019';
    const lang = cookieService.get('goepla-portal-language');
    if (lang.trim() !== '') {
      const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
      const lg = declang;
      browserLang = lg;
      localStorage.setItem('language', lg);
    }

    if (localStorage.getItem('language') !== null) {
      browserLang = localStorage.getItem('language');
    }
    this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.NONE').subscribe((resp1: string) => {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.NOTIFICATIONID').subscribe((resp2: string) => {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.CREATEDRANGE').subscribe((resp3: string) => {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.CREATEDBYME').subscribe((resp4: string) => {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.TOREAD').subscribe((resp5: string) => {
              // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.READ').subscribe((resp6: string) => {
                this.filters = [
                  { label: resp1, value: 'none' },
                  { label: resp2, value: 'not_id' },
                  { label: resp3, value: 'time' },
                  { label: resp4, value: 'createdbyme' },
                  { label: resp5, value: 'toread' },
                  { label: resp6, value: 'read' }
                ];
                this.filter = this.filters[0].value;
              });
            });
          });
        });
      });
    });

    this.selected = 'none';
    this.isNone = true;
    this.isNotId = false;
    this.isPostMsg = false;
    this.isTarget = false;
    this.isRange = false;

    this.notId = ' ';
    this.confId = 0;
    this.type = ' ';
    this.start = ' ';
    this.end = ' ';
    this.read = 0;

    if (localStorage.getItem('not-selected') === null) {

    } else {
      const sel = localStorage.getItem('not-selected');
      const filt = localStorage.getItem('not-filter');
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.NONE').subscribe((resp1: string) => {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.NOTIFICATIONID').subscribe((resp2: string) => {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.CREATEDRANGE').subscribe((resp3: string) => {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.CREATEDBYME').subscribe((resp4: string) => {
              // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.TOREAD').subscribe((resp5: string) => {
                // tslint:disable-next-line: deprecation
                this.translate.get('GENERAL.READ').subscribe((resp6: string) => {
                  this.filters = [
                    { label: resp1, value: 'none' },
                    { label: resp2, value: 'not_id' },
                    { label: resp3, value: 'time' },
                    { label: resp4, value: 'createdbyme' },
                    { label: resp5, value: 'toread' },
                    { label: resp6, value: 'read' }
                  ];
                  this.filter = this.filters[0].value;

                  // tslint:disable-next-line: prefer-for-of
                  for (let i = 0; i < this.filters.length; i++) {
                    if (this.filters[i].value === sel) {
                      this.filter = this.filters[i].value;
                    }
                  }
                });
              });
            });
            // this.filters = [
            //   { label: resp1, value: 'none' },
            //   { label: resp2, value: 'not_id' },
            //   { label: resp3, value: 'time' }
            // ];
            // this.filter = this.filters[0].value;

            // // tslint:disable-next-line: prefer-for-of
            // for (let i = 0; i < this.filters.length; i++) {
            //   if (this.filters[i].value === sel) {
            //     this.filter = this.filters[i].value;
            //   }
            // }
          });
        });
      });
      this.selected = sel;
      if (sel === 'none') {
        this.isNone = true;
        this.isNotId = false;
        this.isPostMsg = false;
        this.isTarget = false;
        this.isRange = false;
      } else if (sel === 'not_id') {
        this.notId = filt;
        this.isNotId = true;
        this.isNone = false;
        this.isPostMsg = false;
        this.isTarget = false;
        this.isRange = false;
      } else if (sel === 'conf_id') {
        this.confId = +filt;
        this.isPostMsg = true;
        this.isNotId = false;
        this.isNone = false;
        this.isTarget = false;
        this.isRange = false;
      } else if (sel === 'type') {
        this.type = filt;
        this.isTarget = true;
        this.isPostMsg = false;
        this.isNotId = false;
        this.isNone = false;
        this.isRange = false;
      } else if (sel === 'time') {
        const split = filt.split(';');
        this.start = split[0];
        this.end = split[1];

        this.date1.setValue(new Date(this.start));
        this.date2.setValue(new Date(this.end));

        this.range = [new Date(this.start), new Date(this.end)];

        this.isRange = true;
        this.isTarget = false;
        this.isPostMsg = false;
        this.isNotId = false;
        this.isNone = false;
      } else if (this.selected === 'createdbyme' || this.selected === 'toread'
      || this.selected === 'read') {
        this.isNone = false;
        this.isNotId = false;
        this.isPostMsg = false;
        this.isTarget = false;
        this.isRange = false;

        const ui = localStorage.getItem('ui');
        const val = CryptoJS.AES.decrypt(ui, v.pass).toString(CryptoJS.enc.Utf8);
        if (this.selected === 'createdbyme') {
          this.createdby = val;
          this.toread = 0;
          this.alreadyread = 0;
          this.readuser = 0;
        } else if (this.selected === 'toread') {
          this.createdby = 0;
          this.toread = 1;
          this.alreadyread = 0;
          this.readuser = val;
        } else if (this.selected === 'read') {
          this.createdby = 0;
          this.toread = 0;
          this.alreadyread = 1;
          this.readuser = val;
        }
      }
    }

    const p = localStorage.getItem('ups');
    if (p != null) {
      const val = CryptoJS.AES.decrypt(p, v.pass).toString(CryptoJS.enc.Utf8);
      const perm = JSON.parse(val);
      if (perm.includes('NTM.Information.Manage') || perm.includes('NTM.Administration.Full')) {
        this.ismanage = true;
      }
      if (perm.includes('NTM.Administration.Full')) {
        this.isadm = true;
      }
    }

    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.DETAILS').subscribe((resp1: string) => {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.OPENCONFIG').subscribe((resp2: string) => {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.DELETE').subscribe((resp3: string) => {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.HISTORY').subscribe((resp4: string) => {
            this.options = [
              { label: resp1, value: 'Details' },
              { label: resp2, value: 'OpenConfig' }
            ];
            if (this.ismanage === true) {
              this.options.push({ label: resp3, value: 'Delete' });
              this.options.push({ label: resp4, value: 'History' });
            }
          });
        });
      });
    });

    this.getNotifications(this.page, this.size);
   }

  ngOnInit() {
    localStorage.removeItem('notif');
    localStorage.removeItem('notification');
  }

  getNotifications(page: number, size: number) {
    this.isLoad = true;
    const v = new Variables();
    const phr = localStorage.getItem('phr');
    const phone = CryptoJS.AES.decrypt(phr, v.pass).toString(CryptoJS.enc.Utf8);

    const va = localStorage.getItem('ui');
    const ui = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
    const uid = +ui;

    const gis = localStorage.getItem('gi');
    const gs = CryptoJS.AES.decrypt(gis, v.pass).toString(CryptoJS.enc.Utf8);
    const gids = JSON.parse(gs);
    let g = '';
    for (const id of gids) {
      g += id + ',';
    }

    this.notification.getInformationGeneric(page, size, this.notId, this.confId, 'I', this.start, this.end
    // tslint:disable-next-line: deprecation
    , this.read, 0, ' ', 0, ' ', ' ', ' ', ' ', this.createdby, this.toread, this.alreadyread,
    this.readuser, phone, g, uid).subscribe((res) => {
      if (res == null) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.NONOTIFICATIONSFOUND').subscribe((resp: string) => {
          this.notificationSvc.info('', resp, 2000);
          this.isLoadMore = true;
          });
      } else {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          if (res[i].configuration.typeRoleGroups !== null && res[i].configuration.typeRoleGroups.length > 0) {
            let show = false;
            for (const val of res[i].configuration.typeRoleGroups) {
              if (val.typeRoleGroup.roleName === 'Expert-All' && gids.includes(val.typeRoleGroup.groupId)) {
                show = true;
              }
            }

            if (this.isadm === true) {
              show = true;
            }

            if (show === false) {
              continue;
            }
          } else {
            if (this.isadm === true) {
            } else {
              continue;
            }
          }

          res[i].readconf = 0;
          if (res[i].infoUserCount === 0) {
            res[i].readconf = '';
          } else {
            if (res[i].readInfoUserCount === 0) {
              res[i].readconf = '0%';
            } else {
              const perc =
               Math.floor(Math.round((((res[i].readInfoUserCount / res[i].infoUserCount ) * 100) + Number.EPSILON) * 100) / 100);
              res[i].readconf = perc + '%';
            }
          }
          this.options = [];
          this.translate.get('GENERAL.DETAILS').subscribe((resp1: string) => {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.OPENCONFIG').subscribe((resp2: string) => {
              // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.DELETE').subscribe((resp3: string) => {
                // tslint:disable-next-line: deprecation
                this.translate.get('GENERAL.HISTORY').subscribe((resp4: string) => {
                  this.options = [
                    { label: resp1, value: 'Details', command: async (event) => { await this.saveNotif(event, res[i], null); } },
                    { label: resp2, value: 'OpenConfig', command: async (event) => { await this.saveNotif(event, res[i], null); } }
                  ];
                  if (this.ismanage === true) {
                    this.options.push({ label: resp3, value: 'Delete',
                     command: async (event) => { await this.saveNotif(event, res[i], null); } });
                    this.options.push({ label: resp4, value: 'History',
                     command: async (event) => { await this.saveNotif(event, res[i], null); } });
                  }
                });
              });
            });
          });
          res[i].options = this.options;
          if (res[i].configuration.configurationType === 'I') {
            res[i].configuration.confType = 'Information';
          } else if (res[i].configuration.configurationType === 'T') {
            res[i].configuration.confType = 'Task';
          }
          const doptions: any = { year: 'numeric', month: 'short', day: 'numeric',
           hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false };
          res[i].created = new Date(res[i].created.toString().replace('T', ' ') + ' UTC').toLocaleString('en-US', doptions);
          res[i].expires = new Date(res[i].expires.toString().replace('T', ' ') + ' UTC').toLocaleString('en-US', doptions);
          /*res[i].created = new Date(res[i].created).toLocaleString();
          res[i].expires = new Date(res[i].expires).toLocaleString();*/
          this.notifications.push(res[i]);
        }

        if (res.length < size) {
          this.isLoadMore = true;
        } else {
          this.isLoadMore = false;
        }
      }
      this.isLoad = false;
    }, err => this.handleError(err));
  }

  loadMore() {
    if (this.isNotId === true || this.isPostMsg === true || this.isRange === true
      || this.isTarget === true) {
        this.pager += 1;
        this.load();
    } else {
      this.page += 1;
      this.isLoad = true;
      const v = new Variables();
      const phr = localStorage.getItem('phr');
      const phone = CryptoJS.AES.decrypt(phr, v.pass).toString(CryptoJS.enc.Utf8);

      const va = localStorage.getItem('ui');
      const ui = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
      const uid = +ui;

      const gis = localStorage.getItem('gi');
      const gs = CryptoJS.AES.decrypt(gis, v.pass).toString(CryptoJS.enc.Utf8);
      const gids = JSON.parse(gs);
      let g = '';
      for (const id of gids) {
        g += id + ',';
      }

      this.notification.getInformationGeneric(this.page, this.size, this.notId, this.confId, 'I', this.start, this.end
      // tslint:disable-next-line: deprecation
        , this.read, 0, ' ', 0, ' ', ' ', ' ', ' ', this.createdby, this.toread, this.alreadyread,
        this.readuser, phone, g, uid).subscribe((res) => {
        if (res == null) {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.NOMORENOTIFICATIONS').subscribe((resp: string) => {
            this.notificationSvc.info('', resp, 2000);
            this.isLoadMore = true;
            });
        } else {
          // tslint:disable-next-line: prefer-for-of
          for (let i = 0; i < res.length; i++) {
            if (res[i].configuration.typeRoleGroups !== null && res[i].configuration.typeRoleGroups.length > 0) {
              let show = false;
              for (const val of res[i].configuration.typeRoleGroups) {
                if (val.typeRoleGroup.roleName === 'Expert-All' && gids.includes(val.typeRoleGroup.groupId)) {
                  show = true;
                }
              }

              if (this.isadm === true) {
                show = true;
              }

              if (show === false) {
                continue;
              }
            } else {
              if (this.isadm === true) {
              } else {
                continue;
              }
            }

            res[i].readconf = 0;
            if (res[i].infoUserCount === 0) {
              res[i].readconf = '';
            } else {
              if (res[i].readInfoUserCount === 0) {
                res[i].readconf = '0%';
              } else {
                const perc =
                Math.floor(Math.round((((res[i].readInfoUserCount / res[i].infoUserCount ) * 100) + Number.EPSILON) * 100) / 100);
                res[i].readconf = perc + '%';
              }
            }
            this.options = [];
            this.translate.get('GENERAL.DETAILS').subscribe((resp1: string) => {
              // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.OPENCONFIG').subscribe((resp2: string) => {
                // tslint:disable-next-line: deprecation
                this.translate.get('GENERAL.DELETE').subscribe((resp3: string) => {
                  // tslint:disable-next-line: deprecation
                  this.translate.get('GENERAL.HISTORY').subscribe((resp4: string) => {
                    this.options = [
                      { label: resp1, value: 'Details', command: async (event) => { await this.saveNotif(event, res[i], null); } },
                      { label: resp2, value: 'OpenConfig', command: async (event) => { await this.saveNotif(event, res[i], null); } }
                    ];
                    if (this.ismanage === true) {
                      this.options.push({ label: resp3, value: 'Delete',
                       command: async (event) => { await this.saveNotif(event, res[i], null); } });
                      this.options.push({ label: resp4, value: 'History',
                       command: async (event) => { await this.saveNotif(event, res[i], null); } });
                    }
                  });
                });
              });
            });
            res[i].options = this.options;
            if (res[i].configuration.configurationType === 'I') {
              res[i].configuration.confType = 'Information';
            } else if (res[i].configuration.configurationType === 'T') {
              res[i].configuration.confType = 'Task';
            }
            const doptions: any = { year: 'numeric', month: 'short', day: 'numeric',
           hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false };
            res[i].created = new Date(res[i].created.toString().replace('T', ' ') + ' UTC').toLocaleString('en-US', doptions);
            res[i].expires = new Date(res[i].expires.toString().replace('T', ' ') + ' UTC').toLocaleString('en-US', doptions);
            /*res[i].created = new Date(res[i].created).toLocaleString();
            res[i].expires = new Date(res[i].expires).toLocaleString();*/
            this.notifications.push(res[i]);
          }

          if (res.length < this.size) {
            this.isLoadMore = true;
          } else {
            this.isLoadMore = false;
          }
        }
        this.isLoad = false;
      }, err => this.handleError(err));
    }
  }

  selectedFilter(event) {
    this.createdby = 0;
    this.toread = 0;
    this.alreadyread = 0;
    this.readuser = 0;

    this.selected = this.filter;
    this.pager = 1;
    if (this.selected === 'none') {
      this.isNone = true;
      this.isNotId = false;
      this.isPostMsg = false;
      this.isTarget = false;
      this.isRange = false;
    } else if (this.selected === 'not_id') {
      this.isNotId = true;
      this.isNone = false;
      this.isPostMsg = false;
      this.isTarget = false;
      this.isRange = false;
      this.notId = '';
    } else if (this.selected === 'conf_id') {
      this.isPostMsg = true;
      this.isNotId = false;
      this.isNone = false;
      this.isTarget = false;
      this.isRange = false;
      this.confId = 0;
    } else if (this.selected === 'type') {
      this.isTarget = true;
      this.isPostMsg = false;
      this.isNotId = false;
      this.isNone = false;
      this.isRange = false;
    } else if (this.selected === 'time') {
      /*this.start = this.date1.value.toDateString();
      this.end = this.date2.value.toDateString();*/

      this.isRange = true;
      this.isTarget = false;
      this.isPostMsg = false;
      this.isNotId = false;
      this.isNone = false;
    } else if (this.selected === 'createdbyme' || this.selected === 'toread'
    || this.selected === 'read') {
      this.isNone = false;
      this.isNotId = false;
      this.isPostMsg = false;
      this.isTarget = false;
      this.isRange = false;

      const v = new Variables();
      const ui = localStorage.getItem('ui');
      const val = CryptoJS.AES.decrypt(ui, v.pass).toString(CryptoJS.enc.Utf8);
      if (this.selected === 'createdbyme') {
        this.createdby = val;
        this.toread = 0;
        this.alreadyread = 0;
        this.readuser = 0;
      } else if (this.selected === 'toread') {
        this.createdby = 0;
        this.toread = 1;
        this.alreadyread = 0;
        this.readuser = val;
      } else if (this.selected === 'read') {
        this.createdby = 0;
        this.toread = 0;
        this.alreadyread = 1;
        this.readuser = val;
      }
    }
  }

  ok() {
    this.isVis = false;
    this.isConfigVis = false;
    localStorage.setItem('not-selected', this.selected);
    const check = true;
    if (check !== true) {

    } else {
      /*if (this.first === true) {
        this.first = false;
        this.notifications = [];
        this.pager = 1;
      } else {
        this.pager += 1;
      }*/

      if (this.isNone === true) {
        this.notId = ' ';
        this.confId = 0;
        this.start = ' ';
        this.end = ' ';
        this.type = ' ';
        this.read = 0;
        localStorage.setItem('not-filter', '');
      } else if (this.isNotId === true) {
        if (this.notId.trim() === '') {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.ENTERNOTIFICATIONID').subscribe((res) => {
            this.confirm(res);
          });
          return;
        } else {
          this.confId = 0;
          this.start = ' ';
          this.end = ' ';
          this.type = ' ';
          this.read = 0;
          localStorage.setItem('not-filter', this.notId.toString());
        }
      } else if (this.isPostMsg === true) {
        if (this.confId === 0) {
          return;
        } else {
          this.notId = ' ';
          this.start = ' ';
          this.end = ' ';
          this.type = ' ';
          this.read = 0;
          localStorage.setItem('not-filter', this.confId.toString());
        }
      } else if (this.isTarget === true) {
        if (this.type === ' ' || this.type === '') {
          return;
        } else {
          this.notId = ' ';
          this.confId = 0;
          this.start = ' ';
          this.end = ' ';
          this.read = 0;
          localStorage.setItem('not-filter', this.type);
        }
      } else if (this.isRange === true) {
        // this.start = this.date1.value.toDateString();
        // this.end = this.date2.value.toDateString();
        if (this.range === undefined || this.range === null) {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.SELECTRANGE').subscribe((res) => {
            this.confirm(res);
          });
          return;
        } else {
          if (this.range[0] !== undefined && this.range[0] !== null) {
            this.start = this.range[0].toDateString();
          } else {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.SELECTRANGE').subscribe((res) => {
              this.confirm(res);
            });
            return;
          }
          if (this.range[1] !== undefined && this.range[1] !== null) {
            this.end = this.range[1].toDateString();
          } else {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.SELECTRANGE').subscribe((res) => {
              this.confirm(res);
            });
            return;
          }
        }

        if (this.start === ' ' || this.start === '' || this.end === ' ' || this.end === '') {
          return;
        } else {
            if (this.invalidDate === true) {
              // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.INVALIDDATEFORMAT').subscribe((resp: string) => {
                this.confirm(resp);
                });
              return;
          } else {
              if (new Date(this.start) > new Date(this.end)) {
                // tslint:disable-next-line: deprecation
                this.translate.get('GENERAL.ENDDATEGREATER').subscribe((resp: string) => {
                  this.confirm(resp);
                  });
                return;
            } else {
              this.notId = ' ';
              this.confId = 0;
              this.type = ' ';
              this.read = 0;
              localStorage.setItem('not-filter', this.start + ';' + this.end);
            }
          }
        }
      }

      this.isLoad = true;
      this.page = 1;
      const v = new Variables();
      const phr = localStorage.getItem('phr');
      const phone = CryptoJS.AES.decrypt(phr, v.pass).toString(CryptoJS.enc.Utf8);

      const va = localStorage.getItem('ui');
      const ui = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
      const uid = +ui;

      const gis = localStorage.getItem('gi');
      const gs = CryptoJS.AES.decrypt(gis, v.pass).toString(CryptoJS.enc.Utf8);
      const gids = JSON.parse(gs);
      let g = '';
      for (const id of gids) {
        g += id + ',';
      }

      this.notification.getInformationGeneric(this.page, this.size, this.notId,
        this.confId, 'I', this.start, this.end, this.read, 0, ' ',
        // tslint:disable-next-line: deprecation
        0, ' ', ' ', ' ', ' ', this.createdby, this.toread, this.alreadyread,
        this.readuser, phone, g, uid).subscribe((res) => {
          this.notifications = [];
          if (res == null) {
            this.isLoad = false;
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.NONOTIFICATIONSFOUND').subscribe((resp: string) => {
              this.notificationSvc.info('', resp, 2000);
              this.isLoadMore = true;
            });
          } else {
            // tslint:disable-next-line: prefer-for-of
            for (let i = 0; i < res.length; i++) {
              if (res[i].configuration.typeRoleGroups !== null && res[i].configuration.typeRoleGroups.length > 0) {
                let show = false;
                for (const val of res[i].configuration.typeRoleGroups) {
                  if (val.typeRoleGroup.roleName === 'Expert-All' && gids.includes(val.typeRoleGroup.groupId)) {
                    show = true;
                  }
                }

                if (this.isadm === true) {
                  show = true;
                }

                if (show === false) {
                  continue;
                }
              } else {
                if (this.isadm === true) {
                } else {
                  continue;
                }
              }

              res[i].readconf = 0;
              if (res[i].infoUserCount === 0) {
                res[i].readconf = '';
              } else {
                if (res[i].readInfoUserCount === 0) {
                  res[i].readconf = '0%';
                } else {
                  const perc =
                  Math.floor( Math.round((((res[i].readInfoUserCount / res[i].infoUserCount ) * 100) + Number.EPSILON) * 100) / 100);
                  res[i].readconf = perc + '%';
                }
              }
              this.options = [];
              this.translate.get('GENERAL.DETAILS').subscribe((resp1: string) => {
                // tslint:disable-next-line: deprecation
                this.translate.get('GENERAL.OPENCONFIG').subscribe((resp2: string) => {
                  // tslint:disable-next-line: deprecation
                  this.translate.get('GENERAL.DELETE').subscribe((resp3: string) => {
                    // tslint:disable-next-line: deprecation
                    this.translate.get('GENERAL.HISTORY').subscribe((resp4: string) => {
                      this.options = [
                        { label: resp1, value: 'Details', command: async (event) => { await this.saveNotif(event, res[i], null); } },
                        { label: resp2, value: 'OpenConfig', command: async (event) => { await this.saveNotif(event, res[i], null); } }
                      ];
                      if (this.ismanage === true) {
                        this.options.push({ label: resp3, value: 'Delete',
                        command: async (event) => { await this.saveNotif(event, res[i], null); } });
                        this.options.push({ label: resp4, value: 'History',
                        command: async (event) => { await this.saveNotif(event, res[i], null); } });
                      }
                    });
                  });
                });
              });
              res[i].options = this.options;
              if (res[i].configuration.configurationType === 'I') {
                res[i].configuration.confType = 'Information';
              } else if (res[i].configuration.configurationType === 'T') {
                res[i].configuration.confType = 'Task';
              }
              const doptions: any = { year: 'numeric', month: 'short', day: 'numeric',
           hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false };
              res[i].created = new Date(res[i].created.toString().replace('T', ' ') + ' UTC').toLocaleString('en-US', doptions);
              res[i].expires = new Date(res[i].expires.toString().replace('T', ' ') + ' UTC').toLocaleString('en-US', doptions);
              /*res[i].created = new Date(res[i].created).toLocaleString();
              res[i].expires = new Date(res[i].expires).toLocaleString();*/
              this.notifications.push(res[i]);
            }

            if (res.length < this.size) {
              this.isLoadMore = true;
            } else {
              this.isLoadMore = false;
            }
          }
          this.isLoad = false;
        }, err => this.handleError(err));
    }
  }

  load() {
    if (this.isNone === true) {
      this.notId = ' ';
      this.confId = 0;
      this.start = ' ';
      this.end = ' ';
      this.type = ' ';
      this.read = 0;
    } else if (this.isNotId === true) {
      if (this.notId === null || this.notId === ' ' || this.notId === '') {
        return;
      } else {
        this.confId = 0;
        this.start = ' ';
        this.end = ' ';
        this.type = ' ';
        this.read = 0;
      }
    } else if (this.isPostMsg === true) {
      if (this.confId === 0) {
        return;
      } else {
        this.notId = ' ';
        this.start = ' ';
        this.end = ' ';
        this.type = ' ';
        this.read = 0;
      }
    } else if (this.isTarget === true) {
      if (this.type === ' ' || this.type === '') {
        return;
      } else {
        this.notId = ' ';
        this.confId = 0;
        this.start = ' ';
        this.end = ' ';
        this.read = 0;
      }
    } else if (this.isRange === true) {
        // this.start = this.date1.value.toDateString();
        // this.end = this.date2.value.toDateString();
        if (this.start === ' ' || this.start === '' || this.end === ' ' || this.end === '') {
          return;
        } else {
          this.notId = ' ';
          this.confId = 0;
          this.type = ' ';
          this.read = 0;
        }
      }

    this.isLoad = true;
    const v = new Variables();
    const phr = localStorage.getItem('phr');
    const phone = CryptoJS.AES.decrypt(phr, v.pass).toString(CryptoJS.enc.Utf8);

    const va = localStorage.getItem('ui');
    const ui = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
    const uid = +ui;

    const gis = localStorage.getItem('gi');
    const gs = CryptoJS.AES.decrypt(gis, v.pass).toString(CryptoJS.enc.Utf8);
    const gids = JSON.parse(gs);
    let g = '';
    for (const id of gids) {
      g += id + ',';
    }

    this.notification.getInformationGeneric(this.pager, this.size, this.notId,
      this.confId, 'I', this.start, this.end, this.read, 0, ' ',
      // tslint:disable-next-line: deprecation
      0, ' ', ' ', ' ', ' ', this.createdby, this.toread, this.alreadyread,
      this.readuser, phone, g, uid).subscribe((res) => {
        if (res == null) {
          this.isLoad = false;
          this.isLoadMore = false;
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.NOMORENOTIFICATIONS').subscribe((resp: string) => {
            this.notificationSvc.info('', resp, 2000);
            this.isLoadMore = true;
          });
        } else {
          // tslint:disable-next-line: prefer-for-of
          for (let i = 0; i < res.length; i++) {
            if (res[i].configuration.typeRoleGroups !== null && res[i].configuration.typeRoleGroups.length > 0) {
              let show = false;
              for (const val of res[i].configuration.typeRoleGroups) {
                if (val.typeRoleGroup.roleName === 'Expert-All' && gids.includes(val.typeRoleGroup.groupId)) {
                  show = true;
                }
              }

              if (this.isadm === true) {
                show = true;
              }

              if (show === false) {
                continue;
              }
            } else {
              if (this.isadm === true) {
              } else {
                continue;
              }
            }

            res[i].readconf = 0;
            if (res[i].infoUserCount === 0) {
              res[i].readconf = '';
            } else {
              if (res[i].readInfoUserCount === 0) {
                res[i].readconf = '0%';
              } else {
                const perc =
                Math.floor(Math.round((((res[i].readInfoUserCount / res[i].infoUserCount ) * 100) + Number.EPSILON) * 100) / 100);
                res[i].readconf = perc + '%';
              }
            }
            this.options = [];
            this.translate.get('GENERAL.DETAILS').subscribe((resp1: string) => {
              // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.OPENCONFIG').subscribe((resp2: string) => {
                // tslint:disable-next-line: deprecation
                this.translate.get('GENERAL.DELETE').subscribe((resp3: string) => {
                  // tslint:disable-next-line: deprecation
                  this.translate.get('GENERAL.HISTORY').subscribe((resp4: string) => {
                    this.options = [
                      { label: resp1, value: 'Details', command: async (event) => { await this.saveNotif(event, res[i], null); } },
                      { label: resp2, value: 'OpenConfig', command: async (event) => { await this.saveNotif(event, res[i], null); } }
                    ];
                    if (this.ismanage === true) {
                      this.options.push({ label: resp3, value: 'Delete',
                       command: async (event) => { await this.saveNotif(event, res[i], null); } });
                      this.options.push({ label: resp4, value: 'History',
                       command: async (event) => { await this.saveNotif(event, res[i], null); } });
                    }
                  });
                });
              });
            });
            res[i].options = this.options;
            if (res[i].configuration.configurationType === 'I') {
              res[i].configuration.confType = 'Information';
            } else if (res[i].configuration.configurationType === 'T') {
              res[i].configuration.confType = 'Task';
            }
            const doptions: any = { year: 'numeric', month: 'short', day: 'numeric',
           hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false };
            res[i].created = new Date(res[i].created.toString().replace('T', ' ') + ' UTC').toLocaleString('en-US', doptions);
            res[i].expires = new Date(res[i].expires.toString().replace('T', ' ') + ' UTC').toLocaleString('en-US', doptions);
            /*res[i].created = new Date(res[i].created).toLocaleString();
            res[i].expires = new Date(res[i].expires).toLocaleString();*/
            this.notifications.push(res[i]);
          }

          if (res.length < this.size) {
            this.isLoadMore = true;
          } else {
            this.isLoadMore = false;
          }
        }
        this.isLoad = false;
      }, err => this.handleError(err));
  }

  gotoCreateNotification() {
    this.router.navigateByUrl('/createnotification');
  }

  saveNot(notification) {
    localStorage.setItem('notification', JSON.stringify(notification));
  }

  show(notification) {
    /*const yr = notification.read.getFullYear();
    const mn = notification.read.getMonth();
    const d = notification.read.getDate();
    const hr = notification.read.getHours();
    const min = notification.read.getMinutes();
    const sec = notification.read.getSeconds();*/

    if (notification.read === '0001-01-01T00:00:00') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.NOTREAD').subscribe((resp: string) => {
        notification.reads = resp;
      });
      this.isNotDate = true;
      this.isDate = false;
    } else {
      this.isNotDate = false;
      this.isDate = true;
    }

    this.notif = notification;
    this.isVis = true;
    this.isConfigVis = false;

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.notifications.length; i++) {
      if (this.notifications[i].notificationId === notification.notificationId) {
        this.notifications[i].color = 'lightgray';
      } else {
        this.notifications[i].color = 'transparent';
      }
    }
  }

  deleteNotification(not) {
    // tslint:disable-next-line: deprecation
    this.notification.deleteNotification(not.notificationId).subscribe((resp) => {
      if (resp === true) {
        // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.DELETED').subscribe((res: string) => {
            this.notificationSvc.info('', res, 2000);
          });
          this.notifications = [];
          this.getNotifications(1, this.size);
          /*if (not.notificationId === this.notif.notificationId) {
            this.notif = null;
            this.isVis = false;
          }*/
        } else {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.COULDNOTDELETE').subscribe((res: string) => {
            alert(res);
          });
        }
    }, err => this.handleError(err));
  }

  async saveNotif(event, notif, dropdown: Dropdown) {
    localStorage.setItem('notif', JSON.stringify(notif));
    if (event.item.value === 'Details') {
      this.setNot();
      await this.getInfoUsers(notif.notificationId);
      this.isinfousers = true;
      // this.router.navigateByUrl('/notifications/' + notif.notificationId);
    } else if (event.item.value === 'OpenConfig') {
      this.router.navigateByUrl('/configurationdetail/' + notif.configurationId);
    } else if (event.item.value === 'Delete') {
      this.deleteNotification(notif);
    } else if (event.item.value === 'History') {
      await this.getHistory(notif.notificationId);
      this.ishistory = true;
    }

    if (dropdown !== null) {
      dropdown.selectedOption = null;
    }
  }

  async getHistory(notid) {
    this.loading = true;

    this.notlogs = [];

    const doptions: any = { year: 'numeric', month: 'short', day: 'numeric',
    hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false };

    const hist = await this.notlogService.getNotificationLogs(0, 0, notid).toPromise()
    .catch(err => this.handleError(err));

    if (hist !== undefined && hist !== null && hist.length > 0) {
      for (const val of hist) {
        val.ov = val.oldValue;
        val.nv = val.newValue;
        val.dt = new Date(val.date.toString().replace('T', ' ') + ' UTC')
          .toLocaleString('en-US', doptions);

        this.notlogs.push(val);
      }
    }

    this.loading = false;
  }

  async getInfoUsers(notid) {
    this.loading = true;

    this.infousers = [];

    const users = await this.infouserService.getInfoUsers(0, 0, notid).toPromise()
    .catch(err => this.handleError(err));

    if (users !== undefined && users !== null && users.length > 0) {
      for (const val of users) {
        if (val.hasRead === true) {
          val.color = 'green';
        } else {
          val.color = 'red';
        }

        this.infousers.push(val);
      }
    }

    this.loading = false;
  }

  async likeClicked(not) {
    this.isLoad = true;

    const v = new Variables();
    const phr = localStorage.getItem('phr');
    const phone = CryptoJS.AES.decrypt(phr, v.pass).toString(CryptoJS.enc.Utf8);

    const feedback = new NotificationFeedback();
    feedback.FeedbackId = 1;
    feedback.NotificationId = not.notificationId;
    feedback.UserPhone = phone;

    if (not.userLikeFeedbackCount > 0) {
      // unlike
      const res = await this.notFeedbackService.deleteFeedback(feedback).toPromise()
      .catch(err => this.handleError(err));
      if (res === true) {
        this.translate.get('GENERAL.UNLIKED').subscribe((resp: string) => {
          this.notificationSvc.info('', resp, 2000);
        });

        not.userLikeFeedbackCount = 0;
        not.likeFeedbackCount -= 1;
        for (let val of this.notifications) {
          if (val.notificationId === not.notificationId) {
            val = not;
          }
        }
      }
    } else {
      // like
      const res = await this.notFeedbackService.postFeedback(feedback).toPromise()
      .catch(err => this.handleError(err));
      if (res === true) {
        this.translate.get('GENERAL.LIKED').subscribe((resp: string) => {
          this.notificationSvc.info('', resp, 2000);
        });

        not.userLikeFeedbackCount = 1;
        not.likeFeedbackCount += 1;
        for (let val of this.notifications) {
          if (val.notificationId === not.notificationId) {
            val = not;
          }
        }
      }
    }

    this.isLoad = false;
  }

  async commentClicked(not) {
    this.mynot = not;
    this.isLoad = true;

    this.comments = [];
    const coms = await this.commentService.getComments(not.notificationId).toPromise()
    .catch(err => this.handleError(err));

    if (coms !== undefined && coms !== null && coms.length > 0) {
      for (const val of coms) {
        const doptions: any = { year: 'numeric', month: 'short', day: 'numeric',
           hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false };
        val.crt = new Date(val.created.toString().replace('T', ' ') + ' UTC')
          .toLocaleString('en-US', doptions);
        if (val.userName === undefined || val.userName === null) {
          val.userName = val.userPhone;
        }
        this.comments.push(val);
      }
    }
    this.iscomments = true;

    this.isLoad = false;
  }

  async send() {
    if (this.comment === undefined || this.comment === null || this.comment.trim() === '') {
      return;
    }
    this.isLoad = true;

    const v = new Variables();
    const phr = localStorage.getItem('phr');
    const phone = CryptoJS.AES.decrypt(phr, v.pass).toString(CryptoJS.enc.Utf8);

    const comment = new NotificationComment();
    comment.NotificationId = this.mynot.notificationId;
    comment.UserPhone = phone;
    comment.Comment = this.comment;
    comment.UserName = localStorage.getItem('uname');

    const res = await this.commentService.postComment(comment).toPromise()
    .catch(err => this.handleError(err));

    if (res === true) {
      const cs = [];
      const coms = await this.commentService.getComments(this.mynot.notificationId).toPromise()
      .catch(err => this.handleError(err));

      if (coms !== undefined && coms !== null && coms.length > 0) {
        for (const val of coms) {
          const doptions: any = { year: 'numeric', month: 'short', day: 'numeric',
             hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false };
          val.crt = new Date(val.created.toString().replace('T', ' ') + ' UTC')
            .toLocaleString('en-US', doptions);
          if (val.userName === undefined || val.userName === null) {
            val.userName = val.userPhone;
          }
          cs.push(val);
        }
      }

      this.comment = null;
      this.comments = cs;

      this.mynot.userCommentCount += 1;
      this.mynot.commentCount += 1;
      for (let val of this.notifications) {
        if (val.notificationId === this.mynot.notificationId) {
          val = this.mynot;
        }
      }
    } else {
      // failed
    }

    this.isLoad = false;
  }

  async likeValueClicked(not) {
    if (not.likeFeedbackCount === 0) {
      return;
    }

    this.mynot = not;
    this.isLoad = true;

    this.likes = [];
    const lks = await this.notFeedbackService.getFeedbacks(not.notificationId, 1).toPromise()
    .catch(err => this.handleError(err));

    if (lks !== undefined && lks !== null && lks.length > 0) {
      for (const val of lks) {
        const doptions: any = { year: 'numeric', month: 'short', day: 'numeric',
           hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false };
        val.crt = new Date(val.created.toString().replace('T', ' ') + ' UTC')
          .toLocaleString('en-US', doptions);
        if (val.userName === undefined || val.userName === null) {
          val.userName = val.userPhone;
        }
        this.likes.push(val);
      }
    }
    this.islikes = true;

    this.isLoad = false;
  }

  setNot() {
    const doptions: any = { year: 'numeric', month: 'short', day: 'numeric',
    hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false };
    const not = JSON.parse(localStorage.getItem('notif'));
    not.created = new Date(not.created).toLocaleString('en-US', doptions);
    not.expires = new Date(not.expires).toLocaleString('en-US', doptions);

    if (not.read === '0001-01-01T00:00:00') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.NOTREAD').subscribe((resp: string) => {
        not.reads = resp;
      });
    } else {
      not.reads = new Date(not.read.toString().replace('T', ' ') + ' UTC').toLocaleString('en-US', doptions);
      // not.reads = new Date(not.read).toLocaleString();
    }

    this.notif2 = not;

    this.MessageTitle = not.messageTitle;
    this.MessageBody = not.messageBody;
    this.TargetType = not.targetType;
    this.Created = not.created;
    this.Expires = not.expires;

    if (not.user === 0) {
      this.Read = 'False';
    } else {
      this.Read = 'True';
    }
  }

  addEvent1(type: string, event: MatDatepickerInputEvent<Date>) {
    if (event.value === null) {
      this.invalidDate = true;
    } else {
      this.invalidDate = false;
      this.start = event.value.toDateString();
    }
  }

  addEvent2(type: string, event: MatDatepickerInputEvent<Date>) {
    if (event.value === null) {
      this.invalidDate = true;
    } else {
      this.invalidDate = false;
      this.end = event.value.toDateString();
    }
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    // this.events.push(`${type}: ${event.value}`);
    console.log(`${type}: ${event.value}`);
    if (event.value === null) {
      console.log('null');
    } else {
      console.log(event.value.toDateString());
    }
  }

  showConfig(id, configuration) {
    configuration.configurationId = id;
    if (configuration.priority === 1) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.CRITICAL').subscribe((res) => {
        configuration.prior = res;
      });
    } else if (configuration.priority === 2) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.HIGH').subscribe((res) => {
        configuration.prior = res;
      });
    } else if (configuration.priority === 3) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.NORMAL').subscribe((res) => {
        configuration.prior = res;
      });
    } else if (configuration.priority === 4) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.LOW').subscribe((res) => {
        configuration.prior = res;
      });
    } else if (configuration.priority === 4) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.VERYLOW').subscribe((res) => {
        configuration.prior = res;
      });
    }

    if (configuration.channel === 'M') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.MQTT').subscribe((res) => {
        configuration.chan = res;
      });
    } else if (configuration.channel === 'S') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SMS').subscribe((res) => {
        configuration.chan = res;
      });
    } else if (configuration.channel === 'E') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.EMAIL').subscribe((res) => {
        configuration.chan = res;
      });
    }

    if (configuration.vibrate === 0) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.NONE').subscribe((res) => {
        configuration.vib = res;
      });
    } else if (configuration.vibrate === 1) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SHORT').subscribe((res) => {
        configuration.vib = res;
      });
    } else if (configuration.vibrate === 2) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.LONG').subscribe((res) => {
        configuration.vib = res;
      });
    } else if (configuration.vibrate === 3) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.VERYLONG').subscribe((res) => {
        configuration.vib = res;
      });
    }

    if (configuration.configurationType === 'I') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.INFORMATION').subscribe((res) => {
        configuration.configType = res;
      });
    } else if (configuration.configurationType === 'T') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.TASK').subscribe((res) => {
        configuration.configType = res;
      });
    }
    this.isVis = false;
    this.isConfigVis = true;
    this.config = configuration;
  }

  async setConf() {
    const v = new Variables();
    const val = localStorage.getItem('ui');
    const ui = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);
    const uid = +ui;

    const uname = localStorage.getItem('uname');

    const conf = new NotificationReads();
    conf.NotificationId = this.notif2.notificationId;
    conf.ReadUser = uname;
    conf.UserId = uid;
    conf.UserName = uname;
    await this.notreadService.PostReadConfirmation(conf).toPromise()
    .catch(err => this.handleError(err));
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  handleError(err) {
    this.isLoad = false;
    console.log(err);
  }

}
