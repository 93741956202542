import { UsersService } from './../../services/users/users.service';
import { ReminderType } from './../../Models/ReminderType';
import { Location } from '@angular/common';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CookieService } from 'ngx-cookie-service';
import { ReminderTypesService } from 'src/app/services/reminder-types/reminder-types.service';
import * as CryptoJS from 'crypto-js';
import { Variables } from 'src/app/Models/variables';

@Component({
  selector: 'app-create-reminder-type',
  templateUrl: './create-reminder-type.component.html',
  styleUrls: ['./create-reminder-type.component.css']
})
export class CreateReminderTypeComponent implements OnInit {

  types = [];
  type: any;

  TypeName: string;
  TypeDetail: string;
  Interval = 1;
  Durations: string;
  Expiration: number;
  Enabled = 'YES';
  ReminderMode = 'Interval';
  Expiry = 1;

  disableInterval = false;
  disableDurations = true;
  loading = false;

  user: string;

  constructor(
    private router: Router,
    private notificationSvc: NotificationService,
    public translate: TranslateService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private remindertypeService: ReminderTypesService,
    private messageService: MessageService,
    private configService: ConfigurationService,
    private location: Location,
    private userService: UsersService
    ) {
    // this.translate.addLangs(['English', 'French']);
    // this.translate.setDefaultLang('English');

    // let browserLang = this.translate.getBrowserLang();

    // const pass = 'ranitessarldev2019';
    // const lang = cookieService.get('goepla-portal-language');
    // if (lang.trim() !== '') {
    //   const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
    //   const lg = declang;
    //   browserLang = lg;
    // }

    // if (localStorage.getItem('language') !== null) {
    //   browserLang = localStorage.getItem('language');
    // }
    // this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    translate.use('English');

   }

  ngOnInit() {
    this.getUser();
    this.getConfigurations();
  }

  getConfigurations() {
    this.loading = true;
    let count = 0;
    // tslint:disable-next-line: deprecation
    this.configService.getConfigurations('T').subscribe((res) => {
      if (res != null) {
        for (const val of res) {
          this.types.push({label: val.configurationName, value: val});
          count += 1;
          if (count === res.length) {
            this.loading = false;
          }
        }
      }
      this.loading = false;
    }, err => this.handleError(err));
  }

  getUser() {
    this.loading = true;
    const v = new Variables();
    const val = localStorage.getItem('ui');
    const ui = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);
    // tslint:disable-next-line: deprecation
    this.userService.getUser(0, +ui, ' ', ' ').subscribe((res) => {
      this.user = res.firstName + ' ' + res.lastName;
      this.loading = false;
    }, err => this.handleError(err));
  }

  showReminderMode() {
    if (this.ReminderMode === 'Interval') {
      this.disableInterval = false;
      this.disableDurations = true;
    } else if (this.ReminderMode === 'Durations') {
      this.disableInterval = true;
      this.disableDurations = false;
    }
  }

  onCreate() {
    if (this.type === undefined || this.type === null) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTTASKTYPE').subscribe((trans) => {
        this.confirm(trans);
      });
      return;
    }

    if (this.TypeName === undefined || this.TypeName === null || this.TypeName.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTERTYPENAME').subscribe((trans) => {
        this.confirm(trans);
      });
      return;
    }

    if (this.TypeDetail === undefined || this.TypeDetail === null || this.TypeDetail.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTERTYPEDETAIL').subscribe((trans) => {
        this.confirm(trans);
      });
      return;
    }

    if (this.ReminderMode === 'Interval') {
      this.Durations = null;
    } else if (this.ReminderMode === 'Durations') {
      if (this.Durations === undefined || this.Durations === null || this.Durations.trim() === '') {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.ENTERDURATIONS').subscribe((trans) => {
          this.confirm(trans);
        });
        return;
      }
      this.Interval = 0;
    }

    const remtype = new ReminderType();
    remtype.ConfigurationId = this.type.configurationId;
    remtype.CreatedBy = this.user;
    remtype.IntervalCycle = this.Interval;
    remtype.IntervalDurations = this.Durations;
    remtype.ReminderExpiry = this.Expiry;
    remtype.ReminderTypeName = this.TypeName;
    remtype.ReminderTypeDetail = this.TypeDetail;

    if (this.Enabled === 'YES') {
      remtype.Status = 'ENABLED';
    } else if (this.Enabled === 'NO') {
      remtype.Status = 'DISABLED';
    }

    this.loading = true;
    // tslint:disable-next-line: deprecation
    this.remindertypeService.postReminderType(remtype).subscribe((res) => {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.REMINDERTYPECREATED').subscribe((trans) => {
        this.showSuccess(trans);
        this.location.back();
      });
    }, err => this.handleError(err));
  }

  onCancel() {
    this.location.back();
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  showSuccess(msg) {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.SUCCESS').subscribe((trans) => {
      this.messageService.add({key: 'tc', severity: 'success', summary: trans, detail: msg});
    });
  }

  showInfo(msg) {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.INFO').subscribe((trans) => {
      this.messageService.add({key: 'tc', severity: 'info', summary: trans, detail: msg});
    });
  }

  showWarn(msg) {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.WARNING').subscribe((trans) => {
      this.messageService.add({key: 'tc', severity: 'warn', summary: trans, detail: msg});
    });
  }

  showError(msg) {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.ERROR').subscribe((trans) => {
      this.messageService.add({key: 'tc', severity: 'error', summary: trans, detail: msg});
    });
  }

  handleError(err) {
    this.loading = false;
    if (err.error.Message === 'Invalid duration format') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.INVALIDDURATIONFORMAT').subscribe((trans) => {
        this.confirm(trans);
      });
    }

    if (err.error.MessageText === 'duplicate key value violates unique constraint "t_ntm_reminder_type_pkey"') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.REMINDERTYPEALREADYEXISTS').subscribe((trans) => {
        this.confirm(trans);
      });
    }
  }

}
