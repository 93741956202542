import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { ConfirmationService } from 'primeng/api';
import { CookieService } from 'ngx-cookie-service';
import * as CryptoJS from 'crypto-js';
import { Variables } from 'src/app/Models/variables';
import { DataCache } from 'src/app/Helpers/data-cache';

@Component({
  selector: 'app-information-configurations',
  templateUrl: './information-configurations.component.html',
  styleUrls: ['./information-configurations.component.css']
})
export class InformationConfigurationsComponent implements OnInit {

  configurations = [];
  config: any;

  loading = false;
  isVis: boolean;

  page = 1;
  size = 100;

  isLoadMore = true;
  ismanage = false;

  isedit = false;
  isadm = false;

  gps = [];

  constructor(
    private router: Router,
    public translate: TranslateService,
    private configService: ConfigurationService,
    private notificationSvc: NotificationService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService
  ) {
    // this.translate.addLangs(['English', 'French']);
    // this.translate.setDefaultLang('English');

    // let browserLang = this.translate.getBrowserLang();

    // const pass = 'ranitessarldev2019';
    // const lang = cookieService.get('goepla-portal-language');
    // if (lang.trim() !== '') {
    //   const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
    //   const lg = declang;
    //   browserLang = lg;
    // }

    // if (localStorage.getItem('language') !== null) {
    //   browserLang = localStorage.getItem('language');
    // }
    // this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    translate.use('English');

    const v = new Variables();
    const p = localStorage.getItem('ups');
    if (p != null) {
      const val = CryptoJS.AES.decrypt(p, v.pass).toString(CryptoJS.enc.Utf8);
      const perm = JSON.parse(val);
      if (perm.includes('NTM.Administration.InformationTypes') || perm.includes('NTM.Administration.Full')) {
        this.ismanage = true;
      }

      if (perm.includes('NTM.Administration.Full')) {
        this.isadm = true;
      }
    }
    this.loading = true;
    this.getConfigurations();
    this.getGroups();
   }

  ngOnInit() {
    localStorage.removeItem('config');
  }

  gotoCreateInformationConfiguration() {
    this.router.navigateByUrl('/createinformationconfiguration');
  }

  edit() {
    localStorage.setItem('config', JSON.stringify(this.config));
    this.router.navigateByUrl('/editinformationconfiguration');
  }

  getConfigurations() {
    // this.loading = true;
    // tslint:disable-next-line: deprecation
    this.configService.getConfigurationsByType('I', this.page, this.size).subscribe((res) => {
      if (res != null) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          if (res[i].queueSize === null || res[i].queueSize === 'null') {
            res[i].queueSize = 'null';
          }
          this.configurations.push(res[i]);
        }
        if (res.length < this.size) {
          this.isLoadMore = true;
        } else {
          this.isLoadMore = false;
        }
      } else {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.NOCONFIGURATIONS').subscribe((resp: string) => {
          this.notificationSvc.info('', resp, 3000);
          this.isLoadMore = true;
        });
      }
      this.loading = false;
    }, err => {
      this.handleError(err);
    });
  }

  loadMore() {
    this.loading = true;
    this.page += 1;
    this.getConfigurations();
  }

  // tslint:disable-next-line: no-shadowed-variable
  saveConfig(config) {
    localStorage.setItem('config', JSON.stringify(config));

    const v = new Variables();
    const gis = localStorage.getItem('gi');
    const gs = CryptoJS.AES.decrypt(gis, v.pass).toString(CryptoJS.enc.Utf8);
    const gids = JSON.parse(gs);

    this.isedit = false;
    if (config.typeRoleGroups !== null && config.typeRoleGroups.length > 0) {
      for (const val of config.typeRoleGroups) {
        if (val.typeRoleGroup.roleName === 'Administrator' && gids.includes(val.typeRoleGroup.groupId)) {
          this.isedit = true;
        }

        val.typeRoleGroup.g = null;
        if (this.gps !== undefined && this.gps !== null && this.gps.length > 0) {
          for (const g of this.gps) {
            if (val.typeRoleGroup.groupId === g.groupId) {
              val.typeRoleGroup.g = g.groupName;
            }
          }
        }
      }
    }

    if (this.isadm === true) {
      this.isedit = true;
    }

    if (config.priority === 1) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.CRITICAL').subscribe((res) => {
        config.prior = res;
      });
    } else if (config.priority === 2) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.HIGH').subscribe((res) => {
        config.prior = res;
      });
    } else if (config.priority === 3) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.NORMAL').subscribe((res) => {
        config.prior = res;
      });
    } else if (config.priority === 4) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.LOW').subscribe((res) => {
        config.prior = res;
      });
    } else if (config.priority === 4) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.VERYLOW').subscribe((res) => {
        config.prior = res;
      });
    }

    if (config.channel === 'M') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.MQTT').subscribe((res) => {
        config.chan = res;
      });
    } else if (config.channel === 'S') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SMS').subscribe((res) => {
        config.chan = res;
      });
    } else if (config.channel === 'E') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.EMAIL').subscribe((res) => {
        config.chan = res;
      });
    }

    if (config.vibrate === 0) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.NONE').subscribe((res) => {
        config.vib = res;
      });
    } else if (config.vibrate === 1) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SHORT').subscribe((res) => {
        config.vib = res;
      });
    } else if (config.vibrate === 2) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.LONG').subscribe((res) => {
        config.vib = res;
      });
    } else if (config.vibrate === 3) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.VERYLONG').subscribe((res) => {
        config.vib = res;
      });
    }

    if (config.configurationType === 'I') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.INFORMATION').subscribe((res) => {
        config.configType = res;
      });
    } else if (config.configurationType === 'T') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.TASK').subscribe((res) => {
        config.configType = res;
      });
    }

    this.config = config;
    this.isVis = true;
  }

  // tslint:disable-next-line: no-shadowed-variable
  deleteConfig(config) {
    this.loading = true;
    // tslint:disable-next-line: deprecation
    this.configService.deleteConfiguration(config.configurationId).subscribe((res) => {
      if (res === true) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.CONFIGURATIONDELETED').subscribe((resp: string) => {
          this.notificationSvc.info('', resp, 3000);
        });
        this.isVis = false;
        this.configurations = [];
        this.getConfigurations();
      } else {
        this.loading = false;
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.COULDNOTDELETECONFIGURATION').subscribe((resp: string) => {
          this.notificationSvc.info('', resp, 3000);
        });
      }
    }, err => {
      this.handleError(err);
    });
  }

  getGroups() {
    DataCache.groupdata$.subscribe(data => {
      if (data !== undefined && data !== null && data.length > 0) {
        for (const val of data) {
          this.gps.push(val);
        }
      }
    });
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  handleError(err) {
    // tslint:disable-next-line: max-line-length
    if (err.error.MessageText === 'update or delete on table "t_ntm_ncfg" violates foreign key constraint "t_ntm_not_ncfg_id_fkey" on table "t_ntm_not"') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.NOTIFICATIONDEPENDS').subscribe((res) => {
        this.confirm(res);
      });
    }
    this.loading = false;
  }

}
