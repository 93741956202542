import { NotificationComment } from './../../Models/notification-comment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CommentService {

  apiUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = environment.apiUrl;
  }

  getComments(notid) {
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/notificationcomment/' + notid + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  postComment(comment: NotificationComment): Observable<any> {
    return this.httpclient.post(this.apiUrl + '/api/notificationcomment', comment)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  deleteComment(comment: NotificationComment): Observable<any> {
    return this.httpclient.put(this.apiUrl + '/api/notificationcomment/delete/id', comment)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  private handleError(error: any): any {
    throw error;
    return error;
  }
}
