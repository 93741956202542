import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ConfirmationService } from 'primeng/api';
import { Urls } from 'src/app/Helpers/Urls';
import { Variables } from 'src/app/Models/variables';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import * as CryptoJS from 'crypto-js';
import { UsersService } from 'src/app/services/users/users.service';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';

@Component({
  selector: 'app-overview-by-user',
  templateUrl: './overview-by-user.component.html',
  styleUrls: ['./overview-by-user.component.css']
})
export class OverviewByUserComponent implements OnInit {

  urls = new Urls();

  myaveragettc = 0;
  myclosedtasks = 0;
  myactivities = 0;
  myqueue = 0;

  inqueue = 0;
  open = 0;
  assigned = 0;
  started = 0;

  options: { title: { display: boolean; text: string; fontSize: number; }; legend: { position: string; }; };

  uid: number;
  // closedUserData: { labels: string[]; datasets: { label: string; backgroundColor: string; borderColor: string; data: number[]; }[]; };
  // usrActData: { labels: string[]; datasets: { label: string; backgroundColor: string; borderColor: string; data: number[]; }[]; };
  // myttcData: { labels: string[]; datasets: { label: string; backgroundColor: string; borderColor: string; data: number[]; }[]; };

  closedUserData: any;
  usrActData: any;
  myttcData: any;

  closed = 0;
  expired = 0;

  assignedontime = 0;
  startedontime = 0;
  closedontime = 0;
  closedlate = 0;

  createdbydayData: any;
  closedbydayData: any;
  ttcbydayData: any;

  assignedontimeData: any;
  startedontimeData: any;
  closedontimeData: any;

  load = false;
  users = [];
  user: any;

  data: any;
  range: any;
  start: any;
  end: any;

  configs1 = [];
  conf1: any;
  confid: number;

  allcount = 0;
  isadm = false;

  constructor(
    private router: Router,
    private notificationSvc: NotificationService,
    public translate: TranslateService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private analyticsService: AnalyticsService,
    private userService: UsersService,
    private configService: ConfigurationService
    ) {
      this.translate.addLangs(['English', 'French']);
      this.translate.setDefaultLang('English');

      let browserLang = this.translate.getBrowserLang();

      const pass = 'ranitessarldev2019';
      const lang = cookieService.get('goepla-portal-language');
      if (lang.trim() !== '') {
        const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
        const lg = declang;
        browserLang = lg;
        localStorage.setItem('language', lg);
      }

      if (localStorage.getItem('language') !== null) {
        browserLang = localStorage.getItem('language');
      }
      this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

      if (localStorage.getItem('filter-start') !== null && localStorage.getItem('filter-end') !== null) {
        const st = localStorage.getItem('filter-start');
        const ed = localStorage.getItem('filter-end');
        this.range = [new Date(st), new Date(ed)];
      }

      if (localStorage.getItem('filter-config') !== null) {
        this.confid = +localStorage.getItem('filter-config');
      }

      if (localStorage.getItem('filter-user') !== null) {
        this.uid = +localStorage.getItem('filter-user');
      }

      // tslint:disable-next-line: deprecation
      translate.get('GENERAL.ALL').subscribe((trans) => {
        const conf: any = [];
        conf.configurationId = 0;
        conf.configurationName = trans;
        this.configs1.push({ label: trans, value: conf });
        if (this.confid === 0) {
          this.conf1 = conf;
        }
      });

      const v = new Variables();
      const p = localStorage.getItem('ups');
      if (p == null) {
        this.isadm = false;
      }

      const pr = CryptoJS.AES.decrypt(p, v.pass).toString(CryptoJS.enc.Utf8);
      const perm = JSON.parse(pr);
      if (perm.includes('NTM.Administration.Full')) {
            this.isadm = true;
      }

      this.getConfigurations();
      this.getUsers();
    }

  ngOnInit() {
  }

  getConfigurations() {
    this.load = true;
    // tslint:disable-next-line: deprecation
    this.configService.getConfigurations('T').subscribe((res) => {
      if (res != null) {
        const v = new Variables();
        const gis = localStorage.getItem('gi');
        const gs = CryptoJS.AES.decrypt(gis, v.pass).toString(CryptoJS.enc.Utf8);
        const gids = JSON.parse(gs);

        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          if (res[i].typeRoleGroups !== null && res[i].typeRoleGroups.length > 0) {
            let show = false;
            for (const val of res[i].typeRoleGroups) {
              if (val.typeRoleGroup.roleName === 'Analytics' && gids.includes(val.typeRoleGroup.groupId)) {
                show = true;
              }
            }

            if (this.isadm === true) {
              show = true;
            }

            if (show === false) {
              continue;
            }
          } else {
            if (this.isadm === true) {
            } else {
              continue;
            }
          }
          this.configs1.push({ label: res[i].configurationName, value: res[i] });
          if (this.confid >= 0) {
            if (res[i].configurationId === this.confid) {
              this.conf1 = res[i];
            }
          }
        }
      }
      this.load = false;
    });
  }

  getUsers() {
    this.load = true;
    // tslint:disable-next-line: deprecation
    this.userService.getUsers().subscribe((res) => {
      if (res != null) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          res[i].UserName = res[i].firstName + ' ' + res[i].lastName;
          this.users.push({label: res[i].UserName, value: res[i]});
          if (res[i].userId === this.uid) {
            this.user = res[i];
          }
        }
      }
      this.load = false;
    });
  }

  async ok() {
    this.data = [];
    if (this.range === undefined || this.range === null) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTRANGE').subscribe((res) => {
        this.confirm(res);
      });
      return;
    } else {
      if (this.range[0] !== undefined && this.range[0] !== null) {
        this.start = this.range[0].toDateString();
        const a = this.range[0].toDateString();
        const b = new Date(a);
        const c = new Date(b.getFullYear(), b.getMonth(), b.getDate());
        this.start = c.toDateString();
      } else {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.SELECTRANGE').subscribe((res) => {
          this.confirm(res);
        });
        return;
      }
      if (this.range[1] !== undefined && this.range[1] !== null) {
        this.end = this.range[1].toDateString();
        const a = this.range[1].toDateString();
        const b = new Date(a);
        const c = new Date(b.getFullYear(), b.getMonth(), b.getDate());
        this.end = c.toDateString();
      } else {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.SELECTRANGE').subscribe((res) => {
          this.confirm(res);
        });
        return;
      }

      if (this.conf1 === undefined || this.conf1 === null) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.SELECTCONFIGURATION').subscribe((res) => {
          this.confirm(res);
        });
        return;
      }

      if (this.user === undefined || this.user === null) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.SELECTUSER').subscribe((res) => {
          this.confirm(res);
        });
        return;
      }
      localStorage.setItem('filter-start', this.start);
      localStorage.setItem('filter-end', this.end);
      localStorage.setItem('filter-config', this.conf1.configurationId);
      localStorage.setItem('filter-user', this.user.userId);

      this.confid = this.conf1.configurationId;
      this.uid = this.user.userId;

      await this.getReport();
    }
   }

  async getReport() {
    if (this.configs1 === undefined || this.configs1 === null || this.configs1.length === 1) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.NODATAAVAILABLE').subscribe((resp: string) => {
        this.notificationSvc.info('', resp, 2000);
        });
      return;
    }
    let tids = '';
    if (this.conf1.configurationId === 0) {
      if (this.allcount + 1 !== this.configs1.length) {
        for (const val of this.configs1) {
          if (val.value.configurationId !== 0) {
            tids += val.value.configurationId + ',';
          }
        }
      }
    }
    if (tids === '') {
      tids = ' ';
    }
    this.load = true;
    await this.getAmount(tids);
    await this.getAmount2(tids);
    await this.getAnalyticsClosedTaskByUser(tids);
    await this.getAnalyticsUserActivitiesByDay(tids);
    await this.getAnalyticsMyTTC(tids);
    await this.getAnalyticsAssignedOnTimeByDay(tids);
    await this.getAnalyticsStartedOnTimeByDay(tids);
    await this.getAnalyticsClosedOnTimeByDay(tids);
    this.load = false;
  }

  async getAmount(tids) {
    const mq = await this.analyticsService.getDashboards3(this.confid, this.start, this.end, this.uid, 'inqueue', ' ', tids)
    .toPromise().catch(err => this.handleError(err));
    const mcls = await this.analyticsService.getDashboards3(this.confid, this.start, this.end, this.uid, 'closed', ' ', tids)
    .toPromise().catch(err => this.handleError(err));

    this.myqueue = mq;
    this.myclosedtasks = mcls;
  }

  async getAmount2(tids) {
    const asg = await this.analyticsService.getDashboards3(this.confid, this.start, this.end, this.uid, 'assigned2', ' ', tids)
    .toPromise().catch(err => this.handleError(err));
    const str = await this.analyticsService.getDashboards3(this.confid, this.start, this.end, this.uid, 'started2', ' ', tids)
    .toPromise().catch(err => this.handleError(err));
    const cls = await this.analyticsService.getDashboards3(this.confid, this.start, this.end, this.uid, 'closed', ' ', tids)
    .toPromise().catch(err => this.handleError(err));
    const exp = await this.analyticsService.getDashboards3(this.confid, this.start, this.end, this.uid, 'expired', ' ', tids)
    .toPromise().catch(err => this.handleError(err));
    const assot = await this.analyticsService.getDashboards3(this.confid, this.start, this.end, this.uid, 'assignedontime', ' ', tids)
    .toPromise().catch(err => this.handleError(err));
    const strot = await this.analyticsService.getDashboards3(this.confid, this.start, this.end, this.uid, 'startedontime', ' ', tids)
    .toPromise().catch(err => this.handleError(err));
    const clsot = await this.analyticsService.getDashboards3(this.confid, this.start, this.end, this.uid, 'closedontime', ' ', tids)
    .toPromise().catch(err => this.handleError(err));
    const clslate = await this.analyticsService.getDashboards3(this.confid, this.start, this.end, this.uid, 'closedlate', ' ', tids)
    .toPromise().catch(err => this.handleError(err));
    const crt = await this.analyticsService.getDashboards3(this.confid, this.start, this.end, this.uid, 'created', ' ', tids)
    .toPromise().catch(err => this.handleError(err));

    this.assignedontime = 0;
    this.startedontime = 0;
    this.closedontime = 0;
    this.closedlate = 0;

    if (assot > 0) {
      this.assignedontime = Math.round((((assot / asg) * 100) + Number.EPSILON) * 100) / 100;
    }
    if (strot > 0) {
      this.startedontime = Math.round((((strot / str) * 100) + Number.EPSILON) * 100) / 100;
    }
    if (clsot > 0) {
      this.closedontime = Math.round((((clsot / cls) * 100) + Number.EPSILON) * 100) / 100;
    }
    if (clslate > 0) {
      this.closedlate = Math.round((((clslate / cls) * 100) + Number.EPSILON) * 100) / 100;
    }
  }

   async getAnalyticsClosedTaskByUser(tids) {
    let amttsks = '';
    let day = '';
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.AMOUNTOFTASKS').subscribe((trans) => {
      amttsks = trans;
    });
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.DAY').subscribe((trans) => {
      day = trans;
    });

    const res = await this.analyticsService.getAnalyticsGeneric(this.confid, 'T', this.start, this.end, this.uid, 'closedbyday', ' ', tids)
    .toPromise().catch(err => this.handleError(err));
    this.closedUserData = [];
    const labs: string[] = [];
    const datasets: number[] = [];
    let count = 0;
    if (res != null && res.length > 0) {
      for (const v of res) {
        const date = new Date(v.value2);
        labs.push(date.toDateString());
        datasets.push(v.value1);
        count += 1;

        if (count === res.length) {
          // labs.push(this.def.toDateString());
          // datasets.push(0);
          this.closedUserData = {
            labels: labs,
            datasets: [
                {
                    label: amttsks,
                    backgroundColor: '#42A5F5',
                    borderColor: '#1E88E5',
                    data: datasets
                }
            ]
          };
        }
      }
    } else {
    }
   }

   async getAnalyticsUserActivitiesByDay(tids) {
    let mins = '';
    let week = '';
    let tot = 0;
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.MINUTES').subscribe((trans) => {
      mins = trans;
    });

    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.WEEK').subscribe((trans) => {
      week = trans;
    });

    const res = await this.analyticsService.getAnalyticsGeneric(this.confid, 'T', this.start, this.end,
                      this.uid, 'useractivitybyday', ' ', tids)
    .toPromise().catch(err => this.handleError(err));
    this.myactivities = 0;
    this.usrActData =  [];
    const labs: string[] = [];
    const datasets: number[] = [];
    let count = 0;
    if (res != null && res.length > 0) {
      for (const v of res) {
        labs.push(new Date(v.value3).toDateString());
        datasets.push(v.value1);
        tot += +v.value1;
        count += 1;

        if (count === res.length) {
          this.myactivities = Math.round(((tot / 60) + Number.EPSILON) * 100) / 100;
          this.usrActData = {
            labels: labs,
            datasets: [
                {
                    label: mins,
                    backgroundColor: '#42A5F5',
                    borderColor: '#1E88E5',
                    data: datasets
                }
            ]
          };
        }
      }
    } else {
    }
   }

   async getAnalyticsMyTTC(tids) {
    let mins = '';
    let week = '';
    let tot = 0;
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.MINUTES').subscribe((trans) => {
      mins = trans;
    });

    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.WEEK').subscribe((trans) => {
      week = trans;
    });

    const res = await this.analyticsService.getAnalyticsGeneric(this.confid, 'T', this.start, this.end,
                      this.uid, 'averagettctrend', ' ', tids)
    .toPromise().catch(err => this.handleError(err));
    this.myaveragettc = 0;
    this.myttcData = [];
    const labs: string[] = [];
    const datasets: number[] = [];

    let count = 0;
    if (res != null && res.length > 0) {
      for (const v of res) {
        labs.push(new Date(v.value3).toDateString());
        datasets.push(Math.round(((v.value1) + Number.EPSILON) * 100) / 100);
        tot += v.value1;
        count += 1;

        if (count === res.length) {
          this.myaveragettc = Math.round(((tot / count) + Number.EPSILON) * 100) / 100;
          this.myttcData = {
            labels: labs,
            datasets: [
                {
                    label: mins,
                    backgroundColor: '#42A5F5',
                    borderColor: '#1E88E5',
                    data: datasets
                }
            ]
          };
        }
      }
    } else {
    }
   }

   async getAnalyticsAssignedOnTimeByDay(tids) {
    let perc = '';
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.PERCENTAGE').subscribe((trans) => {
      perc = trans;
    });

    const resp = await this.analyticsService.getAnalyticsGeneric(this.confid, 'T', this.start, this.end,
                       this.uid, 'assignedontime', ' ', tids)
    .toPromise().catch(err => this.handleError(err));
    this.assignedontimeData = [];
    const labs: string[] = [];
    const datasets: number[] = [];
    let count = 0;
    let total = 0;
    if (resp != null && resp.length > 0) {
      for (const res of resp) {
        labs.push(new Date(res.value2).toDateString());
        total += res.value1;
        count += 1;
        if (count === resp.length) {
          count = 0;
          for (const val of resp) {
            const p = Math.round((((val.value1 / total) * 100) + Number.EPSILON) * 100) / 100;
            datasets.push(p);
            count += 1;
          }

          if (count === resp.length) {
            this.assignedontimeData = {
              labels: labs,
              datasets: [
                  {
                      label: perc,
                      data: datasets,
                      backgroundColor: '#42A5F5',
                      borderColor: '#1E88E5',
                  }]
              };
          }
        }
      }
    } else {
      //
    }
   }

   async getAnalyticsStartedOnTimeByDay(tids) {
    let perc = '';
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.PERCENTAGE').subscribe((trans) => {
      perc = trans;
    });

    const resp = await this.analyticsService.getAnalyticsGeneric(this.confid, 'T', this.start, this.end,
                       this.uid, 'startedontime', ' ', tids)
    .toPromise().catch(err => this.handleError(err));
    this.startedontimeData = [];
    const labs: string[] = [];
    const datasets: number[] = [];
    let count = 0;
    let total = 0;
    if (resp != null && resp.length > 0) {
      for (const res of resp) {
        labs.push(new Date(res.value2).toDateString());
        total += res.value1;
        count += 1;
        if (count === resp.length) {
          count = 0;
          for (const val of resp) {
            const p = Math.round((((val.value1 / total) * 100) + Number.EPSILON) * 100) / 100;
            datasets.push(p);
            count += 1;
          }

          if (count === resp.length) {
            this.startedontimeData = {
              labels: labs,
              datasets: [
                  {
                      label: perc,
                      data: datasets,
                      backgroundColor: '#42A5F5',
                      borderColor: '#1E88E5',
                  }]
              };
          }
        }
      }
    } else {
      //
    }
   }

   async getAnalyticsClosedOnTimeByDay(tids) {
    let perc = '';
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.PERCENTAGE').subscribe((trans) => {
      perc = trans;
    });

    const resp = await this.analyticsService.getAnalyticsGeneric(this.confid, 'T', this.start, this.end,
                       this.uid, 'closedontime', ' ', tids)
    .toPromise().catch(err => this.handleError(err));
    this.closedontimeData = [];
    const labs: string[] = [];
    const datasets: number[] = [];
    let count = 0;
    let total = 0;
    if (resp != null && resp.length > 0) {
      for (const res of resp) {
        labs.push(new Date(res.value2).toDateString());
        total += res.value1;
        count += 1;
        if (count === resp.length) {
          count = 0;
          for (const val of resp) {
            const p = Math.round((((val.value1 / total) * 100) + Number.EPSILON) * 100) / 100;
            datasets.push(p);
            count += 1;
          }

          if (count === resp.length) {
            this.closedontimeData = {
              labels: labs,
              datasets: [
                  {
                      label: perc,
                      data: datasets,
                      backgroundColor: '#42A5F5',
                      borderColor: '#1E88E5',
                  }]
              };
          }
        }
      }
    } else {
      //
    }
   }

   confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  handleError(err) {
    this.load = false;
    console.log(err);
  }

}
