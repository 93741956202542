import { HttpClient } from '@angular/common/http';
import { OrderCategoryItemService } from './services/order-category-item/order-category-item.service';
import { OrderCategoryService } from './services/order-category/order-category.service';
import { LocationService } from './services/location/location.service';
import { MonitorsComponent } from './components/monitors/monitors.component';
import { PanelsComponent } from './components/panels/panels.component';
import { environment } from 'src/environments/environment';
import { LanguageService } from './services/language/language.service';
import { FileService } from './services/file/file.service';
import { KeycloakService } from 'keycloak-angular';
import { MonitorsService } from 'src/app/services/monitors/monitors.service';
import { PanelsService } from 'src/app/services/panels/panels.service';
import { Router } from '@angular/router';
import { UsersgroupsService } from 'src/app/services/usersgroups/usersgroups.service';
import { UserRolesService } from './services/user-roles/user-roles.service';
import { CookieService } from 'ngx-cookie-service';
import { Component, OnInit } from '@angular/core';
import { MenuItem, ConfirmationService, MegaMenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import * as CryptoJS from 'crypto-js';
import { GroupService } from './services/group/group.service';
import { PermissionService } from './services/permission/permission.service';
import { UsersService } from './services/users/users.service';
import { Variables } from './Models/variables';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import * as signalR from '@aspnet/signalr';
import { UpdateService } from './services/update/update.service';
import { DataCache } from './Helpers/data-cache';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  title = 'NTM-UI-Portal';

  // items: MegaMenuItem[];
  items: MenuItem[];
  load = false;

  loading = false;
  groupids: string;

  showmenu = false;

  pop = true;

  appicon: HTMLLinkElement = document.querySelector('#appicon');

  infoText = 'Information';
  closeText = 'Close';

  panelMenu: any;
  monitorMenu: any;

  private hubConnection: HubConnection | undefined;

  constructor(
    private confirmationService: ConfirmationService,
    public translate: TranslateService,
    private cookieService: CookieService,
    private router: Router,
    private panelService: PanelsService,
    private monitorService: MonitorsService,
    private userService: UsersService,
    private permService: PermissionService,
    private groupService: GroupService,
    private keycloakService: KeycloakService,
    private fileService: FileService,
    private language: LanguageService,
    private panelComp: PanelsComponent,
    private monitorComp: MonitorsComponent,
    private myService: UpdateService,
    private locService: LocationService,
    private ocatService: OrderCategoryService,
    private ocatItemService: OrderCategoryItemService,
    private http: HttpClient
  ) {
    localStorage.removeItem('tsk-selected');
    localStorage.removeItem('tsk-filter');

    localStorage.removeItem('not-selected');
    localStorage.removeItem('not-filter');

    router.navigateByUrl('');

    this.translate.addLangs(['English', 'French']);
    this.translate.setDefaultLang('English');

    let browserLang = this.translate.getBrowserLang();

    const pass = 'ranitessarldev2019';
    const lang = cookieService.get('goepla-portal-language');
    if (lang.trim() !== '') {
      const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
      const lg = declang;
      browserLang = lg;
      localStorage.setItem('language', lg);
    }

    if (localStorage.getItem('language') !== null) {
      browserLang = localStorage.getItem('language');
    }
    this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');
    this.loading = true;
  }

  async ngOnInit() {
    this.load = true;

    if (localStorage.getItem('browser') === null || localStorage.getItem('browser') === 'false') {
      await this.showInfo('browser');
      return;
    }

    await this.setIcon();
    await this.getUser();
    this.connect();
  }

  async setIcon() {
    this.loading = true;
    const file = await this.fileService.getFiles(1, 1, ' ', 'ntm-icon', 'ntm-bucket', ' ', ' ')
    .toPromise().catch(err => this.handleError(err));
    if (file !== undefined && file !== null && file.length > 0) {
      const furl = await this.fileService.getFile('ntm-bucket', file[0].fileId)
      .toPromise().catch(err => this.handleError(err));
      if (furl !== null && furl.url !== null) {
        this.appicon.href = furl.url;
      }
    }
    this.loading = false;
  }

  async getUser() {
    this.loading = true;
    const eml = (await this.keycloakService.loadUserProfile()).email;
    const ress = await this.userService.getUser(0, 0, eml, null).toPromise().catch(err => this.handleError(err));

    if (ress !== undefined && ress !== null && ress.userId > 0) {
      const v = new Variables();
      const oid = CryptoJS.AES.encrypt(JSON.stringify(ress.organisationId), v.pass).toString();
      const uid = CryptoJS.AES.encrypt(JSON.stringify(ress.userId), v.pass).toString();
      localStorage.setItem('oi', oid);
      localStorage.setItem('ui', uid);
      localStorage.setItem('org', ress.organisationName);
      const phone = CryptoJS.AES.encrypt(ress.phone, v.pass).toString();
      localStorage.setItem('phr', phone);

      localStorage.setItem('uname', ress.firstName + ' ' + ress.lastName);

      await this.showInfo('null');
      await this.getGroups();

      this.cacheData();
    } else {
      this.loading = false;
      this.load = false;
      this.pop = false;
      // this.router.navigateByUrl('/unauthorizeduser');
      await this.showInfo('user');
    }
  }

  async getGroups() {
    this.loading = true;

    const v = new Variables();
    const va = localStorage.getItem('ui');
    const ui = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);

    const uid = +ui;
    // const uid = +localStorage.getItem('user-id');
    let mygroups = '';
    let username = '';
    let groupids = '';
    const gis = [];
    const usergroups = await this.groupService.getUserGroups(0, uid).toPromise()
                                                .catch(err => this.handleError(err));

    if (usergroups != null && usergroups.length > 0) {
      for (const val of usergroups) {
        groupids += val.groupId + ':';
        gis.push(val.groupId);
        const group = await this.groupService.getGroupById(val.groupId).toPromise()
                                              .catch(err => this.handleError(err));
        if (group != null && group.length > 0) {
          mygroups += group[0].groupName + ':';
        }
      }
    }

    const gs = CryptoJS.AES.encrypt(mygroups, v.pass);
    localStorage.setItem('mg', gs.toString());

    const gds = JSON.stringify(gis);
    const gids = CryptoJS.AES.encrypt(gds, v.pass);
    localStorage.setItem('gi', gids);

    const user = await this.userService.getUserInfo(uid, 0, ' ', ' ').toPromise()
    .catch(err => this.handleError(err));
    if (user != null && user.firstName != null && user.lastName != null) {
      username = user.firstName + ' ' + user.lastName;
    }

    this.groupids = groupids;

    const ugs = CryptoJS.AES.encrypt(groupids, v.pass);
    localStorage.setItem('ugids', ugs);

    await this.getPermissions(user, mygroups);

    this.loading = false;
  }

  async getPermissions(org, mygroups) {
    this.loading = true;
    const permissions = [];
    const groupids = this.groupids;
    // const groupids = localStorage.getItem('user-group-ids');
    if (groupids !== undefined && groupids != null) {
      const gids = groupids.split(':');
      if (gids !== undefined && gids !== null && gids.length > 0) {
        for (const id of gids) {
          if (id !== '') {
            const perms = await this.permService.getUserPermissions(0, id, ' ').toPromise()
            .catch(err => this.handleError(err));
            if (perms != null && perms.length > 0) {
              for (const perm of perms) {
                // localStorage.setItem(perm.perm, perm.perm);
                permissions.push(perm.perm);
              }
            }
          }
        }
      }
    }

    if (org === 'ORG_SUPER_ADMIN' || mygroups.includes('Administrators-Full_Group')) {
      permissions.push('NTM.Administration.Full');
    }

    const v = new Variables();
    const p = CryptoJS.AES.encrypt(JSON.stringify(permissions), v.pass).toString();
    localStorage.setItem('ups', p);

    await this.buildMenu(permissions);
    this.loading = false;
  }

  async buildMenu(perm) {
    this.items = [];

    if (perm != null && perm.length > 0) {
      let isoverview = false;
      if (perm.includes('NTM.Section.Overview') || perm.includes('NTM.Administration.Full')) {
        isoverview = true;
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.OVERVIEW').subscribe((resp1: string) => {
          this.items.push({ label: resp1, routerLink: ['/overview'], icon: 'overview-icon' });
        });
      }

      // set dashboards
      if (perm.includes('NTM.Section.Dashboard') || perm.includes('NTM.Administration.Full')) {
        const dashboarditems = [];
        if (perm.includes('NTM.Dashboard.UserVsGlobal') || perm.includes('NTM.Administration.Full')) {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.USERVSGLOBAL').subscribe((trans2: string) => {
            dashboarditems.push({ label: trans2, routerLink: ['/dashboard-user-vs-global'], id: 'duvg' });
          });
        }
        if (perm.includes('NTM.Dashboard.PerformanceTenDays') || perm.includes('NTM.Administration.Full')) {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.PERFORMANCETENDAYS').subscribe((trans3: string) => {
            dashboarditems.push({ label: trans3, routerLink: ['/dashboard-performance-10-days'], id: 'dptd' });
          });
        }
        if (perm.includes('NTM.Dashboard.PerformanceTenHours') || perm.includes('NTM.Administration.Full')) {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.PERFORMANCETENHOURS').subscribe((trans4: string) => {
            dashboarditems.push({ label: trans4, routerLink: ['/dashboard-performance-10-hours'], id: 'dpth' });
          });
        }

        if (perm.includes('NTM.Dashboard.ProcessComplianceOverview') || perm.includes('NTM.Administration.Full')) {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.PROCESSCOMPLIANCEOVERVIEW').subscribe((trans: string) => {
            dashboarditems.push({ label: trans, routerLink: ['/dashboard-process-compliance-overview'], id: 'dpco' });
          });
        }

        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.DASHBOARD').subscribe((trans: string) => {
          this.items.push({
            label: trans,
            id: 'dashboard',
            items: dashboarditems,
            // items: [[{ items: dashboarditems }]],
            icon: 'dashboard-icon'
          });
        });
      }

      // set panels
      if (perm.includes('NTM.Section.Panels') || perm.includes('NTM.Administration.Full')) {
        const res = await this.panelService.getPanels(0, 0).toPromise()
        .catch(err => this.handleError(err));
        const its = [];
        if (res != null && res.length > 0) {
          const v = new Variables();
          const gis = localStorage.getItem('gi');
          const gs = CryptoJS.AES.decrypt(gis, v.pass).toString(CryptoJS.enc.Utf8);
          const gids = JSON.parse(gs);

          let count = 0;
          // tslint:disable-next-line: prefer-for-of
          for (let i = 0; i < res.length; i++) {
            // count += 1;
            if (res[i].typeRoleGroups !== null && res[i].typeRoleGroups.length > 0) {
              let show = false;
              for (const val of res[i].typeRoleGroups) {
                if (val.typeRoleGroup.roleName === 'Reader-All' && gids.includes(val.typeRoleGroup.groupId)) {
                  show = true;
                }
              }

              if (perm.includes('NTM.Administration.Full')) {
                show = true;
              }

              if (show === true) {
                its.push({ label: res[i].panelName, routerLink: ['/panels/' + res[i].panelName + '/' + res[i].panelId] });
                // continue;
              }
            } else {
              if (perm.includes('NTM.Administration.Full')) {
                its.push({ label: res[i].panelName, routerLink: ['/panels/' + res[i].panelName + '/' + res[i].panelId] });
              }
            }

            // its.push({ label: res[i].panelName, routerLink: ['/panels/' + res[i].panelName + '/' + res[i].panelId] });
            count += 1;
            if (count === res.length) {
              if (its.length === 0)  {
                continue;
              }
              // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.PANELS').subscribe(async (resp: string) => {
                this.items.push({
                  label: resp,
                  items: its,
                  // items: [[{ items: its }]],
                  icon: 'panels-icon'
                });
                this.panelMenu = { label: resp, items: its };
              });
            }
          }

          if (its.length === 0) {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.PANELS').subscribe(async (resp: string) => {
              this.items.push({
                label: resp,
                // items: its,
                // items: [[{ items: its }]],
                icon: 'panels-icon'
              });
              this.panelMenu = { label: resp, items: its };
            });
          }
        } else {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.PANELS').subscribe(async (resp: string) => {
            this.items.push({
              label: resp,
              // items: its,
              // items: [[{ items: its }]],
              icon: 'panels-icon'
            });
            this.panelMenu = { label: resp, items: its };
          });
        }
      }

      // set monitors
      if (perm.includes('NTM.Section.Monitors') || perm.includes('NTM.Administration.Full')) {
        const mons = await this.monitorService.getMonitors(0, 0).toPromise()
        .catch(err => this.handleError(err));
        const mits = [];
        if (mons != null && mons.length > 0) {
          // get logged in user group ids
          const v = new Variables();
          const gis = localStorage.getItem('gi');
          const gs = CryptoJS.AES.decrypt(gis, v.pass).toString(CryptoJS.enc.Utf8);
          const gids = JSON.parse(gs);

          let counter = 0;
          for (const mn of mons) {
            // check user has right to view and select monitor
            if (mn.typeRoleGroups !== null && mn.typeRoleGroups.length > 0) {
              let show = false;
              for (const val of mn.typeRoleGroups) {
                if (val.typeRoleGroup.roleName === 'Reader-All' && gids.includes(val.typeRoleGroup.groupId)) {
                  show = true;
                }
              }

              if (perm.includes('NTM.Administration.Full')) {
                show = true;
              }

              if (show === true) {
                mits.push({ label: mn.monitorName, routerLink: ['/monitors/' + mn.monitorName + '/' + mn.monitorId] });
                // continue;
              }
            } else {
              if (perm.includes('NTM.Administration.Full')) {
                mits.push({ label: mn.monitorName, routerLink: ['/monitors/' + mn.monitorName + '/' + mn.monitorId] });
              }
            }
            // mits.push({ label: mn.monitorName, routerLink: ['/monitors/' + mn.monitorName + '/' + mn.monitorId] });
            counter += 1;
            if (counter === mons.length) {
              if (mits.length === 0)  {
                continue;
              }
              // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.MONITORS').subscribe(async (resp: string) => {
                this.items.push({ label: resp, items: mits, icon: 'monitors-icon' });
                // this.items.push({ label: resp, items: [[{ items: mits }]], icon: 'monitors-icon' });
                this.monitorMenu = { label: resp, items: mits };
              });
            }
          }
          if (mits.length === 0)  {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.MONITORS').subscribe(async (resp: string) => {
              this.items.push({ label: resp, icon: 'monitors-icon' });
            });
          }
        } else {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.MONITORS').subscribe(async (resp: string) => {
            this.items.push({ label: resp, icon: 'monitors-icon' });
          });
        }
      }

      // set info
      if (perm.includes('NTM.Section.Information') || perm.includes('NTM.Administration.Full')) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.INFO').subscribe((resp4: string) => {
          this.items.push({ label: resp4, routerLink: ['/notifications'], icon: 'information-icon' });
        });
      }

      // set tasks
      if (perm.includes('NTM.Section.Tasks') || perm.includes('NTM.Administration.Full')) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.TASKS').subscribe((resp3: string) => {
          this.items.push({ label: resp3, routerLink: ['/tasks'], icon: 'tasks-icon' });
        });
      }

      // set reminders
      if (perm.includes('NTM.Section.Reminders') || perm.includes('NTM.Administration.Full')) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.REMINDERS').subscribe((resp4: string) => {
          this.items.push({ label: resp4, routerLink: ['/reminders'], icon: 'reminders-icon' });
        });
      }

      // set devices
      if (perm.includes('NTM.Section.Devices') || perm.includes('NTM.Administration.Full')) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.DEVICES').subscribe((trans: string) => {
          this.items.push({label: trans, routerLink: ['/devices'], icon: 'devices-icon'});
        });
      }

      // set channels
      if (perm.includes('NTM.Section.Channels') || perm.includes('NTM.Administration.Full')) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.CHANNELS').subscribe((trans: string) => {
          this.items.push({label: trans, routerLink: ['/user-channels'], icon: 'channels-icon'});
        });
      }

      // set orders
      if (perm.includes('NTM.Section.Orders') || perm.includes('NTM.Administration.Full')) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.ORDERS').subscribe((trans: string) => {
          this.items.push({label: trans, routerLink: ['/orders'], icon: 'orders-icon'});
        });
      }

      // set analytics
      if (perm.includes('NTM.Section.Analytics') || perm.includes('NTM.Administration.Full')) {
        const analyticsitems = [];
        const general = [];
        const byuser = [];
        const performance = [];
        const info = [];
        if (perm.includes('NTM.Analytics.OverviewInQueue') || perm.includes('NTM.Administration.Full')) {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.OVERVIEWINQUEUE').subscribe((resp16: string) => {
            analyticsitems.push({ label: resp16, routerLink: ['/overview-in-queue'] });
          });
        }
        if (perm.includes('NTM.Analytics.InQueueByStatus') || perm.includes('NTM.Administration.Full')) {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.INQUEUEBYSTATUS').subscribe((resp17: string) => {
            analyticsitems.push({ label: resp17, routerLink: ['/in-queue-by-status'] });
          });
        }
        if (perm.includes('NTM.Analytics.TimeToClosureValues') || perm.includes('NTM.Administration.Full')) {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.TIMETOCLOSUREVALUES').subscribe((resp18: string) => {
            analyticsitems.push({ label: resp18, routerLink: ['/time-to-closure-values'] });
          });
        }
        if (perm.includes('NTM.Analytics.TimeToClosureAvgTrendByWeek') || perm.includes('NTM.Administration.Full')) {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.TIMETOCLOSUREAVERAGETRENDBYWEEK2').subscribe((resp19: string) => {
            analyticsitems.push({ label: resp19, routerLink: ['/time-to-closure-average-trend-by-week'] });
          });
        }
        if (perm.includes('NTM.Analytics.TimeToClosureAvgTrendByUser') || perm.includes('NTM.Administration.Full')) {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.TIMETOCLOSURETRENDBYUSER2').subscribe((resp20: string) => {
            analyticsitems.push({ label: resp20, routerLink: ['/time-to-closure-trend-by-user'] });
          });
        }
        if (perm.includes('NTM.Analytics.ClosedTasksByUser') || perm.includes('NTM.Administration.Full')) {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.CLOSEDTASKSBYUSER').subscribe((resp21: string) => {
            analyticsitems.push({ label: resp21, routerLink: ['/closed-tasks-by-user'] });
          });
        }
        if (perm.includes('NTM.Analytics.CycleTimeClosed') || perm.includes('NTM.Administration.Full')) {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.CYCLETIMECLOSED2').subscribe((resp22: string) => {
            analyticsitems.push({ label: resp22, routerLink: ['/cycle-time-closed'] });
          });
        }
        if (perm.includes('NTM.Analytics.AvgTimeOnStatus') || perm.includes('NTM.Administration.Full')) {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.AVERAGETIMEONSTATUS').subscribe((resp23: string) => {
            analyticsitems.push({ label: resp23, routerLink: ['/average-time-on-status'] });
          });
        }
        if (perm.includes('NTM.Analytics.CreatedByWeek') || perm.includes('NTM.Administration.Full')) {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.CREATEDBYWEEK').subscribe((resp24: string) => {
            analyticsitems.push({ label: resp24, routerLink: ['/created-by-week'] });
          });
        }
        if (perm.includes('NTM.Analytics.UserActivitiesByDay') || perm.includes('NTM.Administration.Full')) {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.USERACTIVITIESBYDAY').subscribe((resp25: string) => {
            analyticsitems.push({ label: resp25, routerLink: ['/user-activities-by-day'] });
          });
        }
        if (perm.includes('NTM.Analytics.ClosedTaskDistribution') || perm.includes('NTM.Administration.Full')) {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.CLOSEDTASKDISTRIBUTION').subscribe((resp26: string) => {
            analyticsitems.push({ label: resp26, routerLink: ['/closed-task-distribution'] });
          });
        }
        if (perm.includes('NTM.Analytics.PerformanceOverview') || perm.includes('NTM.Administration.Full')) {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.PERFORMANCEOVERVIEW').subscribe((resp26: string) => {
            analyticsitems.push({ label: resp26, routerLink: ['/performance-overview'] });
          });
        }
        if (perm.includes('NTM.Analytics.OverviewByUser') || perm.includes('NTM.Administration.Full')) {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.OVERVIEWBYUSER').subscribe((resp26: string) => {
            analyticsitems.push({ label: resp26, routerLink: ['/overview-by-user'] });
          });
        }
        if (perm.includes('NTM.Analytics.ProcessComplianceRanking') || perm.includes('NTM.Administration.Full')) {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.PROCESSCOMPLIANCERANKING').subscribe((resp: string) => {
            analyticsitems.push({ label: resp, routerLink: ['/process-compliance-ranking'] });
          });
        }
        if (perm.includes('NTM.Analytics.TaskClassification') || perm.includes('NTM.Administration.Full')) {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.TASKCLASSIFICATION').subscribe((resp: string) => {
            analyticsitems.push({ label: resp, routerLink: ['/task-classification'] });
          });
        }
        if (perm.includes('NTM.Analytics.InformationReadConfirmationOverview') || perm.includes('NTM.Administration.Full')) {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.INFOREADCONFOVERVIEW').subscribe((resp: string) => {
            analyticsitems.push({ label: resp, routerLink: ['/info-read-confirmation-overview'] });
          });
        }
        if (perm.includes('NTM.Analytics.DistributionTimeToRead') || perm.includes('NTM.Administration.Full')) {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.DISTTIMETOREAD').subscribe((resp: string) => {
            analyticsitems.push({ label: resp, routerLink: ['/distribution-time-to-read'] });
          });
        }
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.ANALYTICS').subscribe((resp15: string) => {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.GENERAL').subscribe((gen: string) => {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.BYUSER').subscribe((byu: string) => {
              // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.PERFORMANCE').subscribe((per: string) => {
                // tslint:disable-next-line: deprecation
                this.translate.get('GENERAL.INFO').subscribe((inf: string) => {
                  this.items.push({
                    label: resp15,
                    items: analyticsitems,
                    // items: [
                    //   [{ label: gen, items: general },
                    //   { label: byu, items: byuser },
                    //   { label: per, items: performance },
                    //   { label: inf, items: info }]
                    // ],
                    icon: 'analytics-icon'
                  });
                });
              });
            });
          });
        });
      }

      if (perm.includes('NTM.Section.Administration') || perm.includes('NTM.Administration.Full')) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.ADMINISTRATION').subscribe((resp5: string) => {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.TASKCONFIGURATIONS').subscribe((resp6: string) => {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.INFOCONFIGURATIONS').subscribe((resp7: string) => {
              // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.PANELSCONFIGURATIONS').subscribe((resp8: string) => {
                // tslint:disable-next-line: deprecation
                this.translate.get('GENERAL.MONITORCONFIGURATIONS').subscribe((resp9: string) => {
                  // tslint:disable-next-line: deprecation
                  this.translate.get('GENERAL.REMINDERTYPES').subscribe((resp10: string) => {
                    // tslint:disable-next-line: deprecation
                    this.translate.get('GENERAL.CHANNELS').subscribe((resp11: string) => {
                      // tslint:disable-next-line: deprecation
                      this.translate.get('GENERAL.LOCATIONS').subscribe((resploc: string) => {
                        // tslint:disable-next-line: deprecation
                        this.translate.get('GENERAL.ORDERCATEGORIES').subscribe((respocat: string) => {
                          // tslint:disable-next-line: deprecation
                          this.translate.get('GENERAL.ITEMDEFINITIONS').subscribe((respitems: string) => {

                            const its = [];
                            its.push({ label: resp6, routerLink: ['/taskconfigurations'] });
                            its.push({ label: resp7, routerLink: ['/informationconfigurations'] });
                            its.push({ label: resp8, routerLink: ['/panelconfigurations'] });
                            its.push({ label: resp9, routerLink: ['/monitorconfigurations'] });
                            its.push({ label: resp10, routerLink: ['/remindertypes'] });
                            its.push({ label: resp11, routerLink: ['/channels'] });
                            its.push({ label: resploc, routerLink: ['/locations'] });
                            its.push({ label: respocat, routerLink: ['/order-categories'] });
                            its.push({ label: respitems, routerLink: ['/item-definitions'] });
                            this.items.push({
                              label: resp5,
                              items: its,
                              // items: [[{ items: its }]],
                              icon: 'administration-icon'
                            });
                          });
                        });
                      });
                    });
                  });
                });
              });
            });
          });
        });
      }

      const height = document.getElementById('contentdiv').offsetHeight;
      if (height !== undefined && height !== null) {
        localStorage.setItem('content', height.toString());
      }

      if (this.items.length === 0) {
        this.load = false;
        this.router.navigateByUrl('/unauthorizeduser');
      }

      if (isoverview === true) {
        this.load = true;
        this.router.navigateByUrl('/overview');
      }
    } else {
      this.load = false;
      this.router.navigateByUrl('/unauthorizeduser');
    }
  }

  async loadMenuAgain() {
    this.translate.addLangs(['English', 'French']);
    this.translate.setDefaultLang('English');

    let browserLang = this.translate.getBrowserLang();

    const pass = 'ranitessarldev2019';
    const lang = this.cookieService.get('goepla-portal-language');
    if (lang.trim() !== '') {
      const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
      const lg = declang;
      browserLang = lg;
      localStorage.setItem('language', lg);
    }

    if (localStorage.getItem('language') !== null) {
      browserLang = localStorage.getItem('language');
    }
    this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    const p = localStorage.getItem('ups');
    if (p == null) {
      return false;
    }
    const v = new Variables();
    const pe = CryptoJS.AES.decrypt(p, v.pass).toString(CryptoJS.enc.Utf8);
    const perm = JSON.parse(pe);
    // this.items = [];

    if (perm != null && perm.length > 0) {
      // set panels
      if (perm.includes('NTM.Section.Panels') || perm.includes('NTM.Administration.Full')) {
        const gis = localStorage.getItem('gi');
        const gs = CryptoJS.AES.decrypt(gis, v.pass).toString(CryptoJS.enc.Utf8);
        const gids = JSON.parse(gs);

        const res = await this.panelService.getPanels(0, 0).toPromise()
        .catch(err => this.handleError(err));
        const its = [];
        if (res != null && res.length > 0) {
          let count = 0;
          // tslint:disable-next-line: prefer-for-of
          for (let i = 0; i < res.length; i++) {
            if (res[i].typeRoleGroups !== null && res[i].typeRoleGroups.length > 0) {
              let show = false;
              for (const val of res[i].typeRoleGroups) {
                if (val.typeRoleGroup.roleName === 'Reader-All' && gids.includes(val.typeRoleGroup.groupId)) {
                  show = true;
                }
              }
              if (perm.includes('NTM.Administration.Full')) {
                show = true;
              }
              if (show === true) {
                its.push({ label: res[i].panelName, routerLink: ['/panels/' + res[i].panelName + '/' + res[i].panelId] });
              }
            } else {
              if (perm.includes('NTM.Administration.Full')) {
                its.push({ label: res[i].panelName, routerLink: ['/panels/' + res[i].panelName + '/' + res[i].panelId] });
              }
            }
            // its.push({ label: res[i].panelName, routerLink: ['/panels/' + res[i].panelName + '/' + res[i].panelId] });
            count += 1;
            if (count === res.length) {
              // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.PANELS').subscribe(async (resp: string) => {
                let pindex = -1;
                for (const value of this.items) {
                  if (value.label === 'Panels' || value.label === 'Panneaux') {
                    pindex = this.items.indexOf(value, 0);
                  }
                }
                if (pindex > -1) {
                  // this.items[pindex] = { label: resp, items: [[{ items: its }]], icon: 'panels-icon' };
                  this.items[pindex] = { label: resp, items: its, icon: 'panels-icon' };
                }
              });
            }
          }
        } else {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.PANELS').subscribe(async (resp: string) => {
            let pindex = -1;
            for (const value of this.items) {
              if (value.label === 'Panels' || value.label === 'Panneaux') {
                pindex = this.items.indexOf(value, 0);
              }
            }
            if (pindex > -1) {
              // this.items[pindex] = { label: resp, items: [[{ items: its }]], icon: 'panels-icon' };
              this.items[pindex] = { label: resp, icon: 'panels-icon' };
            }
          });
        }
      }

      // set monitors
      if (perm.includes('NTM.Section.Monitors') || perm.includes('NTM.Administration.Full')) {
        const gis = localStorage.getItem('gi');
        const gs = CryptoJS.AES.decrypt(gis, v.pass).toString(CryptoJS.enc.Utf8);
        const gids = JSON.parse(gs);

        const mons = await this.monitorService.getMonitors(0, 0).toPromise()
        .catch(err => this.handleError(err));
        const mits = [];
        if (mons != null && mons.length > 0) {
          let counter = 0;
          for (const mn of mons) {
            if (mn.typeRoleGroups !== null && mn.typeRoleGroups.length > 0) {
              let show = false;
              for (const val of mn.typeRoleGroups) {
                if (val.typeRoleGroup.roleName === 'Reader-All' && gids.includes(val.typeRoleGroup.groupId)) {
                  show = true;
                }
              }

              if (perm.includes('NTM.Administration.Full')) {
                show = true;
              }

              if (show === true) {
                mits.push({ label: mn.monitorName, routerLink: ['/monitors/' + mn.monitorName + '/' + mn.monitorId] });
              }
            } else {
              if (perm.includes('NTM.Administration.Full')) {
                mits.push({ label: mn.monitorName, routerLink: ['/monitors/' + mn.monitorName + '/' + mn.monitorId] });
              }
            }
            // mits.push({ label: mn.monitorName, routerLink: ['/monitors/' + mn.monitorName + '/' + mn.monitorId] });
            counter += 1;
            if (counter === mons.length) {
              // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.MONITORS').subscribe(async (resp: string) => {
                let mindex = -1;
                for (const value of this.items) {
                  if (value.label === 'Monitors' || value.label === 'Moniteurs') {
                    mindex = this.items.indexOf(value, 0);
                  }
                }
                if (mindex > -1) {
                  // this.items[mindex] = { label: resp, items: [[{ items: mits }]], icon: 'monitors-icon' };
                  this.items[mindex] = { label: resp, items: mits, icon: 'monitors-icon' };
                }
              });
            }
          }
        } else {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.MONITORS').subscribe(async (resp: string) => {
            let mindex = -1;
            for (const value of this.items) {
              if (value.label === 'Monitors' || value.label === 'Moniteurs') {
                mindex = this.items.indexOf(value, 0);
              }
            }
            if (mindex > -1) {
              // this.items[mindex] = { label: resp, items: [[{ items: mits }]], icon: 'monitors-icon' };
              this.items[mindex] = { label: resp, icon: 'monitors-icon' };
            }
          });
        }
      }

      if (this.items.length === 0) {
        this.load = false;
        this.router.navigateByUrl('/unauthorizeduser');
      }
    } else {
      this.load = false;
      this.router.navigateByUrl('/unauthorizeduser');
    }
  }

  async showInfo(info) {
    this.loading = true;
    if (this.keycloakService === undefined || this.keycloakService === null
      || this.keycloakService.getKeycloakInstance() === undefined
      || this.keycloakService.getKeycloakInstance() === null) {
      if (info === 'browser') {
        this.load = false;
        // this.confirm('Browser doesn\'t fulfill the browser requirements:' +
        // ' The Browser requirements are: Browser type = Chrome, Minimum browser version = 80.0.3987.122');
        localStorage.setItem('message', 'Browser doesn\'t fulfill the browser requirements:' +
        ' The Browser requirements are: Browser type = Chrome, Minimum browser version = 80.0.3987.122');
        localStorage.setItem('info', this.infoText);
        localStorage.setItem('but', this.closeText);
        this.router.navigateByUrl('xxxxx');
      } else if (info === 'user') {
        // this.confirm('USER NOT FOUND IN USM. User can\'t continue using the application !!!!!');
        localStorage.setItem('message', 'USER NOT FOUND. User can\'t continue using the application !!!!!');
        localStorage.setItem('info', this.infoText);
        localStorage.setItem('but', this.closeText);
        this.router.navigateByUrl('xxxxx');
      }
      this.loading = false;
      return;
    } else {
      if (localStorage.getItem('language') === null) {
        // Replace the user id with the users user id later
        const lang = await this.language.getLanguage(1).toPromise().catch(err => this.handleError(err));
        if (lang.userId > 0) {
          localStorage.setItem('language', lang.lang);

          this.translate.addLangs(['English', 'French']);
          this.translate.setDefaultLang('English');

          let browserLang = this.translate.getBrowserLang();
          if (localStorage.getItem('language') !== null) {
            browserLang = localStorage.getItem('language');
          }
          this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');
        } else {
          this.translate.addLangs(['English', 'French']);
          this.translate.setDefaultLang('English');

          let browserLang = this.translate.getBrowserLang();
          if (localStorage.getItem('language') !== null) {
            browserLang = localStorage.getItem('language');
          }
          this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');
        }

        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.INFO').subscribe((resp) => {
          this.infoText = resp;
        });

        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.CLOSEDIALOG').subscribe((resp) => {
          this.closeText = resp;
        });

        if (info === 'browser') {
          this.load = false;
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.BROWSERCHECK').subscribe((resp) => {
            // this.confirm(resp);
            localStorage.setItem('message', resp);
            localStorage.setItem('info', this.infoText);
            localStorage.setItem('but', this.closeText);
            this.router.navigateByUrl('xxxxx');
          });
        } else if (info === 'user') {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.USERNOTFOUND').subscribe((resp) => {
            // this.confirm(resp);
            localStorage.setItem('message', resp);
            localStorage.setItem('info', this.infoText);
            localStorage.setItem('but', this.closeText);
            this.router.navigateByUrl('xxxxx');
          });
        }
      } else {
        if (info === 'browser') {
          this.load = false;
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.BROWSERCHECK').subscribe((resp) => {
            // this.confirm(resp);
            localStorage.setItem('message', resp);
            localStorage.setItem('info', this.infoText);
            localStorage.setItem('but', this.closeText);
            this.router.navigateByUrl('xxxxx');
          });
        } else if (info === 'user') {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.USERNOTFOUND').subscribe((resp) => {
            // this.confirm(resp);
            localStorage.setItem('message', resp);
            localStorage.setItem('info', this.infoText);
            localStorage.setItem('but', this.closeText);
            this.router.navigateByUrl('xxxxx');
          });
        }
      }
    }

    this.loading = false;
  }

  connect() {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(environment.apiUrl + '/signalRNotification', {
        // skipNegotiation: true,
        // transport: signalR.HttpTransportType.WebSockets
      })
      .configureLogging(signalR.LogLevel.None)
      .build();
    this.hubConnection
      .start()
      .then(() => { const abc = ''; })
      .catch(err => {
        console.log('Error while establishing connection', err);
        setTimeout(() => {
          this.connect();
        }, 5000); // Restart connection after 5 seconds.
      });

    this.hubConnection.on('updatedTask', (name: string, message: string) => {
      this.myService.setRefresh(true);
      // this.panelComp.getPanels();
      // this.monitorComp.getMonitors();
    });

    this.hubConnection.onclose(err => {
      console.log('connection closed', err);
      setTimeout(() => {
        this.connect();
      }, 5000); // Restart connection after 5 seconds.
    });
  }

  toggle(e) {
    this.showmenu = !this.showmenu;
  }

  confirm(msg) {
    this.load = false;
    this.pop = true;
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
            if (this.keycloakService !== undefined && this.keycloakService !== null
              && this.keycloakService.getKeycloakInstance() !== undefined
              && this.keycloakService.getKeycloakInstance() !== null) {
              this.keycloakService.logout();
            }
        },
        reject: () => {
          if (this.keycloakService !== undefined && this.keycloakService !== null
            && this.keycloakService.getKeycloakInstance() !== undefined
            && this.keycloakService.getKeycloakInstance() !== null) {
            this.keycloakService.logout();
          }
        }
    });
  }

  cacheData() {
    DataCache.loadData(this.http);
    // 500000 milliseconds = 8.33333333 mins
    // tslint:disable-next-line: only-arrow-functions
    const to = setInterval(() => {
      DataCache.loadData(this.http);
    }, 500000);
  }

  handleError(err) {
    // this.loading = false;
  }

}

