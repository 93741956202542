import { TaskClassification } from './../../Models/task-classification';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { Urls } from 'src/app/Helpers/Urls';
import { Devices } from 'src/app/Models/Devices';
import { DeviceUpdater } from 'src/app/Models/Updater';
import { Variables } from 'src/app/Models/variables';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class TaskClassificationService {

  url = new Urls();
  apiUrl: string;
  orgId: number;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = this.url.apiUrl;
      const v = new Variables();
      const val = localStorage.getItem('oi');
      const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);

      this.orgId = +oi;
      // this.orgId = +localStorage.getItem('organisation-id');
  }

  getTaskClassifications(page: number, size: number, typeid: number, catid: number, classid: number,
                         itemid: number, start: string, end: string) {
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/taskclassification/' + page + '/' + size + '/' + typeid
    + '/' + catid + '/' + classid + '/' + itemid + '/' + start + '/' + end + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  getTaskClassificationsGeneric(page: number, size: number, typeid: number, catid: number, classid: number,
                                itemid: number, start: string, end: string, tids: string) {
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/taskclassification/' + page + '/' + size + '/' + typeid
    + '/' + catid + '/' + classid + '/' + itemid + '/' + start + '/' + end + '/' + tids + '/';
    return this.httpclient.get<any>(url)
    .pipe(
    tap( // Log the result or error
    error => this.handleError
    )
    );
  }

  postTaskClassification(tc: TaskClassification): Observable<any> {
    // const body = JSON.stringify(inst);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.post<any>(this.apiUrl + '/api/taskclassification', tc, options)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  private handleError(error: any) {
    // console.error('error', error);
    throw error;
    return error;
  }
}
