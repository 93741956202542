import { StringUpdater, KeyValue } from 'src/app/Models/Updater';
import { ReminderTypesService } from './../../services/reminder-types/reminder-types.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CookieService } from 'ngx-cookie-service';
import * as CryptoJS from 'crypto-js';
import { Variables } from 'src/app/Models/variables';

@Component({
  selector: 'app-reminder-types',
  templateUrl: './reminder-types.component.html',
  styleUrls: ['./reminder-types.component.css']
})
export class ReminderTypesComponent implements OnInit {

  types = [];

  page = 1;
  size = 100;

  disableLoadMore = false;
  showDetail = false;
  type: any = [];

  item = [];
  load = false;
  ismanage = false;

  doptions: any = { year: 'numeric', month: 'short', day: 'numeric',
  hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false };

  constructor(
    private router: Router,
    private notificationSvc: NotificationService,
    public translate: TranslateService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private remindertypeService: ReminderTypesService,
    private messageService: MessageService
    ) {
    // this.translate.addLangs(['English', 'French']);
    // this.translate.setDefaultLang('English');

    // let browserLang = this.translate.getBrowserLang();

    // const pass = 'ranitessarldev2019';
    // const lang = cookieService.get('goepla-portal-language');
    // if (lang.trim() !== '') {
    //   const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
    //   const lg = declang;
    //   browserLang = lg;
    // }

    // if (localStorage.getItem('language') !== null) {
    //   browserLang = localStorage.getItem('language');
    // }
    // this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    translate.use('English');

   }

  ngOnInit() {
    localStorage.removeItem('reminder-type');
    const v = new Variables();
    const p = localStorage.getItem('ups');
    if (p != null) {
      const val = CryptoJS.AES.decrypt(p, v.pass).toString(CryptoJS.enc.Utf8);
      const perm = JSON.parse(val);
      if (perm.includes('NTM.Administration.ReminderTypes') || perm.includes('NTM.Administration.Full')) {
        this.ismanage = true;
      }
    }
    this.getReminderTypes();
  }

  getReminderTypes() {
    this.load = true;
    // tslint:disable-next-line: deprecation
    this.remindertypeService.getReminderTypes(this.page, this.size).subscribe((res) => {
      if (res != null) {
        if (res.length < this.size) {
          this.disableLoadMore = true;
        } else {
          this.disableLoadMore = false;
        }
        for (const val of res) {
          const options = [];
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.DETAILS').subscribe((resp1: string) => {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.ENABLE').subscribe((resp2: string) => {
              // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.DISABLE').subscribe((resp3: string) => {
                // tslint:disable-next-line: deprecation
                this.translate.get('GENERAL.EDIT').subscribe((resp4: string) => {
                  if (this.ismanage === true) {
                    options.push({label: resp2, value: 'Enable'});
                    options.push({label: resp3, value: 'Disable'});
                    options.push({label: resp4, value: 'Edit'});
                  }
                  options.push({label: resp1, value: 'Details'});

                  val.options = options;
                });
              });
            });
          });

          if (+val.intervalCycle > 0) {
            val.intervalDurations = '-';
          } else {
            val.intervalCycle = '-';
          }

          val.created = new Date(val.created.toString().replace('T', ' ') + ' UTC').toLocaleString('en-US', this.doptions);
          val.expiration = new Date(new Date(val.created).getTime() + (+val.reminderExpiry * 60000)).toLocaleString('en-US', this.doptions);
          this.types.push(val);
        }
      } else {
        this.disableLoadMore = true;
        if (this.page === 1) {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.NOREMINDERTYPES').subscribe((trans) => {
            this.showInfo(trans);
          });
        } else {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.NOMOREREMINDERTYPES').subscribe((trans) => {
            this.showInfo(trans);
          });
        }
      }
      this.load = false;
    }, err => this.handleError(err));
  }

  loadMore() {
    this.page += 1;
    this.getReminderTypes();
  }

  gotoCreateReminderType() {
    this.router.navigateByUrl('/create-reminder-type');
  }

  saveType(event, type) {
    if (event.value === 'Enable') {
      this.update('ENABLED', type);
    } else if (event.value === 'Disable') {
      this.update('DISABLED', type);
    } else if (event.value === 'Details') {
      this.type = type;
      this.showDetail = true;
    } else if (event.value === 'Edit') {
      this.type = type;
      localStorage.setItem('reminder-type', JSON.stringify(type));
      this.router.navigateByUrl('/edit-reminder-type');
    }
    this.item = [];
  }

  update(value, type) {
    const update = new StringUpdater();
    update.Id = type.reminderTypeName;
    update.Values = [];

    const status = new KeyValue();
    status.Key = 'status';
    status.Value = value;
    update.Values.push(status);

    this.load = true;
    // tslint:disable-next-line: deprecation
    this.remindertypeService.putReminderType(update.Id, update).subscribe((res) => {
      if (res === true) {
        if (value === 'ENABLED') {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.ENABLED').subscribe((trans) => {
            this.showInfo(trans);
          });
        } else {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.DISABLED').subscribe((trans) => {
            this.showInfo(trans);
          });
        }
        this.types = [];
        this.page = 1;
        this.getReminderTypes();
      } else {
        this.load = false;
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.COULDNOTUPDATE').subscribe((trans) => {
          this.showError(trans);
        });
      }
    }, err => this.handleError(err));
  }

  showSuccess(msg) {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.SUCCESS').subscribe((trans) => {
      this.messageService.add({key: 'tc', severity: 'success', summary: trans, detail: msg});
    });
  }

  showInfo(msg) {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.INFO').subscribe((trans) => {
      this.messageService.add({key: 'tc', severity: 'info', summary: trans, detail: msg});
    });
  }

  showWarn(msg) {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.WARNING').subscribe((trans) => {
      this.messageService.add({key: 'tc', severity: 'warn', summary: trans, detail: msg});
    });
  }

  showError(msg) {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.ERROR').subscribe((trans) => {
      this.messageService.add({key: 'tc', severity: 'error', summary: trans, detail: msg});
    });
  }

  handleError(err) {
    this.load = false;
    this.item = [];
  }

}
