import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { ConfirmationService } from 'primeng/api';
import { CookieService } from 'ngx-cookie-service';
import { PanelsService } from 'src/app/services/panels/panels.service';
import { PanelItemsService } from 'src/app/services/panel-items/panel-items.service';
import * as CryptoJS from 'crypto-js';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-panel-item-detail',
  templateUrl: './panel-item-detail.component.html',
  styleUrls: ['./panel-item-detail.component.css']
})
export class PanelItemDetailComponent implements OnInit {
  panel: any;
  isTask = false;

  constructor(
    private router: Router,
    private notificationSvc: NotificationService,
    public translate: TranslateService,
    private configService: ConfigurationService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private panelService: PanelsService,
    private panelItemService: PanelItemsService,
    private location: Location,
    private domSanitizer: DomSanitizer
  ) {
    // this.translate.addLangs(['English', 'French']);
    // this.translate.setDefaultLang('English');

    // let browserLang = this.translate.getBrowserLang();

    // const pass = 'ranitessarldev2019';
    // const lang = cookieService.get('goepla-portal-language');
    // if (lang.trim() !== '') {
    //   const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
    //   const lg = declang;
    //   browserLang = lg;
    // }

    // if (localStorage.getItem('language') !== null) {
    //   browserLang = localStorage.getItem('language');
    // }
    // this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    translate.use('English');

    const pan = JSON.parse(localStorage.getItem('panel-item'));
    this.show(pan);
   }

  ngOnInit() {
  }

  show(panel) {
    localStorage.setItem('panel', JSON.stringify(panel));

    if (panel.configuration.priority === 1) {
      this.translate.get('GENERAL.CRITICAL').subscribe((res) => {
        panel.configuration.prior = res;
      });
    } else if (panel.configuration.priority === 2) {
      this.translate.get('GENERAL.HIGH').subscribe((res) => {
        panel.configuration.prior = res;
      });
    } else if (panel.configuration.priority === 3) {
      this.translate.get('GENERAL.NORMAL').subscribe((res) => {
        panel.configuration.prior = res;
      });
    } else if (panel.configuration.priority === 4) {
      this.translate.get('GENERAL.LOW').subscribe((res) => {
        panel.configuration.prior = res;
      });
    } else if (panel.configuration.priority === 4) {
      this.translate.get('GENERAL.VERYLOW').subscribe((res) => {
        panel.configuration.prior = res;
      });
    }

    if (panel.configuration.channel === 'M') {
      this.translate.get('GENERAL.MQTT').subscribe((res) => {
        panel.configuration.chan = res;
      });
    } else if (panel.configuration.channel === 'S') {
      this.translate.get('GENERAL.SMS').subscribe((res) => {
        panel.configuration.chan = res;
      });
    } else if (panel.configuration.channel === 'E') {
      this.translate.get('GENERAL.EMAIL').subscribe((res) => {
        panel.configuration.chan = res;
      });
    }

    if (panel.configuration.vibrate === 0) {
      this.translate.get('GENERAL.NONE').subscribe((res) => {
        panel.configuration.vib = res;
      });
    } else if (panel.configuration.vibrate === 1) {
      this.translate.get('GENERAL.SHORT').subscribe((res) => {
        panel.configuration.vib = res;
      });
    } else if (panel.configuration.vibrate === 2) {
      this.translate.get('GENERAL.LONG').subscribe((res) => {
        panel.configuration.vib = res;
      });
    } else if (panel.configuration.vibrate === 3) {
      this.translate.get('GENERAL.VERYLONG').subscribe((res) => {
        panel.configuration.vib = res;
      });
    }

    if (panel.configuration.configurationType === 'I') {
      this.isTask = false;
      this.translate.get('GENERAL.INFORMATION').subscribe((res) => {
        panel.configuration.configType = res;
      });
    } else if (panel.configuration.configurationType === 'T') {
      this.isTask = true;
      this.translate.get('GENERAL.TASK').subscribe((res) => {
        panel.configuration.configType = res;
      });
    }

    if (panel.configuration.queueSize === null) {
      panel.q = 'null';
    } else {
      panel.q = panel.configuration.queueSize;
    }

    if (panel.panelItemIcon !== null) {
      panel.pimage = this.domSanitizer.bypassSecurityTrustUrl(panel.panelItemIcon);
    }

    if (panel.panelItemTskDesc === 'NO') {
      this.translate.get('GENERAL.NO').subscribe((res) => {
        panel.toption = res;
      });
    } else {
      this.translate.get('GENERAL.YES').subscribe((res) => {
        panel.toption = res;
      });
    }

    this.panel = panel;
  }

}
