import { Router } from '@angular/router';
import { DevicesService } from './../../services/devices/devices.service';
import { Component, OnInit } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { Variables } from 'src/app/Models/variables';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.css']
})
export class DevicesComponent implements OnInit {

  page = 1;
  size = 100;

  devices = [];

  isAdmin = false;
  isLoadMore = false;
  load = false;

  ismanage = false;

  doptions: any = { year: 'numeric', month: 'short', day: 'numeric',
  hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false };

  constructor(
    private translate: TranslateService,
    private cookieService: CookieService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private notificationSvc: NotificationService,
    private deviceService: DevicesService,
    private router: Router
  ) {
      // this.translate.addLangs(['English', 'French']);
      // this.translate.setDefaultLang('English');

      // let browserLang = this.translate.getBrowserLang();

      // const pass = 'ranitessarldev2019';
      // const lang = cookieService.get('goepla-portal-language');
      // if (lang.trim() !== '') {
      //   const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
      //   const lg = declang;
      //   browserLang = lg;
      // }

      // if (localStorage.getItem('language') !== null) {
      //   browserLang = localStorage.getItem('language');
      // }
      // this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

      translate.use('English');

      const v = new Variables();
      const p = localStorage.getItem('ups');
      if (p != null) {
        const val = CryptoJS.AES.decrypt(p, v.pass).toString(CryptoJS.enc.Utf8);
        const perm = JSON.parse(val);
        if (perm.includes('NTM.Devices.Manage') || perm.includes('NTM.Administration.Full')) {
          this.ismanage = true;
        }
      }
      this.getDevices();
   }

  ngOnInit() {
    localStorage.removeItem('device');
  }

  getDevices() {
    this.load = true;
    // tslint:disable-next-line: deprecation
    this.deviceService.getDevices(this.page, this.size, ' ', ' ', 0, ' ').subscribe((res) => {
      if (res != null) {
        if (res.length < this.size) {
          this.isLoadMore = true;
        }
        for (const dev of res) {
          const options = [];
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.DETAILS').subscribe((res1) => {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.DELETE').subscribe((res2) => {
              // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.EDIT').subscribe((res3) => {
                if (this.ismanage === true) {
                  options.push({label: res3, value: 'Edit'});
                  options.push({label: res2, value: 'Delete'});
                }
                options.push({label: res1, value: 'Details'});

                dev.options = options;
              });
            });
          });

          if (new Date(dev.lastHeartBeat).getFullYear() === 1) {
            dev.lastHeartBeat = '-';
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.OFFLINE').subscribe((ofl) => {
              dev.stat = ofl;
            });
            dev.color = 'Red';
          } else {
            if (dev.lastHeartBeatMinutes > 3 || dev.lastHeartBeatMinutes < 0) {
              // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.OFFLINE').subscribe((ofl) => {
                dev.stat = ofl;
              });
              dev.color = 'Red';
            } else {
              // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.ONLINE').subscribe((onl) => {
                dev.stat = onl;
              });
              dev.color = 'Green';
            }

            dev.lastHeartBeat = new Date(dev.lastHeartBeat.toString().replace('T', ' ') + ' UTC').toLocaleString('en-US', this.doptions);
          }

          if (dev.userName === null) {
            dev.userName = '-';
          }

          if (dev.appVersion === null) {
            dev.appVersion = '-';
          }

          if (dev.batteryStatus === 0) {
            dev.batteryStatus = '-';
          } else {
            dev.batteryStatus = dev.batteryStatus + '%';
          }
          this.devices.push(dev);
        }
      } else {
        this.isLoadMore = true;
      }
      this.load = false;
    }, err => this.handleError(err));
  }

  loadMore() {
    this.page += 1;
    this.getDevices();
  }

  gotoCreateDevice() {
    this.router.navigateByUrl('/create-device');
  }

  saveDevice(event, device) {
    if (event.value === 'Details') {
      localStorage.setItem('device', JSON.stringify(device));
      this.router.navigateByUrl('/devices/' + device.imei);
    }
    if (event.value === 'Delete') {
      this.load = true;
      // tslint:disable-next-line: deprecation
      this.deviceService.deleteDevice(device.imei).subscribe((res) => {
        if (res === true) {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.DEVICEDELETED').subscribe((resp: string) => {
            this.notificationSvc.info('', resp, 2000);
          });
          this.devices = [];
          this.getDevices();
        } else {
          this.load = false;
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.COULDNOTDELETEDEVICE').subscribe((resp: string) => {
            this.notificationSvc.info('', resp, 2000);
          });
        }
      }, err => this.handleError(err));
    }
    if (event.value === 'Edit') {
      localStorage.setItem('device', JSON.stringify(device));
      this.router.navigateByUrl('/edit-device');
    }
  }

  handleError(err) {
    this.load = false;
    console.log(err);
  }

}
