import { UsersgroupsService } from 'src/app/services/usersgroups/usersgroups.service';
import { Devices } from './../../Models/Devices';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { DevicesService } from 'src/app/services/devices/devices.service';
import { Router } from '@angular/router';
import { Variables } from 'src/app/Models/variables';

@Component({
  selector: 'app-create-device',
  templateUrl: './create-device.component.html',
  styleUrls: ['./create-device.component.css']
})
export class CreateDeviceComponent implements OnInit {

  stats = [];
  stat: any;

  IMEI: string;
  DeviceName: string;

  loading = false;

  langs = [];
  lang: any;

  others = [];
  other: any;

  SyncTime: number;
  OrgName: any;
  organisation: any;

  constructor(
    private translate: TranslateService,
    private cookieService: CookieService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private notificationSvc: NotificationService,
    private deviceService: DevicesService,
    private router: Router,
    private location: Location,
    private usersgroupsService: UsersgroupsService
  ) {
      // this.translate.addLangs(['English', 'French']);
      // this.translate.setDefaultLang('English');

      // let browserLang = this.translate.getBrowserLang();

      // const pass = 'ranitessarldev2019';
      // const lang = cookieService.get('goepla-portal-language');
      // if (lang.trim() !== '') {
      //   const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
      //   const lg = declang;
      //   browserLang = lg;
      // }

      // if (localStorage.getItem('language') !== null) {
      //   browserLang = localStorage.getItem('language');
      // }
      // this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

      translate.use('English');

      // tslint:disable-next-line: deprecation
      translate.get('GENERAL.ENABLED').subscribe((en) => {
        // tslint:disable-next-line: deprecation
        translate.get('GENERAL.DISABLED').subscribe((dis) => {
          this.stats.push({label: en, value: 'ENABLED'});
          this.stats.push({label: dis, value: 'DISABLED'});
        });
      });

      // tslint:disable-next-line: deprecation
      translate.get('GENERAL.YES').subscribe((yes) => {
        // tslint:disable-next-line: deprecation
        translate.get('GENERAL.NO').subscribe((no) => {
          this.others.push({label: yes, value: 'YES'});
          this.others.push({label: no, value: 'NO'});
        });
      });

      // tslint:disable-next-line: deprecation
      translate.get('GENERAL.ENGLISH').subscribe((eng) => {
        // tslint:disable-next-line: deprecation
        translate.get('GENERAL.FRENCH').subscribe((fre) => {
          this.langs.push({label: eng, value: 'ENGLISH'});
          this.langs.push({label: fre, value: 'FRENCH'});
        });
      });

      this.getOrganisation();
   }

  ngOnInit() {
  }

  getOrganisation() {
    this.loading = true;
    // tslint:disable-next-line: deprecation
    this.usersgroupsService.getOrganisations().subscribe((res) => {
      if (res != null) {
        this.organisation = res;
        this.OrgName = res.organisationName;
        // tslint:disable-next-line: prefer-for-of
        /*for (let i = 0; i < res.length; i++) {
          this.organisations.push({label: res[i].organisationName, value: res[i]});
        }*/
      }
      this.loading = false;
    });
  }

  onCreate() {
    if (this.IMEI === undefined || this.IMEI === null || this.IMEI.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTERDEVICEIMEI').subscribe((mes) => {
        this.confirm(mes);
      });
    } else if (this.DeviceName === undefined || this.DeviceName === null || this.DeviceName.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTERDEVICENAME').subscribe((mes) => {
        this.confirm(mes);
      });
    } else if (this.stat === undefined || this.stat === null || this.stat.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTSTATUS').subscribe((mes) => {
        this.confirm(mes);
      });
    } else if (this.other === undefined || this.other === null || this.other.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTSHOWOTHERS').subscribe((mes) => {
        this.confirm(mes);
      });
    } else if (this.SyncTime === undefined || this.SyncTime.toString().trim() === null
      || this.SyncTime.toString().trim() === '' || this.SyncTime.toString() === '0') {
        // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.ENTERSYNCTIME').subscribe((res: string) => {
      this.confirm(res);
    });
  } else if (isNaN(this.SyncTime)) {
    // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SYNCTIMESHOULDBENUMBER').subscribe((res: string) => {
        this.confirm(res);
      });
    } else if (this.lang === undefined || this.lang === null || this.lang.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTLANGUAGE').subscribe((mes) => {
        this.confirm(mes);
      });
    } else {
      const dev = new Devices();
      dev.IMEI = this.IMEI;
      dev.DeviceName = this.DeviceName;
      dev.Status = this.stat;

      const v = new Variables();
      const val = localStorage.getItem('oi');
      const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);

      dev.OrganisationId = +oi;
      // dev.OrganisationId = +localStorage.getItem('organisation-id');
      dev.ShowOthers = this.other;
      dev.SyncTime = this.SyncTime;
      dev.Language = this.lang;

      this.loading = true;
      // tslint:disable-next-line: deprecation
      this.deviceService.postDevice(dev).subscribe((res) => {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.DEVICECREATED').subscribe((resp: string) => {
          this.notificationSvc.info('', resp, 2000);
        });
        this.location.back();
        this.loading = false;
      }, err => this.handleError(err));
    }
  }

  onCancel() {
    this.location.back();
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  handleError(err) {
    this.loading = false;
    if (err.error.MessageText === 'duplicate key value violates unique constraint "t_ntm_device_dev_name_key"') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.DEVICENAMEEXISTS').subscribe((res: string) => {
        this.confirm(res);
      });
    }

    if (err.error.MessageText === 'duplicate key value violates unique constraint "t_ntm_device_pkey"') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.IMEIEXISTS').subscribe((res: string) => {
        this.confirm(res);
      });
    }

  }

}
