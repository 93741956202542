import { StringUpdater, KeyValue } from './../../Models/Updater';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CookieService } from 'ngx-cookie-service';
import { ReminderTypesService } from 'src/app/services/reminder-types/reminder-types.service';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { UsersService } from 'src/app/services/users/users.service';
import { ReminderType } from 'src/app/Models/ReminderType';
import * as CryptoJS from 'crypto-js';
import { interval } from 'rxjs';
import { Variables } from 'src/app/Models/variables';

@Component({
  selector: 'app-edit-reminder-type',
  templateUrl: './edit-reminder-type.component.html',
  styleUrls: ['./edit-reminder-type.component.css']
})
export class EditReminderTypeComponent implements OnInit {

  types = [];
  type: any;

  TypeName: string;
  TypeDetail: string;
  Interval = 1;
  Durations: string;
  Expiration: number;
  Enabled = 'NO';
  ReminderMode = 'Interval';
  Expiry = 1;

  disableInterval = false;
  disableDurations = true;
  loading = false;

  user: string;

  rem: any;

  constructor(
    private router: Router,
    private notificationSvc: NotificationService,
    public translate: TranslateService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private remindertypeService: ReminderTypesService,
    private messageService: MessageService,
    private configService: ConfigurationService,
    private location: Location,
    private userService: UsersService
    ) {
    // this.translate.addLangs(['English', 'French']);
    // this.translate.setDefaultLang('English');

    // let browserLang = this.translate.getBrowserLang();

    // const pass = 'ranitessarldev2019';
    // const lang = cookieService.get('goepla-portal-language');
    // if (lang.trim() !== '') {
    //   const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
    //   const lg = declang;
    //   browserLang = lg;
    // }

    // if (localStorage.getItem('language') !== null) {
    //   browserLang = localStorage.getItem('language');
    // }
    // this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    translate.use('English');

    this.rem = JSON.parse(localStorage.getItem('reminder-type'));
    this.TypeName = this.rem.reminderTypeName;
    this.TypeDetail = this.rem.reminderTypeDetail;
    this.Expiry = this.rem.reminderExpiry;
    if (this.rem.intervalCycle === '-') {
      this.ReminderMode = 'Durations';
      this.Interval = 1;
      this.Durations = this.rem.intervalDurations;
      this.showReminderMode();
    } else if (this.rem.intervalDurations === '-') {
      this.ReminderMode = 'Interval';
      this.Interval = +this.rem.intervalCycle;
      this.Durations = null;
      this.showReminderMode();
    }
    if (this.rem.status === 'ENABLED') {
      this.Enabled = 'YES';
    } else {
      this.Enabled = 'NO';
    }
   }

  ngOnInit() {
    this.getUser();
    this.getConfigurations();
  }

  getConfigurations() {
    this.loading = true;
    // tslint:disable-next-line: deprecation
    this.configService.getConfigurations('T').subscribe((res) => {
      if (res != null) {
        let count = 0;
        for (const val of res) {
          if (val.configurationId === this.rem.configurationId) {
            this.type = val;
          }
          this.types.push({label: val.configurationName, value: val});
          count += 1;
          if (count === res.length) {
            this.loading = false;
          }
        }
      }
      this.loading = false;
    }, err => this.handleError(err));
  }

  getUser() {
    this.loading = true;
    const v = new Variables();
    const val = localStorage.getItem('ui');
    // tslint:disable-next-line: deprecation
    const ui = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);
    // tslint:disable-next-line: deprecation
    this.userService.getUser(0, +ui, ' ', ' ').subscribe((res) => {
      this.user = res.firstName + ' ' + res.lastName;
      this.loading = false;
    }, err => this.handleError(err));
  }

  showReminderMode() {
    if (this.ReminderMode === 'Interval') {
      this.disableInterval = false;
      this.disableDurations = true;
    } else if (this.ReminderMode === 'Durations') {
      this.disableInterval = true;
      this.disableDurations = false;
    }
  }

  onSave() {
    if (this.type === undefined || this.type === null) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTTASKTYPE').subscribe((trans) => {
        this.confirm(trans);
      });
      return;
    }

    if (this.TypeName === undefined || this.TypeName === null || this.TypeName.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTERTYPENAME').subscribe((trans) => {
        this.confirm(trans);
      });
      return;
    }

    if (this.TypeDetail === undefined || this.TypeDetail === null || this.TypeDetail.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTERTYPEDETAIL').subscribe((trans) => {
        this.confirm(trans);
      });
      return;
    }

    if (this.ReminderMode === 'Interval') {
      this.Durations = null;
    } else if (this.ReminderMode === 'Durations') {
      if (this.Durations === undefined || this.Durations === null || this.Durations.trim() === '') {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.ENTERDURATIONS').subscribe((trans) => {
          this.confirm(trans);
        });
        return;
      }
      this.Interval = 0;
    }

    const upt = new StringUpdater();
    upt.Id = this.TypeName;
    upt.Values = [];

    const detail = new KeyValue();
    detail.Key = 'detail';
    detail.Value = this.TypeDetail;
    upt.Values.push(detail);

    const expiry = new KeyValue();
    expiry.Key = 'expiry';
    expiry.Value = this.Expiry.toString();
    upt.Values.push(expiry);

    const config = new KeyValue();
    config.Key = 'config';
    config.Value = this.rem.configurationId.toString();
    upt.Values.push(config);

    if (this.Interval > 0) {
      const intcycle = new KeyValue();
      intcycle.Key = 'intcycle';
      intcycle.Value = this.Interval.toString();
      upt.Values.push(intcycle);
    } else {
      const intdur = new KeyValue();
      intdur.Key = 'intdur';
      intdur.Value = this.Durations;
      upt.Values.push(intdur);
    }

    const status = new KeyValue();
    status.Key = 'status';
    if (this.Enabled === 'YES') {
      status.Value = 'ENABLED';
    } else if (this.Enabled === 'NO') {
      status.Value = 'DISABLED';
    }
    upt.Values.push(status);

    this.loading = true;
    // tslint:disable-next-line: deprecation
    this.remindertypeService.putReminderType(this.TypeName, upt).subscribe((res) => {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.REMINDERTYPEUPDATED').subscribe((trans) => {
        this.showSuccess(trans);
        this.location.back();
      });
    }, err => this.handleError(err));
  }

  onCancel() {
    this.location.back();
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  showSuccess(msg) {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.SUCCESS').subscribe((trans) => {
      this.messageService.add({key: 'tc', severity: 'success', summary: trans, detail: msg});
    });
  }

  showInfo(msg) {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.INFO').subscribe((trans) => {
      this.messageService.add({key: 'tc', severity: 'info', summary: trans, detail: msg});
    });
  }

  showWarn(msg) {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.WARNING').subscribe((trans) => {
      this.messageService.add({key: 'tc', severity: 'warn', summary: trans, detail: msg});
    });
  }

  showError(msg) {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.ERROR').subscribe((trans) => {
      this.messageService.add({key: 'tc', severity: 'error', summary: trans, detail: msg});
    });
  }

  handleError(err) {
    this.loading = false;
    if (err.error.Message === 'Invalid duration format') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.INVALIDDURATIONFORMAT').subscribe((trans) => {
        this.confirm(trans);
      });
    }

    if (err.error.MessageText === 'duplicate key value violates unique constraint "t_ntm_reminder_type_pkey"') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.REMINDERTYPEALREADYEXISTS').subscribe((trans) => {
        this.confirm(trans);
      });
    }
  }

}
