export class ReminderType {
    public ReminderTypeName: string;
    public ReminderTypeDetail: string;
    public ReminderExpiry: number;
    public ConfigurationId: number;
    public Status: string;
    public Created: Date;
    public CreatedBy: string;
    public IntervalCycle: number;
    public IntervalDurations: string;
}
