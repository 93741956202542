import { UpdateService } from './../../services/update/update.service';
import { Notification } from './../../Models/Notification';
import { TasksService } from './../../services/tasks/tasks.service';
import { NotificationsServiceService } from './../../services/notifications-service/notifications-service.service';
import { PanelsService } from './../../services/panels/panels.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { PanelItemsService } from 'src/app/services/panel-items/panel-items.service';
import * as CryptoJS from 'crypto-js';
import { Variables } from 'src/app/Models/variables';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-panels',
  templateUrl: './panels.component.html',
  styleUrls: ['./panels.component.css']
})
export class PanelsComponent implements OnInit {

  panels = [];
  panelss = [];
  pid = 0;
  piname: any;

  oldid = 0;

  ispanels = false;
  showmessage = false;

  mtsks: any;

  panel: any;
  showcreate = false;

  TaskDesc: any;

  constructor(
    private panelItemService: PanelItemsService,
    private router: Router,
    private notificationSvc: NotificationService,
    public translate: TranslateService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private notification: NotificationsServiceService,
    private tasksService: TasksService,
    private domSanitizer: DomSanitizer,
    private myService: UpdateService
  ) {
      this.translate.addLangs(['English', 'French']);
      this.translate.setDefaultLang('English');

      let browserLang = this.translate.getBrowserLang();

      const pass = 'ranitessarldev2019';
      const lang = cookieService.get('goepla-portal-language');
      if (lang.trim() !== '') {
        const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
        const lg = declang;
        browserLang = lg;
      }

      if (localStorage.getItem('language') !== null) {
        browserLang = localStorage.getItem('language');
      }
      this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

      try {
        const route = router.url;
        if (route !== undefined && route !== null) {
          const split = route.split('/');
          this.piname = split[2].split('%20').join(' ');
          this.pid = +split[3];
          this.oldid = this.pid;
          localStorage.setItem('pi', this.pid.toString());
          this.ispanels = true;
          this.showmessage = true;
          this.getPanels();
        }
      } catch (error) {
      }
   }

  ngOnInit() {
    // tslint:disable-next-line: deprecation
    this.myService.getRefresh().subscribe((value: boolean) => {
      if (value) {
        this.getPanels();
      }
    });
    // // tslint:disable-next-line: only-arrow-functions
    // setInterval(() => {
    //   this.showmessage = false;
    //   this.getPanels();
    // }, 30000);

    let run = true;
    // tslint:disable-next-line: only-arrow-functions
    const to = setInterval(() => {
      const route = this.router.url;
      const split = route.split('/');
      const n = split[2];
      const id = +split[3];
      if (route.includes('panels') && run === true) {
        if (id === this.oldid) {
          //
        } else {
          this.oldid = id;
          this.pid = id;
          localStorage.setItem('pi', this.pid.toString());
          // this.piname = n.replace('%20', ' ');
          this.piname = n.split('%20').join(' ');
          this.ispanels = true;
          this.showmessage = true;
          this.panels = [];
          this.panelss = [];
          this.getPanels();
        }
      } else {
        run = false;
        clearInterval(to);
      }
    }, 1);
  }

  public getPanels() {
    const id = localStorage.getItem('pi');
    if (id === undefined || id === null) {
      return;
    } else {
      this.pid = +id;
    }

    const pans = [];
    let counting = 1;
    // tslint:disable-next-line: deprecation
    this.panelItemService.getPanels2(0, 0, this.pid, 1).subscribe((response) => {
      const res = response;
      if (res != null) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          if (res[i].panelItemIcon !== null) {
            res[i].pimage = this.domSanitizer.bypassSecurityTrustUrl(res[i].panelItemIcon);
          }
          res[i].queue = res[i].configuration.queueSize;
          // tslint:disable-next-line: deprecation
          if (res[i].configuration.queueSize === null || res[i].configuration.queueSize === 'null') {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.UNLIMITED').subscribe((resp) => {
              res[i].queue = resp;
              res[i].value = 15;
            });
          }
          if (res[i].configuration.configurationType === 'I') {
            let unread = 0;
            const ress = res[i].notifications;
            if (ress != null) {
              unread = ress.length;
              res[i].unread = unread;
              if (res[i].configuration.queueSize === null || res[i].configuration.queueSize === 'null') {
                res[i].color = 'White';
              } else if (+res[i].configuration.queueSize > res[i].unread) {
                res[i].color = 'White';
              } else {
                res[i].color = 'Gray';
              }

              if (unread === 1 && res[i].configuration.queueSize !== null
                  && res[i].configuration.queueSize !== 'null'
                  && res[i].configuration.queueSize.toString() === '1') {
                for (const not of ress) {
                  not.expires = new Date(not.expires.toString().replace('T', ' ') + ' UTC');
                  if (new Date(not.read).getFullYear() === 1) {
                    if (new Date(not.expires) < new Date()) {
                      // tslint:disable-next-line: deprecation
                      this.translate.get('GENERAL.EXPIRED').subscribe((resp1: string) => {
                        res[i].status = resp1;
                        res[i].color = 'Red';
                      });
                    } else {
                      // tslint:disable-next-line: deprecation
                      this.translate.get('GENERAL.NEW').subscribe((resp1: string) => {
                        res[i].status = resp1;
                        res[i].color = 'LightGray';
                      });
                    }
                  }
                }
              }
            } else {
              res[i].unread = 0;
              if (res[i].configuration.queueSize === null || res[i].configuration.queueSize === 'null') {
                res[i].color = 'White';
              } else if (+res[i].configuration.queueSize > res[i].unread) {
                res[i].color = 'White';
              } else {
                res[i].color = 'Gray';
              }
            }
            pans.push(res[i]);
            if (res.length === counting) {
              this.panels = [];
              this.panelss = [];
              pans.sort((a, b) => a.panelName.localeCompare(b.panelName));
              let c = 0;
              // tslint:disable-next-line: prefer-for-of
              for (let j = 0; j < pans.length; j++) {
                if (pans[j].configuration.configurationType === 'I') {
                  /*this.ispanels = true;
                  pans[j].full = true;
                  pans[j].half = false;*/
                  if (pans[j].configuration.queueSize !== null && pans[j].configuration.queueSize !== 'null') {
                    if (pans[j].configuration.queueSize.toString() === '1') {
                      pans[j].full = false;
                      pans[j].half = true;
                      // pans[j].status = '';
                    } else {
                      pans[j].full = true;
                      pans[j].half = false;
                    }
                  } else {
                    pans[j].full = true;
                    pans[j].half = false;
                  }
                }

                // this.panels.push(pans[j]);
                this.panelss.push(pans[j]);
                c += 1;
                if (c >= pans.length) {
                  this.ispanels = false;
                  this.panels = this.panelss;
                }
              }
            }
            counting += 1;
          } else if (res[i].configuration.configurationType === 'T') {
            let count = 0;
            let counter = 1;
            const ress = res[i].notifications;
            if (ress != null) {
              // tslint:disable-next-line: prefer-for-of
              for (let j = 0; j < ress.length; j++) {
                const resp = ress[j];
                if (resp.notificationId !== null) {
                  if (new Date(resp.closed).getFullYear() === 1) {
                    count += 1;
                    if (res[i].configuration.queueSize !== null && res[i].configuration.queueSize !== 'null') {
                      if (res[i].configuration.queueSize.toString() === '1') {
                        res.iscolor = false;
                        const r = resp;
                        r.expires = new Date(ress[j].expires.toString().replace('T', ' ') + ' UTC');
                        this.mtsks = r;

                        res[i].full = false;
                        res[i].half = true;

                        if (r.expires < new Date()) {
                          // tslint:disable-next-line: deprecation
                          this.translate.get('GENERAL.EXPIRED').subscribe((resp1: string) => {
                            res[i].estatus = resp1;
                            res[i].ecolor = 'Red';
                          });
                        }
                        if (r.taskUserId > 0) {
                          res[i].user = r.taskUserName;
                          // tslint:disable-next-line: deprecation
                          this.translate.get('GENERAL.ASSIGNED').subscribe((resp1: string) => {
                            res[i].status = resp1;
                            res[i].color = 'Yellow';
                          });
                          if (new Date(r.started).getFullYear() !== 1) {
                            // tslint:disable-next-line: deprecation
                            this.translate.get('GENERAL.STARTED').subscribe((resp1: string) => {
                              res[i].status = resp1;
                              res[i].color = 'LightBlue';
                            });
                          }
                        } else {
                          // tslint:disable-next-line: deprecation
                          this.translate.get('GENERAL.OPEN').subscribe((resp1: string) => {
                            res[i].status = resp1;
                            res[i].color = 'LightGray';
                          });
                        }
                      } else {
                        res.iscolor = false;
                        res[i].full = true;
                        res[i].half = false;
                      }
                    } else {
                      res[i].color = 'White';
                      res.iscolor = false;
                      res[i].full = true;
                      res[i].half = false;
                    }
                  }

                  res[i].unread = count;
                  if (ress.length === counter) {
                    res[i].unread = count;
                    if (res[i].configuration.queueSize !== null && res[i].configuration.queueSize !== 'null') {
                      if (+res[i].configuration.queueSize > 1 || +res[i].configuration.queueSize === 0) {
                        if (+res[i].configuration.queueSize <= res[i].unread) {
                          res[i].color = 'Gray';
                        }
                      }

                      if (res[i].unread === 0) {
                        res[i].full = false;
                        res[i].half = true;
                        res[i].status = '';
                        res[i].color = 'White';
                      }
                    } else {
                      res[i].full = true;
                      res[i].half = false;
                      res[i].color = 'White';
                    }
                  }
                  counter += 1;
                } else {
                  count += 1;
                  res[i].unread = count;
                  if (ress.length === counter) {
                    res[i].unread = count;
                    if (res[i].configuration.queueSize !== null && res[i].configuration.queueSize !== 'null') {
                      if (+res[i].configuration.queueSize > 1 || +res[i].configuration.queueSize === 0) {
                        if (+res[i].configuration.queueSize <= res[i].unread) {
                          res[i].color = 'Gray';
                        }
                      }

                      if (res[i].unread === 0) {
                        res[i].full = false;
                        res[i].half = true;
                        res[i].status = '';
                        res[i].color = 'White';
                      } else {
                        res[i].full = false;
                        res[i].half = true;
                      }
                    } else {
                      res[i].color = 'White';
                      res[i].full = true;
                      res[i].half = false;
                    }
                  }
                }
              }
            } else {
              res[i].unread = 0;
              res[i].full = true;
              res[i].half = false;
              if (res[i].configuration.queueSize !== null && res[i].configuration.queueSize !== 'null') {
                if (+res[i].configuration.queueSize > 1 || +res[i].configuration.queueSize === 0) {
                  if (+res[i].configuration.queueSize <= res[i].unread) {
                    res[i].color = 'Gray';
                  }
                }
                if (res[i].configuration.queueSize.toString() === '1') {
                  res[i].full = false;
                  res[i].half = true;
                  res[i].status = '';
                  res[i].color = 'White';
                } else {
                  res[i].full = true;
                  res[i].half = false;
                }
              } else {
                res[i].color = 'White';
                res[i].full = false;
                res[i].half = true;
              }
            }
            pans.push(res[i]);
            if (res.length === counting) {
              this.panels = [];
              this.panelss = [];
              pans.sort((a, b) => a.panelName.localeCompare(b.panelName));
              let c = 0;
              // tslint:disable-next-line: prefer-for-of
              for (let j = 0; j < pans.length; j++) {
                if (pans[j].configuration.configurationType === 'I') {
                  /*this.ispanels = true;
                  pans[j].full = true;
                  pans[j].half = false;*/
                  if (pans[j].configuration.queueSize !== null && pans[j].configuration.queueSize !== 'null') {
                    if (pans[j].configuration.queueSize.toString() === '1') {
                      pans[j].full = false;
                      pans[j].half = true;
                      // pans[j].status = '';
                    } else {
                      pans[j].full = true;
                      pans[j].half = false;
                    }
                  } else {
                    pans[j].full = true;
                    pans[j].half = false;
                  }
                }
                // this.panels.push(pans[j]);
                this.panelss.push(pans[j]);
                c += 1;
                if (c >= pans.length) {
                  this.ispanels = false;
                  this.panels = this.panelss;
                }
              }
            }
            counting += 1;
          }
        }
      } else {
        this.panels = [];
        this.panelss = [];
        this.ispanels = false;
        if (this.showmessage === true) {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.NOPANELITEMS').subscribe((res1: string) => {
            this.notificationSvc.info('', res1, 2000);
          });
        }
      }
    }, err => this.handleError(err));
  }

  create(panel) {
    if (panel.configuration.queueSize === null || panel.configuration.queueSize === 'null') {
      //
    } else if (panel.configuration.queueSize <= panel.unread) {
      return;
    }

    if (panel.panelItemTskDesc === 'YES') {
      this.TaskDesc = null;
      this.showcreate = true;
      this.panel = panel;
    } else {
      this.sendTask(panel, panel.panelItemName);
    }
  }

  ok() {
    if (this.TaskDesc === undefined || this.TaskDesc == null || this.TaskDesc.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.PROVIDETASKDESCRIPTION').subscribe((res: string) => {
        this.confirm(res);
      });
    } else {
      this.sendTask(this.panel, this.TaskDesc);
      this.showcreate = false;
    }
  }

  sendTask(panel, desc) {
    const v = new Variables();
    const p = localStorage.getItem('ups');
    if (p != null) {
      const val = CryptoJS.AES.decrypt(p, v.pass).toString(CryptoJS.enc.Utf8);
      const perm = JSON.parse(val);
      if (perm.includes('NTM.Panels.Manage') || perm.includes('NTM.Administration.Full')) {
        const notif = new Notification();
        notif.ConfigurationId = panel.configurationId;
        notif.MessageTitle = panel.panelItemName;
        notif.MessageBody = desc;

    // tslint:disable-next-line: deprecation
        this.notification.postNotification(notif).subscribe(() => {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.CREATED').subscribe((res: string) => {
            this.notificationSvc.info('', res, 2000);
          });
          this.getPanels();
        }, err => {
          this.handleError(err);
        });
      }
    }
  }

  calc(unread, queue) {
    if (unread === 0) {
      return 0;
    }

    if (queue === '0' || queue === 0) {
      return 0;
    }

    if (isNaN(queue)) {
      return 1;
    } else {
      const val = (unread / queue) * 100;
      return val;
    }
  }

  onCancel() {
    this.TaskDesc = null;
    this.showcreate = false;
  }

  refresh() {
    this.ispanels = true;
    this.showmessage = true;
    this.getPanels();
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        },
        reject: () => {
          //
        }
    });
  }

  handleError(err) {
    this.ispanels = false;
    console.log(err);
  }

}
