import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Urls } from 'src/app/Helpers/Urls';
import { Variables } from 'src/app/Models/variables';
import * as CryptoJS from 'crypto-js';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CatClassService {

  url = new Urls();
  apiUrl: string;
  orgId: number;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = this.url.apiUrl;
      const v = new Variables();
      const val = localStorage.getItem('oi');
      const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);

      this.orgId = +oi;
      // this.orgId = +localStorage.getItem('organisation-id');
  }

  getCategoryClasses(page, size, classid, classname, catid, catname) {
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/categoryclass/' + page + '/' + size + '/' + classid + '/' + classname + '/' + catid + '/' + catname + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  private handleError(error: any) {
    // console.error('error', error);
    throw error;
    return error;
  }
}
