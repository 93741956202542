import { Location } from '@angular/common';
import { TasksService } from './../../services/tasks/tasks.service';
import { getTestBed } from '@angular/core/testing';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import * as CryptoJS from 'crypto-js';
import { TaskUpdater, KeyValue } from 'src/app/Models/Updater';

@Component({
  selector: 'app-task-detail',
  templateUrl: './task-detail.component.html',
  styleUrls: ['./task-detail.component.css']
})
export class TaskDetailComponent implements OnInit {

  notif: any;

  isDate: boolean;
  isNotDate: boolean;
  username: string;
  status: string;
  assigned: string;
  started: string;
  closed: string;
  isMore: boolean;

  doptions: any = { year: 'numeric', month: 'short', day: 'numeric',
  hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false };

  constructor(
    public translate: TranslateService,
    private cookieService: CookieService,
    private tskService: TasksService,
    private location: Location
  ) {
    this.translate.addLangs(['English', 'French']);
    this.translate.setDefaultLang('English');

    let browserLang = this.translate.getBrowserLang();

    const pass = 'ranitessarldev2019';
    const lang = cookieService.get('goepla-portal-language');
    if (lang.trim() !== '') {
      const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
      const lg = declang;
      browserLang = lg;
      localStorage.setItem('language', lg);
    }

    if (localStorage.getItem('language') !== null) {
      browserLang = localStorage.getItem('language');
    }
    this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    const notification = JSON.parse(localStorage.getItem('notification'));
    if (notification.read === '0001-01-01T00:00:00') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.NOTREAD').subscribe((resp: string) => {
        notification.reads = resp;
      });
    } else {
      notification.reads = new Date(notification.read.toString().replace('T', ' ') + ' UTC').toLocaleString('en-US', this.doptions);
      // notification.reads = new Date(notification.read).toLocaleString();
    }
    notification.created = new Date(notification.created).toLocaleString('en-US', this.doptions);
    notification.expires = new Date(notification.expires).toLocaleString('en-US', this.doptions);
    if (notification.locationConfirmation === '0001-01-01T00:00:00') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.LOCATIONNOTCONFIRMED').subscribe((resp: string) => {
        notification.locconf = resp;
      });
    } else {
      notification.locconf = new Date(notification.locationConfirmation
        .toString().replace('T', ' ') + ' UTC').toLocaleString('en-US', this.doptions);
    }
    this.notif = notification;

    this.isMore = true;
    if (notification.taskUserId === 0) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.NOUSER').subscribe((resp: string) => {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.OPEN').subscribe((resp2: string) => {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.NOTASSIGNED').subscribe((resp3: string) => {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.NOTSTARTED').subscribe((resp4: string) => {
              // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.NOTCLOSED').subscribe((resp5: string) => {
                this.username = resp;
                this.status = resp2;
                this.assigned = resp3;
                this.started = resp4;
                this.closed = resp5;
              });
            });
          });
        });
      });
    } else {
      this.username = notification.taskUserName;
      if (new Date(notification.assigned).getFullYear() !== 1) {
        this.assigned = new Date(notification.assigned.toString().replace('T', ' ') + ' UTC').toLocaleString('en-US', this.doptions);
        // this.assigned = new Date(notification.tsk.assigned).toLocaleString();
      // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.ASSIGNED').subscribe((resp: string) => {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.NOTSTARTED').subscribe((resp2: string) => {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.NOTCLOSED').subscribe((resp3: string) => {
              this.status = resp;
              this.started = resp2;
              this.closed = resp3;
            });
          });
        });
      }
      if (new Date(notification.started).getFullYear() !== 1) {
        this.started = new Date(notification.started.toString().replace('T', ' ') + ' UTC').toLocaleString('en-US', this.doptions);
        // this.started = new Date(notification.tsk.started).toLocaleString();
      // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.STARTED').subscribe((resp: string) => {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.NOTCLOSED').subscribe((resp2: string) => {
            this.status = resp;
            this.closed = resp2;
          });
        });
      }
      if (new Date(notification.closed).getFullYear() !== 1) {
        this.closed = new Date(notification.closed.toString().replace('T', ' ') + ' UTC').toLocaleString('en-US', this.doptions);
        // this.closed = new Date(notification.tsk.closed).toLocaleString();
      // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.CLOSED').subscribe((resp: string) => {
          this.status = resp;
        });
      }
    }
   }

  ngOnInit() {
  }

  testReassign() {
    const notif = JSON.parse(localStorage.getItem('notification'));
    const values = new TaskUpdater();
    values.Id = notif.notificationId;
    values.Values = [];

    const reas = new KeyValue();
    reas.Key = 'reassign';
    reas.Value = '0';
    values.Values.push(reas);

      // tslint:disable-next-line: deprecation
    this.tskService.putTask(values.Id, values).subscribe((res) => {
      this.location.back();
    }, err => {
    });
  }

}
