import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Configuration } from 'src/app/Models/Configuration';
import { UsrGrp } from 'src/app/Models/UsrGrp';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { UsersgroupsService } from 'src/app/services/usersgroups/usersgroups.service';
import { ConfirmationService } from 'primeng/api';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { CookieService } from 'ngx-cookie-service';
import * as CryptoJS from 'crypto-js';
import { TypeRoleGroup } from 'src/app/Models/role';

@Component({
  selector: 'app-create-information-configuration',
  templateUrl: './create-information-configuration.component.html',
  styleUrls: ['./create-information-configuration.component.css']
})
export class CreateInformationConfigurationComponent implements OnInit {
// users = new FormControl();
userList = [];
users: any;

// groups = new FormControl();
groupList = [];
groups: any;

// organisations: Organisation[] = [];

organisations = [];
organisation: any;

types = [];
type = 'I';

priorities = [];
priority: any;

vibrations = [];
vibration: any;

channels = [];
channel: any = 'M';

Organisation = 0;
MessageType: string;
ConfigurationName: string;
ConfigurationType: string;
Priority: number;
TimeUntilExpiry: number;
Color: string;
Vibration: number;
Channel: string;
TaskReactionTimeLimit = 0;
TaskStartTimeLimit = 0;
TaskClosureTimeLimit = 0;

usrgrp: UsrGrp[] = [];

isTask = false;

isCreate = false;
loading = false;

OrgName: string;
QueueSize: any;

inigroups1 = [];
inigroups2 = [];

eagroups1 = [];
eagroups2 = [];

angroups1 = [];
angroups2 = [];

adgroups1 = [];
adgroups2 = [];

constructor(
  private router: Router,
  public translate: TranslateService,
  private configService: ConfigurationService,
  private notificationSvc: NotificationService,
  private usersgroupsService: UsersgroupsService,
  private confirmationService: ConfirmationService,
  private cookieService: CookieService,
  private location: Location
) {
  // this.translate.addLangs(['English', 'French']);
  // this.translate.setDefaultLang('English');

  // let browserLang = this.translate.getBrowserLang();

  // const pass = 'ranitessarldev2019';
  // const lang = cookieService.get('goepla-portal-language');
  // if (lang.trim() !== '') {
  //   const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
  //   const lg = declang;
  //   browserLang = lg;
  // }

  // if (localStorage.getItem('language') !== null) {
  //   browserLang = localStorage.getItem('language');
  // }
  // this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

  translate.use('English');

  // tslint:disable-next-line: deprecation
  translate.get('GENERAL.INFORMATION').subscribe((res1) => {
    this.types.push({label: res1, value: 'I'});
    // tslint:disable-next-line: deprecation
    translate.get('GENERAL.TASK').subscribe((res2) => {
      this.types.push({label: res2, value: 'T'});
    });
  });

  // tslint:disable-next-line: deprecation
  translate.get('GENERAL.CRITICAL').subscribe((res1) => {
    this.priorities.push({label: res1, value: 1});
    // tslint:disable-next-line: deprecation
    translate.get('GENERAL.HIGH').subscribe((res2) => {
      this.priorities.push({label: res2, value: 2});
      // tslint:disable-next-line: deprecation
      translate.get('GENERAL.NORMAL').subscribe((res3) => {
        this.priorities.push({label: res3, value: 3});
        // tslint:disable-next-line: deprecation
        translate.get('GENERAL.LOW').subscribe((res4) => {
          this.priorities.push({label: res4, value: 4});
          // tslint:disable-next-line: deprecation
          translate.get('GENERAL.VERYLOW').subscribe((res5) => {
            this.priorities.push({label: res5, value: 5});
          });
        });
      });
    });
  });

  // tslint:disable-next-line: deprecation
  translate.get('GENERAL.NONE').subscribe((res1) => {
    this.vibrations.push({label: res1, value: 0});
    // tslint:disable-next-line: deprecation
    translate.get('GENERAL.SHORT').subscribe((res2) => {
      this.vibrations.push({label: res2, value: 1});
      // tslint:disable-next-line: deprecation
      translate.get('GENERAL.LONG').subscribe((res3) => {
        this.vibrations.push({label: res3, value: 2});
        // tslint:disable-next-line: deprecation
        translate.get('GENERAL.VERYLONG').subscribe((res4) => {
          this.vibrations.push({label: res4, value: 3});
        });
      });
    });
  });

  // tslint:disable-next-line: deprecation
  translate.get('GENERAL.MQTT').subscribe((res1) => {
    this.channels.push({label: res1, value: 'M'});
    // tslint:disable-next-line: deprecation
    translate.get('GENERAL.SMS').subscribe((res2) => {
      this.channels.push({label: res2, value: 'S'});
      // tslint:disable-next-line: deprecation
      translate.get('GENERAL.EMAIL').subscribe((res3) => {
        this.channels.push({label: res3, value: 'E'});
      });
    });
  });

  // this.getUsers();
  this.getGroups();
  this.getOrganisation();
 }

ngOnInit() {
}

getUsers() {
  // tslint:disable-next-line: deprecation
  this.usersgroupsService.getUsers().subscribe((res) => {
    if (res != null) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < res.length; i++) {
        res[i].UserName = res[i].firstName + ' ' + res[i].lastName;
        this.userList.push({label: res[i].UserName, value: res[i]});
      }
    }
  });
}

getGroups() {
  this.loading = true;
  // tslint:disable-next-line: deprecation
  this.usersgroupsService.getGroups().subscribe((res) => {
    if (res != null) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < res.length; i++) {
        res[i].GroupName = res[i].groupName;
        this.groupList.push({label: res[i].GroupName, value: res[i]});
        this.eagroups1.push(res[i]);
        this.angroups1.push(res[i]);
        this.adgroups1.push(res[i]);
        this.inigroups1.push(res[i]);
      }
    }
    this.loading = false;
  });
}

getOrganisation() {
  this.loading = true;
  // tslint:disable-next-line: deprecation
  this.usersgroupsService.getOrganisations().subscribe((res) => {
    if (res != null) {
      this.organisation = res;
      this.OrgName = res.organisationName;
      // tslint:disable-next-line: prefer-for-of
      /*for (let i = 0; i < res.length; i++) {
        this.organisations.push({label: res[i].organisationName, value: res[i]});
      }*/
    }
    this.loading = false;
  });
}

selectedMessageType() {
  if (this.type === 'I') {
    this.isTask = false;
  } else if (this.type === 'T') {
    this.isTask = true;
  }
}

selectedPriority(event) {

}

selectedColor(event) {

}

selectedVibration(event) {

}

selectedChannel(event) {

}

onCreate() {
  if (this.organisation === undefined || this.organisation === null) {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.SELECTORGANISATION').subscribe((res: string) => {
      this.confirm(res);
    });
    return;
  }
  if (this.type === undefined || this.type === null || this.type.toString().trim() === null || this.type.toString().trim() === '') {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.SELECTMESSAGETYPE').subscribe((res: string) => {
      this.confirm(res);
    });
    return;
  }
  if (this.ConfigurationName === undefined || this.ConfigurationName.toString().trim() === null
      || this.ConfigurationName.toString().trim() === ''
      || this.ConfigurationName.toString().trim() === ' ') {
        // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.ENTERCONFIGURATIONNAME').subscribe((res: string) => {
      this.confirm(res);
    });
    return;
  }
  if (this.priority === undefined || this.priority.toString().trim() === null) {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.SELECTPRIORITY').subscribe((res: string) => {
      this.confirm(res);
    });
    return;
  }
  if (this.TimeUntilExpiry === undefined || this.TimeUntilExpiry.toString().trim() === null
      || this.TimeUntilExpiry.toString().trim() === '') {
        // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.ENTERTIMEUNTILEXPIRY').subscribe((res: string) => {
      this.confirm(res);
    });
    return;
  } else {
    if (isNaN(this.TimeUntilExpiry)) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.TIMEUNTILEXPIRYCHECK').subscribe((res: string) => {
        this.confirm(res);
      });
      return;
    }
  }
  if (this.Color === undefined || this.Color.toString().trim() === null) {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.SELECTCOLOR').subscribe((res: string) => {
      this.confirm(res);
    });
    return;
  }
  if (this.vibration === undefined || this.vibration.toString().trim() === null) {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.SELECTVIBRATEOPTION').subscribe((res: string) => {
      this.confirm(res);
    });
    return;
  }
  if (this.channel === undefined || this.channel.toString().trim() === null) {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.SELECTCHANNEL').subscribe((res: string) => {
      this.confirm(res);
    });
    return;
  }
  if (this.QueueSize === undefined || this.QueueSize === null
      || this.QueueSize.toString().trim() === '') {
    this.QueueSize = 'null';
    } else {
    if (isNaN(this.QueueSize)) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.QUEUESIZENOTNUMBER').subscribe((res: string) => {
        this.confirm(res);
      });
      return;
    }
  }
  this.usrgrp = [];
  if (this.users !== undefined || this.users != null) {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.users.length; i++) {
      const usr = new UsrGrp();
      usr.Id = this.users[i].userId;
      usr.IdType = 'U';
      usr.Name = this.users[i].UserName;
      this.usrgrp.push(usr);
    }
  }

  if (this.groups !== undefined || this.groups != null) {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.groups.length; i++) {
      const grp = new UsrGrp();
      grp.Id = this.groups[i].groupId;
      grp.IdType = 'G';
      grp.Name = this.groups[i].GroupName;
      this.usrgrp.push(grp);
    }
  }

  if (this.TimeUntilExpiry.toString().includes('.')) {
    // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.NOPOINTS').subscribe((res: string) => {
        this.confirm(res);
      });
      return;
    }

  this.isCreate = true;
  this.loading = true;

  const config = new Configuration();
  config.OrganisationId = this.organisation.organisationId;
  config.ConfigurationId = 0;
  config.ConfigurationName = this.ConfigurationName;
  config.ConfigurationType = this.type;
  config.Priority = this.priority;
  config.ExpiryTime = this.TimeUntilExpiry;
  config.Color = this.Color;
  config.Vibrate = this.vibration;
  config.Channel = this.channel;
  config.ReactionTimeLimit = this.TaskReactionTimeLimit;
  config.StartTimeLimit = this.TaskStartTimeLimit;
  config.CloseTimeLimit = this.TaskClosureTimeLimit;
  config.QueueSize = this.QueueSize;
  config.UsrGrp = this.usrgrp;

  const rgroups = [];
  if (this.eagroups2 !== null && this.eagroups2.length > 0) {
    for (const val of this.eagroups2) {
      const rgroup = new TypeRoleGroup();
      rgroup.GroupId = val.groupId;
      rgroup.RoleName = 'Expert-All';
      rgroup.RoleParentType = 'info_type';

      rgroups.push(rgroup);
    }
  }
  if (this.angroups2 !== null && this.angroups2.length > 0) {
    for (const val of this.angroups2) {
      const rgroup = new TypeRoleGroup();
      rgroup.GroupId = val.groupId;
      rgroup.RoleName = 'Analytics';
      rgroup.RoleParentType = 'info_type';

      rgroups.push(rgroup);
    }
  }
  if (this.adgroups2 !== null && this.adgroups2.length > 0) {
    for (const val of this.adgroups2) {
      const rgroup = new TypeRoleGroup();
      rgroup.GroupId = val.groupId;
      rgroup.RoleName = 'Administrator';
      rgroup.RoleParentType = 'info_type';

      rgroups.push(rgroup);
    }
  }
  if (this.inigroups2 !== null && this.inigroups2.length > 0) {
    for (const val of this.inigroups2) {
      const rgroup = new TypeRoleGroup();
      rgroup.GroupId = val.groupId;
      rgroup.RoleName = 'Initiator';
      rgroup.RoleParentType = 'info_type';

      rgroups.push(rgroup);
    }
  }

  config.RoleGroups = rgroups;

  // tslint:disable-next-line: deprecation
  this.configService.postConfiguration(config).subscribe(() => {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.CONFIGURATIONCREATED').subscribe((res: string) => {
      this.notificationSvc.info('', res, 2000);
    });
    // this.router.navigateByUrl('/informationconfigurations');
    this.location.back();
    this.isCreate = false;
    this.loading = false;
  }, err => {
    this.handleError(err);
  });
}

onCancel() {
  // this.router.navigateByUrl('/informationconfigurations');
  this.location.back();
}

selectedOrganisation(event) {
}

confirm(msg) {
  this.confirmationService.confirm({
      message: msg,
      accept: () => {
          // Actual logic to perform a confirmation
      }
  });
}

handleError(err) {
  this.isCreate = false;
  this.loading = false;
  if (err.error.MessageText === 'duplicate key value violates unique constraint "t_ntm_ncfg_org_id_ncfg_name_key"') {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.CONFIGURATIONALREADYEXISTS').subscribe((res: string) => {
      this.confirm(res);
    });
  }

  if (err.error.Message === 'Input string was not in a correct format.') {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.NOPOINTS').subscribe((res: string) => {
      this.confirm(res);
    });
  }

  if (this.QueueSize === null || this.QueueSize === 'null') {
    this.QueueSize = null;
  }
}

}
