import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
import { Variables } from 'src/app/Models/variables';

@Injectable({
  providedIn: 'root'
})
export class GroupDefService {

  apiUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = environment.abmApiUrl;
  }

  getGroups(page: number, size: number, groupid: number, name: string, filter: string): Observable<any> {
    const v = new Variables();
    let val: string | null;
    val = localStorage.getItem('mg');
    const gps = CryptoJS.AES.decrypt(val as string, v.pass).toString(CryptoJS.enc.Utf8).toString();

    const url = this.apiUrl + '/api/contactgroupdef/' + page + '/' + size + '/' + groupid
     + '/' + name + '/' + filter + '/' + gps + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  private handleError(error: any): any {
    throw error;
    return error;
  }

}
