import { KeyValue } from 'src/app/Models/Updater';
import { PanelItemUpdater } from './../../Models/Updater';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { ConfirmationService } from 'primeng/api';
import { CookieService } from 'ngx-cookie-service';
import { PanelsService } from 'src/app/services/panels/panels.service';
import { PanelItemsService } from 'src/app/services/panel-items/panel-items.service';
import * as CryptoJS from 'crypto-js';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-edit-panel-item',
  templateUrl: './edit-panel-item.component.html',
  styleUrls: ['./edit-panel-item.component.css']
})
export class EditPanelItemComponent implements OnInit {

  ConfigName: any;
  PanelName: any;
  PanelItemName: any;

  ConfigId = 0;
  PanelId = 0;

  PanelItemId = 0;
  loading: boolean;

  IconName: any;
  Icon: any;
  icon: any;
  toption: any;

  constructor(
    private router: Router,
    private notificationSvc: NotificationService,
    public translate: TranslateService,
    private configService: ConfigurationService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private panelService: PanelsService,
    private panelItemService: PanelItemsService,
    private location: Location,
    private domSanitizer: DomSanitizer
  ) {
    // this.translate.addLangs(['English', 'French']);
    // this.translate.setDefaultLang('English');

    // let browserLang = this.translate.getBrowserLang();

    // const pass = 'ranitessarldev2019';
    // const lang = cookieService.get('goepla-portal-language');
    // if (lang.trim() !== '') {
    //   const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
    //   const lg = declang;
    //   browserLang = lg;
    // }

    // if (localStorage.getItem('language') !== null) {
    //   browserLang = localStorage.getItem('language');
    // }
    // this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    translate.use('English');

    const pan = JSON.parse(localStorage.getItem('panel-item'));
    this.ConfigName = pan.configuration.configurationName;
    this.PanelName = pan.panelName;
    this.ConfigId = pan.configurationId;
    this.PanelId = pan.panelId;
    this.PanelItemName = pan.panelItemName;
    this.PanelItemId = pan.id;

    this.toption = pan.panelItemTskDesc;
    if (pan.panelItemIcon !== null) {
      this.icon = this.domSanitizer.bypassSecurityTrustUrl(pan.panelItemIcon);
      this.Icon = pan.panelItemIcon;
    }
   }

  ngOnInit() {
  }

  onSave() {
    if (this.ConfigId <= 0) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTCONFIGURATION').subscribe((res: string) => {
        this.confirm(res);
      });

      this.loading = false;
    } else if (this.PanelItemId <= 0) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTPANEL').subscribe((res: string) => {
        this.confirm(res);
      });

      this.loading = false;
    } else if (this.PanelItemName === undefined || this.PanelItemName === null || this.PanelItemName.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.PROVIDEPANELITEMNAME').subscribe((res: string) => {
        this.confirm(res);
      });

      this.loading = false;
    } else {
      const panel = new PanelItemUpdater();
      panel.Id = this.PanelItemId;
      panel.Values = [];

      const pitemname = new KeyValue();
      pitemname.Key = 'p_item_name';
      pitemname.Value = this.PanelItemName;
      panel.Values.push(pitemname);

      const toption = new KeyValue();
      toption.Key = 'p_item_tsk_desc';
      toption.Value = this.toption;
      panel.Values.push(toption);

      if (this.Icon !== undefined && this.Icon !== null && this.Icon.trim() !== '') {
        const icon = new KeyValue();
        icon.Key = 'p_item_icon';
        icon.Value = this.Icon;
        panel.Values.push(icon);
      }

      this.loading = true;
      // tslint:disable-next-line: deprecation
      this.panelItemService.putPanel(this.PanelItemId, panel).subscribe((res) => {
        if (res  === true) {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.PANELITEMUPDATED').subscribe((resp: string) => {
            this.notificationSvc.info('', resp, 2000);
          });
          this.location.back();
          this.loading = false;
        } else {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.COULDNOTUPDATEPANELITEM').subscribe((resp: string) => {
            this.confirm(resp);
          });
          this.loading = false;
        }
      }, err => this.handleError(err));
    }
  }

  onCancel() {
    this.loading = false;
    this.location.back();
  }

  onFileSelected() {
    const inputNode: any = document.querySelector('#file');

    if (typeof (FileReader) !== 'undefined') {
      const reader = new FileReader();
      if (inputNode.files[0]) {
        reader.readAsDataURL(inputNode.files[0]);
      }

      reader.onloadend = (e: any) => {
        this.IconName = inputNode.files[0].name;
        const base64 = e.target.result;
        this.Icon = base64;
        this.icon = this.domSanitizer.bypassSecurityTrustUrl(base64);
      };
      reader.onerror = (() => {
        this.IconName = '';
        this.Icon = '';
        this.icon = null;
      });
    }
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  handleError(err) {
    this.loading = false;
    if (err.error.MessageText === 'duplicate key value violates unique constraint "t_ntm_panel_item_panel_item_name_key"') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.PANELALITEMREADYEXISTS').subscribe((res: string) => {
        this.confirm(res);
      });
    }
  }

}
