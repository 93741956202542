export class Reminder {
    public ReminderNameId: string;
    public ReminderTypeName: string;
    public ReminderName: string;
    public CreatedBy: string;
    public Status: string;
    public Created: Date;
    public ReminderTypeCreated: Date;
    public Expiry: number;
    public LastReminderGenerate: Date;
}
