import { Location } from '@angular/common';
import { Monitor } from './../../Models/Monitor';
import { MonitorsService } from './../../services/monitors/monitors.service';
import { Component, OnInit } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CookieService } from 'ngx-cookie-service';
import { AppComponent } from 'src/app/app.component';
import { Variables } from 'src/app/Models/variables';
import { UsersgroupsService } from 'src/app/services/usersgroups/usersgroups.service';
import { TypeRoleGroup } from 'src/app/Models/role';

@Component({
  selector: 'app-create-monitor-configuration',
  templateUrl: './create-monitor-configuration.component.html',
  styleUrls: ['./create-monitor-configuration.component.css']
})
export class CreateMonitorConfigurationComponent implements OnInit {

  loading: boolean;

  MonitorName: any;
  MonitorDescription: string;
  MaxSize: number;

  displays = [];
  display: any;

  ragroups1 = [];
  ragroups2 = [];

  adgroups1 = [];
  adgroups2 = [];

  constructor(
    private router: Router,
    private notificationSvc: NotificationService,
    public translate: TranslateService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private location: Location,
    private appComp: AppComponent,
    private monitorService: MonitorsService,
    private usersgroupsService: UsersgroupsService
  ) {
    // this.translate.addLangs(['English', 'French']);
    // this.translate.setDefaultLang('English');

    // let browserLang = this.translate.getBrowserLang();

    // const pass = 'ranitessarldev2019';
    // const lang = cookieService.get('goepla-portal-language');
    // if (lang.trim() !== '') {
    //   const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
    //   const lg = declang;
    //   browserLang = lg;
    // }

    // if (localStorage.getItem('language') !== null) {
    //   browserLang = localStorage.getItem('language');
    // }
    // this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    translate.use('English');

  }

  ngOnInit() {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.FIFO').subscribe((res: string) => {
      this.displays.push({label: res, value: 'FIFO'});
    });
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.LIFO').subscribe((res: string) => {
      this.displays.push({label: res, value: 'LIFO'});
    });
    this.getGroups();
  }

  getGroups() {
    this.loading = true;
    // tslint:disable-next-line: deprecation
    this.usersgroupsService.getGroups().subscribe((res) => {
      if (res != null) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          res[i].GroupName = res[i].groupName;
          // this.groupList.push({label: res[i].GroupName, value: res[i]});
          this.ragroups1.push(res[i]);
          this.adgroups1.push(res[i]);
        }
      }
      this.loading = false;
    });
  }

  onCreate() {
    this.loading = true;

    if (this.MonitorName === undefined || this.MonitorName == null || this.MonitorName.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.PROVIDEMONITORNAME').subscribe((res: string) => {
        this.confirm(res);
      });

      this.loading = false;
    } else if (this.MonitorDescription === undefined || this.MonitorDescription == null || this.MonitorDescription.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.PROVIDEMONITORDESCRIPTION').subscribe((res: string) => {
        this.confirm(res);
      });

      this.loading = false;
    } else {
      if (this.MaxSize === undefined || this.MaxSize == null || this.MaxSize.toString().trim() === '') {
        this.MaxSize = 100;
      }
      if (isNaN(this.MaxSize)) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.MAXSIZENOTNUMBER').subscribe((res: string) => {
          this.confirm(res);
        });
        this.loading = false;
      } else if (this.MaxSize > 100 || this.MaxSize < 0) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.MAXIMUMSIZEEXCEEDED').subscribe((res: string) => {
          this.confirm(res);
        });
        this.loading = false;
      } else if (this.display === undefined || this.display === null) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.SELECTDISPLAY').subscribe((res: string) => {
          this.confirm(res);
        });
        this.loading = false;
      } else {
        const monitor = new Monitor();

        const v = new Variables();
        const org = localStorage.getItem('oi');
        const oi = CryptoJS.AES.decrypt(org, v.pass).toString(CryptoJS.enc.Utf8);

        monitor.OrganisationId = +oi;
        // monitor.OrganisationId = +localStorage.getItem('organisation-id');
        monitor.MonitorName = this.MonitorName;
        monitor.MonitorDescription = this.MonitorDescription;
        monitor.MaxSize = this.MaxSize;
        monitor.Display = this.display;

        const rgroups = [];
        if (this.ragroups2 !== null && this.ragroups2.length > 0) {
          for (const val of this.ragroups2) {
            const rgroup = new TypeRoleGroup();
            rgroup.GroupId = val.groupId;
            rgroup.RoleName = 'Reader-All';
            rgroup.RoleParentType = 'monitor_type';

            rgroups.push(rgroup);
          }
        }
        if (this.adgroups2 !== null && this.adgroups2.length > 0) {
          for (const val of this.adgroups2) {
            const rgroup = new TypeRoleGroup();
            rgroup.GroupId = val.groupId;
            rgroup.RoleName = 'Administrator';
            rgroup.RoleParentType = 'monitor_type';

            rgroups.push(rgroup);
          }
        }

        monitor.RoleGroups = rgroups;

        // tslint:disable-next-line: deprecation
        this.monitorService.postMonitor(monitor).subscribe(async (res) => {
          await this.appComp.loadMenuAgain();
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.MONITORCONFIGURATIONCREATED').subscribe((resp: string) => {
            this.notificationSvc.info('', resp, 2000);
          });
          this.location.back();
          this.loading = false;
        }, err => this.handleError(err));
      }
    }
  }

  onCancel() {
    this.location.back();
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  handleError(err) {
    this.loading = false;
    if (err.error.MessageText === 'duplicate key value violates unique constraint "t_ntm_monitor_monitor_name_key"') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.MONITORALREADYEXISTS').subscribe((res: string) => {
        this.confirm(res);
      });
    }

    if (err.error.MessageText === 'new row for relation "t_ntm_monitor" violates check constraint "valid_size"') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.MAXIMUMSIZEEXCEEDED').subscribe((res: string) => {
        this.confirm(res);
      });
    }
  }
}
