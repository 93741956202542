import { Recipient } from './recipient';

export class Notification {
    ConfigurationId: number;
    NotificationId: string;
    MessageTitle: string;
    MessageBody: string;
    Created: Date;
    Expires: Date;
    Location: string;
    Recipient: Recipient;
    CreatedBy: number;
}
