import { Location } from './../../Models/location';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Urls } from 'src/app/Helpers/Urls';
import { DeviceUpdater } from 'src/app/Models/Updater';
import { Variables } from 'src/app/Models/variables';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  url = new Urls();
  apiUrl: string;
  orgId: number;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = this.url.apiUrl;
      const v = new Variables();
      const val = localStorage.getItem('oi');
      const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);

      this.orgId = +oi;
      // this.orgId = +localStorage.getItem('organisation-id');
  }

  getLocations(page, size, loc) {
    const v = new Variables();
    const val = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);

    this.orgId = +oi;
    // this.orgId = +localStorage.getItem('organisation-id');
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/locations/' + page + '/' + size + '/' + this.orgId + '/' + loc + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  postLocation(loc: Location): Observable<any> {
    // const body = JSON.stringify(inst);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.post<any>(this.apiUrl + '/api/locations', loc, options)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  deleteLocation(loc: Location): Observable<any> {
    // const body = JSON.stringify(inst);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.put<any>(this.apiUrl + '/api/locations/delete/0', loc, options)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  private handleError(error: any) {
    // console.error('error', error);
    throw error;
    return error;
  }
}
