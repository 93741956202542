import { TypeRoleGroup, TypeRoleGroupInfo } from './role';

export class Panels {
    public OrganisationId: number;
    public PanelId: number;
    public PanelType: string;
    public PanelName: string;
    public PanelDescription: string;
    public RoleGroups: TypeRoleGroup[];
    public TypeRoleGroups: TypeRoleGroupInfo[];
}

