import { TypeRoleGroupDataGuid, TypeRoleGroupGuid } from './../../Models/channel';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { KeycloakService } from 'keycloak-angular';
import { MessageService } from 'primeng/api';
import { Channel } from 'src/app/Models/channel';
import { ChannelTaskType } from 'src/app/Models/channel-task-type';
import { ChannelUser } from 'src/app/Models/channel-user';
import { StringUpdater, KeyValue } from 'src/app/Models/Updater';
import { Variables } from 'src/app/Models/variables';
import { ChanTaskTypeService } from 'src/app/services/chan-task-type/chan-task-type.service';
import { ChanUserService } from 'src/app/services/chan-user/chan-user.service';
import { ChannelService } from 'src/app/services/channel/channel.service';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { UsersgroupsService } from 'src/app/services/usersgroups/usersgroups.service';
import * as CryptoJS from 'crypto-js';
import { TypeRoleGroup } from 'src/app/Models/role';
import { DataCache } from 'src/app/Helpers/data-cache';

@Component({
  selector: 'app-channel-adm',
  templateUrl: './channel-adm.component.html',
  styleUrls: ['./channel-adm.component.css']
})
export class ChannelAdmComponent implements OnInit {

  orgs: any = [];
  org: any;

  isnoorgs = false;
  showchannels = false;
  loadmoreo = false;

  isfilter = false;

  divname = 'mydiv';

  page = 1;
  size = 100;

  loading = false;

  OrgName = '';
  NtmUrl = '';
  SsoUrl = '';
  SsoSecret = '';

  orgnameerror = false;
  ntmurlerror = false;
  ssourlerror = false;
  ssosecreterror = false;

  usererror = false;
  uiderror = false;

  iscreateorg = false;
  iseditorg = false;
  isaddorguser = false;
  isaddorgchannel = false;
  iseditorgchannel = false;
  isaddchanneluser = false;
  isaddchanneltype = false;

  isdetail = false;

  orgusers: any = [];
  orguser: any;

  ochannels: any = [];

  divheight = '0px';
  divheight2 = '0px';
  contentdiv = '';

  tempusers: any = [];
  users: any = [];
  user: any;
  Uid = '';

  showfilter = true;

  filters: any = [];
  filter: any;
  Name = '';
  orga = '';

  filterform: any;

  showdivs = false;

  channelnameerror = false;
  channeliconerror = false;

  ChannelName: string | undefined;
  ChannelIcon: string | undefined;

  iconname: string | undefined;
  icon: any;

  chanheader = '';
  ischantasktype = false;
  ischanuser = false;

  chantasktypes: any = [];
  chantasktype: any;

  tempchantasktypes = [];

  chanusers: any = [];
  chanuser: any;

  TaskTypeId: number | undefined;
  typeiderror = false;

  PhoneNumber = '';
  phoneerror = false;

  isdefaulttasktype = false;

  colorpalette = ['#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
                  '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
                  '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
                  '#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
                  '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
                  '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
                  '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
                  '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
                  '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
                  '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'];

  colorpalette2 = ['#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
                  '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
                  '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
                  '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
                  '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
                  '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
                  '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
                  '#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
                  '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
                  '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'];

  colorpalette3 = ['#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
                  '#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
                  '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
                  '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
                  '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
                  '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
                  '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
                  '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
                  '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933'];

  colorpalette4 = ['#6680B3', '#66991A', '#FF99E6', '#CCFF1A', '#FF1A66',
                   '#E6331A', '#33FFCC', '#66994D', '#B366CC', '#4D8000',
                   '#B33300', '#CC80CC', '#FF3380', '#CCCC00', '#66E64D',
                  '#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
                  '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
                  '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
                  '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
                  '#80B300', '#809900', '#E6B3B3', '#4D80CC', '#9900B3',
                  '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933'];

  colorpalette5 = ['#CC9999', '#B3B31A', '#00E680', '#FF99E6', '#CCFF1A',
                  '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
                  '#80B300', '#809900', '#E6B3B3', '#4D80CC', '#9900B3',
                  '#4D8066', '#809980', '#E6FF80', '#6680B3', '#66991A',
                  '#E6331A', '#33FFCC', '#66994D', '#B366CC', '#4D8000',
                  '#B33300', '#CC80CC', '#FF3380', '#CCCC00', '#66E64D',
                  '#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
                  '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
                  '#E666B3', '#33991A', '#1AFF33', '#999933', '#FF1A66'];

  options: any = [];

  orgchan: any;

  menuheight: any;

  innerdiv = '';
  innerdivmobile = '';
  paneldiv = '';
  paneldivmobile = '';
  dialogheight = '';
  picklistdiv = '';

  channels = [];
  channel: any;

  loadmorec = false;
  isedit = false;

  channeluser: any;
  channeltasktype: any;

  configurations = [];
  tempconfigurations = [];

  ismanage = false;

  ragroups1 = [];
  ragroups2 = [];

  adgroups1 = [];
  adgroups2 = [];

  isadm = false;

  gps = [];
  gs = [];

  iseditable = false;

  detail = 'y';
  master = 'x';

  constructor(
    private domSanitizer: DomSanitizer,
    private translate: TranslateService,
    private messageService: MessageService,
    private channelService: ChannelService,
    private keycloak: KeycloakService,
    private fb: FormBuilder,
    private chanTaskTypeService: ChanTaskTypeService,
    private chanUserService: ChanUserService,
    private usersgroupsService: UsersgroupsService,
    private configService: ConfigurationService
  ) {
    translate.use('English');

    const v = new Variables();
    const p = localStorage.getItem('ups');
    if (p == null) {
      this.ismanage = false;
    }

    const pr = CryptoJS.AES.decrypt(p, v.pass).toString(CryptoJS.enc.Utf8);
    const perm = JSON.parse(pr);
    if (perm.includes('NTM.Administration.Channels')
        || perm.includes('NTM.Administration.Full')) {
          this.ismanage = true;
    } else {
      this.ismanage = false;
    }

    if (perm.includes('NTM.Administration.Full')) {
      this.isadm = true;
    }

    this.onResize(null);
  }

  async ngOnInit(): Promise<any> {
    this.filterform = this.fb.group({
      filter: new FormControl(),
      name: new FormControl()
    }) as FormGroup;

    this.showfilter = false;

    this.getGroups2();
    await this.getOrgChannels();
  }

  async getGroups2() {
    const data = await DataCache.groupdata$.toPromise();
    this.gps = [];
    this.gs = [];
    if (data !== undefined && data !== null && data.length > 0) {
      for (const val of data) {
        this.gps.push(val);
        this.gs.push(val);
      }
    }
  }

  arrangeRoles() {
    this.adgroups1 = [];
    this.adgroups2 = [];
    this.ragroups1 = [];
    this.ragroups2 = [];

    if (this.gs !== null && this.gs.length > 0) {
      if (this.channel.typeRoleGroups !== null && this.channel.typeRoleGroups.length > 0) {
        for (const v of this.gs) {
          let ra = true;
          let ad = true;
          for (const val of this.channel.typeRoleGroups) {
            if (val.typeRoleGroup.roleName === 'Reader-All') {
              if (v.groupId === val.typeRoleGroup.groupId) {
                this.ragroups2.push(v);
                ra = false;
              }
            }
            if (val.typeRoleGroup.roleName === 'Administrator') {
              if (v.groupId === val.typeRoleGroup.groupId) {
                this.adgroups2.push(v);
                ad = false;
              }
            }
          }

          if (ra === true) {
            this.ragroups1.push(v);
          }
          if (ad === true) {
            this.adgroups1.push(v);
          }
        }
      } else {
        for (const val of this.gs) {
          this.ragroups1.push(val);
          this.adgroups1.push(val);
        }
      }
    }
  }

  async getGroups() {
    this.loading = true;
    this.showLoad();
    // const res = await this.usersgroupsService.getGroups().toPromise();
    const res = await DataCache.groupdata$.toPromise();
    if (res != null) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < res.length; i++) {
        res[i].GroupName = res[i].groupName;
        this.adgroups1.push(res[i]);
        this.ragroups1.push(res[i]);
      }
    }
    this.loading = false;
    this.hideLoad();
  }

  filterSelection(): any {
    if (this.filterform.value.filter === 'organisation') {
      // this.isorg = true;
      // this.isname = false;
      this.filterform.controls.name.setValue('');
    } else if (this.filterform.value.filter === 'channel') {
      // this.isorg = false;
      // this.isname = true;
      // this.organisation = null;
    } else {
      // this.isorg = false;
      // this.isname = false;

      this.filterform.reset();
      // this.organisation = null;
    }
  }

  filterSelectionObsolete(): any {
    if (this.filter === 'organisation') {
      // this.isorg = true;
      // this.isname = false;
      this.Name = '';
    } else if (this.filter === 'channel') {
      // this.isorg = false;
      // this.isname = true;
      // this.organisation = null;
    } else {
      // this.isorg = false;
      // this.isname = false;

      this.Name = ' ';
      // this.organisation = null;
    }
  }

  cancel(): any {
    this.iscreateorg = false;
    this.iseditorg = false;
    this.isaddorguser = false;

    this.orgnameerror = false;
    this.ntmurlerror = false;
    this.ssourlerror = false;
    this.ssosecreterror = false;

    this.usererror = false;
    this.uiderror = false;

    this.OrgName = '';
    this.NtmUrl = '';
    this.SsoUrl = '';
    this.SsoSecret = '';

    this.user = null;
    this.Uid = '';

    this.iconname = '';
    this.icon = null;

    this.ChannelName = '';
    this.ChannelIcon = '';

    this.channelnameerror = false;
    this.channeliconerror = false;

    this.isaddorgchannel = false;
    this.iseditorgchannel = false;

    this.TaskTypeId = 0;
    this.typeiderror = false;

    this.isaddchanneltype = false;
    this.isaddchanneluser = false;

    this.phoneerror = false;
    this.PhoneNumber = '';

    this.isdefaulttasktype = false;

    this.isedit = false;

    this.tempchantasktypes = [];

    this.adgroups1 = [];
    this.adgroups2 = [];
    this.ragroups1 = [];
    this.ragroups2 = [];

    this.detail = 'y';
    this.master = 'x';

    this.onResize(null);
  }

  async showDetail(): Promise<any> {
    this.isdetail = true;

  }

  async showDelete(): Promise<any> {
    const title = await this.translate.get('GENERAL.DELETE').toPromise().catch(err => console.log(err));
    if (this.orgusers !== undefined && this.orgusers !== null && this.orgusers.length > 0
      && this.ochannels !== undefined && this.ochannels !== null && this.ochannels.length > 0) {
        const addinfo = await this.translate.get('GENERAL.CONTAINSUSERSANDCHANNELS').toPromise().catch(err => console.log(err));
        this.showConfirm(title + ' ' + this.org.organisationName + '?', addinfo, 'cd');
    } else if (this.orgusers !== undefined && this.orgusers !== null && this.orgusers.length > 0) {
      const addinfo = await this.translate.get('GENERAL.CONTAINSUSERS').toPromise().catch(err => console.log(err));
      this.showConfirm(title + ' ' + this.org.organisationName + '?', addinfo, 'cd');
    } else if (this.ochannels !== undefined && this.ochannels !== null && this.ochannels.length > 0) {
      const addinfo = await this.translate.get('GENERAL.CONTAINSCHANNELS').toPromise().catch(err => console.log(err));
      this.showConfirm(title + ' ' + this.org.organisationName + '?', addinfo, 'cd');
    } else {
      this.showConfirm(title + ' ' + this.org.organisationName + '?', '', 'cd');
    }
  }

  async deleteOrgChannel(): Promise<any> {
    this.loading = true;
    this.showLoad();

    const res = await this.channelService.deleteChannel(this.channel.channelId)
    .toPromise().catch(err => this.handleError(err));

    if (res !== undefined && res !== null) {
      if (res === true) {
        // user deleted
        const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch(err => console.log(err));
        const detail = await this.translate.get('GENERAL.CHANNELDELETED').toPromise().catch(err => console.log(err));
        this.showSuccess(title, detail);

        this.cancel();
        this.page = 1;
        await this.getOrgChannels();
        this.channel = null;
      } else {
        // failure
        const title = await this.translate.get('GENERAL.ERROR').toPromise().catch(err => console.log(err));
        const detail = await this.translate.get('GENERAL.CHANNELNOTDELETED').toPromise().catch(err => console.log(err));
        this.showError(title, detail, 5000);
      }
    }

    this.loading = false;
    this.hideLoad();
  }

  async deleteChannelTaskType(): Promise<any> {
    this.loading = true;
    this.showLoad();

    const ttype = new ChannelTaskType();
    ttype.ChannelId = this.chantasktype.channelId;
    ttype.TaskTypeId = this.chantasktype.taskTypeId;

    const res = await this.chanTaskTypeService.deleteChannelTaskType(ttype)
    .toPromise().catch(err => this.handleError(err));

    if (res !== undefined && res !== null) {
      if (res === true) {
        // user deleted
        const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch(err => console.log(err));
        const detail = await this.translate.get('GENERAL.CHANNELNOTTYPEDELETED').toPromise().catch(err => console.log(err));
        this.showSuccess(title, detail);

        await this.getChanTaskTypes();
        // await this.getOrgChannels();
      } else {
        // failure
        const title = await this.translate.get('GENERAL.ERROR').toPromise().catch(err => console.log(err));
        const detail = await this.translate.get('GENERAL.CHANNELNOTTYPENOTDELETED').toPromise().catch(err => console.log(err));
        this.showError(title, detail, 5000);
      }
    }

    this.loading = false;
    this.hideLoad();
  }

  async deleteChannelUser(): Promise<any> {
    this.loading = true;
    this.showLoad();

    const cuser = new ChannelUser();
    cuser.ChannelId = this.chanuser.channelId;
    cuser.GroupId = this.chanuser.groupId;
    cuser.GroupName = this.chanuser.groupName;

    const res = await this.chanUserService.deleteChannelUser(cuser)
    .toPromise().catch(err => this.handleError(err));

    if (res !== undefined && res !== null) {
      if (res === true) {
        // user deleted
        const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch(err => console.log(err));
        const detail = await this.translate.get('GENERAL.CHANNELGROUPDELETED').toPromise().catch(err => console.log(err));
        this.showSuccess(title, detail);

        await this.getChannelUsers();
        // await this.getOrgChannels();
      } else {
        // failure
        const title = await this.translate.get('GENERAL.ERROR').toPromise().catch(err => console.log(err));
        const detail = await this.translate.get('GENERAL.CHANNELGROUPNOTDELETED').toPromise().catch(err => console.log(err));
        this.showError(title, detail, 5000);
      }
    }

    this.loading = false;
    this.hideLoad();
  }

  async getOrgChannels(): Promise<any> {
    this.loading = true;
    this.showLoad();

    const myochannels = [];
    const chans = await this.channelService.getChannels(this.page, this.size, ' ', ' ', ' ')
    .toPromise().catch(err => this.handleError(err));

    if (chans !== undefined || chans !== null && chans.length > 0) {
      if (chans.length >= this.size) {
        this.loadmorec = true;
      } else {
        this.loadmorec = false;
      }
      let count = 0;
      for (const val of chans) {
        val.color = 'white';
        val.chanImage = '';
        val.myImage = '';
        if (val.channelIcon !== null) {
          val.chanImage = this.domSanitizer.bypassSecurityTrustUrl(val.channelIcon);
        } else {
          val.chanImage = '';
          val.myImage = val.channelName.charAt(0).toUpperCase();

          val.col = this.colorpalette2[count];
          count += 1;
          if (count === this.colorpalette2.length) {
            count = 0;
          }
        }

        if (this.page === 1) {
          myochannels.push(val);
        } else {
          this.ochannels.push(val);
        }
      }
    } else {
      this.loadmorec = true;
    }

    if (this.page === 1) {
      this.ochannels = myochannels;
    }

    this.loading = false;
    this.hideLoad();
  }

  async loadMoreC() {
    this.page += 1;
    await this.getOrgChannels();
  }

  async showEditChannel() {
    // edit channel
    this.cancel();

    this.detail = 'z';
    this.master = 'w';

    const val = this.channel;
    this.ChannelName = val.channelName;

    if (val.channelIcon !== null && val.channelIcon !== '') {
      this.icon = this.domSanitizer.bypassSecurityTrustUrl(val.channelIcon);
    } else {
      this.icon = '';
    }
    this.iconname = '';
    this.ChannelIcon = val.channelIcon;

    // this.iseditorgchannel = true;
    this.icon = null;
    this.isedit = true;
    this.iseditorgchannel = true;

    this.tempchantasktypes = this.chantasktypes;
    await this.addOldConfs();
    await this.getGroups2();
    this.arrangeRoles();

    this.onResize(null);
  }

  async showDeleteChannel() {
    // delete channel
    const title = await this.translate.get('GENERAL.DELETE').toPromise().catch(err => console.log(err));
    this.showConfirm(title + ' ' + this.channel.channelName + '?', '', 'cdchan');
  }

  async processChan(event: any, val: any): Promise<any> {
    this.orgchan = val;
    if (event.item.value === 'Delete') {
      // delete channel
      const title = await this.translate.get('GENERAL.DELETE').toPromise().catch(err => console.log(err));
      this.showConfirm(title + ' ' + this.orgchan.channelName + '?', '', 'cdchan');
    } else if (event.item.value === 'Edit') {
      // edit channel
      this.cancel();
      this.ChannelName = val.channelName;

      if (val.channelIcon !== null && val.channelIcon !== '') {
        this.icon = this.domSanitizer.bypassSecurityTrustUrl(val.channelIcon);
      } else {
        this.icon = '';
      }
      this.iconname = '';
      this.ChannelIcon = val.channelIcon;

      this.iseditorgchannel = true;
    } else if (event.item.value === 'Users') {
      // manage channel users
      await this.getChannelUsers();
      const title = await this.translate.get('GENERAL.USERSFOR').toPromise().catch(err => console.log(err));
      // this.chanheader = title + ' ' + val.channelName;
      this.chanheader = val.channelName;
      this.ischanuser = true;
    } else if (event.item.value === 'Types') {
      // manage channel types
      await this.getChanTaskTypes();
      const title = await this.translate.get('GENERAL.TASKTYPESFOR').toPromise().catch(err => console.log(err));
      // this.chanheader = title + ' ' + val.channelName;
      this.chanheader = val.channelName;
      this.ischantasktype = true;
    }
  }

  async getChanTaskTypes(): Promise<any> {
    this.showLoad();
    this.loading = true;

    const types = await this.chanTaskTypeService.getChannelTaskTypes(0, 0, this.channel.channelId, 0, ' ')
    .toPromise().catch(err => this.handleError(err));

    this.chantasktypes = [];
    if (types !== undefined && types !== null && types.length > 0) {
      for (const val of types) {
        val.color = 'white';
        this.chantasktypes.push(val);
      }
    }

    this.hideLoad();
    this.loading = false;
  }

  chanTaskTypeClicked2(type: any): any {
    const mchans = [];
    for (const val of this.tempchantasktypes) {
      let mu: any = [];
      mu = val;
      if (type.configurationId === val.configurationId) {
        mu.color = '#c9e2ee';
      } else {
        mu.color = 'white';
      }
      mchans.push(mu);
    }

    this.tempchantasktypes = mchans;
  }

  chanTaskTypeClicked(type: any): any {
    const ttypes = [];
    for (const val of this.chantasktypes) {
      const mt: any = [];
      mt.channelIcon = val.channelIcon;
      mt.channelId = val.channelId;
      mt.channelName = val.channelName;
      mt.organisationName = val.organisationName;
      mt.taskTypeId = val.taskTypeId;
      mt.isDefault = val.isDefault;
      mt.taskTypeName = val.taskTypeName;
      if (type.taskTypeId === val.taskTypeId) {
        mt.color = '#c9e2ee';
      } else {
        mt.color = 'white';
      }
      ttypes.push(mt);
    }

    this.chantasktypes = ttypes;
  }

  async getChannelUsers(): Promise<any> {
    this.showLoad();
    this.loading = true;

    const cusers = await this.chanUserService.getChannelUsers(0, 0, this.channel.channelId, ' ')
    .toPromise().catch(err => this.handleError(err));

    this.chanusers = [];
    if (cusers !== undefined && cusers !== null && cusers.length > 0) {
      for (const val of cusers) {
        val.color = 'white';
        this.chanusers.push(val);
      }
    }

    this.hideLoad();
    this.loading = false;
  }

  chanUserClicked(chanuser: any): any {
    const mcusers = [];
    for (const val of this.chanusers) {
      const mcu: any = [];
      mcu.parentId = val.parentId;
      mcu.groupId = val.groupId;
      mcu.g = val.g;
      mcu.roleName = val.roleName;
      mcu.roleParentType = val.roleParentType;
      if (chanuser.groupId === val.groupId && chanuser.roleName === val.roleName) {
        mcu.color = '#c9e2ee';
      } else {
        mcu.color = 'white';
      }
      mcusers.push(mcu);
    }
    this.chanusers = mcusers;
  }

  chanUserClicked2(chanuser: any): any {
    const mcusers = [];
    for (const val of this.chanusers) {
      const mcu: any = [];
      mcu.channelId = val.channelId;
      mcu.groupId = val.groupId;
      mcu.groupName = val.groupName;
      if (chanuser.groupId === val.groupId) {
        mcu.color = '#c9e2ee';
      } else {
        mcu.color = 'white';
      }
      mcusers.push(mcu);
    }
    this.chanusers = mcusers;
  }

  async showDeleteChanTaskType(type: any): Promise<any> {
    this.chantasktype = type;
    const index = this.tempchantasktypes.indexOf(type, 0);
    if (index > -1) {
      this.tempchantasktypes.splice(index, 1);
    }
    // const title = await this.translate.get('GENERAL.DELETE').toPromise().catch(err => console.log(err));
    // this.showConfirm(title + ' ' + type.taskTypeName + '?', '', 'cdchantt');
  }

  async showDeleteChanUser(user: any): Promise<any> {
    this.chanuser = user;
    const title = await this.translate.get('GENERAL.DELETE').toPromise().catch(err => console.log(err));
    this.showConfirm(title + ' ' + user.groupName + '?', '', 'cdchanuser');
  }

  async showConfirmAddChannelUser(user: any): Promise<any> {
    this.addChanUserClicked(user);
    this.channeluser = user;
    const title = await this.translate.get('GENERAL.ADD').toPromise().catch(err => console.log(err));
    this.showConfirm(title + ' ' + user.groupName + '?', '', 'cachanuser');
  }

  async showConfirmAddChannelNot(conf: any): Promise<any> {
    this.tempchantasktypes.push(conf);
    this.channeltasktype = conf;
    this.onConfirmAddChannelTaskType();
    await this.getConfs();
    // this.isaddchanneltype = false;
    // this.addChanConfClicked(conf);
    // this.channeltasktype = conf;
    // const title = await this.translate.get('GENERAL.ADD').toPromise().catch(err => console.log(err));
    // this.showConfirm(title + ' ' + conf.configurationName + '?', '', 'cachanttype');
  }

  async showAddTaskType(): Promise<any> {
    // this.cancel();
    this.chantasktype = null;
    await this.getConfs();
    this.isaddchanneltype = true;
  }

  async addOldConfs() {
    this.loading = false;
    this.showLoad();

    this.configurations = [];

    if (this.tempconfigurations.length === 0) {
      await this.getConfigurations();
    }

    if (this.isedit === true) {
      if (this.chantasktypes !== undefined && this.chantasktypes !== null && this.chantasktypes.length > 0) {
        const mychans = [];
        const cfs = [];
        for (const val of this.tempconfigurations) {
          let add = true;
          for (const u of this.chantasktypes) {
            if (u.taskTypeId === val.configurationId) {
              add = false;
            }
          }

          val.color = 'white';
          if (add === true) {
            mychans.push(val);
          } else {
            cfs.push(val);
          }
        }

        this.configurations = mychans;
        this.tempchantasktypes = cfs;
      } else {
        this.configurations = [];
        for (const val of this.tempconfigurations) {
          this.configurations.push(val);
        }
      }
    }

    this.loading = false;
    this.hideLoad();
  }

  async getConfs() {
    this.loading = false;
    this.showLoad();

    this.configurations = [];

    if (this.tempconfigurations.length === 0) {
      await this.getConfigurations();
    }

    if (this.tempchantasktypes !== undefined && this.tempchantasktypes !== null && this.tempchantasktypes.length > 0) {
      const mychans = [];
      for (const val of this.tempconfigurations) {
        let add = true;
        for (const u of this.tempchantasktypes) {
          if (u.configurationId === val.configurationId) {
            add = false;
          }
        }

        if (add === true) {
          val.color = 'white';
          mychans.push(val);
        }
      }

      this.configurations = mychans;
    } else {
      this.users = [];
      for (const val of this.tempconfigurations) {
        val.color = 'white';
        this.configurations.push(val);
      }
    }

    this.loading = false;

    this.hideLoad();
  }

  async getConfigurations() {
    this.loading = true;
    this.showLoad();
    // tslint:disable-next-line: deprecation
    const res = await this.configService.getConfigurations('T').toPromise()
    .catch(err => this.handleError(err));

    if (res != null) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < res.length; i++) {
        res[i].color = 'white';
        if (res[i].queueSize === null || res[i].queueSize === 'null') {
          res[i].queueSize = 'null';
        }
        if (res[i].workflow === 'OASC') {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.OASC').subscribe((oasc) => {
            res[i].work = oasc;
          });
        } else if (res[i].workflow === 'OSC') {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.OSC').subscribe((osc) => {
            res[i].work = osc;
          });
        } else if (res[i].workflow === 'OC') {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.OC').subscribe((oc) => {
            res[i].work = oc;
          });
        }
        this.tempconfigurations.push(res[i]);
      }
    }
    this.loading = false;
    this.hideLoad();
  }

  async showAddChanUser(): Promise<any> {
    this.loading = false;

    if (this.tempusers.length === 0) {
      await this.getUsers();
    }

    if (this.chanusers !== undefined && this.chanusers !== null && this.chanusers.length > 0) {
      const myusers = [];
      for (const val of this.tempusers) {
        let add = true;
        for (const u of this.chanusers) {
          if (u.groupId === val.value.groupId) {
            add = false;
          }
        }

        if (add === true) {
          myusers.push(val.value);
        }
      }

      this.users = myusers;
    } else {
      this.users = [];
      for (const val of this.tempusers) {
        this.users.push(val.value);
      }
    }

    this.cancel();
    this.PhoneNumber = '';
    this.isaddchanneluser = true;
  }

  async addChanTaskType(): Promise<any> {
    const isadd = true;
    if (isadd === true) {
      this.loading = true;
      this.showLoad();

      const type = new ChannelTaskType();
      // type.ChannelId = this.channel.channelId;
      if (this.channel !== undefined && this.channel !== null) {
        type.ChannelId = this.channel.channelId;
      }
      type.TaskTypeId = this.channeltasktype.configurationId as number;
      if (this.isdefaulttasktype === true) {
        type.IsDefault = 'Y';
      } else {
        type.IsDefault = 'N';
      }

      this.tempchantasktypes.push(type);

      this.loading = false;
      this.hideLoad();
    }
  }

  async addChanTaskType2(): Promise<any> {
    const isadd = true;
    if (isadd === true) {
      this.loading = true;
      this.showLoad();

      const type = new ChannelTaskType();
      type.ChannelId = this.channel.channelId;
      type.TaskTypeId = this.channeltasktype.configurationId as number;
      if (this.isdefaulttasktype === true) {
        type.IsDefault = 'Y';
      } else {
        type.IsDefault = 'N';
      }

      const res = await this.chanTaskTypeService.postChannelTaskType(type)
      .toPromise().catch(err => this.handleError(err));

      if (res !== undefined && res !== null) {
        if (res === true) {
          // user deleted
          const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch(err => console.log(err));
          const detail = await this.translate.get('GENERAL.NOTTYPEADDED').toPromise().catch(err => console.log(err));
          this.showSuccess(title, detail);

          this.cancel();
          await this.getChanTaskTypes();
          // await this.getOrgChannels();
        } else {
          // failure
          const title = await this.translate.get('GENERAL.ERROR').toPromise().catch(err => console.log(err));
          const detail = await this.translate.get('GENERAL.NOTTYPENOTADDED').toPromise().catch(err => console.log(err));
          this.showError(title, detail, 5000);
        }
      }

      this.loading = false;
      this.hideLoad();
    }
  }

  async addChanUser(): Promise<any> {
    const isadd = true;
    if (isadd === true) {
      this.loading = true;
      this.showLoad();

      const cuser = new ChannelUser();
      cuser.ChannelId = this.channel.channelId;
      cuser.GroupId = this.channeluser.groupId;
      cuser.GroupName = this.channeluser.groupName;

      const res = await this.chanUserService.postChannelUser(cuser)
      .toPromise().catch(err => this.handleError(err));

      if (res !== undefined && res !== null) {
        if (res === true) {
          // user deleted
          const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch(err => console.log(err));
          const detail = await this.translate.get('GENERAL.CHANNELGROUPADDED').toPromise().catch(err => console.log(err));
          this.showSuccess(title, detail);

          this.cancel();
          await this.getChannelUsers();
          // await this.getOrgChannels();
        } else {
          // failure
          const title = await this.translate.get('GENERAL.ERROR').toPromise().catch(err => console.log(err));
          const detail = await this.translate.get('GENERAL.CHANNELGROUPNOTADDED').toPromise().catch(err => console.log(err));
          this.showError(title, detail, 5000);
        }
      }

      this.loading = false;
      this.hideLoad();
    }
  }

  async showAddOrgChannel(): Promise<any> {
    this.cancel();

    this.detail = 'z';
    this.master = 'w';

    // get groups
    await this.getGroups();
    this.chantasktype = null;

    // get configurations
    await this.getConfs();

    this.isaddorgchannel = true;
    this.isedit = true;
    this.onResize(null);
  }

  async createOrgChannel(): Promise<any> {
    let iscreate = true;
    this.channelnameerror = false;
    this.channeliconerror = false;

    if (this.ChannelName === undefined || this.ChannelName === null || this.ChannelName.trim() === '') {
      this.channelnameerror = true;
      iscreate = false;
    }

    if (iscreate === true) {
      const types = [];
      // check and prepare notification types
      if (this.tempchantasktypes !== undefined && this.tempchantasktypes !== null && this.tempchantasktypes.length > 0) {
        for (const val of this.tempchantasktypes) {
          const type = new ChannelTaskType();
          // type.ChannelId = this.channel.channelId;
          if (this.channel !== undefined && this.channel !== null) {
            type.ChannelId = this.channel.channelId;
          }
          type.TaskTypeId = val.configurationId as number;
          if (this.isdefaulttasktype === true) {
            type.IsDefault = 'Y';
          } else {
            type.IsDefault = 'N';
          }

          types.push(type);
        }
      }

      // prepare role groups
      const rgroups = [];
      if (this.ragroups2 !== null && this.ragroups2.length > 0) {
        for (const val of this.ragroups2) {
          const rgroup = new TypeRoleGroup();
          rgroup.GroupId = val.groupId;
          rgroup.RoleName = 'Reader-All';
          rgroup.RoleParentType = 'channel_type';

          rgroups.push(rgroup);
        }
      }
      if (this.adgroups2 !== null && this.adgroups2.length > 0) {
        for (const val of this.adgroups2) {
          const rgroup = new TypeRoleGroup();
          rgroup.GroupId = val.groupId;
          rgroup.RoleName = 'Administrator';
          rgroup.RoleParentType = 'channel_type';

          rgroups.push(rgroup);
        }
      }

      // create organisation channel
      const chan = new Channel();
      chan.OrganisationName = localStorage.getItem('org');
      chan.ChannelName = this.ChannelName as string;
      chan.ChannelIcon = this.ChannelIcon as string;
      chan.RoleGroups = rgroups;
      chan.NotificationTypes = types;

      this.loading = true;
      this.showLoad();

      const res = await this.channelService.postChannel(chan)
      .toPromise().catch(err => this.handleError(err));

      if (res !== undefined && res !== null) {
        if (res.trim() !== '') {
          // success
          const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch(err => console.log(err));
          const detail = await this.translate.get('GENERAL.CHANNELCREATED').toPromise().catch(err => console.log(err));
          this.showSuccess(title, detail);

          this.cancel();
          this.page = 1;
          await this.getOrgChannels();
        } else {
          // failure
          const title = await this.translate.get('GENERAL.ERROR').toPromise().catch(err => console.log(err));
          const detail = await this.translate.get('GENERAL.CHANNELNOTCREATED').toPromise().catch(err => console.log(err));
          this.showError(title, detail, 5000);
        }
      }

      this.loading = false;
      this.hideLoad();
    }
  }

  async saveOrgChannel(): Promise<any> {
    let iscreate = true;
    this.channelnameerror = false;
    this.channeliconerror = false;

    if (this.ChannelName === undefined || this.ChannelName === null || this.ChannelName.trim() === '') {
      this.channelnameerror = true;
      iscreate = false;
    }
    if (iscreate === true) {
      // update organisation channel
      const val = new StringUpdater();
      val.Id = this.channel.channelId;
      val.Values = [];

      const channame = new KeyValue();
      channame.Key = 'chan_name';
      channame.Value = this.ChannelName as string;
      val.Values.push(channame);

      const chanicon = new KeyValue();
      chanicon.Key = 'chan_icon';
      chanicon.Value = this.ChannelIcon as string;
      val.Values.push(chanicon);

      const types = [];
      // check and prepare notification types
      if (this.tempchantasktypes !== undefined && this.tempchantasktypes !== null && this.tempchantasktypes.length > 0) {
        for (const vall of this.tempchantasktypes) {
          const type = new ChannelTaskType();
          if (this.channel !== undefined && this.channel !== null) {
            type.ChannelId = this.channel.channelId;
          }
          type.TaskTypeId = vall.configurationId as number;
          if (this.isdefaulttasktype === true) {
            type.IsDefault = 'Y';
          } else {
            type.IsDefault = 'N';
          }

          types.push(type);
        }
      }

      // prepare role groups
      const rgroups = [];
      if (this.ragroups2 !== null && this.ragroups2.length > 0) {
        for (const vall of this.ragroups2) {
          const rgroup = new TypeRoleGroupGuid();
          rgroup.GroupId = vall.groupId;
          rgroup.RoleName = 'Reader-All';
          rgroup.RoleParentType = 'channel_type';
          rgroup.ParentId = this.channel.channelId;

          rgroups.push(rgroup);
        }
      }
      if (this.adgroups2 !== null && this.adgroups2.length > 0) {
        for (const vall of this.adgroups2) {
          const rgroup = new TypeRoleGroupGuid();
          rgroup.GroupId = vall.groupId;
          rgroup.RoleName = 'Administrator';
          rgroup.RoleParentType = 'channel_type';
          rgroup.ParentId = this.channel.channelId;

          rgroups.push(rgroup);
        }
      }

      const data = new TypeRoleGroupDataGuid();
      data.ParentId = this.channel.channelId;
      data.RoleParentType = 'channel_type';
      data.TypeRoleGroup = rgroups;

      const rolegroups = new KeyValue();
      rolegroups.Key = 'role_groups';
      rolegroups.Value = JSON.stringify(data);
      val.Values.push(rolegroups);

      const ctypes = new KeyValue();
      ctypes.Key = 'not_types';
      ctypes.Value = JSON.stringify(types);
      val.Values.push(ctypes);

      this.loading = true;
      this.showLoad();

      const res = await this.channelService.putChannel(val)
      .toPromise().catch(err => this.handleError(err));

      if (res !== undefined && res !== null) {
        if (res === true) {
          const ch = await this.channelService.getChannels(1, 1, this.channel.channelId, ' ', ' ')
          .toPromise().catch(err => this.handleError(err));

          // success
          const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch(err => console.log(err));
          const detail = await this.translate.get('GENERAL.CHANNELUPDATED').toPromise().catch(err => console.log(err));
          this.showSuccess(title, detail);

          this.cancel();
          this.size = this.page * this.size;
          this.page = 1;
          await this.getOrgChannels();
          if (this.ochannels !== undefined && this.ochannels !== null && this.ochannels.length > 0) {
            for (const cha of this.ochannels) {
              if (cha.channelId === this.channel.channelId) {
                this.channelClicked(cha);
              }
            }
          }


          // if (ch !== undefined && ch !== null && ch.length > 0) {
          //   for (const c of this.ochannels) {
          //     if (c.channelId === ch[0].channelId) {
          //       c.channelIcon = ch[0].channelIcon;
          //       c.channelName = ch[0].channelName;

          //       c.color = '#c9e2ee';
          //       c.chanImage = '';
          //       c.myImage = '';
          //       if (ch[0].channelIcon !== null) {
          //         c.chanImage = this.domSanitizer.bypassSecurityTrustUrl(ch[0].channelIcon);
          //       } else {
          //         c.chanImage = '';
          //         c.myImage = ch[0].channelName.charAt(0).toUpperCase();
          //       }

          //       this.channel = c;
          //     }
          //   }
          // }
        } else {
          // failure
          const title = await this.translate.get('GENERAL.ERROR').toPromise().catch(err => console.log(err));
          const detail = await this.translate.get('GENERAL.CHANNELNOTUPDATED').toPromise().catch(err => console.log(err));
          this.showError(title, detail, 5000);
        }
      }

      this.loading = false;
      this.hideLoad();
    }
  }

  async getUsers(): Promise<any> {
    let org = ' ';
    const o = localStorage.getItem('uo');
    if (o !== undefined && o !== null) {
      org = o;
    }

    this.loading = true;
    this.showLoad();

    const myusers = [];
    const users = await this.usersgroupsService.getGroups()
    .toPromise().catch(err => this.handleError(err));

    if (users != null && users.length > 0) {
      for (const val of users) {
        val.color = 'white';
        this.tempusers.push({ label: val.GroupName, value: val });
      }
    }

    this.loading = false;
    this.hideLoad();
  }

  addChanUserClicked(u: any): any {
    const musers = [];
    for (const val of this.users) {
      const mu: any = [];
      mu.col = val.col;
      mu.orgs = val.orgs;
      mu.groupId = val.groupId;
      mu.groupName = val.groupName;
      if (u.groupId === val.groupId) {
        mu.color = '#c9e2ee';
      } else {
        mu.color = 'white';
      }
      musers.push(mu);
    }
    this.users = musers;
  }

  addChanConfClicked(conf: any): any {
    const mchans = [];
    for (const val of this.configurations) {
      let mu: any = [];
      mu = val;
      if (conf.configurationId === val.configurationId) {
        mu.color = '#c9e2ee';
      } else {
        mu.color = 'white';
      }
      mchans.push(mu);
    }
    this.configurations = mchans;
  }

  showChannels(org: any): any {

  }

  backClicked() {
    this.detail = 'y';
    this.master = 'x';
  }

  channelClicked(channel: any): any {
    this.detail = 'z';
    this.master = 'w';

    this.isedit = false;
    this.isaddorgchannel = false;
    this.iseditorgchannel = false;
    const mchans = [];
    for (const val of this.ochannels) {
      const mc: any = [];
      mc.chanImage = val.chanImage;
      mc.channelIcon = val.channelIcon;
      mc.channelId = val.channelId;
      mc.channelName = val.channelName;
      mc.col = val.col;
      mc.myImage = val.myImage;
      mc.options = val.options;
      mc.orgnisationName = val.organisationName;
      mc.typeCount = val.typeCount;
      mc.userCount = val.userCount;
      mc.typeRoleGroups = val.typeRoleGroups;
      mc.roleGroups = val.roleGroups;
      if (channel.channelId === val.channelId) {
        mc.color = '#c9e2ee';
      } else {
        mc.color = 'white';
      }
      mchans.push(mc);
    }

    this.ochannels = mchans;

    this.chanusers = [];
    this.chantasktypes = [];

    this.iseditable = false;

    if (channel.typeRoleGroups !== null && channel.typeRoleGroups.length > 0) {
      const v = new Variables();
      const gis = localStorage.getItem('gi');
      const gs = CryptoJS.AES.decrypt(gis, v.pass).toString(CryptoJS.enc.Utf8);
      const gids = JSON.parse(gs);

      for (const val of channel.typeRoleGroups) {
        if (val.typeRoleGroup.roleName === 'Administrator' && gids.includes(val.typeRoleGroup.groupId)) {
          this.iseditable = true;
        }

        val.typeRoleGroup.g = null;
        if (this.gps !== undefined && this.gps !== null && this.gps.length > 0) {
          for (const g of this.gps) {
            if (val.typeRoleGroup.groupId === g.groupId) {
              val.typeRoleGroup.g = g.groupName;
              val.typeRoleGroup.color = 'white';
            }
          }
        }

        this.chanusers.push(val.typeRoleGroup);
      }
    }

    if (this.isadm === true) {
      this.iseditable = true;
    }

    this.channel = channel;

    // this.getChannelUsers();
    this.getChanTaskTypes();

    this.showDetail();
  }

  async apply(): Promise<any> {
    if (this.filterform === undefined || this.filterform === null
    || this.filterform.value === undefined || this.filterform.value === null
    || this.filterform.value.filter === undefined || this.filterform.value.filter === null) {
      // get all
      this.page = 1;
      this.orga = ' ';
      // get
    } else if (this.filterform.value.filter === 'organisation') {
      if (this.filterform.value.name === undefined || this.filterform.value.name === null
         || this.filterform.value.name.trim() === '') {
        // no name
        const title = await this.translate.get('GENERAL.ENTERVALUE').toPromise().catch(err => console.log(err));
        this.showConfirm(title, '', 'cm', 'info');
      } else {
        // filter by organisation name
        this.page = 1;
        this.orga = this.filterform.value.name;
        // get
      }
    } else if (this.filterform.value.filter === 'channel') {
      // filter by channel name
    }
  }

  toggleFilter(): any {
    this.isfilter = !this.isfilter;
  }

  onFileSelected(): any {
    const inputNode: any = document.querySelector('#file');

    if (typeof (FileReader) !== 'undefined') {
      const reader = new FileReader();
      if (inputNode.files[0]) {
        reader.readAsDataURL(inputNode.files[0]);
      }

      reader.onloadend = (e: any) => {
        this.iconname = inputNode.files[0].name;
        const base64 = e.target.result;
        this.ChannelIcon = base64;
        this.icon = this.domSanitizer.bypassSecurityTrustUrl(base64);
      };
      reader.onerror = (() => {
        this.iconname = '';
        this.ChannelIcon = '';
        this.icon = null;
      });
    }
  }

  onFileSelectedEdit(): any {
    const inputNode: any = document.querySelector('#file2');

    if (typeof (FileReader) !== 'undefined') {
      const reader = new FileReader();
      if (inputNode.files[0]) {
        reader.readAsDataURL(inputNode.files[0]);
      }

      reader.onloadend = (e: any) => {
        this.iconname = inputNode.files[0].name;
        const base64 = e.target.result;
        this.ChannelIcon = base64;
        this.icon = this.domSanitizer.bypassSecurityTrustUrl(base64);
      };
      reader.onerror = (() => {
        this.iconname = '';
        this.ChannelIcon = '';
        this.icon = null;
      });
    }
  }

  onFileSelectedEditMobile(): any {
    const inputNode: any = document.querySelector('#file3');

    if (typeof (FileReader) !== 'undefined') {
      const reader = new FileReader();
      if (inputNode.files[0]) {
        reader.readAsDataURL(inputNode.files[0]);
      }

      reader.onloadend = (e: any) => {
        this.iconname = inputNode.files[0].name;
        const base64 = e.target.result;
        this.ChannelIcon = base64;
        this.icon = this.domSanitizer.bypassSecurityTrustUrl(base64);
      };
      reader.onerror = (() => {
        this.iconname = '';
        this.ChannelIcon = '';
        this.icon = null;
      });
    }
  }

  showLoad(): any {
    this.showConfirm('', '', 'loader', '');
  }

  hideLoad(): any {
    this.messageService.clear('loader');
  }

  showSuccess(title: string, details: string, duration: number = 3000): any {
    this.messageService.add({severity: 'success', summary: title, detail: details, key: 'tc', life: duration});
  }

  showInfo(title: string, details: string, duration: number = 3000): any {
      this.messageService.add({severity: 'info', summary: title, detail: details, key: 'tc', life: duration});
  }

  showWarn(title: string, details: string, duration: number = 3000): any {
      this.messageService.add({severity: 'warn', summary: title, detail: details, key: 'tc', life: duration});
  }

  showError(title: string, details: string, duration: number = 3000): any {
      this.messageService.add({severity: 'error', summary: title, detail: details, key: 'tc', life: duration});
  }

  showConfirm(title: string, details: string, keyvalue: string, severe: string = 'warn'): any {
    // this.messageService.clear();
    this.messageService.add({key: keyvalue, sticky: true, severity: severe, summary: title, detail: details});
  }

  async onConfirmDeleteChannel(): Promise<any> {
    this.messageService.clear('cdchan');
    this.deleteOrgChannel();
  }

  async onConfirmDeleteChannelTaskType(): Promise<any> {
    this.messageService.clear('cdchantt');
    this.deleteChannelTaskType();
  }

  async onConfirmDeleteChannelUser(): Promise<any> {
    this.messageService.clear('cdchanuser');
    this.deleteChannelUser();
  }

  async onConfirmAddChannelUser(): Promise<any> {
    this.messageService.clear('cachanuser');
    this.addChanUser();
  }

  async onConfirmAddChannelTaskType(): Promise<any> {
    this.messageService.clear('cachanttype');
    // this.addChanTaskType();
  }

  async onConfirmOk(): Promise<any> {
    this.messageService.clear('cm');
  }

  onReject(): any {
      this.messageService.clear('cd');
      this.messageService.clear('cdu');
      this.messageService.clear('cdchan');
      this.messageService.clear('cdchantt');
      this.messageService.clear('cdchanuser');
      this.messageService.clear('cu');
      this.messageService.clear('cl');
      this.messageService.clear('cm');
  }

  onRejectAddChannelUser(): any {
    this.PhoneNumber = '';
    this.messageService.clear('cachanuser');
  }

  onRejectAddChannelTaskType(): any {
    this.channeltasktype = null;
    this.messageService.clear('cachanttype');
  }

  clear(): any {
      this.messageService.clear();
  }

  onResize(event) {
    const menuheight = document.getElementById('menu').offsetHeight;
    const contentheight = window.innerHeight;

    this.menuheight = menuheight;
    if (menuheight !== undefined && menuheight !== null
      && contentheight !== undefined && contentheight !== null) {
      this.contentdiv = (+contentheight - menuheight - 20) + 'px';
    } else {
      this.contentdiv = '85vh';
    }

    if (this.isedit === true) {
      this.innerdiv = (contentheight - menuheight - 105 - 145) + 'px';
      this.paneldiv = (contentheight - menuheight - 65 - 145 - 35) + 'px';
      this.picklistdiv = (contentheight - menuheight - 105 - 145 - 35 - 150) + 'px';
    } else {
      this.innerdiv = (contentheight - menuheight - 65 - 145) + 'px';
      this.paneldiv = (contentheight - menuheight - 50 - 145 - 35) + 'px';
      this.picklistdiv = (contentheight - menuheight - 105 - 145 - 35 - 150) + 'px';
    }

    this.dialogheight = (contentheight - 175) + 'px';
    this.innerdivmobile = (contentheight - 225) + 'px';
    this.paneldivmobile = (contentheight - 175 - 70) + 'px';
  }

  async handleError(err: any): Promise<any> {
    this.loading = false;
    this.hideLoad();

    if (err !== undefined && err != null) {
      if (err.error !== undefined && err.error !== null) {
        if (err.error.MessageText !== undefined && err.error.MessageText !== null) {
          if (err.error.MessageText === 'duplicate key value violates unique constraint "t_channel_chan_name_org_name_key"') {
            // user already exists
            const title = await this.translate.get('GENERAL.INFO').toPromise().catch(myerr => console.log(myerr));
            const detail = await this.translate.get('GENERAL.CHANNELEXISTS').toPromise().catch(myerr => console.log(myerr));
            this.showWarn(title, detail, 30000);
          } else if (err.error.MessageText ===
            'duplicate key value violates unique constraint "t_chan_tsk_type_chan_id_task_type_id_key"') {
            // user already exists
            const title = await this.translate.get('GENERAL.INFO').toPromise().catch(myerr => console.log(myerr));
            const detail = await this.translate.get('GENERAL.NOTTYPEEXISTS').toPromise().catch(myerr => console.log(myerr));
            this.showWarn(title, detail, 30000);
          } else {
            console.log(err);
          }
        } else if (err.error.Message !== undefined && err.error.Message !== null) {
          if (err.error.Message === 'default task type already set') {
            // default task type already exists
            const title = await this.translate.get('GENERAL.INFO').toPromise().catch(myerr => console.log(myerr));
            const detail = await this.translate.get('GENERAL.DEFAULTNOTTYPEALREADYSET').toPromise().catch(myerr => console.log(myerr));
            this.showWarn(title, detail, 30000);
          } else {
            console.log(err);
          }
        } else {
          console.log(err);
        }
      } else {
        console.log(err);
      }
    } else {
      console.log(err);
    }
  }

}
