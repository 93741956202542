import { Component, OnInit } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { DevicesService } from 'src/app/services/devices/devices.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-device-detail',
  templateUrl: './device-detail.component.html',
  styleUrls: ['./device-detail.component.css']
})
export class DeviceDetailComponent implements OnInit {

  dev: any;

  constructor(
    private translate: TranslateService,
    private cookieService: CookieService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private notificationSvc: NotificationService,
    private deviceService: DevicesService,
    private router: Router
  ) {
      // this.translate.addLangs(['English', 'French']);
      // this.translate.setDefaultLang('English');

      // let browserLang = this.translate.getBrowserLang();

      // const pass = 'ranitessarldev2019';
      // const lang = cookieService.get('goepla-portal-language');
      // if (lang.trim() !== '') {
      //   const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
      //   const lg = declang;
      //   browserLang = lg;
      //   localStorage.setItem('language', lg);
      // }

      // if (localStorage.getItem('language') !== null) {
      //   browserLang = localStorage.getItem('language');
      // }
      // this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');
      
      translate.use('English');

      this.dev = JSON.parse(localStorage.getItem('device'));
      if (+this.dev.syncTime === 1) {
        this.translate.get('GENERAL.SEC').subscribe((resp: string) => {
          this.dev.st = this.dev.syncTime + ' ' + resp;
        });
      } else {
        this.translate.get('GENERAL.SECS').subscribe((resp: string) => {
          this.dev.st = this.dev.syncTime + ' ' + resp;
        });
      }
   }

  ngOnInit() {
  }

}
