import { Urls } from 'src/app/Helpers/Urls';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { Component, OnInit } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { CookieService } from 'ngx-cookie-service';
import { Variables } from 'src/app/Models/variables';

@Component({
  selector: 'app-dashboard-general',
  templateUrl: './dashboard-general.component.html',
  styleUrls: ['./dashboard-general.component.css']
})
export class DashboardGeneralComponent implements OnInit {

  urls = new Urls();

  myaveragettc = 0;
  myclosedtasks = 0;
  myactivities = 0;
  myqueue = 0;

  inqueue = 0;
  open = 0;
  assigned = 0;
  started = 0;

  options: { title: { display: boolean; text: string; fontSize: number; }; legend: { position: string; }; };
  avgData: any;

  uid: number;
  now: string;
  past: string;
  distData: { labels: string[]; datasets: { data: number[]; backgroundColor: any[]; hoverBackgroundColor: any[]; }[]; };
  usrClosedData: { labels: string[]; datasets: { data: number[]; backgroundColor: any[]; hoverBackgroundColor: any[]; }[]; };
  closedUserData: { labels: string[]; datasets: { label: string; backgroundColor: string; borderColor: string; data: number[]; }[]; };
  usrActData: { labels: string[]; datasets: { label: string; backgroundColor: string; borderColor: string; data: number[]; }[]; };
  myttcData: { labels: string[]; datasets: { label: string; backgroundColor: string; borderColor: string; data: number[]; }[]; };
  distData2: { labels: string[]; datasets: { data: number[]; backgroundColor: string[]; hoverBackgroundColor: string[]; }[]; };

  constructor(
    private router: Router,
    private notificationSvc: NotificationService,
    public translate: TranslateService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private analyticsService: AnalyticsService
    ) {
      this.translate.addLangs(['English', 'French']);
      this.translate.setDefaultLang('English');

      let browserLang = this.translate.getBrowserLang();

      const pass = 'ranitessarldev2019';
      const lang = cookieService.get('goepla-portal-language');
      if (lang.trim() !== '') {
        const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
        const lg = declang;
        browserLang = lg;
        localStorage.setItem('language', lg);
      }

      if (localStorage.getItem('language') !== null) {
        browserLang = localStorage.getItem('language');
      }
      this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

      const v = new Variables();
      const val = localStorage.getItem('ui');
      const ui = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);
      this.uid = +ui;
      const d = new Date();
      this.now = new Date().toDateString();
      this.past = new Date(d.setDate(d.getDate() - 10)).toDateString();
    }

  ngOnInit() {
    this.getAmount();
    this.getAnalyticAVGOnStatus();
    this.getAnalyticsClosedDistribution();
    this.getAnalyticsClosedDistribution2();
    this.getAnalyticsClosedTasks();
    this.getAnalyticsClosedTaskByUser();
    this.getAnalyticsUserActivitiesByDay();
    this.getAnalyticsMyTTC();
  }

  getAmount() {
    this.analyticsService.getDashboards(this.past, this.now, 0, 'inqueue', ' ').subscribe((iq) => {
      this.analyticsService.getDashboards(this.past, this.now, 0, 'open', ' ').subscribe((opn) => {
        this.analyticsService.getDashboards(this.past, this.now, 0, 'assigned', ' ').subscribe((asg) => {
          this.analyticsService.getDashboards(this.past, this.now, 0, 'started', ' ').subscribe((str) => {
            this.analyticsService.getDashboards(this.past, this.now, this.uid, 'inqueue', ' ').subscribe((mq) => {
              this.analyticsService.getDashboards(this.past, this.now, this.uid, 'closed', ' ').subscribe((mcls) => {
                this.inqueue = iq;
                this.open = opn;
                this.assigned = asg;
                this.started = str;
                this.myqueue = mq;
                this.myclosedtasks = mcls;
              }, err => this.handleError(err));
            }, err => this.handleError(err));
          }, err => this.handleError(err));
        }, err => this.handleError(err));
      }, err => this.handleError(err));
    }, err => this.handleError(err));
  }

  getAnalyticAVGOnStatus() {
    let open = '';
    let assigned = '';
    let started = '';
    let mins = '';
    let others = '';
    this.translate.get('GENERAL.OPEN').subscribe((trans) => {
      open = trans;
    });
    this.translate.get('GENERAL.ASSIGNED').subscribe((trans) => {
      assigned = trans;
    });
    this.translate.get('GENERAL.STARTED').subscribe((trans) => {
      started = trans;
    });
    this.translate.get('GENERAL.MINUTES').subscribe((trans) => {
      mins = trans;
    });
    this.translate.get('GENERAL.OTHERS').subscribe((trans) => {
      others = trans;
    });

    this.analyticsService.getAnalytics(0, 'T', this.past, this.now,
                               0, 'averagetimeonstatus', ' ')
                  .subscribe((resp) => {
      if (resp === null || (resp[0].value2 === 0 && resp[1].value2 === 0 && resp[2].value2 === 0)) {

      } else {
        let o = 0;
        let a = 0;
        let s = 0;
        let count = 0;
        for (const res of resp) {
          if (res.value1 === 'OPEN_TIME') {
            o = Math.round((res.value2 + Number.EPSILON) * 100) / 100;
          }
          if (res.value1 === 'ASSIGNED_TIME') {
            a = Math.round((res.value2 + Number.EPSILON) * 100) / 100;
          }
          if (res.value1 === 'STARTED_TIME') {
            s = Math.round((res.value2 + Number.EPSILON) * 100) / 100;
          }
          count += 1;
          if (resp.length === count) {
            this.avgData = {
              labels: [open, assigned, started, others],
              datasets: [
                  {
                      label: mins,
                      backgroundColor: '#42A5F5',
                      borderColor: '#1E88E5',
                      data: [o, a, s, 0]
                  }
              ]
            };
            this.translate.get('GENERAL.AVERAGETIMEONSTATUS').subscribe((trans) => {
              this.options = {
                title: {
                    display: true,
                    text: trans,
                    fontSize: 16
                },
                legend: {
                    position: 'top'
                }
              };
            });
          }
        }
      }
    }, err => this.handleError(err));
   }

   getAnalyticsClosedDistribution() {
    let amttsks = '';
    this.translate.get('GENERAL.AMOUNTOFTASKS').subscribe((trans) => {
      amttsks = trans;
    });

    const cols = this.urls.colorpalette;
    const colorpalette = cols;
    const colors = [];

    this.analyticsService.getAnalytics(0, 'T', this.past, this.now,
                                0, 'closedtaskdistribution', ' ')
                  .subscribe((resp) => {
      const labs: string[] = [];
      const datasets: number[] = [];
      let count = 0;
      let ccount = 0;
      let total = 0;
      if (resp != null && resp.length > 0) {
        for (const res of resp) {
          if (ccount >= 49) {
            ccount = 1;
          }
          colors.push(colorpalette[ccount]);
          ccount += 1;
          labs.push(res.value2);
          total += res.value1;
          count += 1;
          if (count === resp.length) {
            count = 0;
            for (const val of resp) {
              const p = Math.round((((val.value1 / total) * 100) + Number.EPSILON) * 100) / 100;
              datasets.push(p);
              count += 1;
            }

            if (count === resp.length) {
              this.distData = {
                labels: labs,
                datasets: [
                    {
                        data: datasets,
                        backgroundColor: colors,
                        hoverBackgroundColor: colors
                    }]
                };
            }
          }
        }
      } else {
        //
      }
    }, err => this.handleError(err));
   }

   getAnalyticsClosedTasks() {
    let amttsks = '';
    this.translate.get('GENERAL.AMOUNTOFTASKS').subscribe((trans) => {
      amttsks = trans;
    });

    const cols = this.urls.colorpalette;
    const colorpalette = cols.reverse();
    const colors = [];

    this.analyticsService.getAnalytics(0, 'T', this.past, this.now,
                               0, 'closedtaskbyusers', ' ')
                  .subscribe((res) => {
      const labs: string[] = [];
      const datasets: number[] = [];
      let ccount = 0;
      let count = 0;
      if (res != null && res.length > 0) {
        for (const v of res) {
          if (ccount >= 49) {
            ccount = 1;
          }
          colors.push(colorpalette[ccount]);
          ccount += 1;
          labs.push(v.value2);
          datasets.push(v.value1);
          count += 1;

          if (count === res.length) {
            // labs.push(this.def.toDateString());
            // datasets.push(0);
            this.usrClosedData = {
              labels: labs,
              datasets: [
                  {
                      data: datasets,
                      backgroundColor: colors,
                      hoverBackgroundColor: colors
                  }
              ]
            };
          }
        }
      } else {
        //
      }
    }, err => this.handleError(err));
   }

   getAnalyticsClosedTaskByUser() {
    let amttsks = '';
    let day = '';
    this.translate.get('GENERAL.AMOUNTOFTASKS').subscribe((trans) => {
      amttsks = trans;
    });
    this.translate.get('GENERAL.DAY').subscribe((trans) => {
      day = trans;
    });

    this.analyticsService.getAnalytics(0, 'T', this.past, this.now,
                               this.uid, 'closedbyday', ' ')
                  .subscribe((res) => {
      const labs: string[] = [];
      const datasets: number[] = [];
      let count = 0;
      if (res != null && res.length > 0) {
        for (const v of res) {
          const date = new Date(v.value2);
          labs.push(date.toDateString());
          datasets.push(v.value1);
          count += 1;

          if (count === res.length) {
            // labs.push(this.def.toDateString());
            // datasets.push(0);
            this.closedUserData = {
              labels: labs,
              datasets: [
                  {
                      label: amttsks,
                      backgroundColor: '#42A5F5',
                      borderColor: '#1E88E5',
                      data: datasets
                  }
              ]
            };
          }
        }
      } else {
      }
    }, err => this.handleError(err));
   }

   getAnalyticsUserActivitiesByDay() {
    let mins = '';
    let week = '';
    let tot = 0;
    this.translate.get('GENERAL.MINUTES').subscribe((trans) => {
      mins = trans;
    });

    this.translate.get('GENERAL.WEEK').subscribe((trans) => {
      week = trans;
    });

    this.analyticsService.getAnalytics(0, 'T', this.past,
                                this.now, this.uid, 'useractivitybyday', ' ').subscribe((res) => {
      const labs: string[] = [];
      const datasets: number[] = [];
      let count = 0;
      if (res != null && res.length > 0) {
        for (const v of res) {
          labs.push(new Date(v.value3).toDateString());
          datasets.push(v.value1);
          tot += +v.value1;
          count += 1;

          if (count === res.length) {
            this.myactivities = Math.round(((tot / 60) + Number.EPSILON) * 100) / 100;
            this.usrActData = {
              labels: labs,
              datasets: [
                  {
                      label: mins,
                      backgroundColor: '#42A5F5',
                      borderColor: '#1E88E5',
                      data: datasets
                  }
              ]
            };
          }
        }
      } else {
      }
    }, err => this.handleError(err));
   }

   getAnalyticsMyTTC() {
    let mins = '';
    let week = '';
    let tot = 0;
    this.translate.get('GENERAL.MINUTES').subscribe((trans) => {
      mins = trans;
    });

    this.translate.get('GENERAL.WEEK').subscribe((trans) => {
      week = trans;
    });

    this.analyticsService.getAnalytics(0, 'T', this.past,
                                this.now, this.uid, 'averagettctrend', ' ').subscribe((res) => {
      const labs: string[] = [];
      const datasets: number[] = [];

      let count = 0;
      if (res != null && res.length > 0) {
        for (const v of res) {
          labs.push(new Date(v.value3).toDateString());
          datasets.push(Math.round(((v.value1) + Number.EPSILON) * 100) / 100);
          tot += v.value1;
          count += 1;

          if (count === res.length) {
            this.myaveragettc = Math.round(((tot / count) + Number.EPSILON) * 100) / 100;
            this.myttcData = {
              labels: labs,
              datasets: [
                  {
                      label: mins,
                      backgroundColor: '#42A5F5',
                      borderColor: '#1E88E5',
                      data: datasets
                  }
              ]
            };
          }
        }
      } else {
      }
    }, err => this.handleError(err));
   }

   getAnalyticsClosedDistribution2() {
    let amttsks = '';
    this.translate.get('GENERAL.AMOUNTOFTASKS').subscribe((trans) => {
      amttsks = trans;
    });

    let lessthanfive = 0;
    let fivetonine = 0;
    let tentofifteen = 0;
    let sixteentotwenty = 0;
    let twentyonetotwentyfive = 0;
    let twentysixtothirty = 0;
    let greaterthanthirty = 0;

    let lessthanfivep = 0;
    let fivetoninep = 0;
    let tentofifteenp = 0;
    let sixteentotwentyp = 0;
    let twentyonetotwentyfivep = 0;
    let twentysixtothirtyp = 0;
    let greaterthanthirtyp = 0;

    this.analyticsService.getAnalytics(0, 'T', this.past, this.now,
                                0, 'cycletimeclosed', ' ')
                  .subscribe((resp) => {
      const labs: string[] = [];
      const datasets: number[] = [];
      let count = 0;
      let total = 0;
      if (resp != null && resp.length > 0) {
        for (const res of resp) {
          if (res.value2 === 'under_5') {
            lessthanfive = res.value1;
            total += res.value1;
          } else if (res.value2 === 'five_to_nine') {
            fivetonine = res.value1;
            total += res.value1;
          } else if (res.value2 === 'ten_to_fifteen') {
            tentofifteen = res.value1;
            total += res.value1;
          } else if (res.value2 === 'sixteen_to_twenty') {
            sixteentotwenty = res.value1;
            total += res.value1;
          } else if (res.value2 === 'twentyOne_to_twentyFive') {
            twentyonetotwentyfive = res.value1;
            total += res.value1;
          } else if (res.value2 === 'twentySix_to_thirty') {
            twentysixtothirty = res.value1;
            total += res.value1;
          } else if (res.value2 === 'above_30') {
            greaterthanthirty = res.value1;
            total += res.value1;
          }
          count += 1;
          if (count === resp.length) {
            lessthanfivep = Math.round((((lessthanfive / total) * 100) + Number.EPSILON) * 100) / 100;
            fivetoninep = Math.round((((fivetonine / total) * 100) + Number.EPSILON) * 100) / 100;
            tentofifteenp = Math.round((((tentofifteen / total) * 100) + Number.EPSILON) * 100) / 100;
            sixteentotwentyp = Math.round((((sixteentotwenty / total) * 100) + Number.EPSILON) * 100) / 100;
            twentyonetotwentyfivep = Math.round((((twentyonetotwentyfive / total) * 100) + Number.EPSILON) * 100) / 100;
            twentysixtothirtyp = Math.round((((twentysixtothirty / total) * 100) + Number.EPSILON) * 100) / 100;
            greaterthanthirtyp = Math.round((((greaterthanthirty / total) * 100) + Number.EPSILON) * 100) / 100;
            this.distData2 = {
              labels: ['<5', '5-9', '10-15', '16-20', '21-25', '26-30', '>30'],
              datasets: [
                  {
                      data: [lessthanfivep, fivetoninep, tentofifteenp,
                              sixteentotwentyp, twentyonetotwentyfivep,
                              twentysixtothirtyp, greaterthanthirtyp],
                      backgroundColor: [
                          'LightBlue',
                          'Orange',
                          'LightGray',
                          'Yellow',
                          'Blue',
                          'DarkGreen',
                          'DarkBlue'
                      ],
                      hoverBackgroundColor: [
                        'LightBlue',
                        'Orange',
                        'LightGray',
                        'Yellow',
                        'Blue',
                        'DarkGreen',
                        'DarkBlue'
                      ]
                  }]
              };
          }
        }
      } else {
      }
    }, err => this.handleError(err));
   }

  handleError(err) {
    console.log(err);
  }

}
