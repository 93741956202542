import { Int64Updater, KeyValue } from './../../Models/Updater';
import { OrderCategoryItem } from './../../Models/order-category-item';
import { DataCache } from 'src/app/Helpers/data-cache';
import { OrderCategoryItemService } from './../../services/order-category-item/order-category-item.service';
import { OrderCategoryService } from './../../services/order-category/order-category.service';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { DomSanitizer } from '@angular/platform-browser';
import * as CryptoJS from 'crypto-js';
import { Variables } from 'src/app/Models/variables';
import { OrderCategory } from 'src/app/Models/order-category';

@Component({
  selector: 'app-order-category',
  templateUrl: './order-category.component.html',
  styleUrls: ['./order-category.component.css']
})
export class OrderCategoryComponent implements OnInit {

  cats = [];
  cat: any;

  catitems = [];
  catitem: any;

  tempitems = [];
  tempitem: any;

  page = 1;
  size = 100;

  loading = false;
  loadmore = false;

  isaddcatitem = false;

  isfilter = false;
  divname = 'mydiv';
  divheight = '0px';
  divheight2 = '0px';
  contentdiv = '';
  showfilter = true;
  filterform: any;
  menuheight: any;

  detail = 'y';
  master = 'x';

  innerdiv = '';
  innerdivmobile = '';
  paneldiv = '';
  paneldivmobile = '';
  dialogheight = '';

  isedit = false;
  iscreate = false;

  catname: any;
  catdesc: any;
  caticon: any;

  catnameerror = false;
  catdescerror = false;

  iconname: any;
  icon: any;

  ismanage = false;

  colorpalette = ['#CC9999', '#B3B31A', '#00E680', '#FF99E6', '#CCFF1A',
                  '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
                  '#80B300', '#809900', '#E6B3B3', '#4D80CC', '#9900B3',
                  '#4D8066', '#809980', '#E6FF80', '#6680B3', '#66991A',
                  '#E6331A', '#33FFCC', '#66994D', '#B366CC', '#4D8000',
                  '#B33300', '#CC80CC', '#FF3380', '#CCCC00', '#66E64D',
                  '#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
                  '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
                  '#E666B3', '#33991A', '#1AFF33', '#999933', '#FF1A66'];

  constructor(
    private messageService: MessageService,
    public translate: TranslateService,
    private http: HttpClient,
    private catService: OrderCategoryService,
    private catitemService: OrderCategoryItemService,
    private domSanitizer: DomSanitizer
    ) {
      translate.use('English');

      const v = new Variables();
      const p = localStorage.getItem('ups');
      if (p == null) {
        this.ismanage = false;
      }

      const pr = CryptoJS.AES.decrypt(p, v.pass).toString(CryptoJS.enc.Utf8);
      const perm = JSON.parse(pr);
      if (perm.includes('NTM.Administration.OrderCategory')
          || perm.includes('NTM.Administration.Full')) {
            this.ismanage = true;
      } else {
        this.ismanage = false;
      }

      this.onResize(null);
  }

 ngOnInit() {
  this.getCategories();
}

async getCategories() {
  this.loading = true;
  this.showLoad();
  const cts = await this.catService.getOrderCategories(this.page, this.size, 0, ' ').toPromise()
  .catch(err => this.handleError(err));

  const cs = [];
  if (cts !== undefined && cts !== null && cts.length > 0) {
    if (cts.length >= this.size) {
      this.loadmore = true;
    } else {
      this.loadmore = false;
    }

    let count = 0;
    for (const val of cts) {
      val.bcolor = 'white';

      val.catImage = '';
      val.myImage = '';
      if (val.categoryImage !== null) {
        val.catImage = this.domSanitizer.bypassSecurityTrustUrl(val.categoryImage);
      } else {
        val.catImage = '';
        val.myImage = val.categoryName.charAt(0).toUpperCase();

        val.col = this.colorpalette[count];
        count += 1;
        if (count === this.colorpalette.length) {
          count = 0;
        }
      }

      if (this.page > 1) {
        this.cats.push(val);
      } else {
        cs.push(val);
      }
    }
  } else {
    this.loadmore = false;
  }

  if (this.page === 1) {
    this.cats = cs;
  }

  this.hideLoad();
  this.loading = false;

  if (window.innerWidth !== undefined && window.innerWidth !== null && window.innerWidth <= 815) {
    // do no load
  } else {
    if (this.cats !== undefined && this.cats !== null && this.cats.length > 0 && this.page === 1
      && (this.cat === undefined || this.cat === null)) {
      this.catClicked(this.cats[0]);
    }
  }
}

async getCategoryItems() {
  this.loading = true;
  this.showLoad();

  const its = await this.catitemService.getOrderCategoryItems(0, 0, this.cat.categoryId).toPromise()
  .catch(err => this.handleError(err));

  this.catitems = [];
  for (const val of its) {
    val.color = 'white';
    this.catitems.push(val);
  }

  this.loading = false;
  this.hideLoad();
}

async catClicked(cat) {
  this.iscreate = false;
  this.isedit = false;
  this.cat = cat;
  const vs = [];
  for (const val of this.cats) {
    const v = val;
    if (val.categoryId === cat.categoryId) {
      v.color = 'lightblue';
    } else {
      v.color = 'white';
    }

    vs.push(v);
  }

  this.cats = vs;

  this.catitems = [];
  await this.getCategoryItems();

  this.detail = 'z';
  this.master = 'w';

  this.onResize(null);
}

backClicked() {
  this.detail = 'y';
  this.master = 'x';
}

loadMore() {
  this.page += 1;
  this.getCategories();
}

async showAddCatItem() {
  this.tempitems = [];
  const data = await DataCache.itemdefdata$.toPromise().catch();

  if (data !== undefined && data !== null && data.length > 0) {
    for (const val of data) {
      val.color = 'white';
      if (this.catitems !== null && this.catitems.length > 0) {
        let add = true;
        for (const i of this.catitems) {
          if (val.itemId === i.categoryItem.itemId) {
            add = false;
          }
        }

        if (add === true) {
          this.tempitems.push(val);
        }
      } else {
        this.tempitems.push(val);
      }
    }
  }

  this.isaddcatitem = true;
}

cancel() {
  this.isaddcatitem = false;
}

catItemClicked(item) {
  const vals = [];
  for (const val of this.catitems) {
    const v = val;
    if (val.categoryItem.itemId === item.categoryItem.itemId) {
      v.color = 'lightblue';
    } else {
      v.color = 'white';
    }

    vals.push(v);
  }

  this.catitems = vals;
}

tempItemClicked(item) {
  this.tempitem = item;
  const vals = [];
  for (const val of this.tempitems) {
    const v = val;
    if (val.itemId === item.itemId) {
      v.color = 'lightblue';
    } else {
      v.color = 'white';
    }

    vals.push(v);
  }

  this.tempitems = vals;
}

async select() {
  this.loading = true;
  this.showLoad();
  if (this.tempitem !== undefined && this.tempitem !== null) {
    const itm = new OrderCategoryItem();
    itm.CategoryId = this.cat.categoryId;
    itm.ItemId = this.tempitem.itemId;

    const res = await this.catitemService.postCategoryItem(itm).toPromise()
    .catch(err => this.handleError(err));
    if (res !== undefined && res !== null && res === true) {
      // success
      const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch();
      const msg = await this.translate.get('GENERAL.ITEMADDED').toPromise().catch();
      this.showSuccess(title, msg);

      this.isaddcatitem = false;
      await this.getCategoryItems();

      DataCache.loadData(this.http);
    } else {
      // failure
      const title = await this.translate.get('GENERAL.ERROR').toPromise().catch();
      const msg = await this.translate.get('GENERAL.ITEMNOTADDED').toPromise().catch();
      this.showError(title, msg, 10000);
    }

    this.isaddcatitem = false;
    this.tempitem = null;
  }

  this.loading = false;
  this.hideLoad();
}

async showConfirmDeleteCatItem(value) {
  this.catitem = value;
  const title = await this.translate.get('GENERAL.DELETE').toPromise().catch(err => console.log(err));
  this.showConfirm(title + ' ' + value.categoryItem.itemName + '?',
  '', 'deletecatitem');
}

async deleteCatItem() {
  const item = new OrderCategoryItem();
  item.CategoryId = this.catitem.orderCategory.categoryId;
  item.ItemId = this.catitem.categoryItem.itemId;

  this.loading = true;
  this.showLoad();
  const res = await this.catitemService.deleteCategoryItem(item).toPromise()
  .catch(err => this.handleError(err));

  if (res !== undefined && res !== null && res === true) {
    // success
    const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch();
    const msg = await this.translate.get('GENERAL.ITEMDELETED').toPromise().catch();
    this.showSuccess(title, msg);

    this.onRejectDeleteCatItem();

    await this.getCategoryItems();

    DataCache.loadData(this.http);
  } else {
    // failure
    const title = await this.translate.get('GENERAL.ERROR').toPromise().catch();
    const msg = await this.translate.get('GENERAL.ITEMNOTDELETED').toPromise().catch();
    this.showError(title, msg, 10000);
  }

  this.hideLoad();
  this.loading = false;
}

showAddCategory() {
  this.cancelCat();

  this.detail = 'z';
  this.master = 'w';

  this.catitems = [];
  this.cat = null;
  this.iscreate = true;
  this.isedit = false;

  const vs = [];
  for (const val of this.cats) {
    const v = val;
    v.color = 'white';

    vs.push(v);
  }

  this.cats = vs;
}

async showDeleteCategory() {
  const title = await this.translate.get('GENERAL.DELETE').toPromise().catch(err => console.log(err));
  this.showConfirm(title + ' ' + this.cat.categoryName + '?',
  '', 'deletecat');
}

async onConfirmDeleteCat() {
  this.loading = true;
  this.showLoad();
  const res = await this.catService.deleteOrderCategory(this.cat.categoryId).toPromise()
  .catch(err => this.handleError(err));

  if (res !== undefined && res !== null && res === true) {
    // success
    const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch();
    const msg = await this.translate.get('GENERAL.CATEGORYDELETED').toPromise().catch();
    this.showSuccess(title, msg);

    this.onRejectDeleteCat();

    this.size = this.page * this.size;
    this.page = 1;
    this.cat = null;
    await this.getCategories();

    DataCache.loadData(this.http);
  } else {
    // failure
    const title = await this.translate.get('GENERAL.ERROR').toPromise().catch();
    const msg = await this.translate.get('GENERAL.CATEGORYNOTDELETED').toPromise().catch();
    this.showError(title, msg, 10000);
  }

  this.hideLoad();
  this.loading = false;
}

showEditCategory() {
  this.cancelCat();

  this.detail = 'z';
  this.master = 'w';

  this.catname = this.cat.categoryName;
  this.catdesc = this.cat.categoryDescription;

  if (this.cat.categoryImage !== null && this.cat.categoryImage !== '') {
    // this.icon = this.domSanitizer.bypassSecurityTrustUrl(this.cat.categoryImage);
    this.icon = '';
  } else {
    this.icon = '';
  }
  this.iconname = '';
  this.caticon = this.cat.categoryImage;

  this.isedit = true;
  this.onResize(null);
}

cancelCat() {
  this.isedit = false;
  this.iscreate = false;
  this.catname = null;
  this.catdesc = null;
  this.caticon = null;
  this.icon = null;
  this.iconname = null;

  this.catnameerror = false;
  this.catdescerror = false;

  this.detail = 'y';
  this.master = 'x';

  this.onResize(null);
}

async saveCategory() {
  let sv = true;
  this.catnameerror = false;
  this.catdescerror = false;
  if (this.catname === undefined || this.catname === null || this.catname.trim() === '') {
    this.catnameerror = true;
    sv = false;
  }
  if (this.catdesc === undefined || this.catdesc === null || this.catdesc.trim() === '') {
    this.catdescerror = true;
    sv = false;
  }

  if (this.catnameerror === true && this.catdescerror === true) {
    this.onResize(40);
  } else if ((this.catnameerror === true && this.catdescerror === false)
   || ( this.catnameerror === false && this.catdescerror === true)) {
     this.onResize(20);
   }

  if (sv === true) {
    const value = new Int64Updater();
    value.Id = this.cat.categoryId;
    value.Values = [];

    const name = new KeyValue();
    name.Key = 'name';
    name.Value = this.catname;
    value.Values.push(name);

    const desc = new KeyValue();
    desc.Key = 'desc';
    desc.Value = this.catdesc;
    value.Values.push(desc);

    const image = new KeyValue();
    image.Key = 'image';
    image.Value = this.caticon;
    value.Values.push(image);

    this.loading = true;
    this.showLoad();

    const res = await this.catService.putOrderCategory(value.Id, value).toPromise()
    .catch(err => this.handleError(err));

    if (res !== undefined && res !== null && res === true) {
      // success
      const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch();
      const msg = await this.translate.get('GENERAL.CATEGORYUPDATED').toPromise().catch();
      this.showSuccess(title, msg);

      this.cancelCat();
      this.size = this.page * this.size;
      this.page = 1;
      await this.getCategories();

      for (const val of this.cats) {
        if (val.categoryId === this.cat.categoryId) {
          this.catClicked(val);
        }
      }

      DataCache.loadData(this.http);
    }

    this.loading = false;
    this.hideLoad();
  }
}

async createCategory() {
  let crt = true;
  this.catnameerror = false;
  this.catdescerror = false;
  if (this.catname === undefined || this.catname === null || this.catname.trim() === '') {
    this.catnameerror = true;
    crt = false;
  }
  if (this.catdesc === undefined || this.catdesc === null || this.catdesc.trim() === '') {
    this.catdescerror = true;
    crt = false;
  }

  if (crt === true) {
    const v = new Variables();
    const value = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(value, v.pass).toString(CryptoJS.enc.Utf8);

    const ocat = new OrderCategory();
    ocat.Organisationid = +oi;
    ocat.CategoryName = this.catname;
    ocat.CategoryDescription = this.catdesc;
    if (this.caticon !== undefined && this.caticon !== null && this.caticon.trim() !== '') {
      ocat.CategoryImage = this.caticon;
    }

    this.loading = true;
    this.showLoad();

    const res = await this.catService.pogtOrderCategory(ocat).toPromise()
    .catch(err => this.handleError(err));

    if (res !== undefined && res !== null && res > 0) {
      // success
      const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch();
      const msg = await this.translate.get('GENERAL.CATEGORYCREATED').toPromise().catch();
      this.showSuccess(title, msg);

      this.cancelCat();
      this.size = this.page * this.size;
      this.page = 1;
      await this.getCategories();

      for (const val of this.cats) {
        if (val.categoryId === res) {
          this.catClicked(val);
        }
      }

      DataCache.loadData(this.http);
    }

    this.loading = false;
    this.hideLoad();
  }
}

onFileSelected(): any {
  const inputNode: any = document.querySelector('#file');

  if (typeof (FileReader) !== 'undefined') {
    const reader = new FileReader();
    if (inputNode.files[0]) {
      reader.readAsDataURL(inputNode.files[0]);
    }

    reader.onloadend = (e: any) => {
      this.iconname = inputNode.files[0].name;
      const base64 = e.target.result;
      this.caticon = base64;
      this.icon = this.domSanitizer.bypassSecurityTrustUrl(base64);
    };
    reader.onerror = (() => {
      this.iconname = '';
      this.caticon = '';
      this.icon = null;
    });
  }
}

onFileSelectedEdit(): any {
  const inputNode: any = document.querySelector('#file2');

  if (typeof (FileReader) !== 'undefined') {
    const reader = new FileReader();
    if (inputNode.files[0]) {
      reader.readAsDataURL(inputNode.files[0]);
    }

    reader.onloadend = (e: any) => {
      this.iconname = inputNode.files[0].name;
      const base64 = e.target.result;
      this.caticon = base64;
      this.icon = this.domSanitizer.bypassSecurityTrustUrl(base64);
    };
    reader.onerror = (() => {
      this.iconname = '';
      this.caticon = '';
      this.icon = null;
    });
  }
}

onRejectDeleteCatItem() {
  this.catitem = null;
  this.messageService.clear('deletecatitem');
}

onRejectDeleteCat() {
  this.messageService.clear('deletecat');
}

showLoad(): any {
  this.loading = true;
  this.showConfirm('', '', 'loader', '');
}

hideLoad(): any {
  this.loading = false;
  this.messageService.clear('loader');
}

showSuccess(title: string, details: string, duration: number = 3000): any {
  this.messageService.add({severity: 'success', summary: title, detail: details, key: 'tc', life: duration});
}

showInfo(title: string, details: string, duration: number = 3000): any {
    this.messageService.add({severity: 'info', summary: title, detail: details, key: 'tc', life: duration});
}

showWarn(title: string, details: string, duration: number = 3000): any {
    this.messageService.add({severity: 'warn', summary: title, detail: details, key: 'tc', life: duration});
}

showError(title: string, details: string, duration: number = 3000): any {
    this.messageService.add({severity: 'error', summary: title, detail: details, key: 'tc', life: duration});
}

showConfirm(title: string, details: string, keyvalue: string, severe: string = 'warn'): any {
  // this.messageService.clear();
  this.messageService.add({key: keyvalue, sticky: true, severity: severe, summary: title, detail: details});
}

onResize(event) {
  const menuheight = document.getElementById('menu').offsetHeight;
  const contentheight = window.innerHeight;

  this.menuheight = menuheight;
  if (menuheight !== undefined && menuheight !== null
    && contentheight !== undefined && contentheight !== null) {
    this.contentdiv = (+contentheight - menuheight - 20) + 'px';
  } else {
    this.contentdiv = '85vh';
  }

  if (this.isedit === true) {
    // this.innerdiv = (contentheight - menuheight - 105 - 145) + 'px';
    // this.paneldiv = (contentheight - menuheight - 105 - 145 - 35) + 'px';
    if (event !== undefined && event !== null) {
      this.innerdiv = (contentheight - menuheight - 145 - 145 - event) + 'px';
      this.paneldiv = (contentheight - menuheight - 145 - 145 - 35 - event) + 'px';
    } else {
      this.innerdiv = (contentheight - menuheight - 145 - 145) + 'px';
      this.paneldiv = (contentheight - menuheight - 145 - 145 - 35) + 'px';
    }
  } else {
    this.innerdiv = (contentheight - menuheight - 65 - 145) + 'px';
    this.paneldiv = (contentheight - menuheight - 65 - 145 - 35) + 'px';
  }

  this.dialogheight = (contentheight - 175) + 'px';
  this.innerdivmobile = (contentheight - 225) + 'px';
  this.paneldivmobile = (contentheight - 175 - 70) + 'px';
}

async handleError(err) {
  this.loading = false;
  this.hideLoad();
  if (err !== undefined && err !== null) {
    if (err.error !== undefined && err.error !== null) {
      if (err.error.MessageText === 'duplicate key value violates unique constraint "org_id_cat_name"'
      || err.error.Message === '23505: duplicate key value violates unique constraint "org_id_cat_name"') {
        const title = await this.translate.get('GENERAL.INFO').toPromise().catch();
        const msg = await this.translate.get('GENERAL.CATEGORYALREADYEXISTS').toPromise().catch();
        this.showWarn(title, msg, 5000);
      } else {
        console.log(err);
      }
    }
  }
}

}
