import { UsersgroupsService } from './../usersgroups/usersgroups.service';
import { Notification } from './../../Models/Notification';
import { Injectable, ɵNOT_FOUND_CHECK_ONLY_ELEMENT_INJECTOR } from '@angular/core';
import { Urls } from 'src/app/Helpers/Urls';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Variables } from 'src/app/Models/variables';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class NotificationsServiceService {

  url = new Urls();
  apiUrl: string;
  orgId: number;

  notifs = [];

  constructor(
    private httpclient: HttpClient,
    private usergroupService: UsersgroupsService
  ) {
      this.apiUrl = this.url.apiUrl;
      const v = new Variables();
      const val = localStorage.getItem('oi');
      const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);

      this.orgId = +oi;
      // this.orgId = +localStorage.getItem('organisation-id');
  }

  // tslint:disable-next-line: max-line-length
  getNotifications(page: number, size: number, notId: string, confId: number, type: string, start: string,
                   end: string, read: number, closed: number, loc: string, uid: number, name: string,
                   status: string, title: string, content: string) {
    const v = new Variables();
    const val = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);

    this.orgId = +oi;
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/notifications/' + page + '/' + size + '/' + this.orgId + '/' + notId + '/'
     + confId + '/' + type + '/' + start + '/' + end + '/' + read + '/' + closed + '/' + loc + '/' + uid + '/'
      + name + '/' + status + '/' + title + '/' + content + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  // tslint:disable-next-line: max-line-length
  getNotificationsPhr(page: number, size: number, notId: string, confId: number, type: string, start: string,
                      end: string, read: number, closed: number, loc: string, uid: number, name: string,
                      status: string, title: string, content: string, types: string, stat: string, phone: string) {
    const v = new Variables();
    const val = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);

    this.orgId = +oi;
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/notifications/' + page + '/' + size + '/' + this.orgId + '/' + notId + '/'
      + confId + '/' + type + '/' + start + '/' + end + '/' + read + '/' + closed + '/' + loc + '/' + uid + '/'
      + name + '/' + status + '/' + title + '/' + content + '/' + types + '/' + stat + '/' + phone + '/';
    return this.httpclient.get<any>(url)
      .pipe(
      tap( // Log the result or error
      error => this.handleError
      )
    );
  }

  // tslint:disable-next-line: max-line-length
  getNotificationsGeneric(page: number, size: number, notId: string, confId: number, type: string, start: string,
                          end: string, read: number, closed: number, loc: string, uid: number, name: string, status: string,
                          title: string, content: string, types: string, stat: string, phone: string, gids: string, userid: number) {
    const v = new Variables();
    const val = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);

    this.orgId = +oi;
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/notifications/getnotification/' + page + '/' + size + '/' + this.orgId + '/' + notId + '/'
    + confId + '/' + type + '/' + start + '/' + end + '/' + read + '/' + closed + '/' + loc + '/' + uid + '/'
    + name + '/' + status + '/' + title + '/' + content + '/' + types + '/' + stat + '/' + phone + '/'
    + gids + '/' + userid + '/';
    return this.httpclient.get<any>(url)
    .pipe(
    tap( // Log the result or error
    error => this.handleError
    )
    );
  }

  // tslint:disable-next-line: max-line-length
  getInformation(page: number, size: number, notId: string, confId: number, type: string, start: string,
                 end: string, read: number, closed: number, loc: string, uid: number, name: string,
                 status: string, title: string, content: string, createdby: number, toread: number, alreadyread: number, readuser: number) {
      const v = new Variables();
      const val = localStorage.getItem('oi');
      const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);

      this.orgId = +oi;
      if (notId === undefined || notId === null || notId.trim() === '') {
        notId = ' ';
      }
      // tslint:disable-next-line: max-line-length
      const url = this.apiUrl + '/api/notifications/' + page + '/' + size + '/' + this.orgId + '/' + notId + '/'
      + confId + '/' + type + '/' + start + '/' + end + '/' + read + '/' + closed + '/' + loc + '/' + uid + '/'
      + name + '/' + status + '/' + title + '/' + content + '/' + createdby + '/' + toread + '/' + alreadyread + '/' + readuser + '/';
      return this.httpclient.get<any>(url)
      .pipe(
      tap( // Log the result or error
      error => this.handleError
      )
    );
  }

  // tslint:disable-next-line: max-line-length
  getInformationPhr(page: number, size: number, notId: string, confId: number, type: string, start: string,
                    end: string, read: number, closed: number, loc: string, uid: number, name: string,
                    status: string, title: string, content: string, createdby: number, toread: number, alreadyread: number,
                    readuser: number, phone: string) {
    const v = new Variables();
    const val = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);

    this.orgId = +oi;
    if (notId === undefined || notId === null || notId.trim() === '') {
    notId = ' ';
    }
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/notifications/' + page + '/' + size + '/' + this.orgId + '/' + notId + '/'
    + confId + '/' + type + '/' + start + '/' + end + '/' + read + '/' + closed + '/' + loc + '/' + uid + '/'
    + name + '/' + status + '/' + title + '/' + content + '/' + createdby + '/' + toread + '/'
    + alreadyread + '/' + readuser + '/' + phone + '/';
    return this.httpclient.get<any>(url)
      .pipe(
      tap( // Log the result or error
      error => this.handleError
      )
    );
  }

  // tslint:disable-next-line: max-line-length
  getInformationGeneric(page: number, size: number, notId: string, confId: number, type: string, start: string,
                        end: string, read: number, closed: number, loc: string, uid: number, name: string,
                        status: string, title: string, content: string, createdby: number, toread: number, alreadyread: number,
                        readuser: number, phone: string, gids: string, userid: number) {
    const v = new Variables();
    const val = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);

    this.orgId = +oi;
    if (notId === undefined || notId === null || notId.trim() === '') {
    notId = ' ';
    }
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/notifications/' + page + '/' + size + '/' + this.orgId + '/' + notId + '/'
    + confId + '/' + type + '/' + start + '/' + end + '/' + read + '/' + closed + '/' + loc + '/' + uid + '/'
    + name + '/' + status + '/' + title + '/' + content + '/' + createdby + '/' + toread + '/'
    + alreadyread + '/' + readuser + '/' + phone + '/' + gids + '/' + userid + '/';
    return this.httpclient.get<any>(url)
      .pipe(
      tap( // Log the result or error
      error => this.handleError
      )
    );
  }

  returnNotifs() {
    return this.notifs;
  }

  // tslint:disable-next-line: max-line-length
  getNotifications2(page: number, size: number, notId: string, confId: number, type: string, start: string,
                    end: string, read: number, closed: number, loc: string, uid: number, name: string,
                    status: string, title: string, content: string) {
    this.getNotifications(page, size, notId, confId, type, start, end
      , read, closed, loc, uid, name, status, title, content).subscribe(async (vals) => {
        if (vals != null) {
          let count = 0;
          for (const val of vals) {
            val.uids = [];
            let counter = 0;
            if (val.configuration.usrGrp != null && val.configuration.usrGrp.length > 0) {
              for await (const val2 of val.configuration.usrGrp) {
                if (val2.idType === 'U') {
                  val.uids.push(val2.id);
                  counter += 1;

                  if (counter === val.configuration.usrGrp.length) {
                    count += 1;
                  }

                  if (count === vals.length && counter === val.configuration.usrGrp.length) {
                    console.log('true1');
                    console.log(vals);
                  }
                } else if (val2.idType === 'G') {
                  await this.usergroupService.getUserGroups(val2.id).subscribe(async (res2) => {
                    if (res2 != null) {
                      for (const val3 of res2) {
                        val.uids.push(val3.userId);
                      }
                    }
                    counter += 1;
                    if (counter === val.configuration.usrGrp.length) {
                      count += 1;
                    }

                    if (count === vals.length && counter === val.configuration.usrGrp.length) {
                      console.log('true2');
                      console.log(vals);
                    }
                  });
                }

                if (count === vals.length && counter === val.configuration.usrGrp.length) {
                  console.log('true3');
                  console.log(vals);
                }
              }
            } else {
              count += 1;
              if (count === vals.length) {
                console.log(false);
                console.log(vals);
              }
            }
          }
        } else {
          return vals;
        }
      });
  }

  postNotification(not: Notification): Observable<any> {
    // const body = JSON.stringify(inst);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        responseType: 'text'
      })
    };
    return this.httpclient.post<any>(this.apiUrl + '/api/notifications', not, options)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  deleteNotification(id: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.delete<any>(this.apiUrl + '/api/notifications/' + id, options)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  private handleError(error: any) {
    console.error('error', error);
    throw error;
    return error;
  }

}
