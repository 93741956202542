import { NotificationComment } from './../../Models/notification-comment';
import { CommentService } from './../../services/comment/comment.service';
import { FeedbackService } from './../../services/feedback/feedback.service';
import { TaskClassificationService } from './../../services/task-classification/task-classification.service';
import { TaskClassification } from './../../Models/task-classification';
import { CategoryService } from 'src/app/services/category/category.service';
import { ClassItemService } from './../../services/class-item/class-item.service';
import { CatClassService } from './../../services/cat-class/cat-class.service';
import { NotLogService } from './../../services/not-log/not-log.service';
import { GroupDefService } from './../../services/group-def/group-def.service';
import { Recipient } from 'src/app/Models/recipient';
import { ContactService } from './../../services/contact/contact.service';
import { Notification } from './../../Models/Notification';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { DevicesService } from 'src/app/services/devices/devices.service';
import { TaskUpdater, KeyValue } from './../../Models/Updater';
import { UsersService } from 'src/app/services/users/users.service';
import { UsrGrp } from 'src/app/Models/UsrGrp';
import { UsersgroupsService } from 'src/app/services/usersgroups/usersgroups.service';
import { ConfirmationService } from 'primeng/api';
import { TasksService } from './../../services/tasks/tasks.service';
import { Component, OnInit } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationStart, RoutesRecognized, RouteConfigLoadStart, RouteConfigLoadEnd, NavigationEnd,
         NavigationCancel, NavigationError } from '@angular/router';
import { NotificationsServiceService } from 'src/app/services/notifications-service/notifications-service.service';
import { FormControl } from '@angular/forms';
import { Tasks } from 'src/app/Models/Tasks';
import { CookieService } from 'ngx-cookie-service';
import * as CryptoJS from 'crypto-js';
import { Variables } from 'src/app/Models/variables';
import { NotificationFeedback } from 'src/app/Models/notification-feedback';
import { DataCache } from 'src/app/Helpers/data-cache';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.css']
})
export class TasksComponent implements OnInit {

  users = [];
  user: any;
  isUser = false;

  disabled = false;

  status: string;
  username: string;
  tskUser: any;
  assigned = 'Not assigned';
  started = 'Not started';
  closed = 'Not closed';

  options = [];
  option: any;

  not: any;

  loading = false;
  isSave = false;

  isEditVis = false;
  config: any;
  isConfigVis = false;

  isDate = false;
  isNotDate = false;

  isVis = false;
  notif: any;

  invalidDate = false;

  date1 = new FormControl(new Date());
  date2 = new FormControl(new Date());
  serializedDate = new FormControl((new Date()).toISOString());

  notifications = [];

  isLoad: boolean;
  isLoadMore: boolean;

  page = 1;
  pager = 0;
  size = 100;

  selected: string;
  Filter: string;

  isNone: boolean;
  isNotId: boolean;
  isPostMsg: boolean;
  isTarget: boolean;
  isRange: boolean;
  isName: boolean;
  isEmpId: boolean;
  isTitle: boolean;
  isContent: boolean;
  isStatus: boolean;

  first = true;

  notId: string;
  confId: number;
  type: string;
  start: string;
  end: string;
  read: number;
  Name: string;
  EmpId: number;
  Title: string;
  Content: string;
  stat: any;
  statuses = [];

  options1 = [];

  filters = [];
  filter: any;

  range: any;
  display = false;
  display2 = false;

  item: any;
  userGroups = [];

  ismanage = false;

  iscreatetask = false;
  configs1 = [];
  conf1: any;

  isSelectUser = false;
  isSelectGroup = false;

  configs = [];
  conf: any;

  alertMessage: string;
  isCancel: boolean;
  isSend: boolean;
  isAlert: boolean;

  MessageTitle: string;
  MessageBody: string;
  Location: string;

  islocation = false;

  assignee: string;

  isassignee = false;
  isassigneeoptional = false;
  assign = false;

  IsAssigneeOpt = false;

  isoptional = false;
  isrecipient = false;
  isrecipientoptional = false;
  isrecp = false;
  isselected = false;

  IsRecipientOpt = false;

  contacts = [];
  contactgroups = [];
  contact: any;
  contactgroup: any;

  recname: any;
  recinfo: any;
  phones: string[];

  val = 'Selection';
  isselect = true;
  ismanual = false;

  notlogs = [];
  ishistory = false;

  uid = 0;

  iscategorize = false;
  isclassdisabled = false;

  classes = [];
  class: any;

  citems = [];
  citem: any;

  notval: any;

  cname = '';

  issavedisabled = true;
  iscomments = false;

  mynot: any;

  comments = [];
  comment: any;

  likes = [];
  islikes = false;

  locations = [];

  isadm = false;

  constructor(
    private notification: NotificationsServiceService,
    private router: Router,
    private notificationSvc: NotificationService,
    public translate: TranslateService,
    private tasksService: TasksService,
    private confirmationService: ConfirmationService,
    private usergroupService: UsersgroupsService,
    private userService: UsersService,
    private cookieService: CookieService,
    private deviceService: DevicesService,
    private configService: ConfigurationService,
    private contactService: ContactService,
    private cgroupService: GroupDefService,
    private notlogService: NotLogService,
    private catclassService: CatClassService,
    private itemService: ClassItemService,
    private catService: CategoryService,
    private tcService: TaskClassificationService,
    private notFeedbackService: FeedbackService,
    private commentService: CommentService
  ) {
    // tslint:disable-next-line: deprecation
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        // Navigation started.
      } else if (event instanceof NavigationEnd) {
          // Router parses the URL and the routes are recognized.
          if (event.url.includes('taskdetail')) {
          } else {
            localStorage.setItem('tsk-selected', null);
          }
      } else if (event instanceof RoutesRecognized) {
        // Router parses the URL and the routes are recognized.
      } else if (event instanceof RouteConfigLoadStart) {
        // Before the Router lazyloads a route configuration.
      } else if (event instanceof RouteConfigLoadEnd) {
        // Route has been lazy loaded.
      } else if (event instanceof NavigationEnd) {
          // Navigation Ended Successfully.
      } else if (event instanceof NavigationCancel) {
          // Navigation is canceled as the Route-Guard returned false during navigation.
      } else if (event instanceof NavigationError) {
          // Navigation fails due to an unexpected error.
      }
    });
    this.translate.addLangs(['English', 'French']);
    this.translate.setDefaultLang('English');

    let browserLang = this.translate.getBrowserLang();

    const pass = 'ranitessarldev2019';
    const lang = cookieService.get('goepla-portal-language');
    if (lang.trim() !== '') {
      const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
      const lg = declang;
      browserLang = lg;
      localStorage.setItem('language', lg);
    }

    if (localStorage.getItem('language') !== null) {
      browserLang = localStorage.getItem('language');
    }
    this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.NONE').subscribe((resp1: string) => {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.NOTIFICATIONID').subscribe((resp2: string) => {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.CREATEDRANGE').subscribe((resp3: string) => {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.ASSIGNEENAME').subscribe((resp4: string) => {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.ASSIGNEEEMPLOYEEID').subscribe((resp5: string) => {
              // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.STATUS2').subscribe((resp6: string) => {
                // tslint:disable-next-line: deprecation
                this.translate.get('GENERAL.TITLE2').subscribe((resp7: string) => {
                  // tslint:disable-next-line: deprecation
                  this.translate.get('GENERAL.CONTENT2').subscribe((resp8: string) => {
                    // tslint:disable-next-line: deprecation
                    this.translate.get('GENERAL.ASSIGNEDTOMEALLSTATUS').subscribe((resp9: string) => {
                      // tslint:disable-next-line: deprecation
                      this.translate.get('GENERAL.ASSIGNEDTOMEANDNOTSTARTED').subscribe((resp10: string) => {
                        // tslint:disable-next-line: deprecation
                        this.translate.get('GENERAL.ASSIGNEDTOMEANDINPROGRESS').subscribe((resp11: string) => {
                          // tslint:disable-next-line: deprecation
                          this.translate.get('GENERAL.STATUSOPEN').subscribe((resp12: string) => {
                            this.filters = [
                              { label: resp1, value: 'none' },
                              { label: resp2, value: 'not_id' },
                              { label: resp4, value: 'name' },
                              { label: resp5, value: 'empid' },
                              { label: resp6, value: 'status' },
                              { label: resp7, value: 'title' },
                              { label: resp8, value: 'content' },
                              { label: resp3, value: 'time' },
                              { label: resp9, value: 'assignedtomeallstatus' },
                              { label: resp10, value: 'assignedtomeandnotstarted' },
                              { label: resp11, value: 'assignedtomeandinprogress' },
                              { label: resp12, value: 'statusopen' }
                            ];
                            this.filter = this.filters[0].value;
                          });
                        });
                      });
                    });
                  });
                });
              });
            });
          });
        });
      });
    });

    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.OPEN').subscribe((resp1: string) => {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ASSIGNED').subscribe((resp2: string) => {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.STARTED').subscribe((resp3: string) => {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.CLOSED2').subscribe((resp4: string) => {
            this.statuses = [
              { label: resp1, value: 'open' },
              { label: resp2, value: 'assigned' },
              { label: resp3, value: 'started' },
              { label: resp4, value: 'closed' }
            ];
            this.stat = this.statuses[0].value;
          });
        });
      });
    });

    this.selected = 'none';
    this.isNone = true;
    this.isNotId = false;
    this.isPostMsg = false;
    this.isTarget = false;
    this.isRange = false;
    this.isName = false;
    this.isEmpId = false;
    this.isStatus = false;
    this.isTitle = false;
    this.isContent = false;

    this.notId = ' ';
    this.confId = 0;
    this.type = ' ';
    this.start = ' ';
    this.end = ' ';
    this.read = 0;
    this.EmpId = 0;
    this.Name = ' ';
    this.stat = ' ';
    this.Title = ' ';
    this.Content = ' ';

    if (localStorage.getItem('tsk-selected') === null) {

    } else {
      const sel = localStorage.getItem('tsk-selected');
      const filt = localStorage.getItem('tsk-filter');

    // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.NONE').subscribe((resp1: string) => {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.NOTIFICATIONID').subscribe((resp2: string) => {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.CREATEDRANGE').subscribe((resp3: string) => {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.ASSIGNEENAME').subscribe((resp4: string) => {
              // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.ASSIGNEEEMPLOYEEID').subscribe((resp5: string) => {
                // tslint:disable-next-line: deprecation
                this.translate.get('GENERAL.STATUS').subscribe((resp6: string) => {
                  // tslint:disable-next-line: deprecation
                  this.translate.get('GENERAL.TITLE').subscribe((resp7: string) => {
                    // tslint:disable-next-line: deprecation
                    this.translate.get('GENERAL.CONTENT').subscribe((resp8: string) => {
                      // tslint:disable-next-line: deprecation
                      this.translate.get('GENERAL.ASSIGNEDTOMEALLSTATUS').subscribe((resp9: string) => {
                        // tslint:disable-next-line: deprecation
                        this.translate.get('GENERAL.ASSIGNEDTOMEANDNOTSTARTED').subscribe((resp10: string) => {
                          // tslint:disable-next-line: deprecation
                          this.translate.get('GENERAL.ASSIGNEDTOMEANDINPROGRESS').subscribe((resp11: string) => {
                            // tslint:disable-next-line: deprecation
                            this.translate.get('GENERAL.STATUSOPEN').subscribe((resp12: string) => {
                              this.filters = [
                                { label: resp1, value: 'none' },
                                { label: resp2, value: 'not_id' },
                                { label: resp4, value: 'name' },
                                { label: resp5, value: 'empid' },
                                { label: resp6, value: 'status' },
                                { label: resp7, value: 'title' },
                                { label: resp8, value: 'content' },
                                { label: resp3, value: 'time' },
                                { label: resp9, value: 'assignedtomeallstatus' },
                                { label: resp10, value: 'assignedtomeandnotstarted' },
                                { label: resp11, value: 'assignedtomeandinprogress' },
                                { label: resp12, value: 'statusopen' }
                              ];
                              this.filter = this.filters[0].value;

                              // tslint:disable-next-line: prefer-for-of
                              for (let i = 0; i < this.filters.length; i++) {
                                if (this.filters[i].value === sel) {
                                  this.filter = this.filters[i].value;
                                }
                              }
                            });
                          });
                        });
                      });
                      // this.filters = [
                      //   { label: resp1, value: 'none' },
                      //   { label: resp2, value: 'not_id' },
                      //   { label: resp3, value: 'time' },
                      //   { label: resp4, value: 'name' },
                      //   { label: resp5, value: 'empid' },
                      //   { label: resp6, value: 'status' },
                      //   { label: resp7, value: 'title' },
                      //   { label: resp8, value: 'content' }
                      // ];
                      // this.filter = this.filters[0].value;

                      // // tslint:disable-next-line: prefer-for-of
                      // for (let i = 0; i < this.filters.length; i++) {
                      //   if (this.filters[i].value === sel) {
                      //     this.filter = this.filters[i].value;
                      //   }
                      // }
                    });
                  });
                });
              });
            });
          });
        });
      });

      this.selected = sel;
      if (sel === 'none') {
        this.isNone = true;
        this.isNotId = false;
        this.isPostMsg = false;
        this.isTarget = false;
        this.isRange = false;
        this.isName = false;
        this.isEmpId = false;
        this.isStatus = false;
        this.isTitle = false;
        this.isContent = false;
      } else if (sel === 'not_id') {
        this.notId = filt;
        this.isNotId = true;
        this.isNone = false;
        this.isPostMsg = false;
        this.isTarget = false;
        this.isRange = false;
        this.isName = false;
        this.isEmpId = false;
        this.isStatus = false;
        this.isTitle = false;
        this.isContent = false;
      } else if (sel === 'conf_id') {
        this.confId = +filt;
        this.isPostMsg = true;
        this.isNotId = false;
        this.isNone = false;
        this.isTarget = false;
        this.isRange = false;
        this.isName = false;
        this.isEmpId = false;
        this.isStatus = false;
        this.isTitle = false;
        this.isContent = false;
      } else if (sel === 'type') {
        this.type = filt;
        this.isTarget = true;
        this.isPostMsg = false;
        this.isNotId = false;
        this.isNone = false;
        this.isRange = false;
        this.isName = false;
        this.isEmpId = false;
        this.isStatus = false;
        this.isTitle = false;
        this.isContent = false;
      } else if (sel === 'time') {
        const split = filt.split(';');
        this.start = split[0];
        this.end = split[1];

        this.date1.setValue(new Date(this.start));
        this.date2.setValue(new Date(this.end));

        this.range = [new Date(this.start), new Date(this.end)];

        this.isRange = true;
        this.isTarget = false;
        this.isPostMsg = false;
        this.isNotId = false;
        this.isNone = false;
        this.isName = false;
        this.isEmpId = false;
        this.isStatus = false;
        this.isTitle = false;
        this.isContent = false;
      } else if (sel === 'name') {
        this.Name = filt;
        this.isTarget = false;
        this.isPostMsg = false;
        this.isNotId = false;
        this.isNone = false;
        this.isRange = false;
        this.isName = true;
        this.isEmpId = false;
        this.isStatus = false;
        this.isTitle = false;
        this.isContent = false;
      } else if (sel === 'empid') {
        this.EmpId = +filt;
        this.Name = localStorage.getItem('filter-name');
        this.isTarget = false;
        this.isPostMsg = false;
        this.isNotId = false;
        this.isNone = false;
        this.isRange = false;
        this.isName = false;
        this.isEmpId = true;
        this.isStatus = false;
        this.isTitle = false;
        this.isContent = false;
      } else if (sel === 'status') {
        this.stat = localStorage.getItem('stat-selected');
        this.isTarget = false;
        this.isPostMsg = false;
        this.isNotId = false;
        this.isNone = false;
        this.isRange = false;
        this.isName = false;
        this.isEmpId = false;
        this.isStatus = true;
        this.isTitle = false;
        this.isContent = false;
      } else if (sel === 'title') {
        this.Title = filt;
        this.isTarget = false;
        this.isPostMsg = false;
        this.isNotId = false;
        this.isNone = false;
        this.isRange = false;
        this.isName = false;
        this.isEmpId = false;
        this.isStatus = false;
        this.isTitle = true;
        this.isContent = false;
      } else if (sel === 'content') {
        this.Content = filt;
        this.isTarget = false;
        this.isPostMsg = false;
        this.isNotId = false;
        this.isNone = false;
        this.isRange = false;
        this.isName = false;
        this.isEmpId = false;
        this.isStatus = false;
        this.isTitle = false;
        this.isContent = true;
      } else if (sel === 'assignedtomeallstatus' || sel === 'assignedtomeandnotstarted'
      || sel === 'assignedtomeandinprogress' || sel === 'statusopen') {
       const v = new Variables();
       this.isNone = false;
       this.isNotId = false;
       this.isPostMsg = false;
       this.isTarget = false;
       this.isRange = false;
       this.isName = false;
       this.isEmpId = false;
       this.isStatus = false;
       this.isTitle = false;
       this.isContent = false;
       if (sel === 'assignedtomeallstatus' || sel === 'assignedtomeandnotstarted'
       || sel === 'assignedtomeandinprogress') {
         const ui = localStorage.getItem('ui');
         const val = CryptoJS.AES.decrypt(ui, v.pass).toString(CryptoJS.enc.Utf8);
         this.uid = val;

         if (sel === 'assignedtomeandnotstarted') {
           this.stat = 'assigned';
         } else if (sel === 'assignedtomeandinprogress') {
           this.stat = 'started';
         }
       } else if (sel === 'statusopen') {
         this.stat = 'open';
       }
     }
    }

    // this.getNotifications2(this.page, this.size);
   }

  ngOnInit() {
    localStorage.removeItem('notification');
    const v = new Variables();
    const p = localStorage.getItem('ups');
    if (p != null) {
      const val = CryptoJS.AES.decrypt(p, v.pass).toString(CryptoJS.enc.Utf8);
      const perm = JSON.parse(val);
      if (perm.includes('NTM.Tasks.Manage') || perm.includes('NTM.Administration.Full')) {
        this.ismanage = true;
      }
      if (perm.includes('NTM.Administration.Full')) {
        this.isadm = true;
      }
    }

    this.isLoad = true;
    this.getUserGroups();
  }

  getUserGroups() {
    const v = new Variables();
    const val = localStorage.getItem('ui');
    const ui = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);
    const uid = +ui;
    // tslint:disable-next-line: deprecation
    this.usergroupService.getUserGroupsByGroupIdUserId(0, uid).subscribe((res) => {
      if (res != null) {
        for (const ug of res) {
          this.userGroups.push(ug.groupId);
        }
      }

      this.getNotifications2(this.page, this.size);
    }, err => this.handleError(err));
  }

  async getNotifications2(page: number, size: number) {
    // this.get(page, size);
    this.getNotifications22(page, size);
  }

  async getNotifications22(page: number, size: number) {
    const v = new Variables();
    const va = localStorage.getItem('ui');
    const ui = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
    const uid = +ui;
    const phr = localStorage.getItem('phr');
    const phone = CryptoJS.AES.decrypt(phr, v.pass).toString(CryptoJS.enc.Utf8);

    const gis = localStorage.getItem('gi');
    const gs = CryptoJS.AES.decrypt(gis, v.pass).toString(CryptoJS.enc.Utf8);
    const gids = JSON.parse(gs);
    let g = '';
    for (const id of gids) {
      g += id + ',';
    }

    this.isLoad = true;
    await this.notification.getNotificationsGeneric(page, size, this.notId, this.confId, 'T', this.start, this.end
    // tslint:disable-next-line: deprecation
    , this.read, 0, ' ', this.uid, this.Name, this.stat, this.Title, this.Content, ' ', ' ', phone, g, uid).subscribe(async (res) => {

      if (res == null || res.length === 0) {
        if (this.pager === 1) {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.NOTASKSFOUND').subscribe((resp: string) => {
            this.notificationSvc.info('', resp, 2000);
            });
        } else {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.NOMORETASKS').subscribe((resp: string) => {
            this.notificationSvc.info('', resp, 2000);
          });
        }
      } else {
        // tslint:disable-next-line: prefer-for-of
        for await (const val of res) {
          let rown = false;
          let rall = false;
          let init = false;
          let eall = false;
          let eas = false;
          // check roles
          if (val.configuration.typeRoleGroups !== null && val.configuration.typeRoleGroups.length > 0) {
            for (const ro of val.configuration.typeRoleGroups) {
              if (ro.typeRoleGroup.roleName === 'Reader-Own' && gids.includes(ro.typeRoleGroup.groupId)) {
                rown = true;
              }
              if (ro.typeRoleGroup.roleName === 'Reader-All' && gids.includes(ro.typeRoleGroup.groupId)) {
                rown = true;
                rall = true;
              }
              if (ro.typeRoleGroup.roleName === 'Initiator' && gids.includes(ro.typeRoleGroup.groupId)) {
                init = true;
              }
              if (ro.typeRoleGroup.roleName === 'Expert-All' && gids.includes(ro.typeRoleGroup.groupId)) {
                eas = true;
                eall = true;
              }
              if (ro.typeRoleGroup.roleName === 'Expert-Assigned' && gids.includes(ro.typeRoleGroup.groupId)) {
                eas = true;
              }
            }
          }

          if (val.notOrders !== null && val.notOrders.length > 0) {
            const vorders = [];
            for (const ov of val.notOrders) {
              if (ov.order.orderStatus === 'open') {
                ov.color = 'yellow';
              } else {
                ov.color = 'green';
              }
              const date = new Date(ov.order.created);
              ov.orderName = date.getFullYear().toString() + date.getMonth().toString()
               + date.getDate().toString() + '-' + ov.order.orderId;

              vorders.push(ov);
            }

            val.notOrder = vorders;
          }

          if (this.isadm === true) {
            rown = true;
            rall = true;
            init = true;
            eall = true;
            eas = true;
          }

          if (val.locationConfirmation === '0001-01-01T00:00:00') {
            val.loccolor = 'red';
          } else {
            val.loccolor = 'green';
          }
          this.options1 = [];
          // val.a = '#bfbfbf';
          // val.s = '#bfbfbf';
          // val.c = '#bfbfbf';
          // val.e = '#bfbfbf';
          val.a = 'green';
          val.s = 'green';
          val.c = 'green';
          val.e = 'green';
          let isuserclassify = false;
          if (val.taskUserId === 0) {
            const resp11 = await this.translate.get('GENERAL.OPEN').toPromise();
            val.status = resp11;
            val.color = 'LightGray';

            // let setOptions = true;
            const usrid = +ui;

            if (val.configuration.workflow === 'OASC') {
              this.options1 = [];
              const resp2 = await this.translate.get('GENERAL.ASSIGNTOUSER').toPromise();
              const resp3 = await this.translate.get('GENERAL.ASSIGNTOME').toPromise();
              this.options1.push({label: resp2, value: 'AssignToUser',
              command: async (event) => { await this.saveNotif(event, val); }});
              this.options1.push({label: resp3, value: 'AssignToMe',
              command: async (event) => { await this.saveNotif(event, val); }});
            } else if (val.configuration.workflow === 'OSC') {
              this.options1 = [];
              const resp2 = await this.translate.get('GENERAL.START').toPromise();
              this.options1.push({label: resp2, value: 'Start',
              command: async (event) => { await this.saveNotif(event, val); }});
            } else if (val.configuration.workflow === 'OC') {
              this.options1 = [];
              const resp3 = await this.translate.get('GENERAL.CLOSE').toPromise();
              this.options1.push({label: resp3, value: 'Close',
              command: async (event) => { await this.saveNotif(event, val); }});
            }

            // if (val.configuration.usrGrp != null && val.configuration.usrGrp.length > 0) {
            //   for await (const val2 of val.configuration.usrGrp) {
            //     if (val.configuration.workflow === 'OASC') {
            //       if (val2.idType === 'U') {
            //         if (val2.id === usrid) {
            //           if (setOptions === true) {
            //             setOptions = false;
            //             this.options1 = [];
            //             const resp2 = await this.translate.get('GENERAL.ASSIGNTOUSER').toPromise();
            //             const resp3 = await this.translate.get('GENERAL.ASSIGNTOME').toPromise();
            //             this.options1.push({label: resp2, value: 'AssignToUser',
            //             command: async (event) => { await this.saveNotif(event, val); }});
            //             this.options1.push({label: resp3, value: 'AssignToMe',
            //             command: async (event) => { await this.saveNotif(event, val); }});
            //           }
            //         }
            //       }
            //       if (val2.idType === 'G') {
            //         if (this.userGroups.includes(val2.id)) {
            //           if (setOptions === true) {
            //             setOptions = false;
            //             this.options1 = [];
            //             const resp2 = await this.translate.get('GENERAL.ASSIGNTOUSER').toPromise();
            //             const resp3 = await this.translate.get('GENERAL.ASSIGNTOME').toPromise();
            //             this.options1.push({label: resp2, value: 'AssignToUser',
            //             command: async (event) => { await this.saveNotif(event, val); }});
            //             this.options1.push({label: resp3, value: 'AssignToMe',
            //             command: async (event) => { await this.saveNotif(event, val); }});
            //           }
            //         }
            //       }
            //     } else if (val.configuration.workflow === 'OSC') {
            //       this.options1 = [];
            //       const resp2 = await this.translate.get('GENERAL.START').toPromise();
            //       this.options1.push({label: resp2, value: 'Start',
            //       command: async (event) => { await this.saveNotif(event, val); }});
            //     } else if (val.configuration.workflow === 'OC') {
            //       this.options1 = [];
            //       const resp3 = await this.translate.get('GENERAL.CLOSE').toPromise();
            //       this.options1.push({label: resp3, value: 'Close',
            //       command: async (event) => { await this.saveNotif(event, val); }});
            //     }
            //   }
            // } else { }
            const dt = new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(),
                                new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds());
            if (new Date(val.closed).getFullYear() === 1 && new Date(val.expires) < dt) {
              const resp1 = await this.translate.get('GENERAL.EXPIRED').toPromise();
              val.exp = resp1;
              val.e = 'Red';
            }
            const crt = new Date(val.created);
            const ovra = new Date(crt.getFullYear(), crt.getMonth(), crt.getDate(), crt.getHours(),
                                    crt.getMinutes() + val.configuration.reactionTimeLimit, crt.getSeconds());
            if (dt > new Date(ovra)) {
              const resp1 = await this.translate.get('GENERAL.ASSIGN').toPromise();
              val.exp = resp1;
              val.a = 'Red';
            }

            const ovrs = new Date(crt.getFullYear(), crt.getMonth(), crt.getDate(), crt.getHours(),
                                      crt.getMinutes() + val.configuration.startTimeLimit, crt.getSeconds());
            if (dt > ovrs) {
              const resp1 = await this.translate.get('GENERAL.STRT').toPromise();
              val.exp = resp1;
              val.s = 'Red';
            }

            const ovrc = new Date(crt.getFullYear(), crt.getMonth(), crt.getDate(), crt.getHours(),
                                      crt.getMinutes() + val.configuration.closeTimeLimit, crt.getSeconds());
            if (dt > ovrc) {
              const resp1 = await this.translate.get('GENERAL.CLS').toPromise();
              val.exp = resp1;
              val.c = 'Red';
            }
          } else {
            // if (val.taskUserId === uid) {
            if ((val.taskUserId === uid && eas === true) || (val.taskUserId === uid && eall === true)) {
              isuserclassify = true;
            }
            if (new Date(val.assigned).getFullYear() !== 1) {
              const resp12 = await this.translate.get('GENERAL.ASSIGNED').toPromise();
              val.status = resp12;
              val.color = 'Yellow';
              this.options1 = [];

              // add assign to user menu item
              // if (val.reassignSize > 0 && val.taskUserId === uid) {
              if (val.reassignSize > 0 && ((val.taskUserId === uid && eas === true) || (val.taskUserId === uid && eall === true))) {
                const resp2 = await this.translate.get('GENERAL.ASSIGNTOUSER').toPromise();
                this.options1.push({label: resp2, value: 'AssignToUser',
                 command: async (event) => { await this.saveNotif(event, val); }});
              }

              // add start menu item
              const resp22 = await this.translate.get('GENERAL.START').toPromise();
              // if (val.taskUserId === uid) {
              if ((val.taskUserId === uid && eas === true) || (val.taskUserId === uid && eall === true)) {
                this.options1.push({label: resp22, value: 'Start',
                 command: async (event) => { await this.saveNotif(event, val); }});
              }

              const dt = new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(),
                                new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds());
              if (new Date(val.closed).getFullYear() === 1 && new Date(val.expires) < dt) {
                const resp1 = await this.translate.get('GENERAL.EXPIRED').toPromise();
                val.exp = resp1;
                val.e = 'Red';
              }

              const crt = new Date(val.created);
              const ovra = new Date(crt.getFullYear(), crt.getMonth(), crt.getDate(), crt.getHours(),
                                      crt.getMinutes() + val.configuration.reactionTimeLimit, crt.getSeconds());
              if (new Date(val.assigned) > ovra) {
                const resp1 = this.translate.get('GENERAL.ASSIGN').toPromise();
                val.exp = resp1;
                val.a = 'Red';
              }

              if (new Date(val.started).getFullYear() === 1) {
                const ovrs = new Date(crt.getFullYear(), crt.getMonth(), crt.getDate(), crt.getHours(),
                                      crt.getMinutes() + val.configuration.startTimeLimit, crt.getSeconds());
                if (dt > ovrs) {
                  const resp1 = await this.translate.get('GENERAL.STRT').toPromise();
                  val.exp = resp1;
                  val.s = 'Red';
                }
              }

              if (new Date(val.closed).getFullYear() === 1) {
                const ovrc = new Date(crt.getFullYear(), crt.getMonth(), crt.getDate(), crt.getHours(),
                                      crt.getMinutes() + val.configuration.closeTimeLimit, crt.getSeconds());
                if (dt > ovrc) {
                  const resp1 = await this.translate.get('GENERAL.CLS').toPromise();
                  val.exp = resp1;
                  val.c = 'Red';
                }
              }
            }
            if (new Date(val.started).getFullYear() !== 1) {
              const resp12 = await this.translate.get('GENERAL.STARTED').toPromise();
              val.status = resp12;
              val.color = 'LightBlue';
              this.options1 = [];

              // add assign to user menu item
              // if (val.reassignSize > 0 && val.taskUserId === uid && val.configuration.workflow === 'OASC') {
              if ((val.reassignSize > 0 && val.taskUserId === uid && val.configuration.workflow === 'OASC' && eas === true)
              || (val.reassignSize > 0 && val.taskUserId === uid && val.configuration.workflow === 'OASC' && eall === true)) {
                const resp2 = await this.translate.get('GENERAL.ASSIGNTOUSER').toPromise();
                this.options1.push({label: resp2, value: 'AssignToUser',
                 command: async (event) => { await this.saveNotif(event, val); }});
              }

              // add close menu item
              const resp23 = await this.translate.get('GENERAL.CLOSE').toPromise();
              // if (val.taskUserId === uid) {
              if ((val.taskUserId === uid && eas === true) || (val.taskUserId === uid && eall === true)) {
                this.options1.push({label: resp23, value: 'Close',
                 command: async (event) => { await this.saveNotif(event, val); }});
              }

              const dt = new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(),
                                new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds());
              if (new Date(val.closed).getFullYear() === 1 && new Date(val.expires) < dt) {
                const resp1 = await this.translate.get('GENERAL.EXPIRED').toPromise();
                val.exp = resp1;
                val.e = 'Red';
              }

              const crt = new Date(val.created);
              const ovra = new Date(crt.getFullYear(), crt.getMonth(), crt.getDate(), crt.getHours(),
                                      crt.getMinutes() + val.configuration.reactionTimeLimit, crt.getSeconds());
              if (new Date(val.assigned) > ovra) {
                const resp1 = await this.translate.get('GENERAL.ASSIGN').toPromise();
                val.exp = resp1;
                val.a = 'Red';
              }

              const ovrs = new Date(crt.getFullYear(), crt.getMonth(), crt.getDate(), crt.getHours(),
                                      crt.getMinutes() + val.configuration.startTimeLimit, crt.getSeconds());
              if (new Date(val.started) > ovrs) {
                const resp1 = await this.translate.get('GENERAL.STRT').toPromise();
                val.exp = resp1;
                val.s = 'Red';
              }

              if (new Date(val.closed).getFullYear() === 1) {
                const ovrc = new Date(crt.getFullYear(), crt.getMonth(), crt.getDate(), crt.getHours(),
                                      crt.getMinutes() + val.configuration.closeTimeLimit, crt.getSeconds());
                if (dt > ovrc) {
                  const resp1 = await this.translate.get('GENERAL.CLS').toPromise();
                  val.exp = resp1;
                  val.c = 'Red';
                }
              }
            }
            if (new Date(val.closed).getFullYear() !== 1) {
              isuserclassify = false;
              const resp14 = await this.translate.get('GENERAL.CLOSED').toPromise();
              val.status = resp14;
              val.color = 'Green';
              this.options1 = [];

              const dt = new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(),
                                new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds());

              if (new Date(val.closed).getFullYear() === 1 && new Date(val.expires) < dt) {
                const resp1 = await this.translate.get('GENERAL.EXPIRED').toPromise();
                val.exp = resp1;
                val.e = 'Red';
              }

              const crt = new Date(val.created);
              const ovra = new Date(crt.getFullYear(), crt.getMonth(), crt.getDate(), crt.getHours(),
                                      crt.getMinutes() + val.configuration.reactionTimeLimit, crt.getSeconds());
              if (new Date(val.assigned) > ovra) {
                const resp1 = await this.translate.get('GENERAL.ASSIGN').toPromise();
                val.exp = resp1;
                val.a = 'Red';
              }

              const ovrs = new Date(crt.getFullYear(), crt.getMonth(), crt.getDate(), crt.getHours(),
                                      crt.getMinutes() + val.configuration.startTimeLimit, crt.getSeconds());
              if (new Date(val.started) > ovrs) {
                const resp1 = await this.translate.get('GENERAL.STRT').toPromise();
                val.exp = resp1;
                val.s = 'Red';
              }

              const ovrc = new Date(crt.getFullYear(), crt.getMonth(), crt.getDate(), crt.getHours(),
                                      crt.getMinutes() + val.configuration.closeTimeLimit, crt.getSeconds());
              if (new Date(val.closed) > ovrc) {
                const resp1 = await this.translate.get('GENERAL.CLS').toPromise();
                val.exp = resp1;
                val.c = 'Red';
              }
            }
          }

           // classify task menu
          if (val.configuration.categoryId > 0 && isuserclassify === true) {
            const resp1 = await this.translate.get('GENERAL.CLASSIFYTASK').toPromise();
            this.options1.push({label: resp1, value: 'ClassifyTask',
             command: async (event) => { await this.classifyTask(event, val); } });
          }

          // orders menu
          // if (val.location !== null && new Date(val.closed).getFullYear() === 1) {
          // if (val.location !== null) {
          if (val.location !== null && ((val.taskUserId === uid && eas === true) || eall === true)) {
            const resp1 = await this.translate.get('GENERAL.ORDERS').toPromise();
            this.options1.push({label: resp1 + ' ', value: 'Orders',
             command: async (event) => { await this.saveNotif(event, val); } });
          }

          // detail menu
          const resp15 = await this.translate.get('GENERAL.DETAILS').toPromise();
          this.options1.push({label: resp15, value: 'Details',
           command: async (event) => { await this.saveNotif(event, val); }});

          // history menu
          const resp16 = await this.translate.get('GENERAL.HISTORY').toPromise();
          this.options1.push({label: resp16, value: 'History',
           command: async (event) => { await this.saveNotif(event, val); } });

          val.options = this.options1;

          if (val.configuration.configurationType === 'I') {
            val.configuration.confType = 'Information';
          } else if (val.configuration.configurationType === 'T') {
            val.configuration.confType = 'Task';
          }

          const doptions: any = { year: 'numeric', month: 'short', day: 'numeric',
           hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false };
          val.created = new Date(val.created.toString().replace('T', ' ') + ' UTC')
          .toLocaleString('en-US', doptions);
          val.expires = new Date(val.expires.toString().replace('T', ' ') + ' UTC').toLocaleString('en-US', doptions);

          let add = false;
          let process = false;
          if (this.isadm === true) {
            add = true;
            process = true;
          }
          if (rall === true) {
            add = true;
          }
          if ((val.createdBy === uid || val.taskUserId === uid) && rown === true) {
            add = true;
          }

          if (eall === true) {
            add = true;
          }

          // if (eall === true) {
          if ((eall === true && val.taskUserId === 0) || (eall === true && val.taskUserId === uid)) {
            process = true;
          }

          // if (((val.createdBy === uid && val.taskUserId === 0) || val.taskUserId === uid) && eas === true) {
          if (val.taskUserId === uid && eas === true) {
            process = true;
            add = true;
          }

          if (add === false) {
            continue;
          }

          const noptions = [];
          for (const opt of this.options1) {
            if (opt.value === 'History' || opt.value === 'Details') {
              noptions.push(opt);
            } else {
              if (process === true) {
                noptions.push(opt);
              }
            }
          }

          val.options = noptions;

          // add to notification list
          this.notifications.push(val);
        }

        if (res.length < size) {
          this.isLoadMore = true;
        } else {
          this.isLoadMore = false;
        }
      }
      this.isLoad = false;
    }, err => this.handleError(err));
  }

  loadMore() {
    if (this.isNotId === true || this.isPostMsg === true || this.isRange === true
      || this.isTarget === true) {
        this.pager += 1;
        this.load();
    } else {
      this.page += 1;
      this.getNotifications2(this.page, this.size);
    }
  }

  selectedFilter(event) {
    const v = new Variables();
    this.selected = this.filter;
    this.pager = 1;
    this.uid = 0;
    this.stat = ' ';
    if (this.selected === 'none') {
      this.isNone = true;
      this.isNotId = false;
      this.isPostMsg = false;
      this.isTarget = false;
      this.isRange = false;
      this.isName = false;
      this.isEmpId = false;
      this.isStatus = false;
      this.isTitle = false;
      this.isContent = false;
    } else if (this.selected === 'not_id') {
      this.isNotId = true;
      this.isNone = false;
      this.isPostMsg = false;
      this.isTarget = false;
      this.isRange = false;
      this.notId = '';
      this.isName = false;
      this.isEmpId = false;
      this.isStatus = false;
      this.isTitle = false;
      this.isContent = false;
    } else if (this.selected === 'conf_id') {
      this.isPostMsg = true;
      this.isNotId = false;
      this.isNone = false;
      this.isTarget = false;
      this.isRange = false;
      this.confId = 0;
      this.isName = false;
      this.isEmpId = false;
      this.isStatus = false;
      this.isTitle = false;
      this.isContent = false;
    } else if (this.selected === 'type') {
      this.isTarget = true;
      this.isPostMsg = false;
      this.isNotId = false;
      this.isNone = false;
      this.isRange = false;
      this.isName = false;
      this.isEmpId = false;
      this.isStatus = false;
      this.isTitle = false;
      this.isContent = false;
    } else if (this.selected === 'time') {
      /*this.start = this.date1.value.toDateString();
      this.end = this.date2.value.toDateString();*/

      this.isRange = true;
      this.isTarget = false;
      this.isPostMsg = false;
      this.isNotId = false;
      this.isNone = false;
      this.isName = false;
      this.isEmpId = false;
      this.isStatus = false;
      this.isTitle = false;
      this.isContent = false;
    } else if (this.selected === 'name') {
      this.isTarget = false;
      this.isPostMsg = false;
      this.isNotId = false;
      this.isNone = false;
      this.isRange = false;
      this.isName = true;
      this.isEmpId = false;
      this.isStatus = false;
      this.isTitle = false;
      this.isContent = false;
      this.Name = '';
    } else if (this.selected === 'empid') {
      this.isTarget = false;
      this.isPostMsg = false;
      this.isNotId = false;
      this.isNone = false;
      this.isRange = false;
      this.isName = false;
      this.isEmpId = true;
      this.isStatus = false;
      this.isTitle = false;
      this.isContent = false;
      this.EmpId = null;
    } else if (this.selected === 'status') {
      this.isTarget = false;
      this.isPostMsg = false;
      this.isNotId = false;
      this.isNone = false;
      this.isRange = false;
      this.isName = false;
      this.isEmpId = false;
      this.isStatus = true;
      this.isTitle = false;
      this.isContent = false;
    } else if (this.selected === 'title') {
      this.isTarget = false;
      this.isPostMsg = false;
      this.isNotId = false;
      this.isNone = false;
      this.isRange = false;
      this.isName = false;
      this.isEmpId = false;
      this.isStatus = false;
      this.isTitle = true;
      this.isContent = false;
      this.Title = '';
    } else if (this.selected === 'content') {
      this.isTarget = false;
      this.isPostMsg = false;
      this.isNotId = false;
      this.isNone = false;
      this.isRange = false;
      this.isName = false;
      this.isEmpId = false;
      this.isStatus = false;
      this.isTitle = false;
      this.isContent = true;
      this.Content = '';
    } else if (this.selected === 'assignedtomeallstatus' || this.selected === 'assignedtomeandnotstarted'
     || this.selected === 'assignedtomeandinprogress' || this.selected === 'statusopen') {
      this.isNone = false;
      this.isNotId = false;
      this.isPostMsg = false;
      this.isTarget = false;
      this.isRange = false;
      this.isName = false;
      this.isEmpId = false;
      this.isStatus = false;
      this.isTitle = false;
      this.isContent = false;
      if (this.selected === 'assignedtomeallstatus' || this.selected === 'assignedtomeandnotstarted'
      || this.selected === 'assignedtomeandinprogress') {
        const ui = localStorage.getItem('ui');
        const val = CryptoJS.AES.decrypt(ui, v.pass).toString(CryptoJS.enc.Utf8);
        this.uid = val;

        if (this.selected === 'assignedtomeandnotstarted') {
          this.stat = 'assigned';
        } else if (this.selected === 'assignedtomeandinprogress') {
          this.stat = 'started';
        }
      } else if (this.selected === 'statusopen') {
        this.stat = 'open';
      }
    }
  }

  selectedStatus(event) {
    if (this.stat === 'open') {

    }
  }

  ok() {
    this.notifications = [];
    this.isVis = false;
    this.isConfigVis = false;
    this.isEditVis = false;
    localStorage.setItem('tsk-selected', this.selected);
    localStorage.setItem('stat-selected', this.stat);
    const check = true;
    if (check !== true) {

    } else {
      /*if (this.first === true) {
        this.first = false;
        this.notifications = [];
        this.pager = 1;
      } else {
        this.pager += 1;
      }*/

      if (this.isNone === true) {
        this.notId = ' ';
        this.confId = 0;
        this.start = ' ';
        this.end = ' ';
        this.type = ' ';
        this.read = 0;
        this.EmpId = 0;
        this.Name = ' ';
        this.stat = ' ';
        this.Title = ' ';
        this.Content = ' ';
        localStorage.setItem('tsk-filter', '');
      } else if (this.isNotId === true) {
        if (this.notId.trim() === '') {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.ENTERNOTIFICATIONID').subscribe((res) => {
            this.confirm(res);
          });
          return;
        } else {
          this.confId = 0;
          this.start = ' ';
          this.end = ' ';
          this.type = ' ';
          this.read = 0;
          this.EmpId = 0;
          this.Name = ' ';
          this.stat = ' ';
          this.Title = ' ';
          this.Content = ' ';
          localStorage.setItem('tsk-filter', this.notId.toString());
        }
      } else if (this.isName === true) {
        if (this.Name === undefined || this.Name === null || this.Name.trim() === '') {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.ENTERASSIGNEENAME').subscribe((res) => {
            this.confirm(res);
          });
          return;
        } else {
          this.confId = 0;
          this.start = ' ';
          this.end = ' ';
          this.type = ' ';
          this.read = 0;
          this.notId = ' ';
          this.EmpId = 0;
          this.stat = ' ';
          this.Title = ' ';
          this.Content = ' ';
          localStorage.setItem('tsk-filter', this.Name.toString());
        }
      } else if (this.isEmpId === true) {
        if (this.EmpId === undefined || this.EmpId === null || this.EmpId.toString().trim() === '') {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.ENTERASSIGNEEEMPLOYEEID').subscribe((res) => {
            this.confirm(res);
          });
          return;
        } else {
          if (isNaN(this.EmpId)) {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.ASSIGNEEEMPLOYEEIDMUSTBEANUMBER').subscribe((res) => {
              this.confirm(res);
            });
            return;
          }
          this.confId = 0;
          this.start = ' ';
          this.end = ' ';
          this.type = ' ';
          this.read = 0;
          this.notId = ' ';
          this.Name = ' ';
          this.stat = ' ';
          this.Title = ' ';
          this.Content = ' ';
          localStorage.setItem('tsk-filter', this.EmpId.toString());
        }
      } else if (this.isTitle === true) {
        if (this.Title === undefined || this.Title === null || this.Title.trim() === '') {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.ENTERTITLE').subscribe((res) => {
            this.confirm(res);
          });
          return;
        } else {
          this.confId = 0;
          this.start = ' ';
          this.end = ' ';
          this.type = ' ';
          this.read = 0;
          this.notId = ' ';
          this.EmpId = 0;
          this.stat = ' ';
          this.Name = ' ';
          this.Content = ' ';
          localStorage.setItem('tsk-filter', this.Title.toString());
        }
      } else if (this.isContent === true) {
        if (this.Content === undefined || this.Content === null || this.Content.trim() === '') {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.ENTERCONTENT').subscribe((res) => {
            this.confirm(res);
          });
          return;
        } else {
          this.confId = 0;
          this.start = ' ';
          this.end = ' ';
          this.type = ' ';
          this.read = 0;
          this.notId = ' ';
          this.EmpId = 0;
          this.stat = ' ';
          this.Title = ' ';
          this.Name = ' ';
          localStorage.setItem('tsk-filter', this.Content.toString());
        }
      } else if (this.isStatus === true) {
        if (this.stat === undefined || this.stat === null || this.stat.trim() === '') {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.SELECTSTATUS').subscribe((res) => {
            this.confirm(res);
          });
          return;
        } else {
          this.confId = 0;
          this.start = ' ';
          this.end = ' ';
          this.type = ' ';
          this.read = 0;
          this.notId = ' ';
          this.EmpId = 0;
          this.Name = ' ';
          this.Title = ' ';
          this.Content = ' ';
          localStorage.setItem('tsk-filter', this.stat.toString());
        }
      } else if (this.isPostMsg === true) {
        if (this.confId === 0) {
          return;
        } else {
          this.notId = ' ';
          this.start = ' ';
          this.end = ' ';
          this.type = ' ';
          this.read = 0;
          this.EmpId = 0;
          this.Name = ' ';
          this.stat = ' ';
          this.Title = ' ';
          this.Content = ' ';
          localStorage.setItem('tsk-filter', this.confId.toString());
        }
      } else if (this.isTarget === true) {
        if (this.type === ' ' || this.type === '') {
          return;
        } else {
          this.notId = ' ';
          this.confId = 0;
          this.start = ' ';
          this.end = ' ';
          this.read = 0;
          this.EmpId = 0;
          this.Name = ' ';
          this.stat = ' ';
          this.Title = ' ';
          this.Content = ' ';
          localStorage.setItem('tsk-filter', this.type);
        }
      } else if (this.isRange === true) {
        // this.start = this.date1.value.toDateString();
        // this.end = this.date2.value.toDateString();
        if (this.range === undefined || this.range === null) {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.SELECTRANGE').subscribe((res) => {
            this.confirm(res);
          });
          return;
        } else {
          if (this.range[0] !== undefined && this.range[0] !== null) {
            this.start = this.range[0].toDateString();
          } else {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.SELECTRANGE').subscribe((res) => {
              this.confirm(res);
            });
            return;
          }
          if (this.range[1] !== undefined && this.range[1] !== null) {
            this.end = this.range[1].toDateString();
          } else {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.SELECTRANGE').subscribe((res) => {
              this.confirm(res);
            });
            return;
          }
        }

        if (this.start === ' ' || this.start === '' || this.end === ' ' || this.end === '') {
          return;
        } else {
            if (this.invalidDate === true) {
              // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.INVALIDDATEFORMAT').subscribe((resp: string) => {
                alert(resp);
                });
              return;
          } else {
              if (new Date(this.start) > new Date(this.end)) {
                // tslint:disable-next-line: deprecation
                this.translate.get('GENERAL.ENDDATEGREATER').subscribe((resp: string) => {
                  alert(resp);
                  });
                return;
            } else {
              this.notId = ' ';
              this.confId = 0;
              this.type = ' ';
              this.read = 0;
              this.EmpId = 0;
              this.Name = ' ';
              this.stat = ' ';
              this.Title = ' ';
              this.Content = ' ';
              localStorage.setItem('tsk-filter', this.start + ';' + this.end);
            }
          }
        }
      }

      this.isLoad = true;
      this.page = 1;
      this.pager = 1;
      this.notifications = [];
      if (this.isEmpId === true) {
        // tslint:disable-next-line: deprecation
        this.userService.getUser(this.EmpId, 0, ' ', ' ').subscribe((res) => {
          if (res.userId === 0) {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.EMPLOYEEIDDOESNOTEXIST').subscribe((trans) => {
              this.confirm(trans);
              this.isLoad = false;
            });
          } else {
            this.Name = res.firstName + ' ' + res.lastName;
            localStorage.setItem('tsk-filter', res.employeeId.toString());
            localStorage.setItem('filter-name', this.Name.toString());
            this.getNotifications2(this.page, this.size);
          }
        }, err => this.handleError(err));
      } else {
        this.getNotifications2(this.page, this.size);
      }
    }
  }

  load() {
    if (this.isNone === true) {
      this.notId = ' ';
      this.confId = 0;
      this.start = ' ';
      this.end = ' ';
      this.type = ' ';
      this.read = 0;
      this.EmpId = 0;
      this.Name = ' ';
      this.stat = ' ';
      this.Title = ' ';
      this.Content = ' ';
    } else if (this.isNotId === true) {
      if (this.notId === null || this.notId === ' ' || this.notId === '') {
        return;
      } else {
        this.confId = 0;
        this.start = ' ';
        this.end = ' ';
        this.type = ' ';
        this.read = 0;
        this.EmpId = 0;
        this.Name = ' ';
        this.stat = ' ';
        this.Title = ' ';
        this.Content = ' ';
      }
    } else if (this.isPostMsg === true) {
      if (this.confId === 0) {
        return;
      } else {
        this.notId = ' ';
        this.start = ' ';
        this.end = ' ';
        this.type = ' ';
        this.read = 0;
        this.EmpId = 0;
        this.Name = ' ';
        this.stat = ' ';
        this.Title = ' ';
        this.Content = ' ';
      }
    } else if (this.isTarget === true) {
      if (this.type === ' ' || this.type === '') {
        return;
      } else {
        this.notId = ' ';
        this.confId = 0;
        this.start = ' ';
        this.end = ' ';
        this.read = 0;
        this.EmpId = 0;
        this.Name = ' ';
        this.stat = ' ';
        this.Title = ' ';
        this.Content = ' ';
      }
    } else if (this.isRange === true) {
        // this.start = this.date1.value.toDateString();
        // this.end = this.date2.value.toDateString();
        if (this.start === ' ' || this.start === '' || this.end === ' ' || this.end === '') {
          return;
        } else {
          this.notId = ' ';
          this.confId = 0;
          this.type = ' ';
          this.read = 0;
          this.EmpId = 0;
          this.Name = ' ';
          this.stat = ' ';
          this.Title = ' ';
          this.Content = ' ';
        }
      }

    this.isLoad = true;
    this.getNotifications2(this.pager, this.size);
    /*this.notification.getNotifications(this.pager, this.size, this.notId,
      this.confId, 'T', this.start, this.end, this.read).subscribe((res) => {
        if (res == null) {
          this.isLoad = false;
          this.isLoadMore = false;
          this.translate.get('GENERAL.NOMORETASKS').subscribe((resp: string) => {
            this.notificationSvc.info('', resp, 2000);
          });
        } else {
          // tslint:disable-next-line: prefer-for-of
          for (let i = 0; i < res.length; i++) {
            this.tasksService.getTaskById(res[i].notificationId).subscribe((resp) => {
              if (resp.notificationId !== null) {
                res[i].tsk = resp;
                if (resp.taskUserId === 0) {
                  this.translate.get('GENERAL.OPEN').subscribe((resp1: string) => {
                    res[i].status = resp1;
                    res[i].color = 'LightGray';
                  });
                  this.options1 = [];
                  this.translate.get('GENERAL.DETAILS').subscribe((resp1: string) => {
                    this.options1.push({label: resp1, value: 'Details'});
                    this.translate.get('GENERAL.ASSIGNTOUSER').subscribe((resp2: string) => {
                      this.options1.push({label: resp2, value: 'AssignToUser'});
                      this.translate.get('GENERAL.ASSIGNTOME').subscribe((resp3: string) => {
                        this.options1.push({label: resp3, value: 'AssignToMe'});
                      });
                    });
                  });
                  if (new Date(res[i].closed).getFullYear() === 1 && new Date(res[i].expires) < new Date()) {
                    this.translate.get('GENERAL.EXPIRED').subscribe((resp1: string) => {
                      res[i].exp = resp1;
                    });
                  }
                } else {
                  if (new Date(resp.assigned).getFullYear() !== 1) {
                    this.translate.get('GENERAL.ASSIGNED').subscribe((resp1: string) => {
                      res[i].status = resp1;
                      res[i].color = 'Yellow';
                    });
                    this.options1 = [];
                    this.translate.get('GENERAL.DETAILS').subscribe((resp1: string) => {
                      this.options1.push({label: resp1, value: 'Details'});
                      this.translate.get('GENERAL.START').subscribe((resp2: string) => {
                        this.options1.push({label: resp2, value: 'Start'});
                      });
                    });

                    if (new Date(res[i].closed).getFullYear() === 1 && new Date(res[i].expires) < new Date()) {
                      this.translate.get('GENERAL.EXPIRED').subscribe((resp1: string) => {
                        res[i].exp = resp1;
                      });
                    }

                    if (new Date(resp.assigned) > new Date(res[i].expires)) {
                      this.translate.get('GENERAL.ASSIGNED').subscribe((resp1: string) => {
                        res[i].exp = resp1;
                      });
                    }
                  }
                  if (new Date(resp.started).getFullYear() !== 1) {
                    this.translate.get('GENERAL.STARTED').subscribe((resp1: string) => {
                      res[i].status = resp1;
                      res[i].color = 'LightBlue';
                    });
                    this.options1 = [];
                    this.translate.get('GENERAL.DETAILS').subscribe((resp1: string) => {
                      this.options1.push({label: resp1, value: 'Details'});
                      this.translate.get('GENERAL.CLOSE').subscribe((resp2: string) => {
                        this.options1.push({label: resp2, value: 'Close'});
                      });
                    });

                    if (new Date(res[i].closed).getFullYear() === 1 && new Date(res[i].expires) < new Date()) {
                      this.translate.get('GENERAL.EXPIRED').subscribe((resp1: string) => {
                        res[i].exp = resp1;
                      });
                    }

                    if (new Date(resp.started) > new Date(res[i].expires)) {
                      this.translate.get('GENERAL.STARTED').subscribe((resp1: string) => {
                        res[i].exp = resp1;
                      });
                    }
                  }
                  if (new Date(resp.closed).getFullYear() !== 1) {
                    this.translate.get('GENERAL.CLOSED').subscribe((resp1: string) => {
                      res[i].status = resp1;
                      res[i].color = 'Gray';
                    });
                    this.options1 = [];
                    this.translate.get('GENERAL.DETAILS').subscribe((resp1: string) => {
                      this.options1.push({label: resp1, value: 'Details'});
                    });

                    if (new Date(res[i].closed).getFullYear() === 1 && new Date(res[i].expires) < new Date()) {
                      this.translate.get('GENERAL.EXPIRED').subscribe((resp1: string) => {
                        res[i].exp = resp1;
                      });
                    }

                    if (new Date(resp.closed) > new Date(res[i].expires)) {
                      this.translate.get('GENERAL.CLOSED').subscribe((resp1: string) => {
                        res[i].exp = resp1;
                      });
                    }
                  }
                }
                res[i].options = this.options1;
              } else {
                if (new Date(res[i].closed).getFullYear() === 1 && new Date(res[i].expires) < new Date()) {
                  this.translate.get('GENERAL.EXPIRED').subscribe((resp1: string) => {
                    res[i].status = resp1;
                    res[i].color = 'Red';
                  });
                  res[i].options = this.options1;
                }
                this.options1 = [];
                this.translate.get('GENERAL.DETAILS').subscribe((resp1: string) => {
                  this.options1.push({label: resp1, value: 'Details'});
                });
                res[i].options = this.options1;
              }
            });
            res[i].options = this.options1;
            if (res[i].configuration.configurationType === 'I') {
              res[i].configuration.confType = 'Information';
            } else if (res[i].configuration.configurationType === 'T') {
              res[i].configuration.confType = 'Task';
            }
            res[i].created = new Date(res[i].created.toString().replace('T', ' ') + ' UTC').toLocaleString();
            res[i].expires = new Date(res[i].expires.toString().replace('T', ' ') + ' UTC').toLocaleString();
            // res[i].created = new Date(res[i].created).toLocaleString();
            // res[i].expires = new Date(res[i].expires).toLocaleString();
            this.notifications.push(res[i]);
          }

          if (res.length < this.size) {
            this.isLoadMore = true;
          } else {
            this.isLoadMore = false;
          }
        }
        this.isLoad = false;
      });*/
  }

  async gotoCreateTask() {
    // this.router.navigateByUrl('/createtask');
    this.iscreatetask = false;
    this.MessageTitle = null;
    this.MessageBody = null;
    this.Location = null;
    this.assignee = null;
    this.conf1 = null;
    this.clear();

    this.val = 'Selection';
    this.isselect = true;
    this.ismanual = false;

    this.islocation = false;
    this.isrecipientoptional = false;
    this.isassigneeoptional = false;
    this.isassignee = false;
    this.isrecp = false;

    this.locations = [];

    await this.getConfigurations();
    await this.getContactGroups();
    this.getLocations();
    this.iscreatetask = true;
  }

  saveNot(notification) {
    localStorage.setItem('notification', JSON.stringify(notification));
  }

  async classifyTask(event: any, val: any) {
    this.notval = val;
    this.classes = [];
    this.citems = [];
    this.class = null;
    this.citem = null;
    this.issavedisabled = true;
    await this.getCatClass(val.configuration.categoryId);
  }

  enableSave() {
    this.issavedisabled = false;
  }

  async getCatClass(catid: any) {
    this.loading = true;

    const res = await this.catclassService.getCategoryClasses(0, 0, 0, ' ', catid, ' ').toPromise()
    .catch(err => console.log(err));

    if (res !== undefined && res !== null && res.length > 0) {
      for (const val of res) {
        this.classes.push({label: val.categoryClassList.className, value: val});
      }
      if (res.length === 1) {
        this.isclassdisabled = true;
        this.class = this.classes[0].value;
        await this.getClassListItems(this.classes[0].value.categoryClassList.classId);
      } else {
        this.isclassdisabled = false;
      }
      this.cname = this.classes[0].value.category.categoryName;
    } else {
      const c = await this.catService.getCategories(0, 0, catid, ' ').toPromise()
      .catch(err => console.log(err));
      if (c !== undefined && c !== null && c.length > 0) {
        this.cname = c[0].categoryName;
      }
    }

    this.iscategorize = true;
    this.loading = false;
  }

  async getClassListItems(classid: any) {
    this.loading = true;

    const res = await this.itemService.getClassItems(0, 0, classid, ' ', 0, ' ').toPromise()
    .catch(err => console.log(err));
    this.citems = [];
    if (res !== undefined && res !== null && res.length > 0) {
      for (const val of res) {
        this.citems.push({label: val.classListItem.itemName, value: val});
      }
    }

    this.loading = false;
  }

  async classSelected() {
    this.citem = null;
    this.issavedisabled = true;
    await this.getClassListItems(this.class.categoryClassList.classId);
  }

  async classify() {
    if (this.class === undefined || this.class === null) {
      this.translate.get('GENERAL.SELECTCLASS').subscribe((res: string) => {
        this.confirm(res);
      });
    } else if (this.citem === undefined || this.citem === null) {
      this.translate.get('GENERAL.SELECTCLASSITEM').subscribe((res: string) => {
        this.confirm(res);
      });
    } else {
      const tc = new TaskClassification();
      tc.ItemId = this.citem.classListItem.itemId;
      tc.NotificationId = this.notval.notificationId;

      this.loading = true;

      const res = await this.tcService.postTaskClassification(tc).toPromise()
      .catch(err => console.log(err));

      if (res !== undefined && res !== null && res === true) {
        this.translate.get('GENERAL.TASKCLASSIFIED').subscribe((resp: string) => {
          this.notificationSvc.info('', resp, 2000);
        });
        this.classifyCancel();
      } else {
        this.translate.get('GENERAL.TASKCLASSIFICATIONFAILED').subscribe((resp: string) => {
          this.notificationSvc.info('', resp, 2000);
        });
      }

      this.loading = false;
    }
  }

  show(notification) {
    /*const yr = notification.read.getFullYear();
    const mn = notification.read.getMonth();
    const d = notification.read.getDate();
    const hr = notification.read.getHours();
    const min = notification.read.getMinutes();
    const sec = notification.read.getSeconds();*/

    if (notification.read === '0001-01-01T00:00:00') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.NOTREAD').subscribe((resp: string) => {
        notification.reads = resp;
      });
      this.isNotDate = true;
      this.isDate = false;
    } else {
      this.isNotDate = false;
      this.isDate = true;
    }

    this.notif = notification;
    this.isVis = true;
    this.isConfigVis = false;
    this.isEditVis = false;

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.notifications.length; i++) {
      if (this.notifications[i].notificationId === notification.notificationId) {
        this.notifications[i].color = 'lightgray';
      } else {
        this.notifications[i].color = 'transparent';
      }
    }
  }

  deleteNotification(not) {
    // tslint:disable-next-line: deprecation
    this.notification.deleteNotification(not.notificationId).subscribe((resp) => {
      if (resp === true) {
        // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.DELETED').subscribe((res: string) => {
            this.notificationSvc.info('', res, 2000);
          });
          this.notifications = [];
          this.getNotifications2(1, this.size);
          if (not.notificationId === this.notif.notificationId) {
            this.notif = null;
            this.isVis = false;
          }
        } else {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.COULDNOTDELETE').subscribe((res: string) => {
            alert(res);
          });
        }
    });
  }

  async likeClicked(not) {
    this.isLoad = true;

    const v = new Variables();
    const phr = localStorage.getItem('phr');
    const phone = CryptoJS.AES.decrypt(phr, v.pass).toString(CryptoJS.enc.Utf8);

    const feedback = new NotificationFeedback();
    feedback.FeedbackId = 1;
    feedback.NotificationId = not.notificationId;
    feedback.UserPhone = phone;

    if (not.userLikeFeedbackCount > 0) {
      // unlike
      const res = await this.notFeedbackService.deleteFeedback(feedback).toPromise()
      .catch(err => this.handleError(err));
      if (res === true) {
        this.translate.get('GENERAL.UNLIKED').subscribe((resp: string) => {
          this.notificationSvc.info('', resp, 2000);
        });

        not.userLikeFeedbackCount = 0;
        not.likeFeedbackCount -= 1;
        for (let val of this.notifications) {
          if (val.notificationId === not.notificationId) {
            val = not;
          }
        }
      }
    } else {
      // like
      const res = await this.notFeedbackService.postFeedback(feedback).toPromise()
      .catch(err => this.handleError(err));
      if (res === true) {
        this.translate.get('GENERAL.LIKED').subscribe((resp: string) => {
          this.notificationSvc.info('', resp, 2000);
        });

        not.userLikeFeedbackCount = 1;
        not.likeFeedbackCount += 1;
        for (let val of this.notifications) {
          if (val.notificationId === not.notificationId) {
            val = not;
          }
        }
      }
    }

    this.isLoad = false;
  }

  async commentClicked(not) {
    this.mynot = not;
    this.isLoad = true;

    this.comments = [];
    const coms = await this.commentService.getComments(not.notificationId).toPromise()
    .catch(err => this.handleError(err));

    if (coms !== undefined && coms !== null && coms.length > 0) {
      for (const val of coms) {
        const doptions: any = { year: 'numeric', month: 'short', day: 'numeric',
           hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false };
        val.crt = new Date(val.created.toString().replace('T', ' ') + ' UTC')
          .toLocaleString('en-US', doptions);
        if (val.userName === undefined || val.userName === null) {
          val.userName = val.userPhone;
        }
        this.comments.push(val);
      }
    }
    this.iscomments = true;

    this.isLoad = false;
  }

  async send() {
    if (this.comment === undefined || this.comment === null || this.comment.trim() === '') {
      return;
    }
    this.isLoad = true;

    const v = new Variables();
    const phr = localStorage.getItem('phr');
    const phone = CryptoJS.AES.decrypt(phr, v.pass).toString(CryptoJS.enc.Utf8);

    const comment = new NotificationComment();
    comment.NotificationId = this.mynot.notificationId;
    comment.UserPhone = phone;
    comment.Comment = this.comment;
    comment.UserName = localStorage.getItem('uname');

    const res = await this.commentService.postComment(comment).toPromise()
    .catch(err => this.handleError(err));

    if (res === true) {
      const cs = [];
      const coms = await this.commentService.getComments(this.mynot.notificationId).toPromise()
      .catch(err => this.handleError(err));

      if (coms !== undefined && coms !== null && coms.length > 0) {
        for (const val of coms) {
          const doptions: any = { year: 'numeric', month: 'short', day: 'numeric',
             hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false };
          val.crt = new Date(val.created.toString().replace('T', ' ') + ' UTC')
            .toLocaleString('en-US', doptions);
          if (val.userName === undefined || val.userName === null) {
            val.userName = val.userPhone;
          }
          cs.push(val);
        }
      }

      this.comment = null;
      this.comments = cs;

      this.mynot.userCommentCount += 1;
      this.mynot.commentCount += 1;
      for (let val of this.notifications) {
        if (val.notificationId === this.mynot.notificationId) {
          val = this.mynot;
        }
      }
    } else {
      // failed
    }

    this.isLoad = false;
  }

  async likeValueClicked(not) {
    if (not.likeFeedbackCount === 0) {
      return;
    }

    this.mynot = not;
    this.isLoad = true;

    this.likes = [];
    const lks = await this.notFeedbackService.getFeedbacks(not.notificationId, 1).toPromise()
    .catch(err => this.handleError(err));

    if (lks !== undefined && lks !== null && lks.length > 0) {
      for (const val of lks) {
        const doptions: any = { year: 'numeric', month: 'short', day: 'numeric',
           hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false };
        val.crt = new Date(val.created.toString().replace('T', ' ') + ' UTC')
          .toLocaleString('en-US', doptions);
        if (val.userName === undefined || val.userName === null) {
          val.userName = val.userPhone;
        }
        this.likes.push(val);
      }
    }
    this.islikes = true;

    this.isLoad = false;
  }

  getLocations() {
    DataCache.locdata$.subscribe(data => {
      if (data !== undefined && data !== null && data.length > 0) {
        for (const val of data) {
          this.locations.push({ label: val.locationName, value: val.locationName});
        }
      }
    });
  }

  addEvent1(type: string, event: MatDatepickerInputEvent<Date>) {
    if (event.value === null) {
      this.invalidDate = true;
    } else {
      this.invalidDate = false;
      this.start = event.value.toDateString();
    }
  }

  addEvent2(type: string, event: MatDatepickerInputEvent<Date>) {
    if (event.value === null) {
      this.invalidDate = true;
    } else {
      this.invalidDate = false;
      this.end = event.value.toDateString();
    }
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    // this.events.push(`${type}: ${event.value}`);
    console.log(`${type}: ${event.value}`);
    if (event.value === null) {
      console.log('null');
    } else {
      console.log(event.value.toDateString());
    }
  }

  showConfig(id, configuration) {
    configuration.configurationId = id;
    if (configuration.priority === 1) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.CRITICAL').subscribe((res) => {
        configuration.prior = res;
      });
    } else if (configuration.priority === 2) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.HIGH').subscribe((res) => {
        configuration.prior = res;
      });
    } else if (configuration.priority === 3) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.NORMAL').subscribe((res) => {
        configuration.prior = res;
      });
    } else if (configuration.priority === 4) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.LOW').subscribe((res) => {
        configuration.prior = res;
      });
    } else if (configuration.priority === 4) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.VERYLOW').subscribe((res) => {
        configuration.prior = res;
      });
    }

    if (configuration.channel === 'M') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.MQTT').subscribe((res) => {
        configuration.chan = res;
      });
    } else if (configuration.channel === 'S') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SMS').subscribe((res) => {
        configuration.chan = res;
      });
    } else if (configuration.channel === 'E') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.EMAIL').subscribe((res) => {
        configuration.chan = res;
      });
    }

    if (configuration.vibrate === 0) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.NONE').subscribe((res) => {
        configuration.vib = res;
      });
    } else if (configuration.vibrate === 1) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SHORT').subscribe((res) => {
        configuration.vib = res;
      });
    } else if (configuration.vibrate === 2) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.LONG').subscribe((res) => {
        configuration.vib = res;
      });
    } else if (configuration.vibrate === 3) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.VERYLONG').subscribe((res) => {
        configuration.vib = res;
      });
    }

    if (configuration.configurationType === 'I') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.INFORMATION').subscribe((res) => {
        configuration.configType = res;
      });
    } else if (configuration.configurationType === 'T') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.TASK').subscribe((res) => {
        configuration.configType = res;
      });
    }
    this.isVis = false;
    this.isConfigVis = true;
    this.isEditVis = false;
    this.config = configuration;
  }

  showEdit(notification) {
    this.isUser = false;
    this.disabled = false;
    this.username = null;
    this.status = null;
    this.option = null;
    this.user = null;
    // tslint:disable-next-line: deprecation
    this.tasksService.getTaskById(notification.notificationId).subscribe((res) => {
      this.tskUser = res;
      if (res.taskUserId === 0) {
        this.options = [];
        this.username = 'No user';
        this.status = 'Open';
        this.assigned = 'Not assigned';
        this.started = 'Not started';
        this.closed = 'Not closed';

    // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.SELECTOPTION').subscribe((resp) => {
          // this.options.push({label: resp, value: null});
    // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.ASSIGNTOUSER').subscribe((resp1: string) => {
            this.options.push({label: resp1, value: 'Assign'});
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.START').subscribe((resp2: string) => {
              // this.options.push({label: resp2, value: 'Start'});
    // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.CLOSE').subscribe((resp3: string) => {
                // this.options.push({label: resp3, value: 'Close'});
                // this.option = this.options[0];
              });
            });
          });
        });

      } else {
        this.username = res.taskUserName;
        this.isUser = false;
        if (new Date(res.assigned).getFullYear() !== 1) {
          this.options = [];
          this.status = 'Assigned';
          const a = new Date(res.assigned);
          this.assigned = a.getDate() + '/' + a.getMonth() + '/' + a.getFullYear()
                          + ' at ' + a.toLocaleTimeString();
          this.started = 'Not started';
          this.closed = 'Not closed';
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.SELECTOPTION').subscribe((resp) => {
            // this.options.push({label: resp, value: null});
    // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.START').subscribe((resp2: string) => {
              this.options.push({label: resp2, value: 'Start'});
              // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.CLOSE').subscribe((resp3: string) => {
                // this.options.push({label: resp3, value: 'Close'});
                // this.option = this.options[0];
              });
            });
          });
        }
        if (new Date(res.started).getFullYear() !== 1) {
          this.options = [];
          this.status = 'Started';
          const a = new Date(res.started);
          this.started = a.getDate() + '/' + a.getMonth() + '/' + a.getFullYear()
                         + ' at ' + a.toLocaleTimeString();
          this.closed = 'Not closed';
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.SELECTOPTION').subscribe((resp) => {
            // this.options.push({label: resp, value: null});
    // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.CLOSE').subscribe((resp3: string) => {
              this.options.push({label: resp3, value: 'Close'});
              // this.option = this.options[0];
            });
          });
        }
        if (new Date(res.closed).getFullYear() !== 1) {
          this.status = 'Closed';
          const a = new Date(res.closed);
          this.closed = a.getDate() + '/' + a.getMonth() + '/' + a.getFullYear()
                        + ' at ' + a.toLocaleTimeString();
                        // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.SELECTOPTION').subscribe((resp) => {
            // this.options.push({label: resp, value: null});
          });
          this.disabled = true;
        }
      }
    }, err => {
      this.handleError(err);
    });
    this.not = notification;
    this.isConfigVis = false;
    this.isVis = false;
    this.isEditVis = true;

    this.users = [];
    // this.username = '';
    if (this.not.configuration.usrGrp != null) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < this.not.configuration.usrGrp.length; i++) {
        if (this.not.configuration.usrGrp[i].idType === 'U') {
          this.users.push({ label: this.not.configuration.usrGrp[i].name, value: this.not.configuration.usrGrp[i] });
        }
        if (this.not.configuration.usrGrp[i].idType === 'G') {
          // tslint:disable-next-line: deprecation
          this.usergroupService.getUserGroups(this.not.configuration.usrGrp[i].id).subscribe((res) => {
            if (res != null) {
              // tslint:disable-next-line: prefer-for-of
              for (let j = 0; j < res.length; j++) {
                // tslint:disable-next-line: prefer-const
                let usr: any;
                res[i].id = res[i].userId;
                res[i].name = res[i].firstName + ' ' + res[i].lastName;
                res[i].idType = 'U';
                this.users.push({ label: res[i].name, value: res[i] });
              }
            }
          });
        }
        /*this.username += this.not.configuration.usrGrp[i].name;
        if (i !== this.not.configuration.usrGrp.length - 1) {
          this.username +=  ', ';
        }*/
      }
    }
  }

  onCancel() {
    this.isVis = false;
    this.isConfigVis = false;
    this.isEditVis = false;
  }

  classifyCancel() {
    this.iscategorize = false;
  }

  saveUser() {
    const values = new TaskUpdater();
    values.Id = this.not.notificationId;
    values.Values = [];

    const id = new KeyValue();
    id.Key = 'id';
    id.Value = this.user.id.toString();
    values.Values.push(id);

    const name = new KeyValue();
    name.Key = 'name';
    name.Value = this.user.name;
    values.Values.push(name);

    const assigned = new KeyValue();
    assigned.Key = 'assigned';
    assigned.Value = '';
    values.Values.push(assigned);

    this.loading = true;
    // tslint:disable-next-line: deprecation
    this.tasksService.putTask(values.Id, values).subscribe((res) => {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.USERASSIGNED').subscribe((resp) => {
        this.notificationSvc.info('', resp, 2000);
      });
      // this.showEdit(this.not);
      this.notifications = [];
      this.page = 1;
      this.getNotifications2(this.page, this.size);
      this.display = false;
      this.loading = false;
    }, err => {
      this.handleError(err);
    });
  }

  saveU(user) {
    const values = new TaskUpdater();
    values.Id = this.not.notificationId;
    values.Values = [];

    const id = new KeyValue();
    id.Key = 'id';
    id.Value = user.id.toString();
    values.Values.push(id);

    const name = new KeyValue();
    name.Key = 'name';
    name.Value = user.name;
    values.Values.push(name);

    const assigned = new KeyValue();
    assigned.Key = 'assigned';
    assigned.Value = '';
    values.Values.push(assigned);

    this.loading = true;
    // tslint:disable-next-line: deprecation
    this.tasksService.putTask(values.Id, values).subscribe((res) => {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.USERASSIGNED').subscribe((resp) => {
        this.notificationSvc.info('', resp, 2000);
      });
      // this.showEdit(this.not);
      this.notifications = [];
      this.page = 1;
      this.getNotifications2(this.page, this.size);
      this.display = false;
      this.loading = false;
    }, err => {
      this.handleError(err);
    });
  }

  async getHistory(notid) {
    this.loading = true;

    this.notlogs = [];

    const doptions: any = { year: 'numeric', month: 'short', day: 'numeric',
    hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false };

    const hist = await this.notlogService.getNotificationLogs(0, 0, notid).toPromise()
    .catch(err => this.handleError(err));

    if (hist !== undefined && hist !== null && hist.length > 0) {
      for (const val of hist) {
        if (val.parameter === 'assign_time' || val.parameter === 'start_time'
         || val.parameter === 'close_time' || val.parameter === 'location_confirmation') {
          if (val.oldValue !== null) {
            val.ov = new Date(val.oldValue.toString().replace('T', ' ') + ' UTC')
                                          .toLocaleString('en-US', doptions);
          }
          if (val.newValue !== null) {
            val.nv = new Date(val.newValue.toString().replace('T', ' ') + ' UTC')
                                          .toLocaleString('en-US', doptions);
          }
        } else {
          val.ov = val.oldValue;
          val.nv = val.newValue;
        }

        val.dt = new Date(val.date.toString().replace('T', ' ') + ' UTC')
          .toLocaleString('en-US', doptions);

        this.notlogs.push(val);
      }
    }

    this.loading = false;
  }

  async saveNotif(event, notif) {
    event.value = event.item.value;
    // localStorage.setItem('notification', JSON.stringify(notif));
    if (event.value === 'Details') {
      localStorage.setItem('notification', JSON.stringify(notif));
      this.router.navigateByUrl('/taskdetail/' + notif.notificationId);
    } else if (event.value === 'History') {
      await this.getHistory(notif.notificationId);
      this.ishistory = true;
    } else if (event.value === 'AssignToMe') {
      const v = new Variables();
      const val = localStorage.getItem('ui');
      const ui = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);
      const uid = +ui;
      this.loading = true;
      // tslint:disable-next-line: deprecation
      this.userService.getUser(0, uid, ' ', ' ').subscribe((res) => {
        const values = new TaskUpdater();
        values.Id = notif.notificationId;
        values.Values = [];

        const id = new KeyValue();
        id.Key = 'id';
        id.Value = uid.toString();
        values.Values.push(id);

        const name = new KeyValue();
        name.Key = 'name';
        name.Value = res.firstName + ' ' + res.lastName;
        values.Values.push(name);

        const assigned = new KeyValue();
        assigned.Key = 'assigned';
        assigned.Value = '';
        values.Values.push(assigned);

    // tslint:disable-next-line: deprecation
        this.tasksService.putTask(values.Id, values).subscribe(() => {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.USERASSIGNED').subscribe((resp) => {
            this.notificationSvc.info('', resp, 2000);
          });
          this.notifications = [];
          this.page = 1;
          this.getNotifications2(this.page, this.size);
          this.loading = false;
        }, err => {
          this.handleError(err);
        });
      });
    } else if (event.value === 'AssignToUser') {
      this.getUsers(notif);
    } else if (event.value === 'Start') {
      const values = new TaskUpdater();
      values.Id = notif.notificationId;
      values.Values = [];

      this.loading = true;
      if (notif.configuration.workflow === 'OSC') {
        const v = new Variables();
        const val = localStorage.getItem('ui');
        const ui = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);
        const uid = +ui;
        const res = await this.userService.getUser(0, uid, ' ', ' ').toPromise().catch(err => this.handleError(err));
        const id = new KeyValue();
        id.Key = 'id';
        id.Value = uid.toString();
        values.Values.push(id);

        const name = new KeyValue();
        name.Key = 'name';
        name.Value = res.firstName + ' ' + res.lastName;
        values.Values.push(name);

        const assigned = new KeyValue();
        assigned.Key = 'assigned';
        assigned.Value = '';
        values.Values.push(assigned);
      }

      const started = new KeyValue();
      started.Key = 'started';
      started.Value = '';
      values.Values.push(started);

    // tslint:disable-next-line: deprecation
      await this.tasksService.putTask(values.Id, values).subscribe((res) => {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.TASKSTARTED').subscribe((resp) => {
          this.notificationSvc.info('', resp, 2000);
        });
        this.notifications = [];
        this.page = 1;
        this.getNotifications2(this.page, this.size);
        this.loading = false;
      }, err => {
        this.handleError(err);
      });
    } else if (event.value === 'Close') {
      this.notval = notif;
      const values = new TaskUpdater();
      values.Id = notif.notificationId;
      values.Values = [];

      this.loading = true;
      if (notif.configuration.workflow === 'OC') {
        const v = new Variables();
        const val = localStorage.getItem('ui');
        const ui = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);
        const uid = +ui;
        const res = await this.userService.getUser(0, uid, ' ', ' ').toPromise().catch(err => this.handleError(err));

        const id = new KeyValue();
        id.Key = 'id';
        id.Value = uid.toString();
        values.Values.push(id);

        const name = new KeyValue();
        name.Key = 'name';
        name.Value = res.firstName + ' ' + res.lastName;
        values.Values.push(name);

        const assigned = new KeyValue();
        assigned.Key = 'assigned';
        assigned.Value = '';
        values.Values.push(assigned);

        const started = new KeyValue();
        started.Key = 'started';
        started.Value = '';
        values.Values.push(started);
      }

      const closed = new KeyValue();
      closed.Key = 'closed';
      closed.Value = '';
      values.Values.push(closed);

    // tslint:disable-next-line: deprecation
      await this.tasksService.putTask(values.Id, values).subscribe((res) => {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.TASKCLOSED').subscribe((resp) => {
          this.notificationSvc.info('', resp, 2000);
        });
        this.notifications = [];
        this.page = 1;
        this.getNotifications2(this.page, this.size);
        this.loading = false;
      }, err => {
        this.handleError(err);
      });
    } else if (event.value === 'Orders') {
      // this.router.navigateByUrl('/orders');
      this.router.navigate(['/orders'],
       { state: { notId: notif.notificationId, loc: notif.location } });
    }
    this.item = [];
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        },
        reject: () => {
          //
        }
    });
  }

  showDialog() {
    let count = 0;
    const us = [];
    if (this.users.length === 0) {
      this.display = true;
      // tslint:disable-next-line: deprecation
      this.translate.get('NOUSERS').subscribe((res) => {
        this.confirm(res);
      });
    } else {
      for (const usr of this.users) {
        // tslint:disable-next-line: deprecation
        this.deviceService.getDevices(1, 1, ' ', ' ', usr.value.id, usr.value.name).subscribe((res) => {
          if (res != null) {
            const dt = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(),
                                  new Date().getHours(), new Date().getMinutes() - 3, new Date().getSeconds());
            const lb = new Date(res[0].lastHeartBeat);
            // const lhb = new Date(lb.getUTCFullYear(), lb.getUTCMonth(), lb.getUTCDate(),
            //                    lb.getUTCHours(), lb.getUTCMinutes(), lb.getUTCSeconds());
            const lhb = new Date(new Date(res[0].lastHeartBeat).toLocaleString() + ' UTC');
            if (lhb < dt) {
              usr.value.color = 'Red';
            } else {
              usr.value.color = 'Green';
            }
          } else {
            usr.value.color = 'Red';
          }
          us.push(usr);
          count += 1;

          if (this.users.length === count) {
            us.sort((a, b) => a.value.name.localeCompare(b.value.name));
            this.users = us;
            this.display = true;
            this.loading = false;
          }
        });
      }
    }
  }

  async getConfigurations() {
    this.loading = true;
    this.configs = [];
    this.configs1 = [];
    const res = await this.configService.getConfigurations('T').toPromise()
    .catch(err => this.handleError(err));
    if (res != null) {
      const v = new Variables();
      const gis = localStorage.getItem('gi');
      const gs = CryptoJS.AES.decrypt(gis, v.pass).toString(CryptoJS.enc.Utf8);
      const gids = JSON.parse(gs);

      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < res.length; i++) {
        if (res[i].typeRoleGroups !== null && res[i].typeRoleGroups.length > 0) {
          let show = false;
          for (const val of res[i].typeRoleGroups) {
            if (val.typeRoleGroup.roleName === 'Initiator' && gids.includes(val.typeRoleGroup.groupId)) {
              show = true;
            }
          }

          if (this.isadm === true) {
            show = true;
          }

          if (show === false) {
            continue;
          }
        } else {
          if (this.isadm === true) {
          } else {
            continue;
          }
        }
        this.configs.push(res[i]);
        this.configs1.push({ label: res[i].configurationName + ' ', value: res[i] });
      }
    }

    this.loading = false;
  }

  change() {
    this.assignee = null;
    this.user = null;
    this.IsAssigneeOpt = false;

    this.IsRecipientOpt = false;
    this.clear();
    this.val = 'Selection';
    this.isselect = true;
    this.ismanual = false;

    if (this.conf1.locationSensitive === 'YES') {
      this.islocation = true;
    } else {
      this.islocation = false;
    }

    if (this.conf1.assigneeAtCreation === 'NOTALLOWED') {
      this.isassignee = false;
      this.isassigneeoptional = false;
    } else {
      if (this.conf1.assigneeAtCreation === 'MANDATORY') {
        this.isoptional = false;
        this.isassignee = true;
        this.isassigneeoptional = false;
      } else if (this.conf1.assigneeAtCreation === 'OPTIONAL') {
        this.isoptional = true;
        this.isassignee = false;
        this.isassigneeoptional = true;
      }
      // this.isassignee = true;
      // this.isassigneeoptional = true;
    }

    if (this.conf1.taskRecOption === 'M') {
      this.isrecipient = true;
      this.isrecipientoptional = false;
      this.isrecp = true;
    } else {
      this.isrecipient = false;
      this.isrecipientoptional = true;
      this.isrecp = false;
    }
  }

  assigneeCheckChanged() {
    if (this.IsAssigneeOpt === true) {
      this.isassignee = true;
    } else {
      this.isassignee = false;
      this.assignee = null;
      this.user = null;
    }
  }

  recipientCheckChanged() {
    if (this.IsRecipientOpt === true) {
      this.isrecp = true;
    } else {
      this.isrecp = false;
      this.clear();
      this.val = 'Selection';
      this.isselect = true;
      this.ismanual = false;
    }
  }

  recipientOption() {
    this.clear();
    if (this.val === 'Selection') {
      this.isselect = true;
      this.ismanual = false;
    } else if (this.val === 'Manual') {
      this.isselect = false;
      this.ismanual = true;
    }
  }

  recipientSelected() {
    this.isselected = true;
    if (this.contact.contact.contactType === 'Person') {
      this.recname = this.contact.contact.firstName + ' ' + this.contact.contact.lastName;
    } else {
      this.recname = this.contact.contact.name;
    }

    this.phones = [];
    const mphones = [];
    for (const val of this.contact.channelsInfo) {
      if (val.channelDef.channelTypeName === 'Phone') {
        if (val.channel.favoriteValue === 'Y') {
          this.phones.push(val.channel.channelValue);
        } else {
          mphones.push(val.channel.channelValue);
        }
      }
    }

    if (mphones.length > 0) {
      this.phones.push(mphones[0]);
    }
  }

  async groupSelected(): Promise<any> {
    await this.getContacts(this.contactgroup.groupId);
  }

  clear() {
    this.recname = null;
    this.recinfo = null;
    this.phones = [];
    this.contact = null;
    this.isselected = false;
    this.contactgroup = null;
    this.contacts = [];
  }

  async getContacts(groupid: number): Promise<any> {
    this.loading = true;

    this.contacts = [];
    const contacts = await this.contactService.getContacts(0, 0, groupid).toPromise()
    .catch(err => this.handleError(err));
    if (contacts !== undefined && contacts !== null && contacts.length > 0) {
      for (const value of contacts) {
        if (value.contact !== undefined && value.contact !== null && value.contact.length > 0) {
          for (const val of value.contact) {
            if (val.contact.contactType === 'Person') {
              this.contacts.push({ label: val.contact.firstName + ' ' + val.contact.lastName, value: val });
            } else {
              this.contacts.push({ label: val.contact.name, value: val });
            }
          }
        }
      }
    }

    this.loading = false;
  }

  async getContactGroups(): Promise<any> {
    this.loading = true;

    this.contactgroups = [];
    const cgroups = await this.cgroupService.getGroups(0, 0, 0, ' ', ' ').toPromise()
    .catch(err => this.handleError(err));

    if (cgroups !== undefined && cgroups !== null && cgroups.length > 0) {
      for (const val of cgroups) {
        this.contactgroups.push({ label: val.groupName, value: val });
      }
    }

    this.loading = false;
  }

  async onSend() {
    this.loading = true;
    this.isSend = true;

    if (this.conf1 === undefined || this.conf1 === null || this.conf1.value === null) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTTASKCONFIGURATION').subscribe((res: string) => {
        this.confirm(res);
      });

      this.loading = false;
      this.isSend = false;
    } else if (this.MessageTitle === undefined || this.MessageTitle == null ||
       this.MessageTitle.trim() === '' || this.MessageTitle === ' ') {
        // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.PROVIDEMESSAGETITLE').subscribe((res: string) => {
        this.confirm(res);
      });

      this.loading = false;
      this.isSend = false;
    } else if (this.MessageBody === undefined || this.MessageBody == null || this.MessageBody.trim() === '' || this.MessageBody === ' ') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.PROVIDEMESSAGEBODY').subscribe((res: string) => {
        this.confirm(res);
      });

      this.loading = false;
      this.isSend = false;
    } else if (this.Location != null && this.Location !== undefined && this.Location.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.LOCATIONHASONLYEMPTYSPACES').subscribe((res: string) => {
        this.confirm(res);
      });
      this.loading = false;
      this.isSend = false;
    } else if ((this.user == null || this.user === undefined) && this.conf1.assigneeAtCreation === 'MANDATORY') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTASSIGNEE').subscribe((res: string) => {
        this.confirm(res);
      });
      this.loading = false;
      this.isSend = false;
    } else {
      if (this.conf1.taskRecOption === 'M') {
        if (this.val === 'Selection') {
          if (this.contact === undefined || this.contact === null) {
           // tslint:disable-next-line: deprecation
           this.translate.get('GENERAL.PROVIDERECIPIENTDATA').subscribe((res: string) => {
             this.confirm(res);
           });
           this.loading = false;
           this.isSend = false;
           return;
         }
        } else if (this.val === 'Manual') {
          if ((this.recname == null || this.recname === undefined
            || this.phones === undefined || this.phones === null || this.phones.length === 0)) {
           // tslint:disable-next-line: deprecation
           this.translate.get('GENERAL.PROVIDERECIPIENTDATA').subscribe((res: string) => {
             this.confirm(res);
           });
           this.loading = false;
           this.isSend = false;
           return;
         }
        }
      }

      this.assign = false;
      if (this.conf1.assigneeAtCreation === 'MANDATORY') {
        this.assign = true;
      } else if (this.conf1.assigneeAtCreation === 'OPTIONAL' && this.user != null && this.user !== undefined) {
        this.assign = true;
      }

      const notif = new Notification();
      notif.NotificationId = '';
      notif.ConfigurationId = this.conf1.configurationId;
      notif.MessageTitle = this.MessageTitle;
      notif.MessageBody = this.MessageBody;
      notif.Location = this.Location;

      const v = new Variables();
      const val = localStorage.getItem('ui');
      const ui = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);
      notif.CreatedBy = +ui;

      if ((this.recname !== undefined && this.recname !== null && this.recname.trim() !== '')
       || (this.phones !== undefined && this.phones !== null && this.phones.length > 0)) {
        const rec = new Recipient();
        if (this.contact !== undefined && this.contact !== null) {
          rec.ContactId = this.contact.contact.contactId;
        }
        rec.RecipientName = this.recname;
        rec.RecipientInfo = this.recinfo;

        if (this.phones !== undefined && this.phones !== null && this.phones.length > 0) {
          if (this.phones.length === 1) {
            rec.Phone1 = this.phones[0];
          } else {
            rec.Phone1 = this.phones[0];
            rec.Phone2 = this.phones[1];
          }
        }

        notif.Recipient = rec;
      }

      const now = new Date();
      notif.Created = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours() + 1,
                                + now.getMinutes(), now.getSeconds());
      notif.Expires = new Date(notif.Created.getFullYear(), notif.Created.getMonth(), notif.Created.getDate(),
                            + notif.Created.getHours() + 5, notif.Created.getMinutes(), notif.Created.getSeconds());

      this.not = notif;

      if (this.conf1.queueSize === null || this.conf1.queueSize === 'null') {
        await this.create(notif);
        // this.iscreatetask = false;
      } else {
        await this.checkQueueSize(notif);
        // this.iscreatetask = false;
      }
    }
  }

  async create(notif) {
    const res = await this.notification.postNotification(notif).toPromise()
    .catch(err => this.handleError(err));

    if (res !== undefined && res !== null) {
      if (this.assign === true) {
        const values = new TaskUpdater();
        values.Id = res;
        values.Values = [];

        const id = new KeyValue();
        id.Key = 'id';
        id.Value = this.user.userId.toString();
        values.Values.push(id);

        const name = new KeyValue();
        name.Key = 'name';
        name.Value = this.user.firstName + ' ' + this.user.lastName;
        values.Values.push(name);

        const assigned = new KeyValue();
        assigned.Key = 'assigned';
        assigned.Value = '';
        values.Values.push(assigned);

    // tslint:disable-next-line: deprecation
        await this.tasksService.putTask(values.Id, values).subscribe((response) => {
          if (response === true) {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.NOTIFICATIONCREATED').subscribe((resp: string) => {
              this.notificationSvc.info('', resp, 2000);
            });
            // this.router.navigateByUrl('/tasks');
            // this.location.back();
          }
          this.notifications = [];
          this.page = 1;
          this.getNotifications2(this.page, this.size);
          this.isSend = false;
          this.loading = false;

          this.iscreatetask = false;
        }, err => {
          this.handleError(err);
        });
      } else {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.NOTIFICATIONCREATED').subscribe((resp: string) => {
          this.notificationSvc.info('', resp, 2000);
        });
        // this.router.navigateByUrl('/tasks');
        // this.location.back();
        this.notifications = [];
        this.page = 1;
        this.getNotifications2(this.page, this.size);
        this.isSend = false;
        this.loading = false;

        this.iscreatetask = false;
      }
    }
  }

  async checkQueueSize(notif) {
    let count = 0;
    let counter = 1;
    const qs = +this.conf1.queueSize;
    const res = await this.notification.getNotifications(0, 0, ' ', this.conf1.configurationId, 'T', ' ', ' ', 0, 0, ' ',
                                        0, ' ', ' ', ' ', ' ').toPromise()
    .catch(err => this.handleError(err));
    if (res != null) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < res.length; i++) {
        const resp = await this.tasksService.getTaskById(res[i].notificationId).toPromise()
        .catch(err => this.handleError(err));
        if (resp.notificationId !== null) {
          if (new Date(resp.closed).getFullYear() === 1) {
            count += 1;
          }

          if (res.length === counter) {
            await this.create2(count, qs, notif);
          }
          counter += 1;
        }
      }
    } else {
      if (qs === 0) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.QUEUELIMIT1').subscribe((res1: string) => {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.TQUEUELIMIT2').subscribe((res2: string) => {
            this.confirm(res1 + ' ' + 0 + ' ' + res2);
          });
        });
        this.isSend = false;
        this.loading = false;
      } else {
        await this.create(notif);
      }
    }
  }

  async create2(count, qs, notif) {
    if (count >= qs) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.QUEUELIMIT1').subscribe((res1: string) => {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.TQUEUELIMIT2').subscribe((res2: string) => {
          this.confirm(res1 + ' ' + count + ' ' + res2);
        });
      });
      this.isSend = false;
      this.loading = false;
    } else {
      await this.create(notif);
    }
  }

  onCancel2() {
    this.loading = false;
    this.isCancel = true;
    // this.router.navigateByUrl('/tasks');
    // this.location.back();
    this.iscreatetask = false;
    this.MessageTitle = null;
    this.MessageBody = null;
    this.Location = null;
    this.assignee = null;
    this.conf1 = null;
    this.clear();
  }

  async showUsers() {
    if (this.conf1 === undefined || this.conf1 === null) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTTASKCONFIGURATION').subscribe((res: string) => {
        this.confirm(res);
      });
    } else {
      this.loading = true;
      await this.getUsers2();
      this.loading = false;
    }
  }

  async getUsers2() {
    this.users = [];
    const us = [];
    let count = 0;
    // this.username = '';
    if (this.conf1.usrGrp != null && this.conf1.usrGrp.length > 0) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < this.conf1.usrGrp.length; i++) {
        if (this.conf1.usrGrp[i].idType === 'U') {
          if (!us.includes(this.conf1.usrGrp[i].name)) {
            this.users.push({ label: this.conf1.usrGrp[i].name, value: this.conf1.usrGrp[i] });
            us.push(this.conf1.usrGrp[i].name);
          }

          count += 1;
          if (count === this.conf1.usrGrp.length) {
            if (this.users.length > 0) {
              await this.showDialog2();
            } else {
              // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.NOUSERSFOUND').subscribe((resp) => {
                this.confirm(resp);
              });
            }
          }
        }
        if (this.conf1.usrGrp[i].idType === 'G') {
          const res = await this.usergroupService.getUserGroups(this.conf1.usrGrp[i].id).toPromise()
          .catch(err => this.handleError(err));

          let counter = 0;
          if (res != null) {
            // tslint:disable-next-line: prefer-for-of
            for (let j = 0; j < res.length; j++) {
              // tslint:disable-next-line: prefer-const
              let usr: any;
              res[j].id = res[j].userId;
              res[j].name = res[j].firstName + ' ' + res[j].lastName;
              res[j].idType = 'U';
              if (!us.includes(res[j].name)) {
                this.users.push({ label: res[j].name, value: res[j] });
                us.push(res[j].name);
              }

              counter += 1;
              if (counter === res.length) {
                count += 1;
                if (count === this.conf1.usrGrp.length) {
                  if (this.users.length > 0) {
                    await this.showDialog2();
                  } else {
                    // tslint:disable-next-line: deprecation
                    this.translate.get('GENERAL.NOUSERSFOUND').subscribe((resp) => {
                      this.confirm(resp);
                    });
                  }
                }
              }
            }
          } else {
            count += 1;
            if (count === this.conf1.usrGrp.length) {
              await this.showDialog2();
              if (this.users.length > 0) {
                await this.showDialog2();
              } else {
                // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.NOUSERSFOUND').subscribe((resp) => {
                this.confirm(resp);
              });
            }
            }
          }
        }
      }
    } else {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.NOUSERSFOUND').subscribe((res) => {
        this.confirm(res);
      });
    }
  }

  async showDialog2() {
    let count = 0;
    const us = [];
    if (this.users.length === 0) {
      this.display2 = true;
      // tslint:disable-next-line: deprecation
      this.translate.get('NOUSERS').subscribe((res) => {
        this.confirm(res);
      });
    } else {
      for (const usr of this.users) {
        const res = await this.deviceService.getDevices(1, 1, ' ', ' ', usr.value.id, usr.value.name).toPromise()
        .catch(err => this.handleError(err));
        if (res != null) {
          const dt = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(),
                                new Date().getHours(), new Date().getMinutes() - 3, new Date().getSeconds());
          const lb = new Date(res[0].lastHeartBeat);
          // const lhb = new Date(lb.getUTCFullYear(), lb.getUTCMonth(), lb.getUTCDate(),
          //                    lb.getUTCHours(), lb.getUTCMinutes(), lb.getUTCSeconds());
          const lhb = new Date(new Date(res[0].lastHeartBeat).toLocaleString() + ' UTC');
          if (lhb < dt) {
            usr.value.color = 'Red';
          } else {
            usr.value.color = 'Green';
          }
        } else {
          usr.value.color = 'Red';
        }
        us.push(usr);
        count += 1;

        if (this.users.length === count) {
          us.sort((a, b) => a.value.name.localeCompare(b.value.name));
          this.users = us;
          this.display2 = true;
        }
      }
    }
  }

  setUser(user) {
    this.assignee = user.firstName + ' ' + user.lastName;
    this.user = user;
    this.display2 = false;
  }

  async getUsers(notif) {
    this.loading = true;
    this.not = notif;
    this.users = [];
    const us = [];
    let count = 0;
    // this.username = '';
    if (notif.configuration.typeRoleGroups != null && notif.configuration.typeRoleGroups.length > 0) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < notif.configuration.typeRoleGroups.length; i++) {
        let res = null;
        if (notif.configuration.typeRoleGroups[i].typeRoleGroup.roleName === 'Expert-All' ||
        notif.configuration.typeRoleGroups[i].typeRoleGroup.roleName === 'Expert-Assigned' ||
        notif.configuration.typeRoleGroups[i].typeRoleGroup.roleName === 'Reader-All' ||
        notif.configuration.typeRoleGroups[i].typeRoleGroup.roleName === 'Reader-Own') {
          res = await this.usergroupService.getUserGroups(notif.configuration.typeRoleGroups[i].typeRoleGroup.groupId)
          .toPromise();
        }

        // const res = await this.usergroupService.getUserGroups(notif.configuration.typeRoleGroups[i].typeRoleGroup.groupId)
        // .toPromise();
        let counter = 0;
        if (res != null) {
          // tslint:disable-next-line: prefer-for-of
          for (let j = 0; j < res.length; j++) {
            // tslint:disable-next-line: prefer-const
            let usr: any;
            res[j].id = res[j].userId;
            res[j].name = res[j].firstName + ' ' + res[j].lastName;
            res[j].idType = 'U';
            if (!us.includes(res[j].name)) {
              this.users.push({ label: res[j].name, value: res[j] });
              us.push(res[j].name);
            }

            counter += 1;
            if (counter === res.length) {
              count += 1;
              if (count === notif.configuration.usrGrp.length) {
                if (this.users.length > 0) {
                  this.showDialog();
                } else {
                  // tslint:disable-next-line: deprecation
                  this.translate.get('GENERAL.NOUSERSFOUND').subscribe((resp) => {
                    this.confirm(resp);
                    this.loading = false;
                  });
                }
              }
            }
          }
        } else {
          count += 1;
          if (count === notif.configuration.typeRoleGroups.length) {
            this.showDialog();
            if (this.users.length > 0) {
              this.showDialog();
            } else {
              // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.NOUSERSFOUND').subscribe((resp) => {
              this.confirm(resp);
              this.loading = false;
            });
          }
          }
        }
      }
      this.showDialog();
      this.loading = false;
      /*if (this.users.length > 0) {
        this.showDialog();
      }
    } else {
      this.translate.get('GENERAL.NOUSERSFOUND').subscribe((resp) => {
        this.confirm(resp);
      });*/
    } else {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.NOUSERSFOUND').subscribe((res) => {
        this.confirm(res);
        this.loading = false;
      });
    }
  }

  getUsers22(notif) {
    this.loading = true;
    this.not = notif;
    this.users = [];
    const us = [];
    let count = 0;
    // this.username = '';
    if (notif.configuration.usrGrp != null && notif.configuration.usrGrp.length > 0) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < notif.configuration.usrGrp.length; i++) {
        if (notif.configuration.usrGrp[i].idType === 'U') {
          if (!us.includes(notif.configuration.usrGrp[i].name)) {
            this.users.push({ label: notif.configuration.usrGrp[i].name, value: notif.configuration.usrGrp[i] });
            us.push(notif.configuration.usrGrp[i].name);
          }

          count += 1;
          if (count === notif.configuration.usrGrp.length) {
            if (this.users.length > 0) {
              this.showDialog();
            } else {
              // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.NOUSERSFOUND').subscribe((resp) => {
                this.confirm(resp);
                this.loading = false;
              });
            }
          }
        }
        if (notif.configuration.usrGrp[i].idType === 'G') {
          // tslint:disable-next-line: deprecation
          this.usergroupService.getUserGroups(notif.configuration.usrGrp[i].id).subscribe((res) => {
            let counter = 0;
            if (res != null) {
              // tslint:disable-next-line: prefer-for-of
              for (let j = 0; j < res.length; j++) {
                // tslint:disable-next-line: prefer-const
                let usr: any;
                res[j].id = res[j].userId;
                res[j].name = res[j].firstName + ' ' + res[j].lastName;
                res[j].idType = 'U';
                if (!us.includes(res[j].name)) {
                  this.users.push({ label: res[j].name, value: res[j] });
                  us.push(res[j].name);
                }

                counter += 1;
                if (counter === res.length) {
                  count += 1;
                  if (count === notif.configuration.usrGrp.length) {
                    if (this.users.length > 0) {
                      this.showDialog();
                    } else {
                      // tslint:disable-next-line: deprecation
                      this.translate.get('GENERAL.NOUSERSFOUND').subscribe((resp) => {
                        this.confirm(resp);
                        this.loading = false;
                      });
                    }
                  }
                }
              }
            } else {
              count += 1;
              if (count === notif.configuration.usrGrp.length) {
                this.showDialog();
                if (this.users.length > 0) {
                  this.showDialog();
                } else {
                  // tslint:disable-next-line: deprecation
                this.translate.get('GENERAL.NOUSERSFOUND').subscribe((resp) => {
                  this.confirm(resp);
                  this.loading = false;
                });
              }
              }
            }
          });
        }
      }
      /*if (this.users.length > 0) {
        this.showDialog();
      }
    } else {
      this.translate.get('GENERAL.NOUSERSFOUND').subscribe((resp) => {
        this.confirm(resp);
      });*/
    } else {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.NOUSERSFOUND').subscribe((res) => {
        this.confirm(res);
        this.loading = false;
      });
    }
  }

  async handleError(err) {
    this.isLoad = false;

    this.isSend = false;
    this.loading = false;
    if (err.error !== undefined && err.error !== null) {
      if (err.error.Message === 'Reassign limit reached') {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.REASSIGNLIMITREACHED').subscribe((res) => {
          this.confirm(res);
        });
      }

      if (err.error.Message === 'Location has to be confirmed before assigning task') {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.LOCATIONHASTOBECONFIRMEDBEFOREASSIGNINGTASK').subscribe((res) => {
          this.confirm(res);
        });
      }

      if (err.error.Message === 'Location has to be confirmed before starting task') {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.LOCATIONHASTOBECONFIRMEDBEFORESTARTINGTASK').subscribe((res) => {
          this.confirm(res);
        });
      }

      if (err.error.Message === 'Location has to be confirmed before closing task') {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.LOCATIONHASTOBECONFIRMEDBEFORECLOSINGTASK').subscribe((res) => {
          this.confirm(res);
        });
      }

      if (err.error.Message === 'Location not set') {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.PROVIDELOCATION').subscribe((res: string) => {
          this.confirm(res);
        });
      }

      if (err.error.Message === 'Same location limit reached') {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.SAMELOCATIONLIMITREACHED').subscribe((res: string) => {
          this.confirm(res);
        });
      }

      if (err.error.Message === 'Queue size limit reached') {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.QUEUELIMITREACHED').subscribe((res: string) => {
          this.confirm(res);
        });
      }

      if (err.error.Message === 'Location not set') {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.PROVIDELOCATION').subscribe((res: string) => {
          this.confirm(res);
        });
      }

      if (err.error.Message === 'Same location limit reached') {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.SAMELOCATIONLIMITREACHED').subscribe((res: string) => {
          this.confirm(res);
        });
      }

      if (err.error.Message === 'Queue size limit reached') {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.QUEUELIMITREACHED').subscribe((res: string) => {
          this.confirm(res);
        });
      }

      if (err.error.MessageText === 'value too long for type character varying(100)') {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.MAXIMUMTASKTITLE').subscribe((res: string) => {
          this.confirm(res);
        });
      }

      if (err.error.Message === 'Task has to be classified before closing task') {
        await this.classifyTask(null, this.notval);
        // // tslint:disable-next-line: deprecation
        // this.translate.get('GENERAL.TASKNOTCLASSIFIED').subscribe((res: string) => {
        //   this.confirm(res);
        // });
      }
    }
  }

}
