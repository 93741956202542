import { ConfirmationService } from 'primeng/api';
import { ConfigurationService } from './../../services/configuration/configuration.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { CookieService } from 'ngx-cookie-service';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-configurations',
  templateUrl: './Configurations.component.html',
  styleUrls: ['./Configurations.component.css']
})
export class ConfigurationsComponent implements OnInit {

  configurations = [];

  loading = false;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private configService: ConfigurationService,
    private notificationSvc: NotificationService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService
  ) {
    // this.translate.addLangs(['English', 'French']);
    // this.translate.setDefaultLang('English');

    // let browserLang = this.translate.getBrowserLang();

    // const pass = 'ranitessarldev2019';
    // const lang = cookieService.get('goepla-portal-language');
    // if (lang.trim() !== '') {
    //   const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
    //   const lg = declang;
    //   browserLang = lg;
    // }

    // if (localStorage.getItem('language') !== null) {
    //   browserLang = localStorage.getItem('language');
    // }
    // this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    translate.use('English');

    this.getConfigurations();
   }

  ngOnInit() {
  }

  getConfigurations() {
    this.loading = true;
    this.configService.getConfigurations('I').subscribe((res) => {
      if (res != null) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          this.configurations.push(res[i]);
        }
      } else {
        this.translate.get('GENERAL.NOCONFIGURATIONS').subscribe((resp: string) => {
          this.notificationSvc.info('', resp, 3000);
        });
      }
      this.loading = false;
    });
  }

  gotoCreateConfiguration() {
    this.router.navigateByUrl('/createconfiguration');
  }

  saveConfig(config) {
    localStorage.setItem('config', JSON.stringify(config));
    this.router.navigateByUrl('/editconfiguration');
  }

  deleteConfig(config) {
    this.configService.deleteConfiguration(config.configurationId).subscribe((res) => {
      if (res === true) {
        this.translate.get('GENERAL.CONFIGURATIONDELETED').subscribe((resp: string) => {
          this.notificationSvc.info('', resp, 3000);
        });
        this.configurations = [];
        this.getConfigurations();
      } else {
        this.translate.get('GENERAL.COULDNOTDELETECONFIGURATION').subscribe((resp: string) => {
          this.notificationSvc.info('', resp, 3000);
        });
      }
    }, err => {
      this.handleError(err);
    });
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  handleError(err) {
    // tslint:disable-next-line: max-line-length
    if (err.error.MessageText === 'update or delete on table "t_ntm_ncfg" violates foreign key constraint "t_ntm_not_ncfg_id_fkey" on table "t_ntm_not"') {
      this.translate.get('GENERAL.NOTIFICATIONDEPENDS').subscribe((res) => {
        this.confirm(res);
      });
    }
    this.loading = false;
  }

}
