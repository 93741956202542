import { TypeRoleGroup } from './../../Models/role';
import { CategoryService } from './../../services/category/category.service';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { ConfirmationService } from 'primeng/api';
import { Configuration } from 'src/app/Models/Configuration';
import { UsrGrp } from 'src/app/Models/UsrGrp';
import { UsersgroupsService } from 'src/app/services/usersgroups/usersgroups.service';
import { CookieService } from 'ngx-cookie-service';
import { Location } from '@angular/common';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-create-task-configuration',
  templateUrl: './create-task-configuration.component.html',
  styleUrls: ['./create-task-configuration.component.css']
})
export class CreateTaskConfigurationComponent implements OnInit {
// users = new FormControl();
userList = [];
users: any;

// groups = new FormControl();
groupList = [];
groups: any;

organisations = [];
organisation: any;

types = [];
type = 'T';

priorities = [];
priority: any;

vibrations = [];
vibration: any;

channels = [];
channel: any = 'M';

Organisation = 0;
MessageType: string;
ConfigurationName: string;
ConfigurationType: string;
Priority: number;
TimeUntilExpiry: number;
Color: string;
Vibration: number;
Channel: string;
TaskReactionTimeLimit: number;
TaskStartTimeLimit: number;
TaskClosureTimeLimit: number;
QueueSize: any;
Workflow: string;

workflows = [];

assigneeoptions = [];
assigneeoption: any;

recoptions = [];
recoption: any;

usrgrp: UsrGrp[] = [];

isTask = true;

isCreate = false;
loading = false;

OrgName: string;

sensitivity = [];
Sensitive: any;

MaxSize: number;

policyloc = [];
PolicyLocation: any;

StatusUpdateList = [];
StatusUpdate: any;
issense = false;

cats = [];
cat: any;

rogroups1 = [];
rogroups2 = [];

ragroups1 = [];
ragroups2 = [];

ingroups1 = [];
ingroups2 = [];

ealgroups1 = [];
ealgroups2 = [];

easgroups1 = [];
easgroups2 = [];

angroups1 = [];
angroups2 = [];

adgroups1 = [];
adgroups2 = [];

constructor(
  private router: Router,
  public translate: TranslateService,
  private configService: ConfigurationService,
  private notificationSvc: NotificationService,
  private usersgroupsService: UsersgroupsService,
  private confirmationService: ConfirmationService,
  private cookieService: CookieService,
  private location: Location,
  private catService: CategoryService
) {
  this.Sensitive = 'NO';
  // this.translate.addLangs(['English', 'French']);
  // this.translate.setDefaultLang('English');

  // let browserLang = this.translate.getBrowserLang();

  // const pass = 'ranitessarldev2019';
  // const lang = cookieService.get('goepla-portal-language');
  // if (lang.trim() !== '') {
  //   const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
  //   const lg = declang;
  //   browserLang = lg;
  // }

  // if (localStorage.getItem('language') !== null) {
  //   browserLang = localStorage.getItem('language');
  // }
  // this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

  translate.use('English');

  // tslint:disable-next-line: deprecation
  translate.get('GENERAL.YES').subscribe((res1) => {
    this.sensitivity.push({label: res1, value: 'YES'});
    // tslint:disable-next-line: deprecation
    translate.get('GENERAL.NO').subscribe((res2) => {
      this.sensitivity.push({label: res2, value: 'NO'});
    });
  });

  // tslint:disable-next-line: deprecation
  translate.get('GENERAL.ALLTASKSTHATHAVETHATLOCATIONSET').subscribe((res1) => {
    this.policyloc.push({label: res1, value: 1});
    // tslint:disable-next-line: deprecation
    translate.get('GENERAL.ONLYFORTHELATESTTASK').subscribe((res2) => {
      this.policyloc.push({label: res2, value: 2});
      // tslint:disable-next-line: deprecation
      translate.get('GENERAL.ONLYFORTHEOLDESTTASK').subscribe((res3) => {
        this.policyloc.push({label: res3, value: 3});
      });
    });
  });

    // tslint:disable-next-line: deprecation
  translate.get('GENERAL.ASSIGNONLYIFLOCATIONISSET').subscribe((res1) => {
    this.StatusUpdateList.push({label: res1, value: 1});
    // tslint:disable-next-line: deprecation
    translate.get('GENERAL.STARTONLYIFLOCATIONISSET').subscribe((res2) => {
      this.StatusUpdateList.push({label: res2, value: 2});
      // tslint:disable-next-line: deprecation
      translate.get('GENERAL.CLOSEONLYIFLOCATIONISSET').subscribe((res3) => {
        this.StatusUpdateList.push({label: res3, value: 3});
      });
    });
  });

    // tslint:disable-next-line: deprecation
  translate.get('GENERAL.INFORMATION').subscribe((res1) => {
    this.types.push({label: res1, value: 'I'});
    // tslint:disable-next-line: deprecation
    translate.get('GENERAL.TASK').subscribe((res2) => {
      this.types.push({label: res2, value: 'T'});
    });
  });

    // tslint:disable-next-line: deprecation
  translate.get('GENERAL.CRITICAL').subscribe((res1) => {
    this.priorities.push({label: res1, value: 1});
    // tslint:disable-next-line: deprecation
    translate.get('GENERAL.HIGH').subscribe((res2) => {
      this.priorities.push({label: res2, value: 2});
      // tslint:disable-next-line: deprecation
      translate.get('GENERAL.NORMAL').subscribe((res3) => {
        this.priorities.push({label: res3, value: 3});
        // tslint:disable-next-line: deprecation
        translate.get('GENERAL.LOW').subscribe((res4) => {
          this.priorities.push({label: res4, value: 4});
          // tslint:disable-next-line: deprecation
          translate.get('GENERAL.VERYLOW').subscribe((res5) => {
            this.priorities.push({label: res5, value: 5});
          });
        });
      });
    });
  });

    // tslint:disable-next-line: deprecation
  translate.get('GENERAL.NONE').subscribe((res1) => {
    this.vibrations.push({label: res1, value: 0});
    // tslint:disable-next-line: deprecation
    translate.get('GENERAL.SHORT').subscribe((res2) => {
      this.vibrations.push({label: res2, value: 1});
      // tslint:disable-next-line: deprecation
      translate.get('GENERAL.LONG').subscribe((res3) => {
        this.vibrations.push({label: res3, value: 2});
        // tslint:disable-next-line: deprecation
        translate.get('GENERAL.VERYLONG').subscribe((res4) => {
          this.vibrations.push({label: res4, value: 3});
        });
      });
    });
  });

    // tslint:disable-next-line: deprecation
  translate.get('GENERAL.MQTT').subscribe((res1) => {
    this.channels.push({label: res1, value: 'M'});
    // tslint:disable-next-line: deprecation
    translate.get('GENERAL.SMS').subscribe((res2) => {
      this.channels.push({label: res2, value: 'S'});
      // tslint:disable-next-line: deprecation
      translate.get('GENERAL.EMAIL').subscribe((res3) => {
        this.channels.push({label: res3, value: 'E'});
      });
    });
  });

      // tslint:disable-next-line: deprecation
  translate.get('GENERAL.OASC').subscribe((oasc) => {
    // tslint:disable-next-line: deprecation
    translate.get('GENERAL.OSC').subscribe((osc) => {
      // tslint:disable-next-line: deprecation
      translate.get('GENERAL.OC').subscribe((oc) => {
        this.workflows.push({label: oasc, value: 'OASC' });
        this.workflows.push({label: osc, value: 'OSC' });
        this.workflows.push({label: oc, value: 'OC' });
      });
    });
  });

  // tslint:disable-next-line: deprecation
  translate.get('GENERAL.MANDATORY').subscribe((mand) => {
    // tslint:disable-next-line: deprecation
    translate.get('GENERAL.OPTIONAL').subscribe((opt) => {
      // tslint:disable-next-line: deprecation
      translate.get('GENERAL.NOTALLOWED').subscribe((notallw) => {
        this.assigneeoptions.push({label: mand, value: 'MANDATORY' });
        this.assigneeoptions.push({label: opt, value: 'OPTIONAL' });
        this.assigneeoptions.push({label: notallw, value: 'NOTALLOWED' });
      });
    });
  });

  // tslint:disable-next-line: deprecation
  translate.get('GENERAL.MANDATORY').subscribe((mand) => {
    // tslint:disable-next-line: deprecation
    translate.get('GENERAL.OPTIONAL').subscribe((opt) => {
      this.recoptions.push({label: mand, value: 'M' });
      this.recoptions.push({label: opt, value: 'O' });
    });
  });

 }

async ngOnInit() {
  // this.getUsers();
  this.getGroups();
  this.getOrganisation();
  await this.getCategories();
}

getUsers() {
  // tslint:disable-next-line: deprecation
  this.usersgroupsService.getUsers().subscribe((res) => {
    if (res != null) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < res.length; i++) {
        res[i].UserName = res[i].firstName + ' ' + res[i].lastName;
        this.userList.push({label: res[i].UserName, value: res[i]});
      }
    }
  });
}

getGroups() {
  this.loading = true;
  // tslint:disable-next-line: deprecation
  this.usersgroupsService.getGroups().subscribe((res) => {
    if (res != null) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < res.length; i++) {
        res[i].GroupName = res[i].groupName;
        this.groupList.push({label: res[i].GroupName, value: res[i]});

        this.rogroups1.push(res[i]);
        this.ragroups1.push(res[i]);
        this.ingroups1.push(res[i]);
        this.ealgroups1.push(res[i]);
        this.easgroups1.push(res[i]);
        this.angroups1.push(res[i]);
        this.adgroups1.push(res[i]);
      }
    }
    this.loading = false;
  });
}

getOrganisation() {
  this.loading = true;
  // tslint:disable-next-line: deprecation
  this.usersgroupsService.getOrganisations().subscribe((res) => {
    if (res != null) {
      this.organisation = res;
      this.OrgName = res.organisationName;
      // tslint:disable-next-line: prefer-for-of
      /*for (let i = 0; i < res.length; i++) {
        this.organisations.push({label: res[i].organisationName, value: res[i]});
      }*/
    }
    this.loading = false;
  });
}

async getCategories() {
  this.loading = true;
  const none = await this.translate.get('GENERAL.NONE').toPromise();
  this.cats.push({label: none, value: null});
  // tslint:disable-next-line: deprecation
  const res = await this.catService.getCategories(0, 0, 0, ' ').toPromise()
  .catch(err => console.log(err));
  if (res != null) {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < res.length; i++) {
      this.cats.push({label: res[i].categoryName, value: res[i]});
    }
  }
  this.cat = null;
  this.loading = false;
}

selectedMessageType() {
  if (this.type === 'I') {
    this.isTask = false;
  } else if (this.type === 'T') {
    this.isTask = true;
  }
}

selectedPriority(event) {

}

selectedColor(event) {

}

selectedVibration(event) {

}

selectedChannel(event) {

}

show() {
  if (this.Sensitive === 'YES') {
    this.issense = true;
  } else {
    this.issense = false;
  }
}

onCreate() {
  let su = '';
  if (this.organisation === undefined || this.organisation === null) {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.SELECTORGANISATION').subscribe((res: string) => {
      this.confirm(res);
    });
    return;
  }
  if (this.type === undefined || this.type === null || this.type.toString().trim() === null || this.type.toString().trim() === '') {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.SELECTMESSAGETYPE').subscribe((res: string) => {
      this.confirm(res);
    });
    return;
  }
  if (this.ConfigurationName === undefined || this.ConfigurationName.toString().trim() === null
      || this.ConfigurationName.toString().trim() === ''
      || this.ConfigurationName.toString().trim() === ' ') {
        // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.ENTERCONFIGURATIONNAME').subscribe((res: string) => {
      this.confirm(res);
    });
    return;
  }
  if (this.priority === undefined || this.priority.toString().trim() === null) {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.SELECTPRIORITY').subscribe((res: string) => {
      this.confirm(res);
    });
    return;
  }
  if (this.TimeUntilExpiry === undefined || this.TimeUntilExpiry.toString().trim() === null
      || this.TimeUntilExpiry.toString().trim() === '') {
        // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.ENTERTIMEUNTILEXPIRY').subscribe((res: string) => {
      this.confirm(res);
    });
    return;
  } else {
    if (isNaN(this.TimeUntilExpiry)) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.TIMEUNTILEXPIRYCHECK').subscribe((res: string) => {
        this.confirm(res);
      });
      return;
    }
  }
  if (this.Color === undefined || this.Color.toString().trim() === null) {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.SELECTCOLOR').subscribe((res: string) => {
      this.confirm(res);
    });
    return;
  }
  if (this.vibration === undefined || this.vibration.toString().trim() === null) {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.SELECTVIBRATEOPTION').subscribe((res: string) => {
      this.confirm(res);
    });
    return;
  }
  if (this.channel === undefined || this.channel.toString().trim() === null) {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.SELECTCHANNEL').subscribe((res: string) => {
      this.confirm(res);
    });
    return;
  }
  if (this.isTask === true) {
    if (this.TaskReactionTimeLimit === undefined || this.TaskReactionTimeLimit.toString().trim() === null
        || this.TaskReactionTimeLimit.toString().trim() === '') {
          // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTERTASKREACTIONTIMELIMIT').subscribe((res: string) => {
        this.confirm(res);
      });
      return;
    } else {
      if (isNaN(this.TaskReactionTimeLimit)) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.TASKREACTIONTIMELIMITCHECK').subscribe((res: string) => {
          this.confirm(res);
        });
        return;
      }
    }
    if (this.TaskStartTimeLimit === undefined || this.TaskStartTimeLimit.toString().trim() === null
        || this.TaskStartTimeLimit.toString().trim() === '') {
          // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTERTASKSTARTTIMELIMIT').subscribe((res: string) => {
        this.confirm(res);
      });
      return;
    } else {
      if (isNaN(this.TaskStartTimeLimit)) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.TASKSTARTTIMELIMITCHECK').subscribe((res: string) => {
          this.confirm(res);
        });
        return;
      }
    }
    if (this.TaskClosureTimeLimit === undefined || this.TaskClosureTimeLimit.toString().trim() === null
        || this.TaskClosureTimeLimit.toString().trim() === '') {
          // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTERTASKCLOSURETIMELIMIT').subscribe((res: string) => {
        this.confirm(res);
      });
      return;
    } else {
      if (isNaN(this.TaskClosureTimeLimit)) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.TASKCLOSURELIMITCHECK').subscribe((res: string) => {
          this.confirm(res);
        });
        return;
      }
    }
    if (this.QueueSize === undefined || this.QueueSize === null
        || this.QueueSize.toString().trim() === '') {
      this.QueueSize = 'null';
    } else {
      if (isNaN(this.QueueSize)) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.QUEUESIZENOTNUMBER').subscribe((res: string) => {
          this.confirm(res);
        });
        return;
      }
    }
  } else {
    this.TaskReactionTimeLimit = 0;
    this.TaskStartTimeLimit = 0;
    this.TaskClosureTimeLimit = 0;
  }

  if (this.Sensitive === 'YES') {
    if (this.MaxSize === undefined || this.MaxSize === null || this.MaxSize.toString().trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTERMAXSIZE').subscribe((res: string) => {
        this.confirm(res);
      });
      if (this.QueueSize === 'null') {
        this.QueueSize = null;
      }
      return;
    } else {
      if (isNaN(this.MaxSize)) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.MAXSIZENOTNUMBER').subscribe((res: string) => {
          this.confirm(res);
        });
        if (this.QueueSize === 'null') {
          this.QueueSize = null;
        }
        return;
      }
    }

    if (this.PolicyLocation === undefined || this.PolicyLocation === null) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTPOLICYLOCATION').subscribe((res: string) => {
        this.confirm(res);
      });
      if (this.QueueSize === 'null') {
        this.QueueSize = null;
      }
      return;
    }

    if (this.StatusUpdate === undefined || this.StatusUpdate === null || this.StatusUpdate.length === 0) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTSTATUSUPDATE').subscribe((res: string) => {
        this.confirm(res);
      });
      if (this.QueueSize === 'null') {
        this.QueueSize = null;
      }
      return;
    } else {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < this.StatusUpdate.length; i++) {
        if (this.StatusUpdate.length === 1) {
          su = this.StatusUpdate[i].toString();
        } else if (this.StatusUpdate.length === 2) {
          su = this.StatusUpdate[0].toString() + ';' + this.StatusUpdate[1].toString();
        }  else if (this.StatusUpdate.length === 3) {
          su = this.StatusUpdate[0].toString() + ';' + this.StatusUpdate[1].toString() + ';' + this.StatusUpdate[2].toString();
        }

        if (su.includes('1')) {
          if (this.assigneeoption === 'MANDATORY' || this.assigneeoption === 'OPTIONAL') {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.ASSIGNEEOPTIONNOTCOMPATIBLE').subscribe((res: string) => {
              this.confirm(res);
            });
            if (this.QueueSize === 'null') {
              this.QueueSize = null;
            }
            return;
          }
        }
      }
    }
  } else {
    this.Sensitive = 'NO';
    this.MaxSize = undefined;
    this.StatusUpdate = undefined;
    this.PolicyLocation = undefined;
  }

  if (this.Workflow === undefined || this.Workflow === null) {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.SELECTWORKFLOW').subscribe((res: string) => {
      this.confirm(res);
    });
    if (this.QueueSize === 'null') {
      this.QueueSize = null;
    }
    return;
  }

  if (this.assigneeoption === undefined || this.assigneeoption === null
    || this.assigneeoption.toString().trim() === '') {
      // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.SELECTASSIGNEEATCREATIONOPTION').subscribe((res: string) => {
      this.confirm(res);
    });
    if (this.QueueSize === 'null') {
      this.QueueSize = null;
    }
    return;
  }

  if (this.Workflow !== 'OASC' && (this.assigneeoption === 'MANDATORY' || this.assigneeoption === 'OPTIONAL')) {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.WORKFLOWNOTCOMPATIBLEWITHASSIGNEEOPTION').subscribe((res: string) => {
      this.confirm(res);
    });
    if (this.QueueSize === 'null') {
      this.QueueSize = null;
    }
    return;
  }

  if (this.recoption === undefined || this.recoption === null
    || this.recoption.toString().trim() === '') {
      // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.SELECTRECOPTION').subscribe((res: string) => {
      this.confirm(res);
    });
    if (this.QueueSize === 'null') {
      this.QueueSize = null;
    }
    return;
  }

  this.usrgrp = [];
  if (this.users !== undefined || this.users != null) {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.users.length; i++) {
      const usr = new UsrGrp();
      usr.Id = this.users[i].userId;
      usr.IdType = 'U';
      usr.Name = this.users[i].UserName;
      this.usrgrp.push(usr);
    }
  }

  if (this.groups !== undefined || this.groups != null) {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.groups.length; i++) {
      const grp = new UsrGrp();
      grp.Id = this.groups[i].groupId;
      grp.IdType = 'G';
      grp.Name = this.groups[i].GroupName;
      this.usrgrp.push(grp);
    }
  }

  if (this.TimeUntilExpiry.toString().includes('.') || this.TaskReactionTimeLimit.toString().includes('.') ||
        this.TaskStartTimeLimit.toString().includes('.') || this.TaskClosureTimeLimit.toString().includes('.')) {
          // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.NOPOINTS').subscribe((res: string) => {
        this.confirm(res);
      });
      return;
    }

  this.isCreate = true;
  this.loading = true;

  const config = new Configuration();
  config.OrganisationId = this.organisation.organisationId;
  config.ConfigurationId = 0;
  config.ConfigurationName = this.ConfigurationName;
  config.ConfigurationType = this.type;
  config.Priority = this.priority;
  config.ExpiryTime = this.TimeUntilExpiry;
  config.Color = this.Color;
  config.Vibrate = this.vibration;
  config.Channel = this.channel;
  config.ReactionTimeLimit = this.TaskReactionTimeLimit;
  config.StartTimeLimit = this.TaskStartTimeLimit;
  config.CloseTimeLimit = this.TaskClosureTimeLimit;
  config.QueueSize = this.QueueSize.toString();
  config.LocationSensitive = this.Sensitive;
  config.SameLocationMax = this.MaxSize;
  config.PolicyLocationConfirmation = this.PolicyLocation;
  config.PolicyStatusUpdate = su;
  config.Workflow = this.Workflow;
  config.AssigneeAtCreation = this.assigneeoption;
  config.TaskRecOption = this.recoption;
  config.UsrGrp = this.usrgrp;

  if (this.cat !== undefined && this.cat !== null) {
    config.CategoryId = this.cat.categoryId;
  } else {
    config.CategoryId = 0;
  }

  const rgroups = [];
  if (this.ragroups2 !== null && this.ragroups2.length > 0) {
    for (const val of this.ragroups2) {
      const rgroup = new TypeRoleGroup();
      rgroup.GroupId = val.groupId;
      rgroup.RoleName = 'Reader-All';
      rgroup.RoleParentType = 'task_type';

      rgroups.push(rgroup);
    }
  }
  if (this.rogroups2 !== null && this.rogroups2.length > 0) {
    for (const val of this.rogroups2) {
      const rgroup = new TypeRoleGroup();
      rgroup.GroupId = val.groupId;
      rgroup.RoleName = 'Reader-Own';
      rgroup.RoleParentType = 'task_type';

      rgroups.push(rgroup);
    }
  }
  if (this.ingroups2 !== null && this.ingroups2.length > 0) {
    for (const val of this.ingroups2) {
      const rgroup = new TypeRoleGroup();
      rgroup.GroupId = val.groupId;
      rgroup.RoleName = 'Initiator';
      rgroup.RoleParentType = 'task_type';

      rgroups.push(rgroup);
    }
  }
  if (this.ealgroups2 !== null && this.ealgroups2.length > 0) {
    for (const val of this.ealgroups2) {
      const rgroup = new TypeRoleGroup();
      rgroup.GroupId = val.groupId;
      rgroup.RoleName = 'Expert-All';
      rgroup.RoleParentType = 'task_type';

      rgroups.push(rgroup);
    }
  }
  if (this.easgroups2 !== null && this.easgroups2.length > 0) {
    for (const val of this.easgroups2) {
      const rgroup = new TypeRoleGroup();
      rgroup.GroupId = val.groupId;
      rgroup.RoleName = 'Expert-Assigned';
      rgroup.RoleParentType = 'task_type';

      rgroups.push(rgroup);
    }
  }
  if (this.angroups2 !== null && this.angroups2.length > 0) {
    for (const val of this.angroups2) {
      const rgroup = new TypeRoleGroup();
      rgroup.GroupId = val.groupId;
      rgroup.RoleName = 'Analytics';
      rgroup.RoleParentType = 'task_type';

      rgroups.push(rgroup);
    }
  }
  if (this.adgroups2 !== null && this.adgroups2.length > 0) {
    for (const val of this.adgroups2) {
      const rgroup = new TypeRoleGroup();
      rgroup.GroupId = val.groupId;
      rgroup.RoleName = 'Administrator';
      rgroup.RoleParentType = 'task_type';

      rgroups.push(rgroup);
    }
  }

  config.RoleGroups = rgroups;

  // tslint:disable-next-line: deprecation
  this.configService.postConfiguration(config).subscribe(() => {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.CONFIGURATIONCREATED').subscribe((res: string) => {
      this.notificationSvc.info('', res, 2000);
    });
    // this.router.navigateByUrl('/taskconfigurations');
    this.location.back();
    this.isCreate = false;
    this.loading = false;
  }, err => {
    this.handleError(err);
  });
}

onCancel() {
  // this.router.navigateByUrl('/taskconfigurations');
  this.location.back();
}

selectedOrganisation(event) {
}

confirm(msg) {
  this.confirmationService.confirm({
      message: msg,
      accept: () => {
          // Actual logic to perform a confirmation
      }
  });
}

handleError(err) {
  this.isCreate = false;
  this.loading = false;
  if (err.error.MessageText === 'duplicate key value violates unique constraint "t_ntm_ncfg_org_id_ncfg_name_key"') {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.CONFIGURATIONALREADYEXISTS').subscribe((res: string) => {
      this.confirm(res);
    });
  }

  if (err.error.Message === 'Input string was not in a correct format.') {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.NOPOINTS').subscribe((res: string) => {
      this.confirm(res);
    });
  }

  if (this.QueueSize === null || this.QueueSize === 'null') {
    this.QueueSize = null;
  }
}

}
