import { KeyValue } from 'src/app/Models/Updater';
import { MonitorUpdater } from './../../Models/Updater';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { MonitorsService } from 'src/app/services/monitors/monitors.service';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { CookieService } from 'ngx-cookie-service';
import { AppComponent } from 'src/app/app.component';
import { Monitor } from 'src/app/Models/Monitor';
import { UsersgroupsService } from 'src/app/services/usersgroups/usersgroups.service';
import { TypeRoleGroup, TypeRoleGroupData } from 'src/app/Models/role';

@Component({
  selector: 'app-edit-monitor-configuration',
  templateUrl: './edit-monitor-configuration.component.html',
  styleUrls: ['./edit-monitor-configuration.component.css']
})
export class EditMonitorConfigurationComponent implements OnInit {

  loading: boolean;

  MonitorId: number;
  MonitorName: any;
  MonitorDescription: string;
  MaxSize: number;

  displays = [];
  display: any;

  mon: any;

  gs = [];

  ragroups1 = [];
  ragroups2 = [];

  adgroups1 = [];
  adgroups2 = [];

  constructor(
    private router: Router,
    private notificationSvc: NotificationService,
    public translate: TranslateService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private location: Location,
    private appComp: AppComponent,
    private monitorService: MonitorsService,
    private usersgroupsService: UsersgroupsService
  ) {
    // this.translate.addLangs(['English', 'French']);
    // this.translate.setDefaultLang('English');

    // let browserLang = this.translate.getBrowserLang();

    // const pass = 'ranitessarldev2019';
    // const lang = cookieService.get('goepla-portal-language');
    // if (lang.trim() !== '') {
    //   const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
    //   const lg = declang;
    //   browserLang = lg;
    // }

    // if (localStorage.getItem('language') !== null) {
    //   browserLang = localStorage.getItem('language');
    // }
    // this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    translate.use('English');

    const mon = JSON.parse(localStorage.getItem('monitor'));
    this.mon = mon;
    this.MonitorId = mon.monitorId;
    this.MonitorName = mon.monitorName;
    this.MaxSize = mon.maxSize;
    this.MonitorDescription = mon.monitorDescription;
    this.display = mon.display;
  }

  ngOnInit() {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.FIFO').subscribe((res: string) => {
      this.displays.push({label: res, value: 'FIFO'});
    });
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.LIFO').subscribe((res: string) => {
      this.displays.push({label: res, value: 'LIFO'});
    });

    this.getGroups();
  }

  getGroups() {
    this.loading = true;
    // tslint:disable-next-line: deprecation
    this.usersgroupsService.getGroups().subscribe((res) => {
      if (res != null) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          res[i].GroupName = res[i].groupName;
          // this.groupList.push({label: res[i].GroupName, value: res[i]});
          this.gs.push(res[i]);
        }

        this.arrangeRoles();
      }
      this.loading = false;
    });
  }

  arrangeRoles() {
    if (this.gs !== null && this.gs.length > 0) {
      if (this.mon.typeRoleGroups !== null && this.mon.typeRoleGroups.length > 0) {
        for (const v of this.gs) {
          let ra = true;
          let ad = true;
          for (const val of this.mon.typeRoleGroups) {
            if (val.typeRoleGroup.roleName === 'Reader-All') {
              if (v.groupId === val.typeRoleGroup.groupId) {
                this.ragroups2.push(v);
                ra = false;
              }
            }
            if (val.typeRoleGroup.roleName === 'Administrator') {
              if (v.groupId === val.typeRoleGroup.groupId) {
                this.adgroups2.push(v);
                ad = false;
              }
            }
          }

          if (ra === true) {
            this.ragroups1.push(v);
          }
          if (ad === true) {
            this.adgroups1.push(v);
          }
        }
      } else {
        for (const val of this.gs) {
          this.ragroups1.push(val);
          this.adgroups1.push(val);
        }
      }
    }
  }

  onSave() {
    this.loading = true;

    if (this.MonitorName === undefined || this.MonitorName == null || this.MonitorName.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.PROVIDEMONITORNAME').subscribe((res: string) => {
        this.confirm(res);
      });

      this.loading = false;
    } else if (this.MonitorDescription === undefined || this.MonitorDescription == null || this.MonitorDescription.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.PROVIDEMONITORDESCRIPTION').subscribe((res: string) => {
        this.confirm(res);
      });

      this.loading = false;
    } else {
      if (this.MaxSize === undefined || this.MaxSize == null || this.MaxSize.toString().trim() === '') {
        this.MaxSize = 100;
      }
      if (isNaN(this.MaxSize)) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.MAXSIZENOTNUMBER').subscribe((res: string) => {
          this.confirm(res);
        });
        this.loading = false;
      } else if (this.MaxSize > 100 || this.MaxSize < 0) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.MAXIMUMSIZEEXCEEDED').subscribe((res: string) => {
          this.confirm(res);
        });
        this.loading = false;
      } else if (this.display === undefined || this.display === null) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.SELECTDISPLAY').subscribe((res: string) => {
          this.confirm(res);
        });
        this.loading = false;
      } else {
        const monitor = new MonitorUpdater();
        monitor.Id = this.MonitorId;
        monitor.Values = [];

        const mname = new KeyValue();
        mname.Key = 'm_name';
        mname.Value = this.MonitorName;
        monitor.Values.push(mname);

        const mdesc = new KeyValue();
        mdesc.Key = 'm_desc';
        mdesc.Value = this.MonitorDescription;
        monitor.Values.push(mdesc);

        const msize = new KeyValue();
        msize.Key = 'm_size';
        msize.Value = this.MaxSize.toString();
        monitor.Values.push(msize);

        const display = new KeyValue();
        display.Key = 'display';
        display.Value = this.display.toString();
        monitor.Values.push(display);

        const rgroups = [];
        if (this.ragroups2 !== null && this.ragroups2.length > 0) {
          for (const val of this.ragroups2) {
            const rgroup = new TypeRoleGroup();
            rgroup.GroupId = val.groupId;
            rgroup.ParentId = this.mon.monitorId;
            rgroup.RoleName = 'Reader-All';
            rgroup.RoleParentType = 'monitor_type';

            rgroups.push(rgroup);
          }
        }
        if (this.adgroups2 !== null && this.adgroups2.length > 0) {
          for (const val of this.adgroups2) {
            const rgroup = new TypeRoleGroup();
            rgroup.GroupId = val.groupId;
            rgroup.ParentId = this.mon.monitorId;
            rgroup.RoleName = 'Administrator';
            rgroup.RoleParentType = 'monitor_type';

            rgroups.push(rgroup);
          }
        }

        const data = new TypeRoleGroupData();
        data.ParentId = this.mon.monitorId;
        data.RoleParentType = 'monitor_type';
        data.TypeRoleGroup = rgroups;

        const rg = new KeyValue();
        rg.Key = 'role_groups';
        rg.Value = JSON.stringify(data);
        monitor.Values.push(rg);

    // tslint:disable-next-line: deprecation
        this.monitorService.putMonitor(monitor.Id, monitor).subscribe(async (res) => {
          await this.appComp.loadMenuAgain();
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.MONITORCONFIGURATIONUPDATED').subscribe((resp: string) => {
            this.notificationSvc.info('', resp, 2000);
          });
          this.location.back();
          this.loading = false;
        }, err => this.handleError(err));
      }
    }
  }

  onCancel() {
    this.location.back();
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  handleError(err) {
    this.loading = false;
    if (err.error.MessageText === 'duplicate key value violates unique constraint "t_ntm_monitor_monitor_name_key"') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.MONITORALREADYEXISTS').subscribe((res: string) => {
        this.confirm(res);
      });
    }

    if (err.error.MessageText === 'new row for relation "t_ntm_monitor" violates check constraint "valid_size"') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.MAXIMUMSIZEEXCEEDED').subscribe((res: string) => {
        this.confirm(res);
      });
    }
  }

}
