import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { MessageService, ConfirmationService } from 'primeng/api';
import { NotificationService } from 'src/app/services/notification/notification.service';
import * as CryptoJS from 'crypto-js';
import { Variables } from 'src/app/Models/variables';

@Component({
  selector: 'app-time-to-closure-values',
  templateUrl: './time-to-closure-values.component.html',
  styleUrls: ['./time-to-closure-values.component.css']
})
export class TimeToClosureValuesComponent implements OnInit {

  data: any;
  configs1 = [];
  conf1: any;

  range: any;
  start: any;
  end: any;

  show = false;
  load = false;
  options: { title: { display: boolean; text: any; fontSize: number; }; legend: { position: string; }; };

  confid: number;

  allcount = 0;
  isadm = false;

  constructor(
    private analytics: AnalyticsService,
    private translate: TranslateService,
    private cookieService: CookieService,
    private configService: ConfigurationService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private notificationSvc: NotificationService
  ) {
      this.translate.addLangs(['English', 'French']);
      this.translate.setDefaultLang('English');

      let browserLang = this.translate.getBrowserLang();

      const pass = 'ranitessarldev2019';
      const lang = cookieService.get('goepla-portal-language');
      if (lang.trim() !== '') {
        const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
        const lg = declang;
        browserLang = lg;
        localStorage.setItem('language', lg);
      }

      if (localStorage.getItem('language') !== null) {
        browserLang = localStorage.getItem('language');
      }
      this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

      if (localStorage.getItem('filter-start') !== null && localStorage.getItem('filter-end') !== null) {
        const st = localStorage.getItem('filter-start');
        const ed = localStorage.getItem('filter-end');
        this.range = [new Date(st), new Date(ed)];
      }

      if (localStorage.getItem('filter-config') !== null) {
        this.confid = +localStorage.getItem('filter-config');
      }

      // tslint:disable-next-line: deprecation
      translate.get('GENERAL.ALL').subscribe((trans) => {
        const conf: any = [];
        conf.configurationId = 0;
        conf.configurationName = trans;
        this.configs1.push({ label: trans, value: conf });
        if (this.confid === 0) {
          this.conf1 = conf;
        }
      });

      const v = new Variables();
      const p = localStorage.getItem('ups');
      if (p == null) {
        this.isadm = false;
      }

      const pr = CryptoJS.AES.decrypt(p, v.pass).toString(CryptoJS.enc.Utf8);
      const perm = JSON.parse(pr);
      if (perm.includes('NTM.Administration.Full')) {
            this.isadm = true;
      }

      this.getConfigurations();
 }

 getConfigurations() {
  this.load = true;
  // tslint:disable-next-line: deprecation
  this.configService.getConfigurations('T').subscribe((res) => {
    if (res != null) {
      const v = new Variables();
      const gis = localStorage.getItem('gi');
      const gs = CryptoJS.AES.decrypt(gis, v.pass).toString(CryptoJS.enc.Utf8);
      const gids = JSON.parse(gs);

      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < res.length; i++) {
        if (res[i].typeRoleGroups !== null && res[i].typeRoleGroups.length > 0) {
          let show = false;
          for (const val of res[i].typeRoleGroups) {
            if (val.typeRoleGroup.roleName === 'Analytics' && gids.includes(val.typeRoleGroup.groupId)) {
              show = true;
            }
          }

          if (this.isadm === true) {
            show = true;
          }

          if (show === false) {
            continue;
          }
        } else {
          if (this.isadm === true) {
          } else {
            continue;
          }
        }
        this.configs1.push({ label: res[i].configurationName, value: res[i] });
        if (this.confid >= 0) {
          if (res[i].configurationId === this.confid) {
            this.conf1 = res[i];
          }
        }
      }
    }
    this.load = false;
  });
 }

  getAnalytics() {
    if (this.configs1 === undefined || this.configs1 === null || this.configs1.length === 1) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.NODATAAVAILABLE').subscribe((resp: string) => {
        this.notificationSvc.info('', resp, 2000);
        });
      return;
    }
    let tids = '';
    if (this.conf1.configurationId === 0) {
      if (this.allcount + 1 !== this.configs1.length) {
        for (const val of this.configs1) {
          if (val.value.configurationId !== 0) {
            tids += val.value.configurationId + ',';
          }
        }
      }
    }
    if (tids === '') {
      tids = ' ';
    }
    this.data = [];
    let mins = '';
    let max = '';
    let min = '';
    let avg = '';
    let others = '';
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.MINUTES').subscribe((trans) => {
      mins = trans;
    });
    this.show = false;
    this.load = true;
    this.analytics.getAnalyticsGeneric(this.conf1.configurationId, 'T', this.start, this.end, 0, 'timetoclosure', ' ', tids)
    // tslint:disable-next-line: deprecation
                  .subscribe((res) => {
      const labs: string[] = [];
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.OTHERS').subscribe((trans) => {
        others = trans;
      });
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.MIN').subscribe((trans) => {
        min = trans;
      });
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.MAX').subscribe((trans) => {
        max = trans;
      });
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.AVERAGE').subscribe((trans) => {
        avg = trans;
      });
      const datasets: number[] = [];
      const datasets2: number[] = [];
      const datasets3: number[] = [];
      const datasets4: number[] = [];
      let count = 0;

      let prevh = 0;
      let prevl = 0;
      let tot = 0;

      if (res != null) {
        for (const val of res) {
          labs.push(val.value1);
          datasets.push(val.value2);
          /*datasets2.push(1);
          datasets3.push(30);
          datasets4.push(60);*/

          if (val.value2 > prevh) {
            prevh = val.value2;
          }
          if (val.value2 < prevl) {
            prevl = val.value2;
          }

          tot += val.value2;
          count += 1;

          if (count === res.length) {
            const av = Math.round(tot / res.length);
            count = 0;
            for (const val2 of res) {
              datasets2.push(prevl);
              datasets3.push(av);
              datasets4.push(prevh);
              count += 1;

              if (count === res.length) {
                datasets.push(prevl);
                datasets2.push(prevl);
                datasets3.push(av);
                datasets4.push(prevh);
                labs.push(others);
                this.data = {
                  labels: labs,
                  datasets: [
                      {
                          label: mins,
                          backgroundColor: '#42A5F5',
                          borderColor: '#1E88E5',
                          data: datasets,
                          fill: false,
                      },
                      {
                          label: min,
                          backgroundColor: 'Green',
                          borderColor: 'Green',
                          data: datasets2,
                          fill: false,
                      },
                      {
                          label: avg,
                          backgroundColor: 'Orange',
                          borderColor: 'Orange',
                          data: datasets3,
                          fill: false,
                      },
                      {
                          label: max,
                          backgroundColor: 'Red',
                          borderColor: 'Red',
                          data: datasets4,
                          fill: false,
                      }
                  ]
                };
                // tslint:disable-next-line: deprecation
                this.translate.get('GENERAL.TIMETOCLOSUREVALUES').subscribe((trans) => {
                  this.options = {
                    title: {
                        display: true,
                        text: trans,
                        fontSize: 16
                    },
                    legend: {
                        position: 'top'
                    }
                  };
                });
                this.show = true;
              }
            }
          }
        }
      } else {
        this.show = false;
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.NODATAAVAILABLE').subscribe((resp: string) => {
          this.notificationSvc.info('', resp, 2000);
          });
      }
      this.load = false;
    }, err => this.handleError(err));
  }

  ok() {
    this.data = [];
    if (this.range === undefined || this.range === null) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTRANGE').subscribe((res) => {
        this.confirm(res);
      });
      return;
    } else {
      if (this.range[0] !== undefined && this.range[0] !== null) {
        this.start = this.range[0].toDateString();
        const a = this.range[0].toDateString();
        const b = new Date(a);
        const c = new Date(b.getFullYear(), b.getMonth(), b.getDate());
        this.start = c.toDateString();
      } else {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.SELECTRANGE').subscribe((res) => {
          this.confirm(res);
        });
        return;
      }
      if (this.range[1] !== undefined && this.range[1] !== null) {
        this.end = this.range[1].toDateString();
        const a = this.range[1].toDateString();
        const b = new Date(a);
        const c = new Date(b.getFullYear(), b.getMonth(), b.getDate());
        this.end = c.toDateString();
      } else {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.SELECTRANGE').subscribe((res) => {
          this.confirm(res);
        });
        return;
      }

      if (this.conf1 === undefined || this.conf1 === null) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.SELECTCONFIGURATION').subscribe((res) => {
          this.confirm(res);
        });
        return;
      }
      localStorage.setItem('filter-start', this.start);
      localStorage.setItem('filter-end', this.end);
      localStorage.setItem('filter-config', this.conf1.configurationId);
      this.getAnalytics();
    }
   }

  ngOnInit() {
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  showInfo() {
    this.messageService.add({severity: 'info', summary: 'Info Message', detail: 'PrimeNG rocks'});
  }

  handleError(err) {
    this.load = false;
    console.log(err);
  }

  setDate() {
    const d = new Date();
    const d1 = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 1);
    const d2 = new Date(d.getFullYear(), d.getMonth(), d.getDate() + 1);

    this.range = [d1, d2];
  }

}
