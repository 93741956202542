export class Recipient {
    constructor() {}

    public NotificationId: string;
    public ContactId: number;
    public RecipientName: string;
    public RecipientInfo: string;
    public Phone1: string;
    public Phone2: string;
}
