import { Int64Updater, KeyValue } from 'src/app/Models/Updater';
import { ItemDefService } from './../../services/item-def/item-def.service';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Variables } from 'src/app/Models/variables';
import { ItemDefinition } from 'src/app/Models/item-definition';
import { DataCache } from 'src/app/Helpers/data-cache';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-item-definition',
  templateUrl: './item-definition.component.html',
  styleUrls: ['./item-definition.component.css']
})
export class ItemDefinitionComponent implements OnInit {

  items = [];
  item: any;

  itemname: any;
  itemprice: any;
  status = 'enabled';

  itemnameerror = false;
  itempriceerror = false;

  page = 1;
  size = 100;

  loading = false;
  loadmore = false;

  isadditem = false;
  isedititem = false;

  ismanage = false;

  constructor(
    private itemdefService: ItemDefService,
    private messageService: MessageService,
    public translate: TranslateService,
    private http: HttpClient
    ) {
      translate.use('English');

      const v = new Variables();
      const p = localStorage.getItem('ups');
      if (p == null) {
        this.ismanage = false;
      }

      const pr = CryptoJS.AES.decrypt(p, v.pass).toString(CryptoJS.enc.Utf8);
      const perm = JSON.parse(pr);
      if (perm.includes('NTM.Administration.ItemDefinitions')
          || perm.includes('NTM.Administration.Full')) {
            this.ismanage = true;
      } else {
        this.ismanage = false;
      }
   }

  ngOnInit() {
    this.getItems();
  }

  async getItems() {
    this.loading = true;
    this.showLoad();
    const its = await this.itemdefService.getItems(this.page, this.size, 0).toPromise()
    .catch(err => this.handleError(err));
    const ls = [];
    if (its !== undefined && its !== null && its.length > 0) {
      if (its.length >= this.size) {
        this.loadmore = true;
      } else {
        this.loadmore = false;
      }

      for (const val of its) {
        val.bcolor = 'white';

        const options = [];

        this.translate.get('GENERAL.EDIT').subscribe((resp: string) => {
          options.push({label: resp, value: 'edit',
           command: async (event) => { await this.manageItem(event, val); }});
        });
        if (val.enabled === 'Y') {
          this.translate.get('GENERAL.DISABLE').subscribe((resp: string) => {
            options.push({label: resp + ' ', value: 'disable',
             command: async (event) => { await this.manageItem(event, val); }});
          });
        } else {
          this.translate.get('GENERAL.ENABLE').subscribe((resp: string) => {
            options.push({label: resp + ' ', value: 'enable',
             command: async (event) => { await this.manageItem(event, val); }});
          });
        }
        this.translate.get('GENERAL.DELETE').subscribe((resp: string) => {
          options.push({label: resp, value: 'delete',
           command: async (event) => { await this.manageItem(event, val); }});
        });

        val.options = options;
        if (this.page > 1) {
          this.items.push(val);
        } else {
          ls.push(val);
        }
      }
    } else {
      this.loadmore = false;
    }

    if (this.page === 1) {
      this.items = ls;
    }

    this.hideLoad();
    this.loading = false;
  }

  async manageItem(event, value) {
    this.item = value;
    if (event.item.value === 'edit') {
      this.itemname = value.itemName;
      this.itemprice = value.itemPrice;
      if (value.enabled === 'Y') {
        this.status = 'enabled';
      } else {
        this.status = 'disabled';
      }

      this.isedititem = true;
    } else if (event.item.value === 'enable' || event.item.value === 'disable') {
      await this.enableDisable(event.item.value);
    } else if (event.item.value === 'delete') {
      this.showConfirmDeleteItem(value);
    }
  }

  async enableDisable(stat) {
    const val = new Int64Updater();
    val.Id = this.item.itemId;
    val.Values = [];

    const status = new KeyValue();
    status.Key = 'enabled';
    if (stat === 'enable') {
      status.Value = 'Y';
    } else {
      status.Value = 'N';
    }
    val.Values.push(status);

    this.loading = true;
    this.showLoad();
    const res = await this.itemdefService.putItem(val).toPromise()
    .catch(err => this.handleError(err));

    if (res !== undefined && res !== null && res === true) {
      // success
      const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch();
      let msg = '';
      if (stat === 'enabled') {
        msg = await this.translate.get('GENERAL.ITEMENABLED').toPromise().catch();
      } else {
        msg = await this.translate.get('GENERAL.ITEMDISABLED').toPromise().catch();
      }
      this.showSuccess(title, msg);

      this.size = this.size * this.page;
      this.page = 1;

      this.isadditem = false;
      await this.getItems();

      DataCache.loadData(this.http);
    } else {
      // failure
      // const title = await this.translate.get('GENERAL.ERROR').toPromise().catch();
      // const msg = await this.translate.get('GENERAL.LOCATIONNOTCREATED').toPromise().catch();
      // this.showError(title, msg, 10000);
    }

    this.hideLoad();
    this.loading = false;
  }

  async save() {
    let issave = true;
    this.itemnameerror = false;
    this.itempriceerror = false;
    if (this.itemname === undefined || this.itemname === null || this.itemname.trim() === '') {
      issave = false;
      this.itemnameerror = true;
    }
    if (this.itemprice === undefined || this.itemprice === null) {
      issave = false;
      this.itempriceerror = true;
    }

    if (issave === true) {
      const val = new Int64Updater();
      val.Id = this.item.itemId;
      val.Values = [];

      const name = new KeyValue();
      name.Key = 'name';
      name.Value = this.itemname;
      val.Values.push(name);

      const price = new KeyValue();
      price.Key = 'price';
      price.Value = this.itemprice;
      val.Values.push(price);

      const status = new KeyValue();
      status.Key = 'enabled';
      if (this.status === 'enabled') {
        status.Value = 'Y';
      } else {
        status.Value = 'N';
      }
      val.Values.push(status);

      this.loading = true;
      this.showLoad();
      const res = await this.itemdefService.putItem(val).toPromise()
      .catch(err => this.handleError(err));

      if (res !== undefined && res !== null && res === true) {
        // success
        const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch();
        const msg = await this.translate.get('GENERAL.ITEMUPDATED').toPromise().catch();
        this.showSuccess(title, msg);

        this.size = this.size * this.page;
        this.page = 1;

        this.isedititem = false;
        await this.getItems();

        DataCache.loadData(this.http);
      } else {
        // failure
        // const title = await this.translate.get('GENERAL.ERROR').toPromise().catch();
        // const msg = await this.translate.get('GENERAL.LOCATIONNOTCREATED').toPromise().catch();
        // this.showError(title, msg, 10000);
      }

      this.hideLoad();
      this.loading = false;
    }
  }

  async create() {
    let iscreate = true;
    this.itemnameerror = false;
    this.itempriceerror = false;
    if (this.itemname === undefined || this.itemname === null || this.itemname.trim() === '') {
      iscreate = false;
      this.itemnameerror = true;
    }
    if (this.itemprice === undefined || this.itemprice === null) {
      iscreate = false;
      this.itempriceerror = true;
    }

    if (iscreate === true) {
      const item = new ItemDefinition();
      item.ItemName = this.itemname;
      item.ItemPrice = this.itemprice;
      if (this.status === 'enabled') {
        item.Enabled = 'Y';
      } else {
        item.Enabled = 'N';
      }

      this.loading = true;
      this.showLoad();
      const res = await this.itemdefService.postItem(item).toPromise()
      .catch(err => this.handleError(err));

      if (res !== undefined && res !== null && res > 0) {
        // success
        const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch();
        const msg = await this.translate.get('GENERAL.ITEMCREATED').toPromise().catch();
        this.showSuccess(title, msg);

        this.size = this.size * this.page;
        this.page = 1;

        this.isadditem = false;
        await this.getItems();

        DataCache.loadData(this.http);
      } else {
        // failure
        // const title = await this.translate.get('GENERAL.ERROR').toPromise().catch();
        // const msg = await this.translate.get('GENERAL.LOCATIONNOTCREATED').toPromise().catch();
        // this.showError(title, msg, 10000);
      }

      this.hideLoad();
      this.loading = false;
    }

  }

  itemClicked(item) {
    const vs = [];
    for (const val of this.items) {
      const v = val;
      if (val.itemId === item.itemId) {
        v.bcolor = 'lightblue';
      } else {
        v.bcolor = 'white';
      }

      vs.push(v);
    }

    this.items = vs;
  }

  loadMore() {
    this.page += 1;
    this.getItems();
  }

  showAddItem() {
    this.itemname = null;
    this.itemprice = null;
    this.itemnameerror = false;
    this.itempriceerror = false;
    this.status = 'enabled';

    this.isadditem = true;
  }

  cancel() {
    this.itemname = null;
    this.itemprice = null;
    this.itemnameerror = false;
    this.itempriceerror = false;

    this.isadditem = false;
    this.isedititem = false;
  }

  async showConfirmDeleteItem(value) {
    this.item = value;
    const title = await this.translate.get('GENERAL.DELETE').toPromise().catch(err => console.log(err));
    this.showConfirm(title + ' ' + value.itemName + '?',
    '', 'deleteitem');
  }

  async deleteItem() {
    this.loading = true;
    this.showLoad();
    const res = await this.itemdefService.deleteItem(this.item.itemId).toPromise()
    .catch(err => this.handleError(err));

    if (res !== undefined && res !== null && res === true) {
      // success
      const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch();
      const msg = await this.translate.get('GENERAL.ITEMDELETED').toPromise().catch();
      this.showSuccess(title, msg);

      this.onRejectDeleteItem();

      this.size = this.size * this.page;
      this.page = 1;

      await this.getItems();

      DataCache.loadData(this.http);
    } else {
      // failure
      // const title = await this.translate.get('GENERAL.ERROR').toPromise().catch();
      // const msg = await this.translate.get('GENERAL.ITEMNOTDELETED').toPromise().catch();
      // this.showError(title, msg, 10000);
    }

    this.hideLoad();
    this.loading = false;
  }

  onRejectDeleteItem() {
    this.item = null;
    this.messageService.clear('deleteitem');
  }

  showLoad(): any {
    this.loading = true;
    this.showConfirm('', '', 'loader', '');
  }

  hideLoad(): any {
    this.loading = false;
    this.messageService.clear('loader');
  }

  showSuccess(title: string, details: string, duration: number = 3000): any {
    this.messageService.add({severity: 'success', summary: title, detail: details, key: 'tc', life: duration});
  }

  showInfo(title: string, details: string, duration: number = 3000): any {
      this.messageService.add({severity: 'info', summary: title, detail: details, key: 'tc', life: duration});
  }

  showWarn(title: string, details: string, duration: number = 3000): any {
      this.messageService.add({severity: 'warn', summary: title, detail: details, key: 'tc', life: duration});
  }

  showError(title: string, details: string, duration: number = 3000): any {
      this.messageService.add({severity: 'error', summary: title, detail: details, key: 'tc', life: duration});
  }

  showConfirm(title: string, details: string, keyvalue: string, severe: string = 'warn'): any {
    // this.messageService.clear();
    this.messageService.add({key: keyvalue, sticky: true, severity: severe, summary: title, detail: details});
  }

  async handleError(err) {
    this.loading = false;
    if (err !== undefined && err !== null) {
      if (err.error !== undefined && err.error !== null) {
        if (err.error.MessageText === 'duplicate key value violates unique constraint "item_def_unique"'
        || err.error.Message === '23505: duplicate key value violates unique constraint "item_def_unique"') {
          const title = await this.translate.get('GENERAL.INFO').toPromise().catch();
          const msg = await this.translate.get('GENERAL.ITEMEXISTS').toPromise().catch();
          this.showWarn(title, msg, 10000);
        // tslint:disable-next-line: max-line-length
        } else if (err.error.Message === '23503: update or delete on table "t_item_def" violates foreign key constraint "t_cat_item_item_id_fkey" on table "t_cat_item"') {
          const title = await this.translate.get('GENERAL.INFO').toPromise().catch();
          const msg = await this.translate.get('GENERAL.ITEMBELONGSTOORDER').toPromise().catch();
          this.showWarn(title, msg, 10000);
        } else {
          console.log(err);
        }
      }
    }
  }

}
