import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import * as CryptoJS from 'crypto-js';
import { Variables } from 'src/app/Models/variables';

@Component({
  selector: 'app-analytics-dist-time-to-read-conf',
  templateUrl: './analytics-dist-time-to-read-conf.component.html',
  styleUrls: ['./analytics-dist-time-to-read-conf.component.css']
})
export class AnalyticsDistTimeToReadConfComponent implements OnInit {

  data: any;
  data2: any;
  range: any;
  start: any;
  end: any;
  show = false;
  load = false;

  configs1 = [];
  conf1: any;
  options: { title: { display: boolean; text: string; fontSize: number; }; legend: { position: string; }; };
  confid: number;
  options2: { title: { display: boolean; text: string; fontSize: number; }; legend: { position: string; }; };

  percents = [];
  perc: any;

  nodata = false;

  ishour = true;
  isday = false;

  xlabel = '';

  generesp: any;

  allcount = 0;
  isadm = false;

  constructor(
    private analytics: AnalyticsService,
    private translate: TranslateService,
    private cookieService: CookieService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private notificationSvc: NotificationService,
    private configService: ConfigurationService
  ) {
      this.translate.addLangs(['English', 'French']);
      this.translate.setDefaultLang('English');

      let browserLang = this.translate.getBrowserLang();

      const pass = 'ranitessarldev2019';
      const lang = cookieService.get('goepla-portal-language');
      if (lang.trim() !== '') {
        const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
        const lg = declang;
        browserLang = lg;
        localStorage.setItem('language', lg);
      }

      if (localStorage.getItem('language') !== null) {
        browserLang = localStorage.getItem('language');
      }
      this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

      if (localStorage.getItem('filter-start') !== null && localStorage.getItem('filter-end') !== null) {
        const st = localStorage.getItem('filter-start');
        const ed = localStorage.getItem('filter-end');
        this.range = [new Date(st), new Date(ed)];
      }

      if (localStorage.getItem('filter-config') !== null) {
        this.confid = +localStorage.getItem('filter-config');
      }

      // tslint:disable-next-line: deprecation
      translate.get('GENERAL.ALL').subscribe((trans) => {
        const conf: any = [];
        conf.configurationId = 0;
        conf.configurationName = trans;
        this.configs1.push({ label: trans, value: conf });
        if (this.confid === 0) {
          this.conf1 = conf;
        }
      });

      for (let i = 1; i <= 100; i++) {
        this.percents.push({ label: i + '%', value: i });
      }

      const v = new Variables();
      const p = localStorage.getItem('ups');
      if (p == null) {
        this.isadm = false;
      }

      const pr = CryptoJS.AES.decrypt(p, v.pass).toString(CryptoJS.enc.Utf8);
      const perm = JSON.parse(pr);
      if (perm.includes('NTM.Administration.Full')) {
            this.isadm = true;
      }

      this.getConfigurations();
   }

   getConfigurations() {
    this.load = true;
    // tslint:disable-next-line: deprecation
    this.configService.getConfigurations('I').subscribe((res) => {
      if (res != null) {
        const v = new Variables();
        const gis = localStorage.getItem('gi');
        const gs = CryptoJS.AES.decrypt(gis, v.pass).toString(CryptoJS.enc.Utf8);
        const gids = JSON.parse(gs);

        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          if (res[i].typeRoleGroups !== null && res[i].typeRoleGroups.length > 0) {
            let show = false;
            for (const val of res[i].typeRoleGroups) {
              if (val.typeRoleGroup.roleName === 'Analytics' && gids.includes(val.typeRoleGroup.groupId)) {
                show = true;
              }
            }

            if (this.isadm === true) {
              show = true;
            }

            if (show === false) {
              continue;
            }
          } else {
            if (this.isadm === true) {
            } else {
              continue;
            }
          }
          this.configs1.push({ label: res[i].configurationName, value: res[i] });
          if (this.confid >= 0) {
            if (res[i].configurationId === this.confid) {
              this.conf1 = res[i];
            }
          }
        }
      }
      this.load = false;
    });
  }

   async getAnalytics() {
    if (this.configs1 === undefined || this.configs1 === null || this.configs1.length === 1) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.NODATAAVAILABLE').subscribe((resp22: string) => {
        this.notificationSvc.info('', resp22, 2000);
        });
      return;
    }
    let tids = '';
    if (this.conf1.configurationId === 0) {
      if (this.allcount + 1 !== this.configs1.length) {
        for (const val of this.configs1) {
          if (val.value.configurationId !== 0) {
            tids += val.value.configurationId + ',';
          }
        }
      }
    }
    if (tids === '') {
      tids = ' ';
    }
    this.nodata = false;

    this.show = false;
    this.load = true;
    const resp = await this.analytics.getAnalytics_DistTimeToRead2(this.conf1.configurationId, this.perc, tids)
    .toPromise().catch(err => this.handleError(err));

    this.generesp = resp;
    if (this.ishour === true) {
      await this.showHours(resp);
    } else if (this.isday === true) {
      await this.showDays(resp);
    }

    this.load = false;
   }

   async showHours(resp: any) {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.HOUR2').subscribe((trans) => {
      this.xlabel = trans;
    });

    let amttsks = '';
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.AMTINFO').subscribe((trans) => {
      amttsks = trans;
    });
    let hl1 = 0;
    let h1to2 = 0;
    let h3to5 = 0;
    let h6to10 = 0;
    let h11to15 = 0;
    let h16to20 = 0;
    let h21to25 = 0;
    let h26to48 = 0;
    let h49to72 = 0;
    let hg72 = 0;

    let hl1p = 0;
    let h1to2p = 0;
    let h3to5p = 0;
    let h6to10p = 0;
    let h11to15p = 0;
    let h16to20p = 0;
    let h21to25p = 0;
    let h26to48p = 0;
    let h49to72p = 0;
    let hg72p = 0;

    let count = 0;
    let htotal = 0;
    if (resp != null && resp.value1 !== null && resp.value1.length > 0) {
      for (const res of resp.value1) {
        if (res.value2 === 'under_1') {
          hl1 = res.value1;
          htotal += res.value1;
        } else if (res.value2 === 'one_to_two') {
          h1to2 = res.value1;
          htotal += res.value1;
        } else if (res.value2 === 'three_to_five') {
          h3to5 = res.value1;
          htotal += res.value1;
        } else if (res.value2 === 'six_to_ten') {
          h6to10 = res.value1;
          htotal += res.value1;
        } else if (res.value2 === 'eleven_to_fifteen') {
          h11to15 = res.value1;
          htotal += res.value1;
        } else if (res.value2 === 'sixteen_to_twenty') {
          h16to20 = res.value1;
          htotal += res.value1;
        } else if (res.value2 === 'twentyone_to_twentyfive') {
          h21to25 = res.value1;
          htotal += res.value1;
        } else if (res.value2 === 'twentysix_to_fortyeight') {
          h26to48 = res.value1;
          htotal += res.value1;
        } else if (res.value2 === 'fortynine_to_seventytwo') {
          h49to72 = res.value1;
          htotal += res.value1;
        } else if (res.value2 === 'above_72') {
          hg72 = res.value1;
          htotal += res.value1;
        }
        count += 1;
        if (count === resp.value1.length) {
          hl1p = Math.round((((hl1 / htotal) * 100) + Number.EPSILON) * 100) / 100;
          h1to2p = Math.round((((h1to2 / htotal) * 100) + Number.EPSILON) * 100) / 100;
          h3to5p = Math.round((((h3to5 / htotal) * 100) + Number.EPSILON) * 100) / 100;
          h6to10p = Math.round((((h6to10 / htotal) * 100) + Number.EPSILON) * 100) / 100;
          h11to15p = Math.round((((h11to15 / htotal) * 100) + Number.EPSILON) * 100) / 100;
          h16to20p = Math.round((((h16to20 / htotal) * 100) + Number.EPSILON) * 100) / 100;
          h21to25p = Math.round((((h21to25 / htotal) * 100) + Number.EPSILON) * 100) / 100;
          h26to48p = Math.round((((h26to48 / htotal) * 100) + Number.EPSILON) * 100) / 100;
          h49to72p = Math.round((((h49to72 / htotal) * 100) + Number.EPSILON) * 100) / 100;
          hg72p = Math.round((((hg72 / htotal) * 100) + Number.EPSILON) * 100) / 100;
          this.data = {
            labels: ['<1', '1-2', '3-5', '6-10', '11-15', '16-20', '21-25', '26-48', '49-72', '>72'],
            datasets: [
                {
                    label: amttsks,
                    backgroundColor: '#42A5F5',
                    borderColor: '#1E88E5',
                    data: [hl1, h1to2, h3to5, h6to10, h11to15, h16to20, h21to25, h26to48, h49to72, hg72]
                }
            ]
          };
          this.data2 = {
            labels: ['<1', '1-2', '3-5', '6-10', '11-15', '16-20', '21-25', '26-48', '49-72', '>72'],
            datasets: [
                {
                    // data: [hl1p, h1to2p, h3to5p, h6to10p, h11to15p, h16to20p, h21to25p, h26to48p, h49to72p, hg72p],
                    data: [hl1, h1to2, h3to5, h6to10, h11to15, h16to20, h21to25, h26to48, h49to72, hg72],
                    backgroundColor: [
                        'LightBlue',
                        'Orange',
                        'LightGray',
                        'Yellow',
                        'Blue',
                        'DarkGreen',
                        'DarkBlue',
                        'LightGreen',
                        '#58508d',
                        '#bc5090'
                    ],
                    hoverBackgroundColor: [
                      'LightBlue',
                      'Orange',
                      'LightGray',
                      'Yellow',
                      'Blue',
                      'DarkGreen',
                      'DarkBlue',
                      'LightGreen',
                      '#58508d',
                      '#bc5090'
                    ]
                }]
            };
            // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.DISTTIMETOREAD').subscribe((trans) => {
            this.options = {
              title: {
                  display: false,
                  text: trans,
                  fontSize: 16
              },
              legend: {
                  position: 'top'
              }
            };
          });

    // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.DISTTIMETOREAD').subscribe((trans) => {
            this.options2 = {
              title: {
                  display: false,
                  text: trans + '(%)',
                  fontSize: 16
              },
              legend: {
                  position: 'top'
              }
            };
          });

          this.show = true;
        }
      }
    } else {
      this.nodata = true;
      this.show = false;
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.NODATAAVAILABLE').subscribe((res: string) => {
        // this.notificationSvc.info('', res, 2000);
        });
    }
   }

   async showDays(resp: any) {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.DAY').subscribe((trans) => {
      this.xlabel = trans;
    });

    let amttsks = '';
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.AMTINFO').subscribe((trans) => {
      amttsks = trans;
    });

    let dl1 = 0;
    let d1 = 0;
    let d2 = 0;
    let d3 = 0;
    let d4 = 0;
    let d5 = 0;
    let d6 = 0;
    let d7 = 0;
    let d8to10 = 0;
    let d11to15 = 0;
    let d16to20 = 0;
    let d21to30 = 0;
    let dg30 = 0;

    let dl1p = 0;
    let d1p = 0;
    let d2p = 0;
    let d3p = 0;
    let d4p = 0;
    let d5p = 0;
    let d6p = 0;
    let d7p = 0;
    let d8to10p = 0;
    let d11to15p = 0;
    let d16to20p = 0;
    let d21to30p = 0;
    let dg30p = 0;

    let count = 0;
    let dtotal = 0;
    if (resp != null && resp.value2 !== null && resp.value2.length > 0) {
      for (const res of resp.value2) {
        if (res.value2 === 'under_1') {
          dl1 = res.value1;
          dtotal += res.value1;
        } else if (res.value2 === 'one') {
          d1 = res.value1;
          dtotal += res.value1;
        } else if (res.value2 === 'two') {
          d2 = res.value1;
          dtotal += res.value1;
        } else if (res.value2 === 'three') {
          d3 = res.value1;
          dtotal += res.value1;
        } else if (res.value2 === 'four') {
          d4 = res.value1;
          dtotal += res.value1;
        } else if (res.value2 === 'five') {
          d5 = res.value1;
          dtotal += res.value1;
        } else if (res.value2 === 'six') {
          d6 = res.value1;
          dtotal += res.value1;
        } else if (res.value2 === 'seven') {
          d7 = res.value1;
          dtotal += res.value1;
        } else if (res.value2 === 'eight_to_ten') {
          d8to10 = res.value1;
          dtotal += res.value1;
        } else if (res.value2 === 'eleven_to_fifteen') {
          d11to15 = res.value1;
          dtotal += res.value1;
        } else if (res.value2 === 'sixteen_to_twenty') {
          d16to20 = res.value1;
          dtotal += res.value1;
        } else if (res.value2 === 'twentyone_to_thirty') {
          d21to30 = res.value1;
          dtotal += res.value1;
        } else if (res.value2 === 'above_30') {
          dg30 = res.value1;
          dtotal += res.value1;
        }
        count += 1;
        if (count === resp.value2.length) {
          dl1p = Math.round((((dl1 / dtotal) * 100) + Number.EPSILON) * 100) / 100;
          d1p = Math.round((((d1 / dtotal) * 100) + Number.EPSILON) * 100) / 100;
          d2p = Math.round((((d2 / dtotal) * 100) + Number.EPSILON) * 100) / 100;
          d3p = Math.round((((d3 / dtotal) * 100) + Number.EPSILON) * 100) / 100;
          d4p = Math.round((((d4 / dtotal) * 100) + Number.EPSILON) * 100) / 100;
          d5p = Math.round((((d5 / dtotal) * 100) + Number.EPSILON) * 100) / 100;
          d6p = Math.round((((d6 / dtotal) * 100) + Number.EPSILON) * 100) / 100;
          d7p = Math.round((((d7 / dtotal) * 100) + Number.EPSILON) * 100) / 100;
          d8to10p = Math.round((((d8to10 / dtotal) * 100) + Number.EPSILON) * 100) / 100;
          d11to15p = Math.round((((d11to15 / dtotal) * 100) + Number.EPSILON) * 100) / 100;
          d16to20p = Math.round((((d16to20 / dtotal) * 100) + Number.EPSILON) * 100) / 100;
          d21to30p = Math.round((((d21to30 / dtotal) * 100) + Number.EPSILON) * 100) / 100;
          dg30p = Math.round((((dg30 / dtotal) * 100) + Number.EPSILON) * 100) / 100;
          this.data = {
            labels: ['<1', '1', '2', '3', '4', '5', '6', '7', '8-10', '11-15', '16-20', '21-30', '>30'],
            datasets: [
                {
                    label: amttsks,
                    backgroundColor: '#42A5F5',
                    borderColor: '#1E88E5',
                    data: [dl1, d1, d2, d3, d4, d5, d6, d7, d8to10, d11to15, d16to20, d21to30, dg30]
                }
            ]
          };
          this.data2 = {
            labels: ['<1', '1', '2', '3', '4', '5', '6', '7', '8-10', '11-15', '16-20', '21-30', '>30'],
            datasets: [
                {
                    // data: [dl1p, d1p, d2p, d3p, d4p, d5p, d6p, d7p, d8to10p, d11to15p, d16to20p, d21to30p, dg30p],
                    data: [dl1, d1, d2, d3, d4, d5, d6, d7, d8to10, d11to15, d16to20, d21to30, dg30],
                    backgroundColor: [
                        'LightBlue',
                        'Orange',
                        'LightGray',
                        'Yellow',
                        'Blue',
                        'DarkGreen',
                        'DarkBlue',
                        'LightGreen',
                        '#58508d',
                        '#bc5090',
                        '#66FFFF',
                        '#663300',
                        '#1AB399'
                    ],
                    hoverBackgroundColor: [
                      'LightBlue',
                      'Orange',
                      'LightGray',
                      'Yellow',
                      'Blue',
                      'DarkGreen',
                      'DarkBlue',
                      'LightGreen',
                      '#58508d',
                      '#bc5090',
                      '#66FFFF',
                      '#663300',
                      '#1AB399'
                    ]
                }]
            };
            // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.DISTTIMETOREAD').subscribe((trans) => {
            this.options = {
              title: {
                  display: false,
                  text: trans,
                  fontSize: 16
              },
              legend: {
                  position: 'top'
              }
            };
          });

    // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.DISTTIMETOREAD').subscribe((trans) => {
            this.options2 = {
              title: {
                  display: false,
                  text: trans + '(%)',
                  fontSize: 16
              },
              legend: {
                  position: 'top'
              }
            };
          });

          this.show = true;
        }
      }
    } else {
      this.nodata = true;
      this.show = false;
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.NODATAAVAILABLE').subscribe((res: string) => {
        // this.notificationSvc.info('', res, 2000);
        });
    }
   }

   ok() {
    if (this.conf1 === undefined || this.conf1 === null) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTINFORMATIONCONFIGURATION').subscribe((res) => {
        this.confirm(res);
      });
      return;
    }
    if (this.perc === undefined || this.perc === null) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTVALUE').subscribe((res) => {
        this.confirm(res);
      });
      return;
    }
    this.data = [];
    this.data2 = [];
    localStorage.setItem('filter-config', this.conf1.configurationId);
    this.getAnalytics();
   }

   showHour() {
     this.showHours(this.generesp);
     this.isday = false;
     this.ishour = true;
   }

   showDay() {
     this.showDays(this.generesp);
     this.isday = true;
     this.ishour = false;
   }

  ngOnInit() {
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  showInfo() {
    this.messageService.add({severity: 'info', summary: 'Info Message', detail: 'PrimeNG rocks'});
  }

  handleError(err) {
    this.load = false;
    console.log(err);
  }

  setDate() {
    const d = new Date();
    const d1 = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 1);
    const d2 = new Date(d.getFullYear(), d.getMonth(), d.getDate() + 1);

    this.range = [d1, d2];
  }

}
