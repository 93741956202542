import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Urls } from 'src/app/Helpers/Urls';
import { Variables } from 'src/app/Models/variables';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  url = new Urls();
  apiUrl: string;
  orgId: number;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = this.url.apiUrl;

      const v = new Variables();
      const val = localStorage.getItem('oi');
      const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);

      this.orgId = +oi;
      // this.orgId = +localStorage.getItem('organisation-id');
  }

  getAnalytics(confId: number, type: string, start: string, end: string, uid: number, key: string, time: string) {
        const v = new Variables();
        const val = localStorage.getItem('oi');
        const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);

        this.orgId = +oi;
        // tslint:disable-next-line: max-line-length
        const url = this.apiUrl + '/api/analytics/' + this.orgId + '/' + confId + '/' + type + '/'
            + start + '/' + end + '/' + uid + '/' + key + '/' + time + '/';
        return this.httpclient.get<any>(url)
        .pipe(
        tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  getAnalyticsGeneric(confId: number, type: string, start: string, end: string, uid: number, key: string,
                      time: string, tids: string) {
      const v = new Variables();
      const val = localStorage.getItem('oi');
      const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);

      this.orgId = +oi;
      // tslint:disable-next-line: max-line-length
      const url = this.apiUrl + '/api/analytics/' + this.orgId + '/' + confId + '/' + type + '/'
          + start + '/' + end + '/' + uid + '/' + key + '/' + time + '/' + tids + '/';
      return this.httpclient.get<any>(url)
      .pipe(
      tap( // Log the result or error
      error => this.handleError
    )
  );
  }

  getAnalytics_DistTimeToRead(confId: number, perc: number) {
      const v = new Variables();
      const val = localStorage.getItem('oi');
      const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);

      this.orgId = +oi;
      // tslint:disable-next-line: max-line-length
      const url = this.apiUrl + '/api/analytics/' + this.orgId + '/' + confId + '/' + perc + '/';
      return this.httpclient.get<any>(url)
      .pipe(
      tap( // Log the result or error
      error => this.handleError
    )
  );
  }

  getAnalytics_DistTimeToRead2(confId: number, perc: number, tids: string) {
      const v = new Variables();
      const val = localStorage.getItem('oi');
      const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);

      this.orgId = +oi;
      // tslint:disable-next-line: max-line-length
      const url = this.apiUrl + '/api/analytics/' + this.orgId + '/' + confId + '/' + perc + '/' + tids + '/';
      return this.httpclient.get<any>(url)
      .pipe(
      tap( // Log the result or error
      error => this.handleError
    )
  );
  }

  getDashboards(start: string, end: string, uid: number, status: string, time: string) {
    const v = new Variables();
    const val = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);

    this.orgId = +oi;
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/analytics/' + this.orgId + '/' + start + '/' + end + '/' + uid + '/' + status + '/' + time + '/';
    return this.httpclient.get<any>(url)
        .pipe(
        tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  getDashboards2(type: number, start: string, end: string, uid: number, status: string, time: string) {
    const v = new Variables();
    const val = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);

    this.orgId = +oi;
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/analytics/' + this.orgId + '/' + type + '/' + start + '/' + end + '/' + uid + '/' + status + '/' + time + '/';
    return this.httpclient.get<any>(url)
        .pipe(
        tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  getDashboards3(type: number, start: string, end: string, uid: number, status: string, time: string, tids: string) {
    const v = new Variables();
    const val = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);

    this.orgId = +oi;
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/analytics/useroverview/' + this.orgId + '/' + type + '/' + start + '/' + end + '/' + uid + '/' + status + '/' + time + '/' + tids + '/';
    return this.httpclient.get<any>(url)
        .pipe(
        tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  private handleError(error: any) {
    console.error('error', error);
    throw error;
    return error;
  }
}
