import { Panels } from './../../Models/Panels';
import { Injectable } from '@angular/core';
import { Urls } from 'src/app/Helpers/Urls';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PanelUpdater } from 'src/app/Models/Updater';
import { Variables } from 'src/app/Models/variables';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class PanelsService {
  url = new Urls();
  apiUrl: string;
  orgId: number;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = this.url.apiUrl;
      const v = new Variables();
      const val = localStorage.getItem('oi');
      const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);

      this.orgId = +oi;
      // this.orgId = +localStorage.getItem('organisation-id');
  }

  getPanels(page, size) {
    const v = new Variables();
    const val = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);

    this.orgId = +oi;
    // this.orgId = +localStorage.getItem('organisation-id');
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/panels/' + page + '/' + size + '/' + this.orgId;
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  getPanelId(id: number) {
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/panels/' + id;
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  postPanel(pan: Panels): Observable<any> {
    // const body = JSON.stringify(inst);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.post<any>(this.apiUrl + '/api/panels', pan, options)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  putPanel(id: number, upt: PanelUpdater): Observable<any> {
    // const body = JSON.stringify(inst);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.put<any>(this.apiUrl + '/api/panels/' + id, upt, options)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  deletePanel(id: number): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.delete<any>(this.apiUrl + '/api/panels/' + id, options)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  private handleError(error: any) {
    // console.error('error', error);
    throw error;
    return error;
  }
}
