import { CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Variables } from 'src/app/Models/variables';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class DeviceService implements CanActivate {

constructor() { }

canActivate() {
  const v = new Variables();
  const p = localStorage.getItem('ups');
  if (p == null) {
    return false;
  }
  const val = CryptoJS.AES.decrypt(p, v.pass).toString(CryptoJS.enc.Utf8);
  const perm = JSON.parse(val);
  if (perm.includes('NTM.Section.Devices')
      || perm.includes('NTM.Administration.Full')) {
        return true;
  } else {
    return false;
  }
}

}
